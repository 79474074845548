import React, { useRef, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { FiCheckCircle, FiAlertTriangle, FiXCircle } from 'react-icons/fi';

import { CloseModal, Form } from './styles';
import Modal from '../Modal';

interface IModalProps {
    isOpen: boolean;
    setIsOpen: () => void;
    buttonTitle: string;
    typeIcon?: 'success' | 'info';
}

const icons = {
    info: <FiAlertTriangle size={44} />,
    success: <FiCheckCircle size={44} />,
};

const ModalEdit: React.FC<IModalProps> = ({
    isOpen,
    setIsOpen,
    buttonTitle,
    typeIcon,
}) => {
    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async () => {
            setIsOpen();
        },
        [setIsOpen],
    );

    const handleClose = useCallback(
        async () => {
            setIsOpen();
        },
        [setIsOpen],
    );

    return (
        <Modal isOpen={isOpen} setIsOpen={handleSubmit}>
            <CloseModal onClick={() => handleClose()}>
                <FiXCircle size={24} />
            </CloseModal>

            <Form ref={formRef} onSubmit={handleSubmit} type={typeIcon}>
                {icons[typeIcon || 'success']}

                <h1>Agendamento de</h1>
                <h1>horário indisponível</h1>

                <p>Por gentileza escolher um novo horário.</p>

                <button>
                    <div className="text">{buttonTitle}</div>
                </button>
            </Form>
        </Modal>
    );
};

export default ModalEdit;