import { ButtonHTMLAttributes, ReactNode } from "react";
import { ButtonComponent } from "./styles";

interface Props {
  inverted?: boolean;
  children: ReactNode;
  onClick?: () => void;
  width?: string;
  height?: string;
  icon?: string;
  setIsConfirmed?: () => void;
  isConfirmed?: boolean;
  widthButtonContinueMobile?: string;
  widthButtonCloseMobile?: string;
  widthButtonContinue?: string;
  cancelButton?: boolean;
}

type ButtonProps = ButtonHTMLAttributes<any> & Props;

export default function Button({
  inverted,
  children,
  onClick = () => {},
  width,
  height = "48px",
  icon = '',
  setIsConfirmed,
  isConfirmed,
  widthButtonContinueMobile,
  widthButtonCloseMobile,
  cancelButton,
  ...rest
}: ButtonProps) {
  return (
    <ButtonComponent
      onClick={onClick}
      isConfirmed={isConfirmed}
      setIsConfirmed={setIsConfirmed}
      inverted={inverted}
      width={width}
      height={height}
      widthButtonContinueMobile={widthButtonContinueMobile}
      widthButtonCloseMobile={widthButtonCloseMobile}
      {...rest}
    >
      {!!icon && <img src={icon} alt="Ícone" />}
      {children}
    </ButtonComponent>
  );
}
