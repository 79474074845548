import iconAddbutton from '../../../../images/icons/icon-add-button.svg';
import { Container } from './styles';

interface SubCategoriaButtonProps {
    carregar: () => void
}

export default function SubCategoriaButton({ carregar }: SubCategoriaButtonProps) {
    return (
        <Container onClick={carregar}>
            <img src={iconAddbutton} />
            Ver mais serviços
        </Container>
    );
}