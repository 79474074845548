import Banners from "../../components/Banners";
import SempreBem from "../../components/SempreBem";
import ServiceHome from "../../components/ServiceHome";
import Partners from "../../components/Partners";
import ScheduleServices from "../../components/ScheduleServices";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import ModalLoading from "../../components/ModalLoading";

export default function Home() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const subCategory = localStorage.getItem("_subCategorias");
    const flagReload = localStorage.getItem("_flagReloadHome");
    let flagFormat;

    if (flagReload !== null) {
      flagFormat = JSON.parse(flagReload);
    }

    if (subCategory !== null && flagFormat && flagFormat.reload === true) {
      localStorage.setItem(
        "_flagReloadHome",
        JSON.stringify({
          reload: false,
        })
      );

      window.location.reload();
    }
  }, []);

  const toggleIsOpenModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <Container>
      <Banners />
      <ServiceHome toggleIsOpenModal={toggleIsOpenModal} />
      <ScheduleServices toggleIsOpenModal={toggleIsOpenModal} />
      <SempreBem />
      <Partners />
      <ModalLoading isOpen={modalIsOpen} />
    </Container>
  );
}
