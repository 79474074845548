import { ReactElement, ReactNode, useEffect, useState } from "react";
import { format, isAfter } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import * as S from "./styles";
import { Appointment } from "./types";
import { CardService } from "../CardService";
import iconUserSecondary from "../../images/icons/icon-user-secondary.svg";
import iconSuitcase from "../../images/icons/icon-suitcase.svg";
import { mask, titleCase } from "../../utils";
import { ModalFormReagendamento } from "../ModalFormReagendamento";
import { ModalMotivoReagendamento } from "../ModalMotivoReagendamento";
import { ModalSimple } from "../ModalSimple";
import iconWarning from "../../images/icons/icon-warning.svg";
import iconSuccess from "../../images/icons/icon-success.svg";
import iconLoading from "../../images/icons/loading.gif";
import iconError from "../../images/icons/icon-error.svg";
import { ModalMotivoCancelamento } from "../ModalMotivoCancelamento";
import { useHistory } from "react-router-dom";
import { convert } from "../../utils";

import iconStatusConfirmed from "../../images/icons/icon-confirmed.svg";
import iconStatusCanceled from "../../images/icons/icon-canceled.svg";
import iconStatusPreSale from "../../images/icons/icon-pre-sale.svg";
import iconStatusExecuteService from "../../images/icons/icon-execute-service.svg";
import iconStatusPayout from "../../images/icons/icon-payout.svg";
import iconStatusWarning from "../../images/icons/icon-status-warning.svg";

import ModalEnderecoPagamento from "../Paciente/ModalEnderecoPagamento";
import ModalConfirmarNaoPagamento from "../Paciente/ModalConfirmarNaoPagamento";
import ModalErrorEndereco from "../Paciente/ModalErrorEndereco";
import { FaRegCommentDots } from "react-icons/fa";

interface CardAppointmentProps {
  appointment: Appointment;
  setReload?: (reload: boolean | undefined) => void;
  setIdModalOpen?: (id: number) => void;
}

interface StatusIconMapProps {
  status: number;
  name: string;
  icon: ReactElement;
}

export function CardAppointment({
  appointment,
  setReload = () => {},
  setIdModalOpen = (id: number) => {},
}: CardAppointmentProps) {
  const [motivoCancelamento, setMotivoCancelamento] = useState(-1);
  const [descricaoMotivoCancelamento, setDescricaoMotivoCancelamento] =
    useState("");
  const [motivoReagendamento, setMotivoReagendamento] = useState(-1);
  const [descricaoMotivoReagendamento, setDescricaoMotivoReagendamento] =
    useState("");

  const [isExpanded, setIsExpanded] = useState(false);

  const [linkAgendamento, setLinkAgendamento] = useState("");
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalFormReagendamentoOpen, setIsModalFormReagendamentoOpen] =
    useState(false);
  const [isModalFormPagamentoOpen, setIsModalFormPagamentoOpen] =
    useState(false);
  const [isModalMotivoReagendamentoOpen, setIsModalMotivoReagendamentoOpen] =
    useState(false);
  const [isModalMotivoCancelamentoOpen, setIsModalMotivoCancelamentoOpen] =
    useState(false);
  const [isModalWarningReagendamentoOpen, setIsModalWarningReagendamentoOpen] =
    useState(false);
  const [isModalWarningCancelamentoOpen, setIsModalWarningCancelamentoOpen] =
    useState(false);
  const [isModalSuccessReagendamentoOpen, setIsModalSuccessReagendamentoOpen] =
    useState(false);
  const [isModalSuccessCancelamentoOpen, setIsModalSuccessCancelamentoOpen] =
    useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMsg, setErrorMsg] = useState<string | ReactNode>("");

  const [agendamentoAPagar, setAgendamentoAPagar] = useState();
  const [errorModalEndereco, setErrorModalEndereco] = useState(false);
  const [errorModalLoja, setErrorModalLoja] = useState(false);
  const [confirmarNaoPagamentoModalOpen, setConfirmarNaoPagamentoModalOpen] =
    useState(false);
  const [enderecoPagamentoModalOpen, setEnderecoPagamentoModalOpen] =
    useState(false);

  const [dataReagendar, setDataReagendar] = useState("");
  const status: StatusIconMapProps[] = [
    {
      status: 1,
      name: "Agendado",
      icon: (
        <img
          src={iconStatusConfirmed}
          alt="Agendamento confirmado"
          title="Agendamento confirmado"
        />
      ),
    },
    {
      status: 2,
      name: "Cancelado",
      icon: (
        <img
          src={iconStatusCanceled}
          alt="Agendamento cancelado"
          title="Agendamento cancelado"
        />
      ),
    },
    {
      status: 3,
      name: "Serviço consumido",
      icon: (
        <img
          src={iconStatusExecuteService}
          alt="Agendamento utilizado"
          title="Agendamento utilizado"
        />
      ),
    },
    {
      status: 4,
      name: "Não compareceu",
      icon: (
        <img
          src={iconStatusWarning}
          alt="Não compareceu"
          title="Não compareceu"
        />
      ),
    },
    {
      status: 5,
      name: "Pré-venda gerada",
      icon: (
        <img
          src={iconStatusPreSale}
          alt="Pré-venda gerada para o agendamento"
          title="Pré-venda gerada para o agendamento"
        />
      ),
    },
    {
      status: 6,
      name: "Agendamento pago virtualmente",
      icon: (
        <img
          src={iconStatusPayout}
          alt="Agendamento pago virtualmente"
          title="Agendamento pago virtualmente"
        />
      ),
    },
    {
      status: 7,
      name: "Agendamento pago em loja",
      icon: (
        <img
          src={iconStatusPayout}
          alt="Agendamento pago em loja"
          title="Agendamento pago em loja"
        />
      ),
    },
  ];

  const itemStatus = status?.find(
    (status) => status.status === Number(appointment.status)
  );

  useEffect(() => {
    const apiUrl = `agendaData/LinkRedirect`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Success") setLinkAgendamento(data.linkRedirect);
      });
  }, []);

  function getDate() {
    if (!appointment._data) return "";
    let data = appointment._data.split("T")[0];
    let _date =
      data.split("-")[2] + "/" + data.split("-")[1] + "/" + data.split("-")[0];
    return _date;
  }

  function getDayOfWeek() {
    if (!appointment._data) return "";
    let data = appointment._data.split("T")[0];
    let _date =
      data.split("-")[2] + "/" + data.split("-")[1] + "/" + data.split("-")[0];
    return titleCase(
      format(
        new Date(
          `${convert({ dateString: _date, pattern: "yyyy-mm-dd" })}T00:00:00`
        ),
        "eeee",
        { locale: ptBR }
      )
    );
  }

  function getTime() {
    if (!appointment._data) return "";
    let horario = appointment._data.split("T")[1].slice(0, 5);
    return horario;
  }

  function formattedNewDate(date: string) {
    let data = dataReagendar.split(" ")[0];
    let horario = dataReagendar.split(" ")[1];
    let _date =
      data.split("/")[2] +
      "-" +
      data.split("/")[1] +
      "-" +
      data.split("/")[0] +
      "T" +
      horario;
    return _date;
  }

  function convertUTCDate(_data: string) {
    const date = new Date(_data);
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  function checkAppointmentDate(title: string, message: string | ReactNode) {
    let date = new Date().toLocaleString("en-US", {
      timeZone: "America/Sao_Paulo",
    });
    let currentDate = new Date(date).setHours(0, 0, 0, 0);
    let appointmentDate = new Date(appointment._data).setHours(0, 0, 0, 0);

    if (isAfter(currentDate, appointmentDate)) {
      setErrorTitle(title);
      setErrorMsg(message);
      setIsModalErrorOpen(true);
      return false;
    }

    return true;
  }

  function handleCancelAppointment() {
    if (
      !checkAppointmentDate(
        "Erro no cancelamento",
        <span>
          {window.innerWidth <= 1024 ? (
            <p>
              Não é possível realizar um cancelamento <br /> durante ou após o
              horário do serviço <br /> marcardo.
            </p>
          ) : (
            <p>
              Não é possível realizar um cancelamento durante ou após o <br />{" "}
              horário do serviço marcardo.
            </p>
          )}
        </span>
      )
    ) {
      return;
    }

    fetch(
      `AgendamentoData/Cancelar?idAgendamento=${appointment.id}&idMotivo=${motivoCancelamento}&motivo=${descricaoMotivoCancelamento}`,
      {
        method: "PUT",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          setErrorTitle("Erro no cancelamento");
          setErrorMsg(data.message);
          setIsModalErrorOpen(true);
          return;
        }

        setIsModalSuccessCancelamentoOpen(true);
      })
      .catch(() => {
        setErrorTitle("Erro no cancelamento");
        setErrorMsg(
          <span>
            Infelizmente ocorreu um erro inesperado durante o seu <br />
            cancelamento. Recomendamos que tente de novo e caso o erro <br />
            permaneça, entre em <a className="link-contact">contato conosco</a>.
          </span>
        );
        setIsModalErrorOpen(true);
      });
  }

  function handleRescheduleAppointment() {
    if (
      !checkAppointmentDate(
        "Erro no reagendamento",
        <span>
          {window.innerWidth <= 1024 ? (
            <p>
              Não é possível realizar um reagendamento <br /> durante ou após o
              horário do serviço <br /> marcardo.
            </p>
          ) : (
            <p>
              Não é possível realizar um reagendamento durante ou após o <br />{" "}
              horário do serviço marcardo.
            </p>
          )}
        </span>
      )
    ) {
      return;
    }

    setIsLoading(true);
    setIsModalSuccessReagendamentoOpen(true);

    fetch(`AgendamentoData/Reagendar`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAgendamento: appointment.id,
        dataAgendamento: dataReagendar,
        idMotivo: motivoReagendamento,
        motivo: descricaoMotivoReagendamento,
        idParceiro: appointment.parceiro?.idParceiro,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "Success") {
          setErrorTitle("Erro no reagendamento");
          setErrorMsg(data.message);
          setIsLoading(false);
          setIsModalSuccessReagendamentoOpen(false);
          setIsModalErrorOpen(true);
          return;
        }

        setIsLoading(false);
      })
      .catch(() => {
        setErrorTitle("Erro no reagendamento");
        setErrorMsg(
          <span>
            Infelizmente ocorreu um erro inesperado durante o seu <br />
            reagendamento. Recomendamos que tente de novo e caso o erro <br />
            permaneça, entre em <a className="link-contact">contato conosco</a>.
          </span>
        );
        setIsModalErrorOpen(true);
      });
  }

  function getPeriodo(date: string) {
    const _date = new Date(date);
    if (_date.getHours() >= 6 && _date.getHours() < 12) return "Manhã";
    else if (_date.getHours() >= 12 && _date.getHours() < 18) return "Tarde";
    else if (
      (_date.getHours() >= 18 && _date.getHours() < 23) ||
      (_date.getHours() >= 0 && _date.getHours() < 6)
    )
      return "Noite";
  }

  return (
    <S.Container
      className="cardAppointment"
      style={{
        height: isExpanded ? "100%" : "auto",
        border: isExpanded ? "1px solid #0054A6" : "0",
      }}
    >
      <strong>{appointment.paciente.nome}</strong>

      <div className="inline-info">
        <p className="status">
          {itemStatus?.icon}
          {itemStatus?.name}
        </p>
        <span className="address">
          <a>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="3"
                y="1"
                width="10"
                height="14"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.33331 6.17668C3.33331 3.59668 5.41998 1.51001 7.99998 1.51001C10.58 1.51001 12.6666 3.59668 12.6666 6.17668C12.6666 8.95668 9.71998 12.79 8.51331 14.25C8.24665 14.57 7.75998 14.57 7.49331 14.25C6.27998 12.79 3.33331 8.95668 3.33331 6.17668ZM6.33331 6.17668C6.33331 7.09668 7.07998 7.84334 7.99998 7.84334C8.91998 7.84334 9.66665 7.09668 9.66665 6.17668C9.66665 5.25668 8.91998 4.51001 7.99998 4.51001C7.07998 4.51001 6.33331 5.25668 6.33331 6.17668Z"
                  fill="black"
                />
              </mask>
              <g mask="url(#mask0)">
                <rect width="16" height="16" fill="#0054A6" />
              </g>
            </svg>
            {appointment?.loja?.endereco}, {appointment?.loja?.numero} -{" "}
            {appointment?.loja?.bairro}
          </a>
        </span>
        <p className="data" style={{ marginRight: 43 }}>
          {appointment.data}
        </p>
        <p className="servicos">
          {String(appointment.servicos?.length).padStart(2, "0")}
        </p>
        <div className="see-more">
          <a onClick={() => setIsExpanded((prevState) => !prevState)}>
            {!isExpanded ? "Ver mais" : "Ver menos"}
            {!isExpanded ? (
              <svg
                width="10"
                height="10"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.39502 6.00025C5.11881 6.00025 4.8426 5.90225 4.6322 5.70725L0.316402 1.70725C-0.105467 1.31625 -0.105467 0.68425 0.316402 0.29325C0.738272 -0.09775 1.42017 -0.09775 1.84204 0.29325L5.40797 3.59825L8.96095 0.41825C9.39145 0.03525 10.0723 0.04625 10.4866 0.44325C10.9009 0.84025 10.889 1.47425 10.4607 1.85725L6.14489 5.71925C5.9345 5.90725 5.66476 6.00025 5.39502 6.00025Z"
                  fill="#0054A6"
                />
              </svg>
            ) : (
              <svg
                width="10"
                height="20"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: 10 }}
              >
                <path
                  transform="scale(1, -1)"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.39502 6.00025C5.11881 6.00025 4.8426 5.90225 4.6322 5.70725L0.316402 1.70725C-0.105467 1.31625 -0.105467 0.68425 0.316402 0.29325C0.738272 -0.09775 1.42017 -0.09775 1.84204 0.29325L5.40797 3.59825L8.96095 0.41825C9.39145 0.03525 10.0723 0.04625 10.4866 0.44325C10.9009 0.84025 10.889 1.47425 10.4607 1.85725L6.14489 5.71925C5.9345 5.90725 5.66476 6.00025 5.39502 6.00025Z"
                  fill="#0054A6"
                />
              </svg>
            )}
          </a>
        </div>
      </div>

      <div style={{ display: isExpanded ? "" : "none" }} className="details">
        <section>
          <span className="details-title">
            <img src={iconUserSecondary} alt="Icon user" />
            Dados pessoais
          </span>
          <div className="row">
            <span style={{ width: 521 }}>
              <strong>CPF</strong>
              <p>{appointment.paciente.cpf}</p>
            </span>
            <span style={{ width: 248 }}>
              <strong>Gênero</strong>
              <p>{appointment.paciente.sexo}</p>
            </span>
            <span>
              <strong>Data de nascimento</strong>
              <p>{appointment.paciente.dataNascimento}</p>
            </span>

            <br />

            <span style={{ width: 521 }} className="details-email">
              <strong>Email</strong>
              <p>{appointment.paciente.email}</p>
            </span>
            <span>
              <strong>Telefone</strong>
              <p>{mask(appointment.paciente.telefone, "(##) #####-####")}</p>
            </span>
          </div>
        </section>
        <section>
          <span className="details-title" style={{ marginBottom: 10 }}>
            <img src={iconSuitcase} alt="Icon user" />
            Serviços marcados
          </span>
          <div className="info-row">
            <div className="complement">
              <div className="provider">
                <FaRegCommentDots size="1.2em" />
              </div>
              <div className="provider">
                <label>Serviço(s) prestado(s) por: </label>
                <label>
                  {appointment.parceiro?.nomeFantasia ?? "Pague Menos"}
                </label>
              </div>
            </div>
          </div>
          {appointment.servicos.map((servico) => (
            <CardService key={servico.id} service={servico} />
          ))}
        </section>
        {[1, 6, 7].includes(Number(appointment.status)) && (
          <S.ButtonsContainer>
            <S.ButtonCancelAppointment
              onClick={() => {
                window.CustomScrollToTop();
                window.removeDivWorkArround();

                if (
                  !checkAppointmentDate(
                    "Erro no cancelamento",
                    <span>
                      {window.innerWidth <= 1024 ? (
                        <p>
                          Não é possível realizar um cancelamento <br /> durante
                          ou após o horário do serviço <br /> marcardo.
                        </p>
                      ) : (
                        <p>
                          Não é possível realizar um cancelamento durante ou
                          após o <br /> horário do serviço marcardo.
                        </p>
                      )}
                    </span>
                  )
                ) {
                  window.createDivWorkArround();
                  return;
                }

                setIsModalMotivoCancelamentoOpen(true);
                setIdModalOpen(appointment.id);
              }}
            >
              Cancelar agendamento
            </S.ButtonCancelAppointment>
            <S.ButtonReschedule
              onClick={() => {
                window.CustomScrollToTop();
                window.removeDivWorkArround();

                if (
                  !checkAppointmentDate(
                    "Erro no reagendamento",
                    <span>
                      {window.innerWidth <= 1024 ? (
                        <p>
                          Não é possível realizar um reagendamento <br />{" "}
                          durante ou após o horário do serviço <br /> marcardo.
                        </p>
                      ) : (
                        <p>
                          Não é possível realizar um reagendamento durante ou
                          após o <br /> horário do serviço marcardo.
                        </p>
                      )}
                    </span>
                  )
                ) {
                  window.createDivWorkArround();
                  return;
                }

                setIsModalFormReagendamentoOpen(true);
                setIdModalOpen(appointment.id);
              }}
            >
              Reagendar
            </S.ButtonReschedule>

            {Number(appointment.status) === 1 &&
              (!appointment.parceiro ||
                appointment?.parceiro?.pagamentoOnlineParceiro) && (
                <S.ButtonPay
                  onClick={() => {
                    window.CustomScrollToTop();
                    window.removeDivWorkArround();

                    if (
                      !checkAppointmentDate(
                        "Erro no pagamento",
                        <span>
                          {window.innerWidth <= 1024 ? (
                            <p>
                              Não é possível realizar um pagamento <br />{" "}
                              durante ou após o horário do serviço <br />{" "}
                              marcardo.
                            </p>
                          ) : (
                            <p>
                              Não é possível realizar um pagamento durante ou
                              após o <br /> horário do serviço marcardo.
                            </p>
                          )}
                        </span>
                      )
                    ) {
                      window.createDivWorkArround();
                      return;
                    }

                    const apiUrl = `AgendamentoData/GetAgendamentoAPagar?idAgendamento=${appointment.id}`;
                    fetch(apiUrl)
                      .then((response) => response.json())
                      .then((data) => {
                        if (data.result === "Success") {
                          setAgendamentoAPagar(data.data);
                          setIsModalFormPagamentoOpen(true);
                        }
                      });
                  }}
                >
                  Pagar
                </S.ButtonPay>
              )}
          </S.ButtonsContainer>
        )}
      </div>

      {isModalFormReagendamentoOpen && (
        <ModalFormReagendamento
          isOpen={isModalFormReagendamentoOpen}
          onRequestClose={() => {
            setIdModalOpen(0);
            setIsModalFormReagendamentoOpen(false);
            window.createDivWorkArround();
          }}
          onContinue={(dateTime: string) => {
            setDataReagendar(dateTime);
            setIsModalFormReagendamentoOpen(false);
            setIsModalMotivoReagendamentoOpen(true);
            window.createDivWorkArround();
          }}
          data={{
            servicos: appointment.servicos.map((servico) => servico.id),
            data: appointment.data,
            codigoLoja: appointment?.loja?.codigo,
            idParceiro: appointment?.parceiro?.idParceiro,
          }}
        />
      )}

      <ModalEnderecoPagamento
        isOpen={isModalFormPagamentoOpen}
        setIsOpen={() => {
          setIsModalFormPagamentoOpen(false);
        }}
        idAgendamento={appointment.id}
        appointmentToPay={agendamentoAPagar}
        onError={() => setErrorModalEndereco(true)}
        setErrorMsg={(text) => {
          let _text = text.split(":").reverse();
          setIsModalFormPagamentoOpen(false);
          setIsModalErrorOpen(true);
          setErrorTitle("Atenção");
          setErrorMsg(
            <>
              <p>{_text[0]?.trim()}</p>
              <p>Efetue o pagamento em loja.</p>
            </>
          );
        }}
        onHandler={() => {}}
        overflow={false}
        redirectToUrl={`${new URL(
          "agendamentos",
          window.location.href
        ).toString()}?pagamentoEfetuadoComSucesso=true`}
      />

      <ModalErrorEndereco
        isOpen={errorModalEndereco}
        setIsOpen={() => setErrorModalEndereco(false)}
        styles={{
          content: {
            position: "relative",
            inset: "0",
            border: "none",
            background: "#F0F0F5",
            overflow: "hidden",
            borderRadius: "8px",
            outline: "none",
            padding: "20px",
            maxWidth: "35%",
            width: "100%",
            height: "auto",
            margin: "auto",
          },
          overlay: {
            backgroundColor: "#121214b1",
            display: "flex",
            zIndex: 10,
          },
        }}
      />

      {isModalMotivoReagendamentoOpen && (
        <ModalMotivoReagendamento
          isOpen={isModalMotivoReagendamentoOpen}
          onRequestClose={() => {
            setIdModalOpen(0);
            setIsModalMotivoReagendamentoOpen(false);
          }}
          onContinue={(motivoSelecionado: number, descricaoMotivo: string) => {
            setMotivoReagendamento(motivoSelecionado);
            setDescricaoMotivoReagendamento(descricaoMotivo);
            setIsModalMotivoReagendamentoOpen(false);
            setIsModalWarningReagendamentoOpen(true);
          }}
        />
      )}

      {isModalMotivoCancelamentoOpen && (
        <ModalMotivoCancelamento
          isOpen={isModalMotivoCancelamentoOpen}
          onRequestClose={() => {
            setIdModalOpen(0);
            setIsModalMotivoCancelamentoOpen(false);
            window.createDivWorkArround();
          }}
          onContinue={(motivoSelecionado: number, descricaoMotivo: string) => {
            setMotivoCancelamento(motivoSelecionado);
            setDescricaoMotivoCancelamento(descricaoMotivo);
            setIsModalMotivoCancelamentoOpen(false);
            setIsModalWarningCancelamentoOpen(true);
            window.createDivWorkArround();
          }}
        />
      )}

      <ModalSimple
        isOpen={isModalWarningReagendamentoOpen}
        title="Reagendamento"
        message={
          <span style={{ display: "block" }}>
            {dataReagendar.split(" ")[0] !== "" &&
              `Deseja realizar o reagendamento para o dia ${
                dataReagendar.split(" ")[0]
              }
                      (${titleCase(
                        format(
                          new Date(
                            `${convert({
                              dateString: dataReagendar.split(" ")[0],
                              pattern: "yyyy-mm-dd",
                            })}T00:00:00`
                          ),
                          "eeee",
                          { locale: ptBR }
                        )
                      )})
                      às ${dataReagendar.split(" ")[1]} da ${getPeriodo(
                formattedNewDate(dataReagendar)
              )}?`}
          </span>
        }
        icon={iconWarning}
        onRequestClose={() => {
          setIdModalOpen(0);
          setIsModalWarningReagendamentoOpen(false);
        }}
        showCancel
        continueButtonText="Reagendar"
        widthButtonContinue="179px"
        widthButtonClose="180px"
        widthButtonContinueMobile="calc(50% - 18px)"
        widthButtonCloseMobile="calc(50% - 18px)"
        confirm={() => {
          setIsModalWarningReagendamentoOpen(false);
          handleRescheduleAppointment();
        }}
      />

      <ModalSimple
        isOpen={isModalWarningCancelamentoOpen}
        title={
          <>
            Cancelamento <br /> do agendamento
          </>
        }
        message={
          <p
            style={
              window.innerWidth < 1024
                ? { padding: "0 10px" }
                : { padding: "0 48px" }
            }
          >
            Deseja cancelar o agendamento marcado para a data{" "}
            {getDate() + " (" + getDayOfWeek() + ") às " + getTime()} da{" "}
            {getPeriodo(appointment._data)}?
          </p>
        }
        icon={iconWarning}
        imgStyle={{ height: "38.32px" }}
        onRequestClose={() => {
          setIdModalOpen(0);
          setIsModalWarningCancelamentoOpen(false);
        }}
        confirm={() => {
          setIsModalWarningCancelamentoOpen(false);
          setIdModalOpen(0);
          handleCancelAppointment();
        }}
        showCancel
        cancelButtonText="Sair"
        continueButtonText="Confirmar"
        widthButtonContinue="179px"
        widthButtonClose="180px"
        widthButtonContinueMobile="137px"
        widthButtonCloseMobile="137px"
      />

      <ModalSimple
        Height={window.innerWidth < 1024 ? "382px" : "371px"}
        isOpen={isModalSuccessReagendamentoOpen}
        title={
          isLoading ? (
            "Carregando informações"
          ) : (
            <h2 style={{ marginTop: 10 }}>
              Reagendamento <br />
              realizado com sucesso
            </h2>
          )
        }
        message={
          isLoading ? (
            ""
          ) : (
            <p
              style={
                window.innerWidth < 1024
                  ? { padding: "0 10px" }
                  : { padding: "0 36px" }
              }
            >
              O agendamento atual permanecerá visivel na sua aba de agendamentos
              com o status de cancelado. Mas não se preocupe um novo
              agendamento, com os mesmos serviços, foi adicionado.
            </p>
          )
        }
        icon={isLoading ? iconLoading : iconSuccess}
        onRequestClose={() => {
          if (!isLoading) {
            setReload(true);
            setIdModalOpen(0);
            setIsModalSuccessReagendamentoOpen(false);
          }
        }}
        confirm={() => {
          if (!isLoading) {
            setReload(true);
            setIsModalSuccessReagendamentoOpen(false);
            setIdModalOpen(0);
          }
        }}
        className={"custom-loading"}
        isAnimation={isLoading}
        hideButtons={isLoading}
        continueButtonText="Fechar"
        widthButtonContinue="180px"
        widthButtonClose="180px"
      />

      <ModalSimple
        Height={"341px"}
        isOpen={isModalSuccessCancelamentoOpen}
        title={
          <h2 style={{ marginTop: 10 }}>
            Cancelamento <br />
            realizado com sucesso
          </h2>
        }
        message={
          <p>
            Sua solicitação de cancelamento foi recebida, todos os serviços
            referentes a este agendamento foram cancelados.
          </p>
        }
        icon={iconSuccess}
        onRequestClose={() => {
          setReload(true);
          setIsModalSuccessCancelamentoOpen(false);
        }}
        confirm={() => {}}
        showCancel
        linkAgendamento={linkAgendamento}
        continueButtonText="Novo agendamento"
        cancelButtonText="Fechar"
        widthButtonContinue="180px"
        widthButtonClose="180px"
        heightButton="43px"
        backgroundButtonClose="#0054A6"
        colorButtonClose="white"
        backgroundButtonContinue="#09B2AB"
        marginTitleBottom="0"
        buttonsMarginTop="20px"
        widthButtonContinueMobile="160px"
        widthButtonCloseMobile="114px"
      />

      <ModalSimple
        Height={"305px"}
        isOpen={isModalErrorOpen}
        title={errorTitle}
        message={errorMsg}
        icon={iconError}
        continueButtonText="Fechar"
        widthButtonContinue="180px"
        onRequestClose={() => setIsModalErrorOpen(false)}
        confirm={() => {
          setIsModalErrorOpen(false);
        }}
      />
    </S.Container>
  );
}
