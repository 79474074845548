import axiosConfig from "../config/axiosConfig";
import axios from "axios";
import { toast } from "react-toastify";

export const schedulingService = {
  abandonScheduling: async (id) => {
    const req = await axios.get(
      `agendamentoData/CancelarPreAgendamento?idAgendamento=${id}`,
      axiosConfig
    );

    if (req.status === 200) {
      return true;
    } else {
      const { message } = req.data;

      toast.error(message);

      return false;
    }
  },

  verifyOnlinePaying: async (codigoLoja, idParceiroLojaSala) => {

    const req = await axios.get(
      `/agendamentoData/PodePagarOnline?codigoLoja=${codigoLoja}&idParceiroLojaSala=${idParceiroLojaSala !== null ? idParceiroLojaSala : ""}`,
      axiosConfig
    );

    const {status, data} = req

    if (status === 200) {
      return data.podePagarOnline;
    } else {
      const { message } = req.data;

      toast.error(message);

      return false;
    }
  },
};
