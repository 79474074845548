import { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import moment from "moment";
import { removeElementDOM } from "../../LoadGetnetPayment";
import "../base_style.css";
import Swal from "sweetalert2";
import {
  AgendamentoContextData,
  AgendamentoContext,
} from "../../../contexts/agendamento";

import { cpfMask, phoneMask, moneyFormatter } from "../../mask";

import ModalSuccess from "../ModalSuccess";
import ModalError from "../ModalError";
import ModalEnderecoPagamento from "../ModalEnderecoPagamento";
import ModalConfirmarNaoPagamento from "../ModalConfirmarNaoPagamento";
import ModalErrorEndereco from "../ModalErrorEndereco";

import IconLoading from "../../../images/icons/Loading.svg";
import IconUserOutline from "../../../images/icons/user-outline.svg";
import IconInfo from "../../../images/icons/icon-apartir.svg";
import IconTime from "../../../images/icons/icon-time-exame.svg";
import IconSuitcase from "../../../images/icons/icon-suitcase.svg";

import {
  Container,
  ContainerInfo,
  Button,
  ButtonEditData,
  Divider,
} from "./styles";
import { Servico } from "../../CardAppointment/types";
import ModalLoading from "../../ModalLoading";

/*
interface IFormData {
    name: string
    email: string
    cpf: string
    phone: string
}*/

const maskDate = "99/99/9999";

export const validateCpf = (cpf: any) => {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf === "") return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
};

export const isNullOrEmpty = (value: any) => {
  if (value === null || value?.trim() === "") return true;
  else return false;
};

class FormComponent extends Component<any, any> {
  static contextType = AgendamentoContext;
  public buttonsContainer: any;

  constructor(props: any) {
    super(props);

    this.state = {
      successModalOpen: false,
      errorModalOpen: false,
      enderecoPagamentoModalOpen: false,
      pagamentoConfirmadoModalOpen: false,
      confirmarNaoPagamentoModalOpen: false,
      termChecked: false,
      isFormValid: false,
      isValidCPF: true,
      isValidDataNascimento: true,
      isLoading: false,
      isSaveLoading: false,
      loadCount: 1,
      errorMessage: "",
      pagamentoEfetuado: false,
      naoPagamentoConfirmado: false,

      codigoCliente: "",
      nome: "",
      cpf: "",
      email: "",
      telefone: "",
      dataNascimento: "",
      sexo: "",
      aceitouTermos: false,

      idAgendamento: 0,

      codigoColaborador: 0,
      nomeColaborador: "",
      cargoColaborador: "",

      servicos: [],
      servicoList: [],
      isServicoLoading: false,
      loadCountServico: 0,
      totalPreco: 0,
      totalTempo: 0,
      loja: null,
      data: "",
      horario: "",
      parceiroLojaSalaId: undefined,
      modalBallon: false,

      isTooltipAttendanceTimeVisible: false,
      isTooltipOrderSummaryVisible: false,

      agendamentoAssistido: false,

      idServiceDetailsOpen: 0,

      agendamentoAPagar: {},
      dataNascimentoJaPreenchido: false,
      errorModal: false,
      innerWidth: 0,
      parceiroInfo: null,
      paymentTimeout: false,
    };

    this.fecharModalSucesso = this.fecharModalSucesso.bind(this);
    this.setIsOpenModalSuccess = this.setIsOpenModalSuccess.bind(this);
    this.pagarAgendamento = this.pagarAgendamento.bind(this);
    this.toggleErrorModal = this.toggleErrorModal.bind(this);
    this.setIsOpenModalEnderecoPagamento =
      this.setIsOpenModalEnderecoPagamento.bind(this);
    this.modalConfimacaoPagarAgora = this.modalConfimacaoPagarAgora.bind(this);
    this.modalConfimacaoPagarDepois =
      this.modalConfimacaoPagarDepois.bind(this);
    this.modalConfimacaoPagarDepois.bind(this);
    this.toggleConfirmarNaoPagamentoModal =
      this.toggleConfirmarNaoPagamentoModal.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeCpf = this.onChangeCpf.bind(this);
    this.onFocusOutCpf = this.onFocusOutCpf.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeTelefone = this.onChangeTelefone.bind(this);
    this.toggleTerms = this.toggleTerms.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.handlePaymentTimeout = this.handlePaymentTimeout.bind(this);
  }

  componentDidMount() {
    const agendamentoContext = this.context as AgendamentoContextData;
    if (!agendamentoContext.completedSteps.includes("agenda")) {
      agendamentoContext.handleCompleteStep("agenda");
    }

    //GUARDAR STEP
    let pathname = this.props.location.pathname;
    let path = pathname.substring(pathname.lastIndexOf("/"));
    localStorage.setItem("_pmLastStep", path);
    //GUARDAR STEP

    this.setState({ innerWidth: window.innerWidth });
    window.addEventListener("resize", (event) => {
      this.resizeScreen();
    });

    agendamentoContext.handleUpdateCliente(this.props.cliente);

    this.setState({ codigoCliente: this.props.cliente.codigoCliente });
    this.setState({ nome: this.props.cliente.nome.toUpperCase() });
    this.setState({ cpf: this.props.cliente.cpf });
    if (this.props.cliente.email !== null)
      this.setState({ email: this.props.cliente.email });

    if (this.props.cliente.dataNascimento)
      this.setState({
        dataNascimento: this.props.cliente.dataNascimento,
        dataNascimentoJaPreenchido: this.props.cliente?.dataNascimento ?? false,
      });

    this.setState({ telefone: phoneMask(this.props.cliente.telefone) });
    if (this.props.cliente.sexo === "M") this.setState({ sexo: "MASCULINO" });
    else if (this.props.cliente.sexo === "F")
      this.setState({ sexo: "FEMININO" });

    let _agendamentoAssistido = localStorage.getItem("_pmAgendamentoAssistido");
    if (_agendamentoAssistido !== null) {
      this.setState({ agendamentoAssistido: true });

      if (this.props.cliente.telefone !== null)
        this.setState({ telefone: this.props.cliente.telefone });
      if (this.props.cliente.dataNascimento !== null)
        this.setState({ dataNascimento: this.props.cliente.dataNascimento });
      if (this.props.cliente.sexo === "M") this.setState({ sexo: "MASCULINO" });
      else if (this.props.cliente.sexo === "F")
        this.setState({ sexo: "FEMININO" });
      else this.setState({ sexo: this.props.cliente.sexo });

      let codigoColaborador = 0;
      const _codigoColaborador = localStorage.getItem("_pmCodigoColaborador");
      if (_codigoColaborador !== null) {
        codigoColaborador = parseInt(_codigoColaborador);
      }
      this.setState({ codigoColaborador: codigoColaborador });

      let nomeColaborador = "";
      const _nomeColaborador = localStorage.getItem("_pmNomeColaborador");
      if (_nomeColaborador !== null) {
        nomeColaborador = _nomeColaborador;
      }
      this.setState({ nomeColaborador: nomeColaborador });

      let cargoColaborador = "";
      const _cargoColaborador = localStorage.getItem("_pmCargoColaborador");
      if (_cargoColaborador !== null) {
        cargoColaborador = _cargoColaborador;
      }
      this.setState({ cargoColaborador: cargoColaborador });
    }

    if (!this.state.telefone && agendamentoContext.telefone) {
      this.setState({ telefone: agendamentoContext.telefone?.trim() });
    }

    this.setState({ aceitouTermos: this.props.cliente.aceitouTermos });

    if (!this.state.termChecked && agendamentoContext.aceitouTermos) {
      this.setState({ termChecked: agendamentoContext.aceitouTermos });
      this.setState({ aceitouTermos: agendamentoContext.aceitouTermos });
    }

    if (this.props.cliente.aceitouTermos) this.setState({ termChecked: true });

    //CARREGAR RESUMO AGENDAMENTO
    const sServicos = localStorage.getItem("_ApmServicos");
    let arrayServicos: any[] = [];
    if (sServicos !== null) arrayServicos = JSON.parse(sServicos);

    const servicos = arrayServicos
      .map(function (elem) {
        return elem.id;
      })
      .join(",");
    if (servicos === "") {
      this.props.history.push("/hubdesaude/exame");
      return;
    }

    let codigoLoja = localStorage.getItem("_pmLoja");
    if (codigoLoja === null) {
      this.props.history.push("/hubdesaude/loja");
      return;
    }

    let dataSelecionada = localStorage.getItem("_pmDataSelecionada");
    if (dataSelecionada === null) {
      this.props.history.push("/hubdesaude/agenda");
      return;
    }

    this.setState({ servicos: arrayServicos });
    let totalPreco = arrayServicos.reduce(
      (a, b) => a + (parseFloat(b["preco"]) || 0),
      0
    );
    this.setState({ totalPreco: totalPreco });

    let loja = JSON.parse(codigoLoja);
    this.setState({ loja: loja });

    let _data = JSON.parse(dataSelecionada);
    this.setState({
      data: _data.data,
      horario: _data.horario,
      parceiroLojaSalaId: _data.parceiroLojaSalaId,
    });

    //localStorage.removeItem('_pmDataSelecionada');
    this.buttonsContainer = document.getElementById("navigation-buttons");

    //CARREGAR SERVIÇOS COM PREÇOS
    this.loadInformation(loja, servicos, _data.parceiroLojaSalaId);
  }

  async loadInformation(loja: any, servicos: any, parceiroLojaSalaId?: number) {
    this.setState({ isServicoLoading: true });
    await Promise.all([
      this.loadServicos(loja.codigo, servicos, parceiroLojaSalaId),
      this.getInformationPartner(parceiroLojaSalaId),
    ])
      .then((values) => {
        const [servicesData, parceiro] = values;

        if (servicesData.result !== "Error") {
          if (servicesData.code === "001" && this.state.loadCountServico < 3) {
            this.setState({
              loadCountServico: this.state.loadCountServico + 1,
              errorMessage: servicesData.message,
            });
            this.loadInformation(loja, servicos, parceiroLojaSalaId);
          }
          this.setState({ servicoList: servicesData.items });
          this.setState({ parceiroInfo: parceiro });

          this.mapearPrecosServicos();
          this.mapearTempoServicos();

          let totalPreco = servicesData.items.reduce(
            (a: any, b: any) => a + (parseFloat(b["preco"]) || 0),
            0
          );
          this.setState({ totalPreco: totalPreco });
        }
      })
      .finally(() => this.setState({ isServicoLoading: false }));
  }

  async loadServicos(
    codigoLoja: any,
    servicos: any,
    parceiroLojaSalaId?: number
  ) {
    console.log(`Buscando servicos valores ${this.state.loadCountServico}x`);

    let apiUrl = `servicoData/GetAllPorLoja?codLoja=${codigoLoja}&servicos=${servicos}`;
    apiUrl += parceiroLojaSalaId
      ? `&idParceiroLojaSala=${parceiroLojaSalaId}`
      : "";
    try {
      const response = await fetch(apiUrl, { cache: "no-cache" });
      const data = await response.json();
      return await Promise.resolve(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async getInformationPartner(parceiroLojaSalaId?: number) {
    if (parceiroLojaSalaId) {
      const apiUrl = `parceiroData/GetParceiroByParceiroLojaSala?idParceiroLojaSala=${parceiroLojaSalaId}`;
      try {
        const response = await fetch(apiUrl, { cache: "no-cache" });
        const data = await response.json();
        return await Promise.resolve(data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    return await Promise.resolve(null);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const atualizarPrecoOuServicos =
      prevProps.precosServicos !== this.props.precosServicos ||
      prevState.servicos !== this.state.servicos;

    if (prevProps.salvarFormulario !== this.props.salvarFormulario)
      this.saveForm();

    if (atualizarPrecoOuServicos) {
      this.mapearPrecosServicos();
      this.mapearTempoServicos();
    }
  }

  resizeScreen() {
    if (window.innerWidth <= 360) this.setState({ innerWidth: 360 });
    else if (window.innerWidth < 420) this.setState({ innerWidth: 420 });
    else this.setState({ innerWidth: window.innerWidth });
  }

  handlePaymentTimeout = () => {
    Swal.fire({
      title: "Tempo esgotado",
      html: `
                <p>O tempo limite de 5 minutos de pré-reserva se esgotou, por favor, prossiga para um novo agendamento.</p>
            `,
      icon: "error",
      confirmButtonColor: "#0054A6",
      confirmButtonText: "Entendi",
      allowOutsideClick: false,
    }).then(() => {
      localStorage.clear();
      window.location.reload();
    });
  };

  mapearPrecosServicos = () => {
    let atualizarPrecoOuServicos = true;

    atualizarPrecoOuServicos =
      atualizarPrecoOuServicos &&
      !!this.state.servicoList &&
      Array.isArray(this.state.servicoList) &&
      this.state.servicoList.length > 0;

    if (!atualizarPrecoOuServicos) return;

    let valorTotal = 0;
    const servicos = this.state.servicoList;

    for (const servico of servicos) {
      valorTotal += servico.preco || 0;
    }

    this.setState({ totalPreco: valorTotal });
  };

  mapearTempoServicos = () => {
    let totalTempo = 0;
    const servicos = this.state.servicoList;

    for (const servico of servicos)
      totalTempo += this.formatTime(servico.hora) || 0;

    totalTempo += Number(servicos[0]?.tempoBase) ?? 0;
    this.setState({ totalTempo: totalTempo });
  };

  formatTime = (time: any) => {
    let index = time?.split(":");
    if (!index) return undefined;

    return +index[0] * 60 * 60 + +index[1] + +index[2];
  };

  handleSubmit(): void {}

  setIsOpenModalSuccess() {
    if (this.podePagarOnline())
      this.setState({ confirmarNaoPagamentoModalOpen: true });
  }

  fecharModalSucesso() {
    if (this.podePagarOnline())
      this.setState({ confirmarNaoPagamentoModalOpen: true });
    else {
      this.props.history.push("/hubdesaude/exame");

      const agendamentoContext = this.context as AgendamentoContextData;
      agendamentoContext.clearContext();
    }
  }

  pagarAgendamento() {
    this.setState({ successModalOpen: false });
    this.setState({ enderecoPagamentoModalOpen: true });
  }

  toggleErrorModal() {
    this.props.history.push("/hubdesaude/agenda");
  }

  reloadClient() {
    this.setState({ isLoading: true });
    const apiUrl = `clienteData/GetCliente/cpf/${this.state.cpf}`;
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "Error") {
          this.setState({ codigoCliente: data.clienteData.codigoCliente });
          this.setState({ nome: data.clienteData.nome.toUpperCase() });
          this.setState({ cpf: data.clienteData.cpf });
          if (data.clienteData.email !== null)
            this.setState({ email: data.clienteData.email });
          if (data.clienteData?.dataNascimento) {
            data.clienteData.dataNascimento = moment(
              data.clienteData.dataNascimento.replace("Z", "").replace("z", "")
            ).format("DD/MM/YYYY");
            this.setState({ dataNascimento: data.clienteData.dataNascimento });
          }
          this.setState({ telefone: data.clienteData.telefone });
          if (data.clienteData.sexo === "M")
            this.setState({ sexo: "MASCULINO" });
          else if (data.clienteData.sexo === "F")
            this.setState({ sexo: "FEMININO" });

          localStorage.setItem(
            "_pmPacienteAssistido",
            JSON.stringify(data.clienteData)
          );
          this.props.history.push("/hubdesaude/paciente");
        }

        this.setState({ isLoading: false });
      });
  }

  setIsOpenModalEnderecoPagamento() {
    this.setState({ confirmarNaoPagamentoModalOpen: true });
  }

  modalConfimacaoPagarAgora() {
    this.setState({ enderecoPagamentoModalOpen: true });
    this.setState({ confirmarNaoPagamentoModalOpen: false });
  }

  modalConfimacaoPagarDepois() {
    this.props.history.push("/hubdesaude/exame");

    const agendamentoContext = this.context as AgendamentoContextData;
    agendamentoContext.clearContext();
  }

  toggleConfirmarNaoPagamentoModal() {
    this.setState({ pagamentoConfirmadoModalOpen: true });
  }

  saveForm(): void {
    console.log(`Tentando agendar ${this.state.loadCount}x`);

    const pagamentoOnlineStr = localStorage.getItem("_pmPagamentoOnline");

    const servicos = this.state.servicoList.map((elem: any) => {
      return {
        idServico: elem.id,
        preco: elem.preco,
      };
    });

    if (this.state.servicoList && this.state.servicoList.length <= 0) {
      this.props.handleEnableButton();
      return;
    }

    const _data = {
      autoAtendimento: !this.state.agendamentoAssistido,
      codigoLoja: this.state.loja.codigo,
      codigoCliente: this.state.codigoCliente,
      codigoColaborador: this.state.codigoColaborador,
      nomeColaborador: this.state.nomeColaborador,
      cargoColaborador: this.state.cargoColaborador,
      data: this.state.data + " " + this.state.horario,
      paciente: {
        codigoCliente: this.state.codigoCliente,
        nome: this.state.nome,
        cpf: this.state.cpf,
        email: this.state.email,
        telefone: this.state.telefone,
        sexo: this.state.sexo,
        dataNascimento: this.state.dataNascimento,
      },
      servicos: servicos,
      parceiroLojaSalaId: this.state.parceiroLojaSalaId,
      AceitaPagamentoOnline: pagamentoOnlineStr === "true",
    };

    this.setState({ isSaveLoading: true });
    fetch("AgendamentoData/agendar", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(_data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.result === "Error" && response.code === "001") {
          if (this.state.loadCount < 3) {
            this.setState({
              loadCount: this.state.loadCount + 1,
              errorMessage: response.message,
            });
            this.saveForm();
          }
        } else {
          //ERRO LAMBDA, OU ERRO CONTROLADO

          //SE ERRO..
          //ABRE O MODAL
          if (response.result === "Error") {
            this.setState({ errorModalOpen: true });
          } else {
            localStorage.clear();

            if (this.state.agendamentoAssistido) {
              localStorage.setItem("_pmAgendamentoAssistido", "true");
              localStorage.setItem(
                "_pmCodigoColaborador",
                this.state.codigoColaborador
              );
              localStorage.setItem(
                "_pmNomeColaborador",
                this.state.nomeColaborador
              );
              localStorage.setItem(
                "_pmCargoColaborador",
                this.state.cargoColaborador
              );
            }

            this.setState({ idAgendamento: response.data.id });
            this.setState({ agendamentoAPagar: response.data.data });

            const selectedDate = JSON.parse(this.props.selectedDate);

            const { parceiroLojaSalaId } = selectedDate;

            if (this.podePagarOnline() && parceiroLojaSalaId === null) {
              setTimeout(() => {
                this.setState({
                  ...this.state,
                  paymentTimeout: true,
                });
                removeElementDOM("getnetPaymentScript");
                removeElementDOM("getnet-checkout");
                const existingScript = document.getElementById(
                  "getnetPaymentScript"
                );
                if (existingScript) existingScript.remove();
                this.handlePaymentTimeout();
              }, 300000);
              this.setState({ enderecoPagamentoModalOpen: true });
            } else {
              this.setState({ successModalOpen: true });
            }
          }

          window.CustomScrollToTop();
        }

        this.setState({ isSaveLoading: false });
      })
      .catch((error) => {
        this.setState({ isSaveLoading: false });
        console.error("Error:", error);
      });
  }

  validateForm() {
    if (this.state.isServicoLoading) return false;
    if (!this.state.agendamentoAssistido) {
      const validNome = this.state.nome?.length > 5;
      const validTelefone = this.state.telefone?.length > 13;

      if (
        this.state.termChecked &&
        validNome &&
        validTelefone &&
        this.state.isValidCPF &&
        this.state.dataNascimento &&
        this.state.isValidDataNascimento
      )
        return true;
      else return false;
    } else {
      const validNome = this.state.nome?.length > 5;
      const validDataNascimento =
        this.state.dataNascimento?.length > 8 &&
        this.state.isValidDataNascimento;
      //const validSexo = this.state.sexo?.length > 0;

      const validContato =
        this.state.email?.length > 0 || this.state.telefone?.length >= 11;

      if (
        validNome &&
        this.state.isValidCPF &&
        validDataNascimento &&
        validContato
      )
        return true;
      else return false;
    }
  }

  onChangeNome(event: any) {
    this.setState({ nome: event.target.value });
    //this.validateForm();
  }

  onChangeCpf(event: any) {
    this.setState({ cpf: cpfMask(event.target.value) });
  }

  onFocusOutCpf(event: any) {
    const validCpf = validateCpf(this.state.cpf);
    this.setState({ isValidCPF: validCpf });
  }

  onChangeEmail(event: any) {
    this.setState({ email: event.target.value });
    //this.validateForm();
  }

  onChangeTelefone(event: any) {
    this.setState({ telefone: phoneMask(event.target.value) });

    const agendamentoContext = this.context as AgendamentoContextData;
    agendamentoContext.handleUpdateTelefone(event.target.value);
    //this.validateForm();
  }

  onChangeDate = (event: any) => {
    this.setState({ dataNascimento: event.target.value });
    this.setState({
      isValidDataNascimento: moment(
        event.target.value,
        "DD/MM/YYYY",
        "pt-BR",
        true
      ).isValid(),
    });
  };

  onBlurDate = (event: any) => {
    let fieldMasked = maskDate.replaceAll("9", "_");
    if (fieldMasked === event.target.value)
      this.setState({ dataNascimento: "" });
  };

  toggleTerms(): void {
    this.setState({ termChecked: !this.state.termChecked });

    const agendamentoContext = this.context as AgendamentoContextData;
    agendamentoContext.handleUpdateTermos(!this.state.termChecked);
    //this.validateForm();
  }

  loadClienteCPF = (cpf: string) => {
    const apiUrl = `clienteData/GetCliente/cpf/${cpf}`;
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "Error") {
          this.setState({ nome: data.clienteData.nome.toUpperCase() });
          const agendamentoContext = this.context as AgendamentoContextData;
          agendamentoContext.handleUpdateCliente(data.clienteData);

          this.setState({ cpf: data.clienteData.cpf });
          this.setState({ email: data.clienteData.email });
          this.setState({ telefone: data.clienteData.telefone });
          if (data.clienteData.dataNascimento) {
            let auxDataNascimento = moment(
              data.clienteData.dataNascimento.replace("Z", "").replace("z", "")
            ).format("DD/MM/YYYY");
            this.setState({
              dataNascimento: auxDataNascimento,
              dataNascimentoJaPreenchido:
                data.clienteData.dataNascimento ?? false,
            });
          }
          if (data.clienteData.sexo === "M")
            this.setState({ sexo: "MASCULINO" });
          else if (data.clienteData.sexo === "F")
            this.setState({ sexo: "FEMININO" });
          else this.setState({ sexo: data.clienteData.sexo });
        }
      });
  };

  podePagarOnline = (): boolean => {
    let codigoColaboradorUsuario = localStorage.getItem("_pmCodigoColaborador");

    let podePagar =
      !codigoColaboradorUsuario &&
      !!this.state.agendamentoAPagar &&
      !!this.state.agendamentoAPagar.lojaTemConfiguracaoPagamento &&
      !!this.state.servicoList &&
      Array.isArray(this.state.servicoList) &&
      this.state.servicoList.length > 0 &&
      this.state.servicoList.some((x) => x.preco !== 0);

    return podePagar;
  };

  render() {
    return (
      <>
        <Container>
          {
            // @ts-ignore
            this.buttonsContainer &&
              ReactDOM.createPortal(
                <Button
                  type="submit"
                  onClick={() => this.saveForm()}
                  disabled={!this.validateForm()}
                >
                  Concluir agendamento
                </Button>,
                this.buttonsContainer
              )
          }

          {!this.state.isSaveLoading && this.state.loadCount === 3 && (
            <div className="row">
              <div className="w-100">
                <p>{this.state.errorMessage}</p>
              </div>
            </div>
          )}

          <ContainerInfo>
            {this.state.isSaveLoading && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={IconLoading}
                  alt="loading"
                  className="spiner"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                  }}
                />
              </div>
            )}

            {!this.state.isSaveLoading && (
              <>
                <div className="personal-data">
                  <div className="title-container">
                    <img src={IconUserOutline} alt="Ícone de usuário" />
                    <h3>Dados pessoais</h3>
                  </div>

                  <div className="info-container hide-container">
                    <div className="info-row">
                      <div className="item">
                        <h4>Nome completo</h4>
                        <span>{this.state.nome}</span>
                      </div>

                      <div className="item">
                        <h4>CPF</h4>
                        <span>{this.state.cpf}</span>
                      </div>

                      <div className="item">
                        <h4>Gênero</h4>
                        <span>{this.state.sexo}</span>
                      </div>
                    </div>

                    <div className="info-row">
                      <div className="item">
                        <h4>Email</h4>
                        <span>{this.state.email}</span>
                      </div>

                      <div className="item">
                        <h4>Telefone</h4>
                        <span>{this.state.telefone}</span>
                      </div>

                      <div className="item">
                        <h4>Data de nascimento</h4>
                        <span>{this.state.dataNascimento}</span>
                      </div>
                    </div>
                  </div>

                  <div className="info-container-mobile">
                    <div className="info">
                      <div className="item">
                        <h4>Nome completo</h4>
                        <span>{this.state.nome}</span>
                      </div>
                    </div>

                    <div className="info">
                      <div className="item">
                        <h4>CPF</h4>
                        <span>{this.state.cpf}</span>
                      </div>

                      <div className="item">
                        <h4>Gênero</h4>
                        <span>{this.state.sexo}</span>
                      </div>
                    </div>

                    <div className="info">
                      <div className="item">
                        <h4>Data de nascimento</h4>
                        <span>{this.state.dataNascimento}</span>
                      </div>

                      <div className="item">
                        <h4>Telefone</h4>
                        <span>{this.state.telefone}</span>
                      </div>
                    </div>

                    <div className="info">
                      <div className="item">
                        <h4>Email</h4>
                        <span>{this.state.email}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.agendamentoAssistido ? (
                  <div className="order-summary order-summary-watched">
                    <div className="info-container" style={{ border: "none" }}>
                      <div className="update-data">
                        <ButtonEditData
                          href={this.props.linkDadosCliente}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Editar dados
                        </ButtonEditData>
                        {this.state.agendamentoAssistido &&
                          this.state.isLoading && (
                            <div>
                              <a>carregando...</a>
                            </div>
                          )}
                        {this.state.agendamentoAssistido &&
                          !this.state.isLoading && (
                            <div>
                              <a
                                href="/"
                                onClick={(event) => {
                                  event.preventDefault();
                                  //this.props.updateCliente(this.props.cliente.email)
                                  this.reloadClient();
                                }}
                              >
                                Atualizar dados
                                <svg
                                  width="19"
                                  height="15"
                                  viewBox="0 0 19 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9.49997 2.25C6.60372 2.25 4.24997 4.60375 4.24997 7.5H5.81622C6.20997 7.5 6.40247 7.9725 6.13122 8.24375L3.68997 10.685C3.51497 10.86 3.24372 10.86 3.06872 10.685L0.627466 8.24375C0.347466 7.9725 0.539966 7.5 0.933716 7.5H2.49997C2.49997 3.6325 5.63247 0.5 9.49997 0.5C10.41 0.5 11.285 0.675 12.0812 0.99875C12.6675 1.235 12.825 1.9875 12.3787 2.43375C12.1425 2.67 11.7837 2.76625 11.4687 2.635C10.865 2.38125 10.1912 2.25 9.49997 2.25ZM12.8775 6.7475L15.3187 4.30625C15.485 4.14 15.765 4.14 15.9312 4.315L18.3725 6.75625C18.6525 7.0275 18.46 7.5 18.0662 7.5H16.5C16.5 11.3675 13.3675 14.5 9.49997 14.5C8.58997 14.5 7.71497 14.325 6.91872 14.0012C6.33247 13.765 6.17497 13.0125 6.62122 12.5662C6.85747 12.33 7.21622 12.2337 7.53122 12.365C8.13497 12.6187 8.80872 12.75 9.49997 12.75C12.3962 12.75 14.75 10.3962 14.75 7.5H13.1837C12.79 7.5 12.5975 7.0275 12.8775 6.7475Z"
                                    fill="#7b8fa5"
                                  />
                                </svg>
                              </a>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="services">
                  <div className="info-container">
                    <div className="title-container">
                      <span className="title">
                        <img src={IconSuitcase} alt="Ícone de relógio" />
                        <h3>Serviços marcados</h3>
                      </span>
                      <span
                        className="time-container"
                        style={{
                          display: window.innerWidth < 1024 ? "none" : "flex",
                        }}
                      >
                        <img src={IconTime} alt="Ícone de relógio" />
                        Tempo Total de atendimento:{" "}
                        <span className="time" style={{ marginLeft: "5px" }}>
                          {this.state.totalTempo || 0} min
                          <img
                            src={IconInfo}
                            width="9"
                            height="9"
                            alt="Ícone de informação"
                            style={{
                              marginLeft: "8px",
                              marginRight: 0,
                            }}
                            onClick={() => {
                              this.setState({
                                modalBallon: !this.state.modalBallon,
                              });
                            }}
                          />
                        </span>
                      </span>

                      <div className="wrapper-ballon">
                        {this.state.modalBallon && (
                          <div className="modal-info-ballon">
                            <div className="ballon">
                              O total de atendimento contempla o tempo de
                              abertura de ficha + a realização dos exames
                              selecionados.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="info-row">
                      <span className="label">Local</span>
                      <span className="address">
                        <svg
                          width="15"
                          height="19"
                          viewBox="0 0 15 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.5 8.43704C6.72469 8.43704 6.09375 7.8061 6.09375 7.03079C6.09375 6.25548 6.72469 5.62454 7.5 5.62454C8.27531 5.62454 8.90625 6.25548 8.90625 7.03079C8.90625 7.8061 8.27531 8.43704 7.5 8.43704ZM7.5 3.74954C5.69063 3.74954 4.21875 5.22142 4.21875 7.03079C4.21875 8.84017 5.69063 10.312 7.5 10.312C9.30937 10.312 10.7812 8.84017 10.7812 7.03079C10.7812 5.22142 9.30937 3.74954 7.5 3.74954ZM7.5 16.5431C5.92969 15.0581 1.875 10.8891 1.875 7.42688C1.875 4.36594 4.39781 1.875 7.5 1.875C10.6022 1.875 13.125 4.36594 13.125 7.42688C13.125 10.8891 9.07031 15.0581 7.5 16.5431ZM7.5 0C3.36469 0 0 3.33094 0 7.42688C0 12.5597 6.60844 18.2822 6.88969 18.5231C7.06594 18.6741 7.2825 18.75 7.5 18.75C7.7175 18.75 7.93406 18.6741 8.11031 18.5231C8.39156 18.2822 15 12.5597 15 7.42688C15 3.33094 11.6353 0 7.5 0Z"
                            fill="#0054A6"
                          />
                        </svg>
                        {this.state.loja?.endereco}, {this.state.loja?.numero} -{" "}
                        {this.state.loja?.bairro}
                      </span>
                      <span className="label">Data e horário</span>
                      <span className="date">
                        {this.state.data} - {this.state.horario}
                      </span>
                    </div>
                    <div className="info-row">
                      <span className="label">
                        Serviço(s) prestado(s) por:{" "}
                      </span>
                      <div className="complement">
                        <div className="provider">
                          <FaRegCommentDots size="1.2em" />
                        </div>
                        <div className="provider">
                          <label>Serviço(s) prestado(s) por: </label>
                          <label>
                            {this.state.parceiroInfo?.nomeFantasia ??
                              "Pague Menos"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <span
                      className="time-container"
                      style={{
                        display: window.innerWidth < 1024 ? "flex" : "none",
                        marginBottom: window.innerWidth < 1024 ? "17px" : "0",
                      }}
                    >
                      <img src={IconTime} alt="Ícone de relógio" />
                      Tempo Total de atendimento:{" "}
                      <span className="time" style={{ marginLeft: "5px" }}>
                        {this.state.totalTempo || 0} min
                        <img
                          src={IconInfo}
                          width="9"
                          height="9"
                          alt="Ícone de informação"
                          style={{
                            marginLeft: "8px",
                            marginRight: 0,
                          }}
                        />
                      </span>
                    </span>

                    {this.state.servicoList.map((servico: Servico) => (
                      <div
                        className="service"
                        style={{
                          borderColor:
                            this.state.idServiceDetailsOpen === servico.id
                              ? "#007DC5"
                              : "#F2F2F2",
                        }}
                      >
                        <div className="info-short">
                          <div>
                            <h4>{servico.nomeAbreviado}</h4>
                            <span>{servico.subtitulo}</span>
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              if (
                                this.state.idServiceDetailsOpen === servico.id
                              ) {
                                this.setState({ idServiceDetailsOpen: 0 });
                              } else {
                                this.setState({
                                  idServiceDetailsOpen: servico.id,
                                });
                              }
                            }}
                          >
                            {this.state.idServiceDetailsOpen === servico.id
                              ? "Ocultar"
                              : "Mostrar"}
                            {this.state.idServiceDetailsOpen === servico.id ? (
                              <svg
                                width="15"
                                height="7"
                                viewBox="0 0 15 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.0043 -0.000472069C6.7763 -0.000472069 6.5493 0.0765276 6.3643 0.231528L0.364304 5.23153C-0.0596964 5.58453 -0.117696 6.21553 0.236304 6.63953C0.589304 7.06353 1.2193 7.12053 1.6443 6.76753L7.0153 2.29153L12.3773 6.60653C12.8073 6.95253 13.4373 6.88453 13.7833 6.45453C14.1293 6.02453 14.0613 5.39553 13.6313 5.04853L7.6313 0.220528C7.4483 0.0735283 7.2263 -0.000472069 7.0043 -0.000472069Z"
                                  fill="#0054A6"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="11"
                                height="6"
                                viewBox="0 0 11 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M5.39502 6.00025C5.11881 6.00025 4.8426 5.90225 4.6322 5.70725L0.316402 1.70725C-0.105467 1.31625 -0.105467 0.68425 0.316402 0.29325C0.738272 -0.09775 1.42017 -0.09775 1.84204 0.29325L5.40797 3.59825L8.96095 0.41825C9.39145 0.03525 10.0723 0.04625 10.4866 0.44325C10.9009 0.84025 10.889 1.47425 10.4607 1.85725L6.14489 5.71925C5.9345 5.90725 5.66476 6.00025 5.39502 6.00025Z"
                                  fill="#0054A6"
                                />
                              </svg>
                            )}
                          </button>
                        </div>

                        {this.state.idServiceDetailsOpen === servico.id && (
                          <div className="details">
                            <div className="row-services">
                              <div>
                                <h4>Coleta de material</h4>
                                <span>{servico.coletaMaterial}</span>
                              </div>

                              <div>
                                <h4>Preparo para o exame</h4>
                                <span>{servico.descricaoPreparo}</span>
                              </div>

                              <div>
                                <h4>Tempo de exame</h4>
                                <span>{servico.horaExecucao}</span>
                              </div>
                            </div>

                            <div className="row-services">
                              <div>
                                <h4>Recomendações</h4>
                                <span>{servico.descricaoRestricao}</span>
                              </div>

                              <div>
                                <h4>Resultado em</h4>
                                <span>{servico.tempoResultado} minutos</span>
                              </div>
                            </div>

                            <div className="row-services">
                              <div>
                                <h4>Descrição</h4>
                                <span>{servico.descricao}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                    <Divider
                      style={{
                        display: window.innerWidth < 1024 ? "flex" : "none",
                      }}
                    />
                  </div>
                </div>
                <div className="order-summary">
                  <div className="info-container">
                    <div className="title-container">
                      <h3>Resumo do pedido</h3>
                    </div>

                    {this.state.servicoList.map((servico: any) => (
                      <div className="info-row">
                        <span>{servico.nomeAbreviado}</span>
                        <span>{moneyFormatter.format(servico.preco)}</span>
                      </div>
                    ))}

                    <div className="info-row info-row-total">
                      <span>Total</span>
                      <span>
                        {moneyFormatter.format(this.state.totalPreco)}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </ContainerInfo>

          <ModalSuccess
            isOpen={this.state.successModalOpen}
            setIsOpen={this.setIsOpenModalSuccess}
            fecharModal={this.fecharModalSucesso}
            pagarAgendamento={this.pagarAgendamento}
            buttonTitle="Novo agendamento"
            typeIcon="success"
            showPaymentButton={this.podePagarOnline()}
            overflow={false}
          />

          <ModalError
            isOpen={this.state.errorModalOpen}
            setIsOpen={this.toggleErrorModal}
            buttonTitle="Voltar para a agenda"
            typeIcon="info"
          />

          <ModalEnderecoPagamento
            paymentTimeout={this.state.paymentTimeout}
            isOpen={this.state.enderecoPagamentoModalOpen}
            setIsOpen={this.setIsOpenModalEnderecoPagamento}
            idAgendamento={this.state.idAgendamento}
            appointmentToPay={this.state.agendamentoAPagar}
            onError={() => this.setState({ errorModal: true })}
            onHandler={() => {}}
            overflow={false}
            redirectToUrl={new URL(
              "pagamentoConfirmado",
              window.location.href
            ).toString()}
            setErrorMsg={(e: any) => {}}
          />

          <ModalErrorEndereco
            isOpen={this.state.errorModal}
            setIsOpen={() =>
              this.setState({ errorModal: !this.state.errorModal })
            }
            styles={{
              content: {
                position: "relative",
                inset: "0",
                border: "none",
                background: "#F0F0F5",
                overflow: "hidden",
                borderRadius: "8px",
                outline: "none",
                padding: "20px",
                maxWidth: "35%",
                width: "100%",
                height: "auto",
                margin: "auto",
              },
              overlay: {
                backgroundColor: "#121214b1",
                display: "flex",
                zIndex: 10,
              },
            }}
          />

          <ModalConfirmarNaoPagamento
            isOpen={this.state.confirmarNaoPagamentoModalOpen}
            pagarAgora={this.modalConfimacaoPagarAgora}
            pagarDepois={this.modalConfimacaoPagarDepois}
            overflow={false}
          />
        </Container>
        <ModalLoading isOpen={this.state.isServicoLoading} />
      </>
    );
  }
}
export default withRouter(FormComponent);
