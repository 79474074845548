import { useEffect, useState } from "react";
import HeaderTitle from "../HeaderTitle";
import { Container, PartnersContent } from "./styles";
import BoxPartner from "./BoxPartner";
import SliderPartners, { Settings } from "../SliderPartners";

interface Partern {
  imgUrl: string;
  linkUrl: string;
  id: number;
  descricao: string;
}

export default function Partners() {
  const [listPartners, setListPartners] = useState<Partern[]>();

  useEffect(() => {
    fetch("/servicoData/GetAllHomeParceiro")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setListPartners(data.items);
      })
      .catch((error) => console.log(error));
  }, []);

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
        },
      },
    ],
  };

  const conf: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Container>
      <div className="title">
        <HeaderTitle
          title="Nossos parceiros"
          boxRedTitle="Conheça"
          helperText=""
        />
      </div>

      {/* CARROSSEL */}
      {window.innerWidth > 1025 && (
        <SliderPartners settings={settings}>
          {listPartners?.map((partner: Partern) => (
            <BoxPartner
              key={partner.id}
              image={partner.imgUrl}
              name="logo"
              info={partner.descricao}
              link={partner.linkUrl}
            />
          ))}
        </SliderPartners>
      )}

      {window.innerWidth <= 1024 && (
        <SliderPartners settings={conf}>
          {listPartners?.map((partner: Partern) => (
            <BoxPartner
              key={partner.id}
              image={partner.imgUrl}
              name="logo"
              info={partner.descricao}
              link={partner.linkUrl}
            />
          ))}
        </SliderPartners>
      )}
    </Container>
  );
}
