import { createGlobalStyle } from 'styled-components';
import colors from '../tokens/colors';

export default createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    background-color: ${colors.white};
  }

  [disabled] {
    cursor: not-allowed;
  }
`;