import styled from 'styled-components';
import colors from '../../tokens/colors';

export const Container = styled.div`
  
`;

interface InputProps {
  cpfInvalid: boolean;
}

export const Content = styled.div<InputProps>`
  display: flex;
  align-items: center;
  margin: 60px auto 0 auto;
  flex-direction: column;
  width: 21.875rem;

  p {
    color: #666666;
    margin-left: -10px;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    text-align: center;
  }

  Strong {
    color:  ${(props) => props.cpfInvalid ? '#D14A4A' : '#828282'};
    font-size: 12px;
    font-weight: normal;
    justify-content: left;
  }

  input {
    height: 36px;
    width: 344px;
    border: 2px solid ${(props) => props.cpfInvalid ? '#D14A4A' : '#E3E4E6'};
    border-radius: 4px;
    padding: 8px 18px;
  }

  input::placeholder {
    color: #A9A9A9;
  }

  span {
    color: #D14A4A;
    font-size: 14px;
    margin-left: 10px;
  }
`;

export const ContentButtons = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 29px;
`;

export const ButtonCancel = styled.button`
  width: 150px;
  height: 43px;

  background: #FFFFFF;
  border: 1px solid ${colors.blue};
  box-sizing: border-box;
  border-radius: 6px;

  color: ${colors.blue};
`;

interface ContinueProps {
  isPermission: string | boolean;
}

export const ButtonContinue = styled.button<ContinueProps>`
  width: 150px;
  height: 43px;
  margin-left: 20px;

  background: ${(props) => props.isPermission ? colors.green : '#E0E0E0'};
  border-radius: 6px;

  border: 1px solid #E0E0E0;
  box-sizing: border-box;

  color: #fff;
`;

export const ContentAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0 auto;
  flex-direction: column;
  width: 376px;
  text-align: center;

  p {
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
  }

  span {
    font-weight: normal;
    font-size: 14px;
  }
`;

export const ButtonRegister = styled.a`
  padding-top: 10px;
  height: 43px;
  background: ${colors.blue};
  border-radius: 6px;
  color: #FFFFFF;

  margin-top: 30px;
  width: 320px;
  height: 43px;

  text-align: center;
  border: 0;  
  
   :hover {
    color: #FFFFFF;
    text-decoration: none;
   }
`;