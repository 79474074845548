import Modal from 'react-modal';
import * as S from './styles';
import iconCloseModal from '../../images/icons/icon-close.svg';
import { Form } from '@unform/web';
import { InputCalendar } from '../InputCalendar';
import { useState } from 'react';
import { convert, dateFormat } from '../../utils';
import { InputSelect } from '../InputSelect';

interface HistoricoBeneficioServicoData {
  id: number;
  nome: string;
  codProduto: string;
}

interface ModalFiltroHistoricoBeneficiosProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onContinue: (servico: string, periodoDe: string, periodoAte: string) => void;
  servicos?: HistoricoBeneficioServicoData[]
}

export function ModalFiltroHistoricoBeneficios({ isOpen, onRequestClose, onContinue, servicos }: ModalFiltroHistoricoBeneficiosProps) {
  const [servico, setServico] = useState('');
  const [periodoDe, setPeriodoDe] = useState('');
  const [periodoAte, setPeriodoAte] = useState('');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      portalClassName="modal-FiltroHistoricoBeneficios"
      style={{
        content: {
          top: '50%',
          left: '50%',
          marginRight: '-40%',
          transform: 'translate(-50%, -50%)',
          color: '#000000',
          borderRadius: '8px',
          width: '100%',
          maxWidth: (window.innerWidth >= 1024) ? '502px' : '338px',
          height: 'fit-content',
          maxHeight: '465px',
          border: 'none',
          padding: '0',
          marginTop: 40,
          overflow: 'visible'
        },
        overlay: {
          backgroundColor: '#231f2066',
        },
      }}
    >
      <S.Container>
        <button type="button" className="close">
          <img src={iconCloseModal} alt="Close" onClick={onRequestClose} />
        </button>

        <S.ModalHeader>
          <h2>Filtro</h2>
        </S.ModalHeader>

        <S.ModalContent>
          <Form onSubmit={() => { }}>
            <div className="row" style={{ display: 'block' }}>
              <label htmlFor="servico">Serviço</label>
              <InputSelect
                name="servico"
                placeholder="Selecione"
                newValue={{
                  label:
                    servicos?.find((item: any) => item.id === servico)?.nome ||
                    "Selecione",
                  value: servico ? servico : "",
                }}
                options={servicos?.map(servico => ({
                  value: servico.id,
                  label: servico.nome
                }))}
                value={servico}
                onChange={(event: any) => setServico(event.value)}
              />
            </div>
            <div className="row" style={{ marginTop: '15px' }}>
              <div style={{ marginRight: 2 }}>
                <InputCalendar
                  name="periodoDe"
                  title="Período"
                  isRange={false}
                  placeholder="00/00/0000"
                  handleDateChange={value => setPeriodoDe(dateFormat(value as Date))}
                  {...(periodoDe != "") ? { defaultDateSelected: new Date(convert({ dateString: periodoDe, pattern: "yyyy-mm-dd" })) } : {}}
                  showAllDates={true}
                />
              </div>
              <span style={{ padding: '0 16px' }}>
                <p>&nbsp;</p>
                <p>à</p>
              </span>
              <div>
                <InputCalendar
                  name="periodoAte"
                  title="⠀"
                  isRange={false}
                  placeholder="00/00/0000"
                  handleDateChange={value => setPeriodoAte(dateFormat(value as Date))}
                  {...(periodoAte != "") ? { defaultDateSelected: new Date(convert({ dateString: periodoAte, pattern: "yyyy-mm-dd" })) } : {}}
                  showAllDates={true}
                />
              </div>
            </div>
          </Form>
        </S.ModalContent>

        <S.ModalFooter>
          <S.ButtonCancel onClick={onRequestClose}>Cancelar</S.ButtonCancel>
          <S.ButtonGoForward
            disabled={
              servico === "" &&
              periodoDe === "" &&
              periodoAte === ""
            }
            onClick={() => {
              onContinue(servico, periodoDe, periodoAte);
            }}>Aplicar</S.ButtonGoForward>
        </S.ModalFooter>
      </S.Container>
    </Modal>
  );
}