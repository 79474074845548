import { Component } from 'react';

import { AgendamentoContextData, AgendamentoContext } from '../../../contexts/agendamento';

import { ButtonConf, ButtonFloat } from './styles';

class Button extends Component<any, any> {
    static contextType = AgendamentoContext;

    constructor(props: any) {
        super(props);

        this.state = {
            innerWidth: 0
        }
    }

    componentDidMount() {
        this.setState({ innerWidth: window.innerWidth });
        window.addEventListener('resize', (event) => {
            this.resizeScreen()
        });
    }

    resizeScreen() {
        if (window.innerWidth <= 360)
            this.setState({ innerWidth: 360 });
        else if (window.innerWidth < 420)
            this.setState({ innerWidth: 420 });
        else
            this.setState({ innerWidth: window.innerWidth });
    }

    componentDidUpdate() {
    }

    render() {
        return (
            <>
                <ButtonConf onClick={this.props.onClick} disabled={this.props.disabled} inverted={this.props.nextButtonActive} context={this.props.context}>
                    <span>Continuar</span>
                </ButtonConf>
                {this.state.innerWidth > 420}
                {/*this.state.innerWidth <= 420 &&
                    <div id="outer">
                        <div id="inner">
                            <ButtonFloat onClick={this.handleButton} inverted={this.props.nextButtonActive}>
                                    <span>Continuar</span>
                            </ButtonFloat>
                        </div>
                    </div>
                */}
            </>
        );
    }
}
export default Button;