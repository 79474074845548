import { useState, useEffect } from "react";
import {
  Container,
  ContainerRow,
  ContainTypeService,
  Button,
  ContainerBtn
} from "./styles";
import { useMobile } from "../../../hooks/useMobile";
import ServicesCard from "../ServicesCard";
import TypeService from "../TypeService";

import HomeServicoConfigService from "../../../services/HomeConfigService";

import IHomeServico from "../../../types/HomeServico";

// interface HomeServicoConfig {
//     id: number;
//     subCategoriaId: number;
//     servicoId: number;
//     categoriaId: number;
//     linkUrl: string;
//     inicioVigencia: Date;
//     fimVigencia: Date;
//     ativo: boolean;
//     tipo: number;
// }
// interface ServicoData {
//     id: number;
//     imgUrl: string;
//     titulo: string;
//     subTitulo: string;
//     linkUrl: string;
//     tipo: number;
//     ordem: number;
//     config: HomeServicoConfig;
// }

export default function ContainCards(props: any) {
  const [servicos, setServicos] = useState<IHomeServico[]>();
  const [servicosFooter, setServicosFooter] = useState<IHomeServico[]>();
  const [row1, setRow1] = useState<IHomeServico[]>();
  const [row2, setRow2] = useState<IHomeServico[]>();
  const [row3, setRow3] = useState<IHomeServico[]>();
  const [rowFooter, setRowFooter] = useState<IHomeServico[]>();
  const [previwerServices, setPreviwerServices] = useState<IHomeServico[]>();
  const [showServices, setShowServices] = useState<IHomeServico[]>();
  const isMobile = useMobile();
  const [tagBtn, setTagBtn] = useState("Ver mais");
  const [flag, setFlag] = useState(false);
  const { toggleIsOpenModal } = props;

  useEffect(() => {
    (async () => {
      await HomeServicoConfigService.getAllHomeServicoConfig()
        .then((response) => {
          if (response?.status !== 200 || !response.data) return;

          setServicos(
            response.data.filter((homeServico) => homeServico.tipo === 1)
          );
          setServicosFooter(
            response.data.filter((homeServico) => homeServico.tipo === 2)
          );
        })
        .catch((e: Error) => {
          console.error(e);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRow1(servicos?.slice(0, 3));
    setRow2(servicos?.slice(3, 6));
    setRow3(servicos?.slice(6, 9));
    setRowFooter(servicosFooter?.slice(0, 3));
    setPreviwerServices(servicos?.slice(0, 4));
    setShowServices(previwerServices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicos, servicosFooter]);

  function btn() {
    // if (flag === 0) {
    //     setShowServices(servicos)
    //     setTagBtn("Ver menos")
    //     setFlag(1)
    // } else if (flag === 1) {
    //     setShowServices(previwerServices)
    //     setTagBtn("Ver mais")
    //     setFlag(0)
    // }

    setShowServices(flag ? previwerServices : servicos);
    setTagBtn(flag ? "Ver mais" : "Ver menos");
    setFlag(!flag);
  }

  const handleClickSubCategoria = (servico: IHomeServico) => {
    if (
      !servico.config ||
      !servico.config?.categoriaId ||
      !servico.config?.subCategoriaId
    )
      return;

    toggleIsOpenModal();
    fetch(
      `/servicoData/GetAllPorCategoria?idCategoria=${servico.config?.categoriaId}`
    )
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("_subCategorias", JSON.stringify(data.items));

        localStorage.setItem(
          "_subCatHomeSelected",
          JSON.stringify(servico.config?.subCategoriaId)
        );
        localStorage.setItem("_pmLastStep", "/subCategoria");
        localStorage.setItem(
          "_tituloSubCategorias",
          JSON.stringify(
            servico.config?.categoriaId === 1 ? "farmacêuticos" : "de vacinação"
          )
        );
        localStorage.setItem(
          "_flagReload",
          JSON.stringify({
            reload: true,
          })
        );
      })
      .then(
        () =>
          (window.location.href = `/hubdesaude/subCategoria?csub=${servico.config?.categoriaId}`)
      )
      .catch((error) => console.log(error))
      .finally(() => toggleIsOpenModal());
  };

  const renderServiceCard = (servicos?: IHomeServico[]) => {
    return (
      <>
        {servicos?.map((servico) => (
          <ServicesCard
            key={servico.id}
            handleClick={
              servico.config?.subCategoriaId &&
              servico.config?.subCategoriaId >= 0
                ? () => handleClickSubCategoria(servico)
                : undefined
            }
            linkRedirect={!servico.config ? servico.linkUrl : undefined}
            title={servico.titulo}
            img={servico.imgUrl}
          />
        ))}
      </>
    );
  };

  const renderServicos = () => {
    return isMobile ? (
      <Container>
        <ContainerBtn>
          <Button onClick={btn}>{tagBtn}</Button>
        </ContainerBtn>
        <ContainTypeService>
          {rowFooter?.map((row) => (
            <TypeService
              title={row.titulo}
              subtitle={row.subTitulo}
              linkRedirect={row.linkUrl}
              img={row.imgUrl}
            />
          ))}
        </ContainTypeService>
      </Container>
    ) : (
      <Container>
        <ContainerRow key={"row1"}>
          <>{renderServiceCard(row1)}</>
        </ContainerRow>
        <ContainerRow key={"row2"}>
          <>{renderServiceCard(row2)}</>
        </ContainerRow>
        <ContainerRow key={"row3"}>
          <>{renderServiceCard(row3)}</>
        </ContainerRow>
      </Container>
    );
  };

  return <>{renderServicos()}</>;
}
