import React, { useState, useEffect } from 'react';

import { useMobile } from '../../hooks/useMobile';
import SeeBenefits from '../SeeBenefits';
import Slider, { Settings } from '../Slider';
import { Container, Banner } from './styles';

import HomeBannerService from "../../services/HomeBannerService";

import IBanner from "../../types/Banner";

const Banners: React.FC = () => {
  const [banners, setBanners] = useState<IBanner[]>([]);
  const isMobile = useMobile();

  useEffect(() => {
    loadBanners();
  }, []);

  async function loadBanners() {
    // const response = await fetch('/servicoData/GetAllBanner');
    await HomeBannerService.getAllHomeBannerConfig()
      .then(response => {
        if (response?.status !== 200 || !response.data) return;

        setBanners(response.data);
      })
      .catch((e: Error) => console.error(e));
  };

  const settings: Settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000
  };

  const handleClick = (banner: IBanner) => {
    console.log(banner);

    if(!banner.config || !banner.config?.categoriaId || !banner.config?.subCategoriaId)
      return;

      fetch(`/servicoData/GetAllPorCategoria?idCategoria=${banner.config?.categoriaId}`)
            .then(response => response.json())
            .then(data => {
                localStorage.setItem('_subCategorias', JSON.stringify(data.items));

                localStorage.setItem('_subCatHomeSelected', JSON.stringify(banner.config?.subCategoriaId));
                localStorage.setItem('_pmLastStep', '/subCategoria');
                localStorage.setItem('_tituloSubCategorias', JSON.stringify(
                    banner.config?.categoriaId === 1 ? 'farmacêuticos' : 'de vacinação'
                ));
                localStorage.setItem('_flagReload', JSON.stringify({
                    reload: true,
                }));
            })
            .then(() => window.location.href = `/hubdesaude/subCategoria#subcategoria${banner.config?.subCategoriaId}`)
            .catch(error => console.log(error));
  };

  const renderBannerConfig = (banner: IBanner) => {
    return (
      <Banner key={banner.id}
        onClick={() => handleClick(banner)}
      >
        <img src={((isMobile && banner.config?.mobileImg) ? banner.config.mobileImg : banner.config?.desktopImg ? banner.config?.desktopImg : banner.desktopImg)} alt="Banner" />
      </Banner>
    );
  };

  return (
    <Container>
      <Slider settings={settings}>
        {banners.map(banner => (
          !banner.config ?
            <Banner key={banner.id} href={banner.linkUrl !== "" ? banner.linkUrl : "#"} target={banner.linkUrl !== "" ? "_blank" : ""}>
              <img src={isMobile ? banner.mobileImg : banner.desktopImg} alt="Banner" />
            </Banner>
            :
            renderBannerConfig(banner)
        ))}
      </Slider>
      <SeeBenefits />
    </Container>
  );
}

export default Banners;