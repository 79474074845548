import styled from 'styled-components';

export const Container = styled.button`
    margin: 0;
    border: 0;
    width: 100%;
    height: auto;
    box-shadow: 1px 1px 10px rgba(0, 24, 48, 0.22);
    background: transparent;
    border-radius: 5px;
    color: #0054A6;
    font-size: 18px;
    font-weight: 700; 
    margin: 20px 0;

    @media (max-width: 1024px) {
        margin-top: 0;
        height: 25px;
        text-align: center;
        box-shadow: none;
        margin-bottom: 25px;
        text-decoration: underline;

        img { 
            display: none;
        }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        margin-bottom: 23px;
    }

    &:hover {
        filter: brightness(0.8);
    }
`;