const axiosConfig = {
    validateStatus: function (status) {
        return status >= 200 && status < 503
    },
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
}

export default axiosConfig
