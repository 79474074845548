import { Component } from 'react';
import '../style.css'

class Cidade extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            loadCount: 1,
            errorMessage: "",
            cidadeList: [],

            uf: "",
            cidade: "",
            innerWidth: 0
        }

        this.handleSelect = this.handleSelect.bind(this);
        this.resizeScreen = this.resizeScreen.bind(this);
    }

    componentDidMount() {
        this.setState({ innerWidth: window.innerWidth });
        window.addEventListener('resize', (event) => {
            this.resizeScreen()
        });

    }

    componentDidUpdate() {
        if (this.props.selectedUf !== this.state.uf) {
            this.setState({ cidadeList: [] });
            this.setState({ uf: this.props.selectedUf });

            let arrayServicos: any[] = [];

            const sServicos = localStorage.getItem('_ApmServicos');
            if (sServicos !== null)
                arrayServicos = JSON.parse(sServicos);

            let uf = this.props.selectedUf;
            if (arrayServicos.length > 0 && uf !== null && uf !== "") {

                const servicos = arrayServicos.map(function (elem) { return elem.id; }).join(",");
                this.loadCidades(servicos, uf);
            }
        }
    }

    loadCidades(servicos: string, uf: string) {
        console.log(`Buscando Cidades ${this.state.loadCount}x`);
        this.setState({ isLoading: true });

        const apiUrl = `lojaData/GetCidades?servicos=${servicos}&uf=${uf}&parceiro=${this.props.isPartnerServices}`;
        fetch(apiUrl, { cache: "no-cache" })
            .then(response => response.json())
            .then(data => {

                if (data.result === "Error") {
                    if (data.code === "001" && this.state.loadCount < 3) {
                        this.setState({ loadCount: this.state.loadCount + 1, errorMessage: data.message });
                        this.loadCidades(servicos, uf);
                    }
                } else {
                    this.setState({ cidadeList: data.items });
                }
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    handleSelect(event: any) {
        this.props.onChange(event);
    }

    resizeScreen() {
        this.setState({ innerWidth: window.innerWidth });
    }

    render() {
        return (
            <>

                <div className="DropDownCidade">
                    <label className="labelDropDown">Cidade</label>
                    <select onChange={this.handleSelect} className="form-control DropDown" disabled={this.state.cidadeList?.length === 0} >

                        {this.state.innerWidth >= 1024 &&
                            <>
                                <option value="">Selecione sua Cidade</option>
                            </>
                        }
                        {this.state.innerWidth < 1024 &&
                            <>
                                <option value="">Selecione</option>
                            </>
                        }
                        {
                            this.state.cidadeList && (
                                this.state.cidadeList.map((item: any) => (
                                    <option key={item.nome} value={item.nome} selected={this.props.selectedCidade.toLowerCase() === item.nome.toLowerCase()}>{item.descricao}</option>
                                )))
                        }
                    </select>
                </div>
                {!this.state.isLoading && this.state.loadCount === 3 && (
                    <div className="row">
                        <div className="w-100">
                            <p>{this.state.errorMessage}</p>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default Cidade;