import { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import ModalPagamentoConfirmado from '../components/Paciente/ModalPagamentoConfirmado';

const PagamentoConfirmado: React.FC = () => {
    const [pagamentoConfimadoModalOpen, setPagamentoConfimadoModalOpen] = useState(true);
    const history = useHistory();

    function ToggleModalPagamentoConfirmado() {
        history.push('/hubdesaude/agenda');
    }

    return (
        <ModalPagamentoConfirmado
            isOpen={pagamentoConfimadoModalOpen}
            setIsOpen={ToggleModalPagamentoConfirmado}
            buttonTitle="Fazer outro agendamento"
            typeIcon="success"
        />
    );
}

export default withRouter(PagamentoConfirmado);