import Button from "../../components/Button";
import Modal from "react-modal";
import { ReactNode, useState } from "react";
import IconClose from "../../images/icons/icon-close.svg";
import { Container, ButtonNewAppointment } from "./styles";
import { CSSProperties } from "styled-components";

interface SimpleModalProps {
  onRequestClose: () => void;
  confirm: () => void;
  onCancel?: () => void;
  isOpen: boolean;
  showCancel?: boolean;
  hideButtons?: boolean;
  linkAgendamento?: string;
  icon: string;
  title: string | ReactNode;
  message: string | ReactNode;
  messageFontSize?: string;
  messageFontFamily?: string;
  continueButtonText?: string;
  cancelButtonText?: string;
  buttonsMarginTop?: string;
  widthButtonContinue?: string;
  widthButtonClose?: string;
  heightButton?: string;
  marginTitle?: string;
  marginTitleBottom?: string;
  marginButton?: string;
  isIconClose?: boolean;
  containerStyle?: CSSProperties;
  imgStyle?: CSSProperties;
  isAnimation?: boolean;
  buttonFontFamily?: string;
  className?: string;
  backgroundButtonClose?: string;
  backgroundButtonContinue?: string;
  colorButtonClose?: string;
  Height?: string;
  widthButtonContinueMobile?: string;
  widthButtonCloseMobile?: string;
}

export function ModalSimple({
  onRequestClose,
  confirm,
  isOpen,
  showCancel = false,
  hideButtons = false,
  linkAgendamento = "",
  icon,
  title,
  message,
  messageFontSize = '16px',
  messageFontFamily = 'Roboto',
  continueButtonText = "Continuar",
  cancelButtonText = "Cancelar",
  buttonsMarginTop = "32px",
  widthButtonClose = "123px",
  widthButtonContinue,
  heightButton = "48px",
  marginTitle = "24px",
  marginTitleBottom = "",
  marginButton,
  onCancel,
  isIconClose,
  containerStyle = {
    marginTop: '-10px'
  },
  imgStyle = {},
  isAnimation = false,
  buttonFontFamily = 'Roboto',
  className = '',
  backgroundButtonClose,
  backgroundButtonContinue,
  colorButtonClose,
  Height,
  widthButtonContinueMobile,
  widthButtonCloseMobile
}: SimpleModalProps) {

  const [isConfirmed, setIsConfirmed] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      portalClassName={`modal-simple ${className}`}
      style={{
        content: {
          top: '50%',
          left: '50%',
          marginRight: '-40%',
          transform: 'translate(-50%, -50%)',
          color: '#000000',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '502px',
          height: Height || 'fit-content',
          maxHeight: Height || '336px',
          minHeight: Height || '314px',
          border: 'none',
          marginTop: 0,
          overflow: 'visible',
          padding: '50px 14px'
        },
        overlay: {
          backgroundColor: '#231f2066',
        },
      }}
    >
      <Container style={containerStyle} showCancel={showCancel} buttonsMarginTop={buttonsMarginTop} isAnimation={isAnimation}>

        {!hideButtons && 
          <img
            src={IconClose}
            alt="close"
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              width: "24px",
              height: "24px",
              cursor: "pointer"
            }}
            onClick={onRequestClose}
          />
        }

        <img src={icon} alt={String(title)} style={imgStyle} />
        <h2 style={{ marginTop: marginTitle, marginBottom: marginTitleBottom, fontSize: '24px', fontFamily: 'Roboto', }}>
          {title}
        </h2>
        <p style={{
          fontSize: '14px',
          fontFamily: 'Roboto',
        }}>{message}</p>
        <div className={marginButton}>
          {showCancel && !hideButtons && (
            <Button
              onClick={onCancel ? onCancel : onRequestClose}
              width={widthButtonClose}
              height={heightButton}
              widthButtonCloseMobile={widthButtonCloseMobile}
              inverted
              style={{
                fontFamily: buttonFontFamily,
                height: '43px',
                color: colorButtonClose,
                backgroundColor: backgroundButtonClose ? backgroundButtonClose : ''
              }}
            >
              {cancelButtonText}
            </Button>
          )}
          {!hideButtons && !linkAgendamento && (
            <Button
              onClick={confirm}
              isConfirmed={isConfirmed}
              setIsConfirmed={() => setIsConfirmed}
              width={widthButtonContinue}
              widthButtonContinueMobile={widthButtonContinueMobile}
              height={heightButton}
              style={{
                fontFamily: buttonFontFamily,
                height: '43px',
                backgroundColor: backgroundButtonContinue ? backgroundButtonContinue : ''
              }}
            >
              {isConfirmed ? isConfirmed : continueButtonText}
            </Button>
           )}
            {linkAgendamento && (
                <ButtonNewAppointment href={linkAgendamento} target="_top">Novo agendamento</ButtonNewAppointment>
            )}
        </div>
      </Container>
    </Modal>
  );
}
