import React, { useState, useEffect, useCallback } from 'react';
import InputMask from 'react-input-mask';

import IconLoading from '../../images/icons/Loading.svg';
import { useMobile } from '../../hooks/useMobile';
import { Modal, Benefits } from './components';
import IconTimeExceeded from '../../images/icons/time-exceeded.svg';
import IconNoDataRegistered from '../../images/icons/no-data-registered.svg';
import IconNoInfo from '../../images/icons/no-info-medical.svg';
import IconNoBenefits from '../../images/icons/no-benefits.svg';
import IconSecureRecaptcha from '../../images/icons/secure-recaptcha.svg';
import {
  QueryContainer,
  TextContainer,
  FindContainer,
  NoBenefits,
  NoBenefitsButton,
  ReactModal,
  overlaySylesModal,
  overlaySyles,
  overlaySylesMobile,
  overlaySylesModalMobile,
  contentStyles,
  contentStylesMobile
} from './styles';

export interface IBenefit {
  id: number;
  name: string;
  renews: string;
  quantity: string;
}

export interface IBenefitAPI {
  idServico: number;
  servico: string;
  renovaEm: string;
  quantidadeDisponivel: number;
  quantidadeTotal: number;
}

export interface ISponsorAPI {
  nome: string;
}

const SeeBenefits: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [openModalTimeExceeded, setOpenModalTimeExceeded] = useState(false);
  const [openModalNoDataRegistered, setOpenModalNoDataRegistered] = useState(false);
  const [openModalNoInfo, setOpenModalNoInfo] = useState(false);
  const [openModalRecaptcha, setOpenModalRecaptcha] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);
  const [cpf, setCpf] = useState('');
  const [goldClient, setGoldClient] = useState(true);

  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [benefits, setBenefits] = useState<IBenefit[]>([]);
  const [sponsors, setSponsors] = useState<string[]>([]);

  const [linkGoldClient, setLinkGoldClient] = useState('');
  const [linkSempreBemClub, setLinkSempreBemClub] = useState('');

  const isMobile = useMobile();

  const handleToggleModal = useCallback(() => {
    document.body.style.overflow = open ? 'initial' : 'hidden';
    setOpen(prevState => !prevState);
    window.CustomScrollToTop();
  }, [open]);

  const startClosingTimer = useCallback(() => {
    /** 
     * How long will be set to close the modal automatically
     */
    const TIME = 300000;

    var id = setTimeout(() => {
      handleToggleModal();
      handleToggleModalTimeExceeded();
    }, TIME);

    return Number(id);
  }, [handleToggleModal]);

  function handleToggleModalTimeExceeded() {
    setOpenModalTimeExceeded(prevState => !prevState);
    blockedScroll();
    window.CustomScrollToTop(); 
  }

  function handleCloseModal(modal: string) {
    if (modal === 'timeExceeded') setOpenModalTimeExceeded(false);
    else if (modal === 'noDataRegistered') setOpenModalNoDataRegistered(false);
    else setOpenModalNoInfo(false);
    document.body.style.overflow = 'initial';
  }

  function blockedScroll() {
    document.body.style.overflow = 'hidden';
  }

  async function handleConsultBenefits() {
    if (!cpf) return;

    setLoading(true);

    const response = await fetch(`/beneficioData/cpf/${cpf}`);
    const json = await response.json();

    if (!json?.beneficioCliente?.nome) {
      blockedScroll();
      setOpenModalNoDataRegistered(true);
      setLoading(false);
      setCpf('');
      return;
    }

    setName(json.beneficioCliente.nome);

    if (!json.beneficioCliente.beneficios) {
      blockedScroll();
      setOpenModalNoInfo(true);
      setLoading(false);
      setCpf('');
      setGoldClient(false);
      return;
    }

    const benefitsArray: IBenefit[] = json.beneficioCliente.beneficios.map((beft: IBenefitAPI) => {
      return {
        id: beft.idServico,
        name: beft.servico,
        renews: beft.renovaEm.toLowerCase(),
        quantity: beft.quantidadeDisponivel + ' de ' + beft.quantidadeTotal
      }
    });

    setBenefits(benefitsArray);

    const sponsorsArray: string[] = json.beneficioCliente.parcerias.map((sps: ISponsorAPI) => {
      return sps.nome;
    });

    setSponsors(sponsorsArray);

    setLoading(false);
    setCpf('');

    handleToggleModal();
  }

  async function loadLinksExternals() {
    const responseProgramaFidelidade = await fetch('/BeneficioData/LinkProgramaFidelidade');
    const jsonProgramaFidelidade = await responseProgramaFidelidade.json();
    setLinkGoldClient(jsonProgramaFidelidade.linkProgramaFidelidade);

    const responseSempreBemClub = await fetch('/BeneficioData/LinkSempreBemClub');
    const jsonSempreBemClub = await responseSempreBemClub.json();
    setLinkSempreBemClub(jsonSempreBemClub.linkSempreBemClub);
  }

  function handleVerifyRobot(token: string | null) {
    window.createDivWorkArround();
    setOpenModalRecaptcha(false);
    handleConsultBenefits();
  }

  useEffect(() => {
    loadLinksExternals();
  }, []);

  useEffect(() => {
    if (open) {
      const id = startClosingTimer();
      setCpf('');
      setTimeoutId(id);
    } else {
      clearInterval(timeoutId);
    }
  }, [open]);

  function handleModalRecaptcha(e: any) {
    e.preventDefault();
    setOpenModalRecaptcha(true);

    if (window.innerWidth < 1024) {
      window.CustomScrollToTop();
      window.removeDivWorkArround();
    }
  }

  return (
    <>
      {goldClient &&
        <QueryContainer>
          <TextContainer>
            <h2>Consulte seus benefícios</h2>
            <p>
              Digite o seu CPF e saiba de
              <strong> maneira fácil e segura </strong>
              quais os benefícios você tem a sua disposição.
            </p>
          </TextContainer>

          <FindContainer>
            <form onSubmit={(e) => {
              handleModalRecaptcha(e)
            }}>
              <InputMask
                type="text"
                placeholder="000.000.000-00"
                mask="999.999.999-99"
                value={cpf}
                onChange={(event) => setCpf(event.target.value)}
              />
              {loading ? (
                <div style={{
                  display: "flex",
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <img src={IconLoading} alt="loading" className='spiner' style={
                    {
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  } />
                </div>
              ) : (
                <button type="submit" disabled={loading}>Consultar</button>
              )}
            </form>
          </FindContainer>
        </QueryContainer>
      }
      {!goldClient &&
        <QueryContainer>
          <NoBenefits>
            <img src={IconNoBenefits} alt="Você não tem benefícios" />
            <TextContainer gold>
              <h2>Você não tem benefícios</h2>
              <p>
                Para obter o desconto é necessário o cadastro do CPF no programa.
              </p>
            </TextContainer>
          </NoBenefits>
          <nav>
            <NoBenefitsButton href={linkGoldClient} target="_blank" gold>Cliente Ouro</NoBenefitsButton>
            <NoBenefitsButton href={linkSempreBemClub} target="_blank">Sempre Bem Saúde</NoBenefitsButton>
          </nav>
        </QueryContainer>
      }

      <ReactModal
        isOpen={open}
        style={{
          overlay: isMobile ? overlaySylesMobile : {...overlaySyles, alignItems: 'flex-start'},
          content: isMobile ? contentStylesMobile : contentStyles
        }}
      >
        <Benefits
          handleToggleModal={handleToggleModal}
          data={{
            name,
            loading,
            benefits,
            sponsors
          }}
        />
      </ReactModal>

      <ReactModal
        isOpen={openModalTimeExceeded}
        style={{
          overlay: isMobile ? overlaySylesModalMobile : overlaySylesModal,
          content: isMobile ? contentStylesMobile : contentStyles
        }}
      >
        <Modal
          handleClose={() => handleCloseModal('timeExceeded')}
          Icon={IconTimeExceeded}
          title="Tempo excedido"
          description="O tempo limite de 5 minutos foi excedido. Caso tenha queira ver os dados novamente, basta digitar o CPF no mesmo campo e realizar a consulta novamente."
        />
      </ReactModal>

      <ReactModal
        isOpen={openModalNoDataRegistered}
        style={{
          overlay: isMobile ? overlaySylesModalMobile : overlaySylesModal,
          content: isMobile ? contentStylesMobile : contentStyles
        }}
      >
        <Modal
          handleClose={() => handleCloseModal('noDataRegistered')}
          Icon={IconNoDataRegistered}
          title="Nenhum dado cadastrado"
          description="Não há usuário cadastrado com este CPF em nossa plataforma. Verifique os números digitados e tente consultar novamente."
        />
      </ReactModal>

      <ReactModal
        isOpen={openModalNoInfo}
        style={{
          overlay: isMobile ? overlaySylesModalMobile : overlaySylesModal,
          content: isMobile ? contentStylesMobile : contentStyles
        }}
      >
        <Modal
          handleClose={() => handleCloseModal('noInfo')}
          Icon={IconNoInfo}
          title="Opsss!!!"
          subtitle="Não encontramos nenhum beneficio."
          description="Para ter acesso torne-se um cliente Ouro ou membro do Viva bem Club."
          noBenefits
        />
      </ReactModal>

      <ReactModal
        isOpen={openModalRecaptcha}
        style={{
          overlay: isMobile ? overlaySylesModalMobile : overlaySylesModal,
          content: isMobile ? contentStylesMobile : contentStyles,
        }}
      >
        <Modal
            handleClose={() => {
                setOpenModalRecaptcha(false);
                window.createDivWorkArround();
            }}
          Icon={IconSecureRecaptcha}
          title="Verificação"
          isRecaptcha
          onChangeRecaptcha={handleVerifyRobot}
        />
      </ReactModal>
    </>
  );
}

export default SeeBenefits;