import CardShowcase from './CardShowcase';
import { Container, Carousel, HeaderContent } from './styles';
import SliderScheduleServices, { Settings } from '../../SliderScheduleServices';
import { useHistory } from 'react-router';
import IServico from '../../../types/Servico';
interface CarouselCardsProps {
    id: number;
    name: string | undefined;
    description: string | undefined;
    vaccination?: IServico[];
    pharmaceutical?: IServico[];
    toggleIsOpenModal: () => void;
}

export default function CarouselCards({ name, description, id, vaccination, pharmaceutical, toggleIsOpenModal }: CarouselCardsProps) {

    const history = useHistory();
    const goToSubCategory = (categoryId: number) => { window.location.href = `/hubdesaude/subCategoria?csub=${categoryId}` };

    const settings: Settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    dots: true,
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    speed: 500,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    dots: true,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 500,
                },
            },
        ]
    };

    const conf: Settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    function handleMoveToSubCategory() {
        toggleIsOpenModal();
        fetch(`/servicoData/GetAllPorCategoria?idCategoria=${id}`)
            .then(response => response.json())
            .then(data => {
                localStorage.setItem('_subCategorias', JSON.stringify(data.items));

                localStorage.setItem('_pmLastStep', '/subCategoria');
                localStorage.setItem('_tituloSubCategorias', JSON.stringify(
                    id === 1 ? 'farmacêuticos' : 'de vacinação'
                ));
                localStorage.setItem('_flagReload', JSON.stringify({
                    reload: true,
                }));

                //history.push('/hubdesaude/subCategoria');
                goToSubCategory(id);
            })
            .catch(error => console.log(error))
            .finally(() => toggleIsOpenModal());
    }

    return (
        <Container>
            <HeaderContent>
                <div className="title">
                    <p className="description-desktop">
                        <strong>{name}: </strong>
                        {description}
                    </p>
                </div>
                <div className="button">
                    <button onClick={handleMoveToSubCategory} className="link-redirect">
                        Ver mais
                    </button>
                </div>
            </HeaderContent>

            <SliderScheduleServices settings={window.innerWidth > 1025 ? settings : conf}>

                {id === 1 && pharmaceutical?.map((card: IServico) => (
                    <CardShowcase card={card} handleMoveToSubCategory={handleMoveToSubCategory} key={card.id} image={card.urlImagem} name={card.nomeAbreviado} description={card.subtitulo} timeExecutation={card.horaExecucao} />
                ))}

                {id === 2 && vaccination?.map((card: IServico) => (
                    <CardShowcase card={card} handleMoveToSubCategory={handleMoveToSubCategory} key={card.id} image={card.urlImagem} name={card.nomeAbreviado} description={card.subtitulo} timeExecutation={card.horaExecucao} />
                ))}
            </SliderScheduleServices>
        </Container>
    );
}