import styled, { css } from "styled-components";
import media from "../../../tokens/media";
import { Form as Unform } from "@unform/web";

interface IconProps {
  type?: "success" | "info";
}

const iconTypeVariations = {
  info: css`
    color: #ffbe02;
  `,

  success: css`
    color: #09b2ab;
  `,
};

export const HeaderModal = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  ${media.sm} {
    position: relative;
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #515151;
    width: 100%;

    ${media.sm} {
      padding-top: 5px;
      font-size: 22px;
    }

    ${media.xs} {
      margin-right: 36px;
      margin-top: 0.3rem;
    }
  }
`;

export const FooterButtonsModal = styled.div`
  position: relative;
  ${media.sm} {
    padding: 0 20px 15px;
  }

  button {
    width: 298px;
    height: 43px;
    margin: 0 auto;
    align-self: center;
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #0054a6;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .text {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      padding: 16px 50px;
    }

    ${media.sm} {
      width: 100%;
    }
  }

  button.success {
    color: #e8e6e3;
    background: #208637;
    border-color: #259c40;
  }

  ${media.xs} {
    padding: 0px 15px !important;
    button {
      width: 100%;
      border: 0;
      margin: 0;
      padding: 0;
    }
    div {
      padding: 0px 15px !important;
    }
  }
`;

export const CloseModal = styled.button`
  border: none;

  svg {
    color: #4d5567;
  }
`;

export const Form = styled(Unform)<IconProps>`
  ${media.lg} {
    width: 502px;
  }

  max-height: 80%;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;

  ${media.sm} {
    height: 400px;
    overflow-y: auto;
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #515151;
  }

  p {
    padding: 0 15px;
    ${media.lg} {
      padding: 0 48px;
    }

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #383838;
    margin: 0;
  }

  button {
    width: 298px;
    height: 43px;
    margin-top: 24px;
    align-self: center;
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #0054a6;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      padding: 16px 50px;
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  min-width: 150px;
  width: 100%;

  div {
    height: 36px;
  }

  &:disabled {
    background: #dddddd;
    color: #bdbdbd;
  }

  img {
    position: absolute;
    right: 12px;
    bottom: 15px;
  }

  label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #828282;
    margin-bottom: 2px;
  }

  .DropDown {
    flex: 1;
    background: transparent;

    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    &::placeholder {
      color: #a9a9a9;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background: #dddddd;
      color: #bdbdbd;
    }
  }

  ${media.xs} {
    width: 100%;
  }
`;

export const SpanErroCep = styled.span`
  font-size: 11px;
  line-height: 20px;
  color: #cc1117;

  position: absolute;
  margin-top: 58px;
  margin-left: 10px;
`;
