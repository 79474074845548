import { useEffect, useState } from "react";
import { Appointment } from "../../components/CardAppointment/types";
import { CardAppointment } from "../../components/CardAppointment";
import * as S from "./styles";
import { useRemoveSteps } from "../../hooks/useRemoveSteps";
import Input from "../../components/Input";
import { Form } from "@unform/web";
import { debounce } from "../../utils";

export function Agendamentos() {
  useRemoveSteps();

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [filteredAppointments, setFilteredAppointments] = useState<
    Appointment[]
  >([]);

  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [seeingMore, setSeeingMore] = useState(false);
  const [linkAgendamento, setLinkAgendamento] = useState("");
  const [idModalOpen, setIdModalOpen] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    setSeeingMore(false);

    const vtexclientemail = localStorage.getItem("_pmVtexclientemail");

    fetch(
      `/agendamentoData/GetAgendamentosPorCliente?email=${vtexclientemail}`,
      { cache: "no-cache" }
    )
      .then((response) => response.json())
      .then((data) => {
        setAppointments(data.items);
        const items = [...data.items];
        if (items.length > 9) {
          const numberOfItemsToShowByDefault = 9;
          items.length = numberOfItemsToShowByDefault;
        }

        setReload(false);
        setFilteredAppointments(items);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));

    const apiUrl = `agendaData/LinkRedirect`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result === "Success") setLinkAgendamento(data.linkRedirect);
      });
  }, [reload]);

  function handleChangeSearchInput(search: string) {
    debounce(() => {
      setFilteredAppointments(
        appointments?.filter(
          (appointment) =>
            appointment.paciente.nome
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            appointment.paciente.cpf
              .replace(".", "")
              .replace(".", "")
              .replace("-", "")
              .includes(search.replace(".", "").replace("-", ""))
        )
      );
    }, 500);
  }

  function handleSeeMore() {
    setFilteredAppointments(appointments);
    setSeeingMore(true);
  }

  return (
    <>
      <S.Container>
        <S.Header>
          <S.NewAppointment>
            <span>Agendamentos</span>
            <S.ButtonNewAppointment href={linkAgendamento} target="_top">
              Novo agendamento
            </S.ButtonNewAppointment>
          </S.NewAppointment>

          <Form onSubmit={() => {}} style={{ marginLeft: "auto" }}>
            <Input
              isSearchInput
              name="search"
              onChange={(e) => handleChangeSearchInput(e.target.value)}
              placeholder="Buscar por nome ou CPF"
              style={{ height: 23, color: "#AAAAAA" }}
            />
          </Form>
        </S.Header>
        <S.AppointmentsList>
          <S.ListHeader>
            <p style={{ marginRight: 155, marginLeft: 16 }}>Status</p>
            <p style={{ marginRight: 327 }}>Local</p>
            <p style={{ marginRight: 200 }}>Data e horário</p>
            <p>Nº de serviços</p>
          </S.ListHeader>
          {loading && <p>Carregando...</p>}
          {error && <p>Falha ao buscar os dados do servidor.</p>}
          {!loading && !filteredAppointments.length && (
            <strong className="no-results">Sem resultados para a busca</strong>
          )}
          {!loading &&
            !!filteredAppointments.length &&
            filteredAppointments
              .filter((appointment: any) => {
                if (idModalOpen !== 0) return appointment.id === idModalOpen;
                else return appointment;
              })
              .map((appointment) => (
                <CardAppointment
                  key={appointment.id}
                  appointment={appointment}
                  setReload={() => setReload(true)}
                  setIdModalOpen={(id) => {
                    setIdModalOpen(id);
                  }}
                />
              ))}
          {!seeingMore && !!filteredAppointments.length && (
            <S.ButtonSeeMore onClick={handleSeeMore}>Ver mais</S.ButtonSeeMore>
          )}
          &nbsp; &nbsp;
        </S.AppointmentsList>
      </S.Container>
    </>
  );
}
