import styled from 'styled-components';

export const Container = styled.div`
    padding: 0 64px 1rem;
    background-color: #0076BF;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    @media (max-width: 1024px) {
       padding: 0 11px 1rem;
    }
`;

export const ContainerRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &:first-child{
        margin-top: -40px;
    }
`;
export const ContainTypeService = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 109px;
    margin-bottom: -60.5px;
    align-items: center;
    @media (max-width: 1024px){
        flex-direction: column;
        margin-top: 17.8px;
        margin-bottom: 0px;
        align-items: center;
        //margin-bottom: 17.1px;
    }
`;
export const Button = styled.button`
    background: #0054A6;
    border-radius: 4px;
    width: 240px;
    height: 43px;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    border: 0;
`;
export const ContainerBtn = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    border: 0;
    background-color:  #ffffff00;
`;