import Modal from 'react-modal';
import * as S from './styles';
import iconCloseModal from '../../images/icons/icon-close.svg';
import { useState } from 'react';
import { useEffect } from 'react';

export interface MotivoData {
  id: number;
  descricao: string;
}

interface ModalMotivoCancelamentoProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onContinue: (motivoSelecionado: number, descricaoMotivo: string) => void;
}

export function ModalMotivoCancelamento({ isOpen, onRequestClose, onContinue }: ModalMotivoCancelamentoProps) {
  const [motivoSelecionado, setMotivoSelecionado] = useState(-1);
  const [descricaoMotivo, setDescricaoMotivo] = useState("");
  const [motivos, setMotivos] = useState<MotivoData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [agendamentoAssistido, setAgendamentoAssistido] = useState(false);

  useEffect(() => {
    loadMotivos();

    const _agendamentoAssistido = localStorage.getItem('_pmAgendamentoAssistido');
    if (_agendamentoAssistido !== null)
      setAgendamentoAssistido(true);
  }, []);

  function loadMotivos() {
    fetch('AgendamentoData/GetCancelamentoMotivos', { cache: "no-cache" })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.result === "Error") {
          loadMotivos();
        }
        setMotivos(data.items);
      })
      .catch(() => setError(false))
      .finally(() => setLoading(false));
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      portalClassName="modal-cancelamento"
      style={{
        content: {
          top: '50%',
          left: '50%',
          marginRight: '-40%',
          transform: 'translate(-50%, -50%)',
          color: '#000000',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '502px',
          height: 'fit-content',
          minHeight: '420px',
          maxHeight: '520px',
          border: 'none',
          padding: '0',
          marginTop: '0px',
          overflow: 'visible',
          background: '#fff',

              overflowY: 'auto',
              overflowX: 'hidden',
              position: 'absolute',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',

        },
        overlay: {
          backgroundColor: '#231f2066',
        },
      }}
    >
      <S.Container>
        <button type="button" className="close" >
          <img src={iconCloseModal} alt="Close" onClick={onRequestClose} />
        </button>

        <S.ModalHeader>
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.9766 31.2812C23.9766 33.1767 25.5186 34.7188 27.4141 34.7188H31.4531C33.3486 34.7188 34.8906 33.1767 34.8906 31.2812V27.2422C34.8906 25.3468 33.3486 23.8047 31.4531 23.8047H27.4141C25.5186 23.8047 23.9766 25.3468 23.9766 27.2422V31.2812ZM27.4141 27.2422H31.4531L31.4553 31.2812C31.4553 31.2812 31.4547 31.2812 31.4531 31.2812H27.4141V27.2422Z" fill="#E50004" />
            <path d="M33.1719 19.7656C34.1211 19.7656 34.8906 18.9961 34.8906 18.0469C34.8906 17.0976 34.1211 16.3281 33.1719 16.3281C32.2226 16.3281 31.4531 17.0976 31.4531 18.0469C31.4531 18.9961 32.2226 19.7656 33.1719 19.7656Z" fill="#E50004" />
            <path d="M25.6953 19.7656C26.6446 19.7656 27.4141 18.9961 27.4141 18.0469C27.4141 17.0976 26.6446 16.3281 25.6953 16.3281C24.7461 16.3281 23.9766 17.0976 23.9766 18.0469C23.9766 18.9961 24.7461 19.7656 25.6953 19.7656Z" fill="#E50004" />
            <path d="M42.2812 30.25C43.2305 30.25 44 29.4805 44 28.5312V10.3125C44 6.52163 40.9159 3.4375 37.125 3.4375H34.8906V1.71875C34.8906 0.769484 34.1211 0 33.1719 0C32.2226 0 31.4531 0.769484 31.4531 1.71875V3.4375H23.6328V1.71875C23.6328 0.769484 22.8633 0 21.9141 0C20.9648 0 20.1953 0.769484 20.1953 1.71875V3.4375H12.4609V1.71875C12.4609 0.769484 11.6915 0 10.7422 0C9.79292 0 9.02344 0.769484 9.02344 1.71875V3.4375H6.875C3.08413 3.4375 0 6.52163 0 10.3125V37.125C0 40.9159 3.08413 44 6.875 44H37.125C40.9159 44 44 40.9159 44 37.125C44 36.1757 43.2305 35.4062 42.2812 35.4062C41.332 35.4062 40.5625 36.1757 40.5625 37.125C40.5625 39.0204 39.0204 40.5625 37.125 40.5625H6.875C4.97956 40.5625 3.4375 39.0204 3.4375 37.125V10.3125C3.4375 8.41706 4.97956 6.875 6.875 6.875H9.02344V8.59375C9.02344 9.54302 9.79292 10.3125 10.7422 10.3125C11.6915 10.3125 12.4609 9.54302 12.4609 8.59375V6.875H20.1953V8.59375C20.1953 9.54302 20.9648 10.3125 21.9141 10.3125C22.8633 10.3125 23.6328 9.54302 23.6328 8.59375V6.875H31.4531V8.59375C31.4531 9.54302 32.2226 10.3125 33.1719 10.3125C34.1211 10.3125 34.8906 9.54302 34.8906 8.59375V6.875H37.125C39.0204 6.875 40.5625 8.41706 40.5625 10.3125V28.5312C40.5625 29.4805 41.332 30.25 42.2812 30.25Z" fill="#E50004" />
            <path d="M10.7422 34.7188C11.6914 34.7188 12.4609 33.9492 12.4609 33C12.4609 32.0508 11.6914 31.2812 10.7422 31.2812C9.79295 31.2812 9.02344 32.0508 9.02344 33C9.02344 33.9492 9.79295 34.7188 10.7422 34.7188Z" fill="#E50004" />
            <path d="M10.7422 19.7656C11.6914 19.7656 12.4609 18.9961 12.4609 18.0469C12.4609 17.0976 11.6914 16.3281 10.7422 16.3281C9.79295 16.3281 9.02344 17.0976 9.02344 18.0469C9.02344 18.9961 9.79295 19.7656 10.7422 19.7656Z" fill="#E50004" />
            <path d="M10.7422 27.2422C11.6914 27.2422 12.4609 26.4727 12.4609 25.5234C12.4609 24.5742 11.6914 23.8047 10.7422 23.8047C9.79295 23.8047 9.02344 24.5742 9.02344 25.5234C9.02344 26.4727 9.79295 27.2422 10.7422 27.2422Z" fill="#E50004" />
            <path d="M18.2188 27.2422C19.168 27.2422 19.9375 26.4727 19.9375 25.5234C19.9375 24.5742 19.168 23.8047 18.2188 23.8047C17.2695 23.8047 16.5 24.5742 16.5 25.5234C16.5 26.4727 17.2695 27.2422 18.2188 27.2422Z" fill="#E50004" />
            <path d="M18.2188 19.7656C19.168 19.7656 19.9375 18.9961 19.9375 18.0469C19.9375 17.0976 19.168 16.3281 18.2188 16.3281C17.2695 16.3281 16.5 17.0976 16.5 18.0469C16.5 18.9961 17.2695 19.7656 18.2188 19.7656Z" fill="#E50004" />
            <path d="M18.2188 34.7188C19.168 34.7188 19.9375 33.9492 19.9375 33C19.9375 32.0508 19.168 31.2812 18.2188 31.2812C17.2695 31.2812 16.5 32.0508 16.5 33C16.5 33.9492 17.2695 34.7188 18.2188 34.7188Z" fill="#E50004" />
          </svg>

          <h2>Cancelamento <br /> do agendamento</h2>

          <p>Qual é o motivo para sua solicitação de cancelamento?</p>
        </S.ModalHeader>

        <S.ModalContent>
          <div className="motivosContainer">
            {loading && !error && <p>Carregando...</p>}
            {error && !loading && <p>Falha ao buscar os dados, por favor, tente novamente mais tarde.</p>}
            {!error && !loading && motivos?.map((motivo) => (
              <span key={motivo.id}>
                <input
                  type="radio"
                  name="motivo"
                  id={"motivo-" + motivo.id}
                  className={motivoSelecionado === motivo.id ? "checked" : ""}
                  onClick={() => setMotivoSelecionado(motivo.id)}
                />
                <label htmlFor={"motivo-" + motivo.id}>
                  {motivo.descricao}
                </label>
              </span>
            ))}
            <span>
              <input
                type="radio"
                name="motivo"
                className={motivoSelecionado === 0 ? "checked" : ""}
                id="motivo-outro"
                onClick={() => setMotivoSelecionado(0)}
              />
              <label htmlFor="motivo-outro">Outro motivo.</label>
            </span>
          </div>

          {motivoSelecionado === 0 && (
            <span className="descricaoContainer">
              <label htmlFor="motivo-descricao">Qual o motivo?</label>
              <input
                name="motivo-descricao"
                id="motivo-descricao"
                placeholder="Limite de 40 caracteres"
                maxLength={40}
                value={descricaoMotivo}
                onChange={(event) => setDescricaoMotivo(event.target.value)}
              />
            </span>
          )}
        </S.ModalContent>

        <S.ModalFooter>
          <S.ButtonCancel onClick={onRequestClose}>Sair</S.ButtonCancel>
          <S.ButtonGoForward
            disabled={
              agendamentoAssistido && (
                motivoSelecionado === -1 ||
                (motivoSelecionado === 0 && !descricaoMotivo))
            }
            onClick={() => {
              onContinue(motivoSelecionado, descricaoMotivo);
              setMotivoSelecionado(-1);
              setDescricaoMotivo('');
            }}
          >{agendamentoAssistido ||
            (motivoSelecionado > -1) ? 'Continuar' : 'Pular'}
          </S.ButtonGoForward>
        </S.ModalFooter>
      </S.Container>
    </Modal>
  );
}