import styled from 'styled-components';
export const ContainWrapped = styled.div`
`;
export const ContainLink = styled.a`
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
    &:first-child {
    margin-left: 0px;
    }
    @media (max-width: 1024px){
        margin-left: 0px;
        &:first-child {
            margin-left: 0px;
        }
    }
`;
export const Card = styled.div`
    width: 100%;
    max-width: 290.13px;
    height: 319px;
    min-height:319px ;
    max-height: 319px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 1px 1px 10px rgba(0, 24, 48, 0.22);
    padding-top: 0;
   
`;
export const Imagem = styled.img`
    top: 0;
    width: 100%;
    height: 202px;
    border-radius: 6px;
    object-fit: cover;
    margin-bottom: 16px;
`;
export const Title = styled.h6`
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
   width: 100%;
    height: 21px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #515151;
`;
export const Description = styled.p`
    overflow-y:hidden;
    text-overflow: ellipsis;

    min-height: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16.41px;
    color: #666666;
    margin-left: 28px;
`;
export const ContainTitle = styled.div`
    display: flex;
`;
export const Before = styled.div`
    width: 4px;
    height: 21px;
    background: #E50004;
    border-radius: 6px;
    margin-right: 8px;
    margin-left: 16px;
`;