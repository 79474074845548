import styled, { css } from 'styled-components';
import { Form as Unform } from '@unform/web';
import media from '../../../tokens/media';

interface IconProps {
  type?: 'success' | 'info';
}

const iconTypeVariations = {
  info: css`
    color: #FFBE02;
  `,

  success: css`
    color: #09B2AB;
  `,
};

export const CloseModal = styled.button`
  border: none;
  position: absolute;
  right: 4px;
  top: 5px;

  svg {
    color: #4D5567;
  }
`;

export const Form = styled(Unform) <IconProps>`

    ${media.lg} {
        width: 502px;
    }

  padding: 20px 15px 37px;
  display: flex;
  flex-direction: column;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #515151;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #383838;
    margin: 0;
  }

  button {
    width: 298px;
    height: 43px;
    margin-top: 48px;
    align-self: center;
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #0054A6;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
        width: 100%;
    }

    .text {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      padding: 16px 50px;
    }
  }

  svg {
    width: 44px;
    height: 44px;
    ${props => iconTypeVariations[props.type || 'success']}
    align-self: center;
    margin-bottom: 20px;
    margin-top: 15px;
  }
`;

interface ContainerProps {
  showCancel: boolean;
  buttonsMarginTop: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "Source Sans Pro", sans-serif;
  text-align: center;

  img {
    width: 66px;
    height: 66px;
  }

  h2 {
    color: #2a333e;
    font-size: 26px;
    font-weight: 600;
    margin-top: 24px;
  }

  p {
    color: #404f61;
    margin: 0;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      line-height: 20px;
      padding: 16px 50px;
      margin-top: 48px;
      border-radius: 8px;
      border: 0;
      background: #0054A6;
      color: #fff;
      display: flex;
      width: 100%;
      max-width: calc(45%);
      justify-content: center;

      &:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
  }
`;
