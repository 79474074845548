import Select from "react-select";
import styled, { css } from "styled-components";
import { colors } from "../../tokens";

export const SelectExtend = styled(Select).attrs((props) => ({}))`
  font-family: "Source Sans Pro", sans-serif;

  [class*="-singleValue"] {
    color: ${(props) =>
    props?.styleSingleValue
      ? props?.styleSingleValue
      : colors.grayText} !important;
  }

  [class*="-singleValue"] {
    color: #AAAAAA !important;
    height: 100%;
    display: flex;
    align-items: center;
  }

  [class*="-control"] {
    max-height: 40px;
    min-height: 40px;

    > div {
      padding: 0 2px;
      height: 40px;
      display: flex;
      align-items: center;
    }

    > div:first-child {
      > div:last-child {
        height: 40px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        > div {
          /* position: absolute; */
        }
      }
    }

    input::placeholder {
      color: #666666;
    }
  }

  [class*="-placeholder"] {
    color: #666666;
    margin-left: 8px;
    font-size: 16px;
  }

  div {
    ${(props) => {
    if (props.isDisabled) {
      return css`
          cursor: not-allowed;
          background: #d7e2ed;
        `;
    }
  }}
  }

  [class*="-menu"] {
    height: fit-content;
    max-height: 250px;
    z-index: 11;

    line-height: 16px;

    > div {
      height: fit-content;
      max-height: 250px;
      z-index: 11;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  && {
    [class*="MenuList"] {
      padding: 20px 0 20px 8px;
      margin-right: 6px;
      max-height: 250px;
      height: fit-content;
      z-index: 11;

      ::-webkit-scrollbar {
        width: 5px;
        height: ${(props: any) => props?.theme?.scrollHeight ?? "initial"};
      }

      ::-webkit-scrollbar-track {
        border-radius: 5px;
        margin: 13px 0 10px;
        background: ${(props: any) =>
    props?.theme?.scrollUnhoverBackgroundColor ?? "#E8EDF3"};
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: ${(props: any) =>
    props?.theme?.scrollUnhoverBackgroundColor ?? "#A7BED6"};
      }
    }
  }
`;
