import { Container, ContainerHeader } from "./styles";
import ContainCard from "./ContainCards";
export default function ServiceHome(props: any) {
  const { toggleIsOpenModal } = props;
  return (
    <Container>
      <ContainerHeader>
        <h2>Serviços</h2>
        <p>Nossas linhas de cuidado com você</p>
      </ContainerHeader>
      <ContainCard toggleIsOpenModal={toggleIsOpenModal}/>
    </Container>
  );
}
