import React from 'react';
import Modal from "react-modal";
import loadingIcon from "../../images/icons/loading.gif";
const _customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    height: "45%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "flex-direction": "column",
  },
};

interface IModalProps {
  isOpen: boolean,
  customStyles?: Modal.Styles
}

const ModalLoading: React.FC<IModalProps> = ({
  isOpen,
  customStyles
}) => {
  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={() => {}}
        style={customStyles ?? _customStyles}
        overlayClassName="overlay-modal-loading"
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
      >
        <img src={loadingIcon} alt="Ícone de carregamento das informações" />
        <span>Carregando informações...</span>
      </Modal>
  );
};

export default ModalLoading;