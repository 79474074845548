import styled from 'styled-components';
import colors from '../../tokens/colors';

interface ButtonComponentProps {
  inverted?: boolean;
  width?: string;
  height?: string;
  isConfirmed?: boolean;
  setIsConfirmed?: () => void;
  widthButtonContinueMobile?: string;
  widthButtonCloseMobile?: string;
  cancelButton?: boolean;
}

export const ButtonComponent = styled.button<ButtonComponentProps>`
  border-radius: 4px;
  /* padding: 12px 32px; */
  min-width: ${props => props.width};
  max-width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    max-width: ${(props) => props.widthButtonContinueMobile};
    min-width: ${(props) => props.widthButtonContinueMobile};
  }

  @media (max-width: 1024px) {
    max-width: ${(props) => props.widthButtonCloseMobile};
    min-width: ${(props) => props.widthButtonCloseMobile};
  }

  background: ${(props) => (props.inverted === true
    ? '#fff'
    : colors.blue)
  };
  color: ${(props) => (props.inverted === true
    ? colors.blue
    : '#fff')
  };
  ${props => props.inverted && `border: 1px solid ${colors.blue} !important`};

  img {
    margin-right: 8px;
  }

  :disabled {
    color: #FFFFFF;
    background: #AAAAAA;
    cursor: not-allowed;
  }


`;
