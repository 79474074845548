import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  h2 {
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 42px;
    color: #0054a6;
    padding-bottom: 64px;

    @media (max-width: 1024px) {
      font-weight: bold;
      font-size: 28px;
      text-align: left;
      line-height: 42px;
      color: #0054a6;
      padding-bottom: 20px;
      width: 334px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const Apptitle = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-left: 31px;

  @media (max-width: 1024px) {
    margin-left: 2px;
    margin-top: 22px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ClinicFarm = styled.div`
  /* border: 1px solid red; */
  /* width: 100%; */
  button {
    background-color: #ed1d24;
    border-width: 0;
    padding: 4px 8px;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    cursor: inherit;
  }

  .titleText {
    margin-bottom: 18px !important;
    padding: 0 !important;
  }

  span {
    color: #999;
    font-weight: bold;
    font-size: 18px;
  }
`;
