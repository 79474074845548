import {
  Container,
  Imagem,
  Title,
  LinkVerMais,
  ContainImgTitle,
  Link,
  Div,
} from "./styles";

interface Props {
  img: string;
  title: string | undefined;
  linkRedirect?: string;
  handleClick?: () => void;
}

export default function ServicesCard({
  img,
  title,
  linkRedirect,
  handleClick,
}: Props) {
  return (
    <Div className="col-xs-12 col-sm-6 col-md-4 pr-2 pl-2">
      <Link
        onClick={() => handleClick && handleClick()}
        href={linkRedirect}
        target="_blank"
      >
        <Container>
          <ContainImgTitle>
            <Imagem src={img} />
            <Title>{title}</Title>
          </ContainImgTitle>
          <LinkVerMais>
            <span>Ver mais</span>
          </LinkVerMais>
        </Container>
      </Link>
    </Div>
  );
}
