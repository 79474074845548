import { useEffect, useState } from 'react';
import * as S from './styles';
import iconIdentity from '../../images/icons/identity.svg';
import iconSettings from '../../images/icons/icon-settings.svg';
import Button from '../../components/Button';

interface HistoricoBeneficioData {
  id: number;
  servico: HistoricoBeneficioServicoData;
  loja: LojaData;
  dataConsumo: string;
}

interface LojaData {
    codigo: number;
    nome: string;
    endereco: string;
    numero: string;
    bairro: string;
    cep: string;
    cidade: string;
    estado: string;
    telefone01: string;
    telefone02: string;
}

interface HistoricoBeneficioServicoData {
    id: number;
    nome: string;
    codProduto: string;
}

interface HistoricoBeneficioServicoProps {
    beneficios: HistoricoBeneficioData[];
    loading: boolean;
    error: boolean;
}

export function GridHistoricoBeneficios({ beneficios, loading, error }: HistoricoBeneficioServicoProps) {


    const [innerWidth, setInnerWidth] = useState(window.innerWidth -40);


    window.addEventListener('resize', (event) => {
        setInnerWidth(window.innerWidth - 40);
    });

  return (
    <S.Container id="grid-container">
      <table>
        <thead>
          <tr>
            <th>Serviço</th>
            <th>Loja</th>
            <th>Data e horário</th>
          </tr>
        </thead>
        <tbody>
          {loading && !error && <p>Carregando...</p>}
          {!loading && !error && !!beneficios.length && 
            <strong className="mobile-title">Serviços consumidos</strong>}
          {!loading && !error && !!beneficios.length && beneficios.map(beneficio => (
            <tr key={beneficio.dataConsumo}>
              <td className="nome">{beneficio.servico.nome}</td>
              <td className="loja">{`Loja ${beneficio.loja.codigo} - ${beneficio.loja.endereco}, ${beneficio.loja.numero} - ${beneficio.loja.bairro}`}</td>
              <td className="dataHorario">{beneficio.dataConsumo}</td>
            </tr>
          ))}
          {!loading && !error && !beneficios.length && (
            <tr>
            <td className="no-content" colSpan={3} style={{width: innerWidth}}>
                <div style={{margin: 'auto'}}>
                    <div>
                        <strong>Não há histórico de consumo!</strong>
                    </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </S.Container>
  )
}