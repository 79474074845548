import styled from "styled-components";

export const Container = styled.div`
  width: 307px;
  height: auto;
  display: flex;
  flex-direction: column;
  
  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 35px 13px 16px 22px;
    border: 0;

    .info {
      display: flex;
      flex-direction: row;
      
      .icon-exame {
        width: 15px;
        height: 16.67px;
      }

      .detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        margin-left: 10.5px;

        a {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 14px;
          color: #0054A6;

          &:hover {
            text-decoration: none;
            filter: brightness(1.2);
          }
        }
        
        span {
          font-size: 14px;
          color: #666666;
        }
       
      }

    }

    .info.disabled {
      a, a:hover, .text-disabled, svg path  {
        color: #A9A9A9;
        fill: #A9A9A9;
        filter: brightness(1);
        cursor: not-allowed;
      }
    }

    .icon-select {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 10px;

      &:hover {
        filter: brightness(1.2);
      }
    }

  }

  .cards-exames {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px 56px;
    background: #FBFBFB;

    p {
      margin: 0;
      font-weight: bold;
      font-size: 14px;
      color: #666666;
    }

    span {
      font-size: 12px;
      color: #666666;
    }

    b {
      color: #0054A6;
    }
  }

  @media (max-width: 1024px) {
    border: 1px solid #F4F4F4;
    width: 100vw;
  }
`;