import http from "../http-common";

import IHomeServico from "../types/HomeServico";

const getAllHomeServicoConfig = () => {
    return http.get<Array<IHomeServico>>(`/servicoData/GetAllHomeServicoConfig`);
};

const HomeServicoConfigService = {
    getAllHomeServicoConfig
};

export default HomeServicoConfigService;