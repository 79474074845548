import React, { useRef, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { FiXCircle } from 'react-icons/fi';
import IconBoard from '../../../images/icons/icon-board-exame.svg';
import IconTime from '../../../images/icons/icon-time-exame.svg';
import { CloseModal, Form, Header, Details, Result } from './styles';
import ModalShowCase from '../Modal';
import { ButtonDefault } from './styles';


interface IModalProps {
    isOpen: boolean;
    setIsOpen: () => void;
    servico: any;
    onSelectCard: () => void;
}

const ModalInfoShowcase: React.FC<IModalProps> = ({
    isOpen,
    setIsOpen,
    servico,
    onSelectCard,
}) => {
    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async () => {
            setIsOpen();
        },
        [setIsOpen],
    );

    const handleClose = useCallback(
        async () => {
            setIsOpen();
        },
        [setIsOpen],
    );

    return (

        <ModalShowCase isOpen={isOpen} setIsOpen={handleSubmit}>
            <CloseModal onClick={() => handleClose()}>
                <FiXCircle size={24} />
            </CloseModal>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <Header>
                    <h1>{servico.nomeAbreviado}</h1>
                    <p>{servico.subTitulo}</p>
                </Header>

                <Details>
                    <h2>Descrição</h2>
                    <p>{servico.descricao}</p>

                    <h2>Coleta de material</h2>
                    <p>{servico.coletaMaterial}</p>

                    <h2>Preparo para exame</h2>
                    <p>{servico.descricaoPreparo}.</p>

                    <h2>Recomendações</h2>
                    <p>{servico.descricaoRestricao}</p>
                </Details>

                <Result>
                    <div className="resultado">
                        <p>
                            <img src={IconBoard} alt="Board" /> Resultado: <span>{servico.tempoResultado}</span>
                        </p>

                        {/*<span>A partir de</span>*/}
                    </div>

                    <div className="value">
                        <p>
                            <img src={IconTime} alt="Clock-Time" /> Tempo de exame: <span>{servico.horaExecucao} min</span>
                        </p>

                        {/*<strong>R$ {servico.preco}</strong>*/}
                    </div>

                    <ButtonDefault onClick={onSelectCard} style={{ width: '100%' }}>
                        Adicionar exame
                    </ButtonDefault>
                </Result>
            </Form>
        </ModalShowCase>
    );
};

export default ModalInfoShowcase;