import axios from "axios";

export const servicoDataService = {
  getAllCategoria: async () => {
    const req = await axios.get<any>("/servicoData/GetAllCategoria");

    if (req.status === 200) {
      if (req.data.result === "Success") {
        return req.data.items;
      } else {
        return {
          result: "Error",
          code: req.data?.code,
          message: req.data?.message,
        };
      }
    } else {
      const { message } = req.data;

      return {
        message,
      };
    }
  },
};
