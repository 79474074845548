import React, { useRef, useState, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Form } from './styles';
import Modal from '../Modal';

interface IModalProps {
    isOpen: boolean;
    pagarAgora: () => void;
    pagarDepois: () => void;
    overflow: boolean
}

const ModalConfirmarNaoPagamento: React.FC<IModalProps> = ({
    isOpen,
    pagarAgora,
    pagarDepois,
    overflow = false
}) => {
    const formRef = useRef<FormHandles>(null);
    const [naoPagar, setNaoPagar] = useState(false);

    const handleFecharModal = useCallback(
        async () => {
        }, [],
    );

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();
        }, [],
    );

    const handlePagarAgora = useCallback(
        async () => {
            pagarAgora();
        }, [pagarAgora],
    );

    const handlePagarDepois = useCallback(
        async () => {
            pagarDepois();
        }, [pagarDepois],
    );

    return (
        <Modal isOpen={isOpen} setIsOpen={handleFecharModal} Mtop="15%" overflow={overflow}>
            <Form ref={formRef} onSubmit={() => { return false; }} type="info">
                <FiAlertCircle size={62} />

                <h1>Atenção</h1>
                <p>Deseja finalizar sem realizar o pagamento?</p>

                <button className="success" onClick={handlePagarAgora}>
                    <div className="text">Pagar agora</div>
                </button>

                <button onClick={handlePagarDepois}>
                    <div className="text">Pagar depois</div>
                </button>
            </Form>
        </Modal >
    );
};

export default ModalConfirmarNaoPagamento;