import Modal from 'react-modal';
import * as S from './styles';
import iconCalendar from '../../images/icons/icon-calendar-modal.svg';
import iconCloseModal from '../../images/icons/icon-close.svg';
import { Form } from '@unform/web';
import { InputCalendar } from '../InputCalendar';
import { InputSelect } from '../InputSelect';
import { useState } from 'react';
import { manha, noite, tarde } from "../../data/horarios.json";
import { dateFormat } from '../../utils';
import { useEffect } from 'react';

interface HorariosDisponiveis {
  manha: string[];
  tarde: string[];
  noite: string[];
}

interface DataProps {
  servicos: number[];
  codigoLoja: number;
  data: string;
  idParceiro?: number;
}

interface ModalFormReagendamentoProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onContinue: (dateTime: string) => void;
  data: DataProps;
}

export function ModalFormReagendamento({ isOpen, onRequestClose, onContinue, data }: ModalFormReagendamentoProps) {
  const [periodo, setPeriodo] = useState<'manha' | 'tarde' | 'noite' | ''>('');
  const [horario, setHorario] = useState('');
  const [dateSelected, setDateSelected] = useState('');

  const [availableDays, setAvailableDays] = useState<string[]>([]);
  const [availableHours, setAvailableHours] = useState<HorariosDisponiveis>({} as HorariosDisponiveis);

  useEffect(() => {
    (async () => {
      try {
        const responseAvailableDays = await fetch(`/agendaData/DisponiveisMes?servicos=${data.servicos.join(',')}&codigoLoja=${data.codigoLoja}&parceiro=${!!data?.idParceiro}`, { cache: "no-cache" }).then(response => response.json());
        setAvailableDays(responseAvailableDays.items);
      } catch(err) {
        alert(err);
      }
    })();
  }, [data]);

  // function obterHorariosPorPeriodo() {
  //   switch (periodo) {
  //     case "manha":
  //       return manha;
  //     case "tarde":
  //       return tarde;
  //     case "noite":
  //       return noite;
  //     default:
  //       return [...manha, ...tarde, ...noite];
  //   }
  // }

  function obterHorariosPorPeriodo() {
    if (!availableHours?.manha) {
      return [];
    }

    if (!periodo) {
      return Object.values(availableHours)
        .flat()
        .filter(item => item.item1.includes(":"))
        .map(object => ({
          value: object.item1.replace(":", ""),
          label: object.item1
        })).sort(function (a: any, b: any) {
            return a.value - b.value;
        });
    }
    
    return availableHours[periodo]?.map(hour => ({
      value: hour.replace(":", ""),
      label: hour
    }));
  }

  async function handleChangeDate(value: Date) {
    const dateFormatted = dateFormat(value as Date);
    setDateSelected(dateFormatted);
    
    const responseAvailableHours = await fetch(`/agendaData/DisponiveisDia?servicos=${data.servicos.join(',')}&codigoLoja=${data.codigoLoja}&data=${dateFormatted}&parceiro=${!!data?.idParceiro}`, { cache: "no-cache" }).then(response => response.json());    
    if(responseAvailableHours.result === "Success"){
      const newObject = {
        manha: responseAvailableHours.manha ?? [],
        tarde: responseAvailableHours.tarde ?? [],
        noite: responseAvailableHours.noite ?? []
      }
      setAvailableHours(newObject);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      portalClassName="modal-reagendamento"
      style={{
        content: {
          top: '50%',
          left: '50%',
          marginRight: '-40%',
          transform: 'translate(-50%, -50%)',
          color: '#000000',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '502px',
          height: '100%',
          maxHeight: '465px',
          border: 'none',
          padding: '0',
          marginTop: 0,
          overflow: 'visible'
        },
        overlay: {
          backgroundColor: '#231f2066',
        },
      }}
    >
      <S.Container>
        <button type="button" className="close">
          <img src={iconCloseModal} alt="Close" onClick={onRequestClose} />
        </button>

        <S.ModalHeader>
          <img src={iconCalendar} alt="Calendário" />

          <h2>Reagendamento</h2>          
        {window.innerWidth <= 1024 ?
            <p>Selecione abaixo a data e o horário para qual <br /> deseja reagendar os serviços contratados.</p> :
            <p>Selecione abaixo a data e o horário para qual deseja <br /> reagendar os serviços contratados.</p>
        }
        </S.ModalHeader>

        <S.ModalContent>
          <Form onSubmit={() => { }}>
            <div className="row">
           
            </div>
            <div className="row">
              <div style={{ marginRight: 2 }}>
                <InputCalendar
                    name="data"
                    title="Data (obrigatório)"
                    isRange={false}
                    handleDateChange={value => handleChangeDate(value as Date)}
                    availableDays={availableDays}
                />
              </div>
              <div>
                <label htmlFor="horario">Horário (obrigatório)</label>
                <InputSelect
                  name="horario"
                  placeholder="Selecione"
                  newValue={{
                    label:
                      [
                        /*{
                          value: "todos",
                          label: "Todos os horários",
                        },*/
                        ...obterHorariosPorPeriodo(),
                      ].find((item) => item.label === horario)?.label ||
                      "Selecione",
                    value: horario ? horario : "",
                  }}
                  options={[/*
                    {
                      value: "todos",
                      label: "Todos os horários",
                    },*/
                    ...obterHorariosPorPeriodo(),
                  ]}
                  onChange={(event: any) => setHorario(event.label)}
                />
              </div>
            </div>
          </Form>
        </S.ModalContent>

        <S.ModalFooter>
          <S.ButtonCancel onClick={onRequestClose}>Cancelar</S.ButtonCancel>
          <S.ButtonGoForward 
            disabled={
                          horario === "" || dateSelected === ""
            }
            onClick={() => {
            onContinue(`${dateSelected} ${horario}`);
            setPeriodo('');
            setHorario('');
            setDateSelected('');
          }}>Prosseguir</S.ButtonGoForward>
        </S.ModalFooter>
      </S.Container>
    </Modal>
  );
}