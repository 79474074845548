import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Content, ButtonCancel, ButtonContinue, ContentButtons, ContentAlert, ButtonRegister } from './styles';
import IconLoading from "../../images/icons/Loading.svg";
import { cpfMask, validateCpf } from '../ValidateCpf';
import { useAgendamento } from '../../contexts/agendamento';
import moment from 'moment';

interface Props {
    setCliente: Function;
    linkDadosCliente: "",
    setReviewPage: Function;
}

const Indetification: React.FC<Props> = (props) => {
    const history = useHistory();
    const { handleUpdateCliente } = useAgendamento();

    const [isEmpty, setIsEmpty] = useState('');
    const [isPaciente, setIsPaciente] = useState(true);
    const [cpfIsValid, setCpfIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function handleMaskCpf(e: React.ChangeEvent<HTMLInputElement>) {
        if (cpfIsValid)
            setCpfIsValid(false);
        setIsEmpty(cpfMask(e.target.value));
    }

    function handleContinue(value: string) {

        if (!validateCpf(value)) {
            setCpfIsValid(true);
            return;
        }

        if (value === null)
            return;

        setIsLoading(true);
        const apiUrl = `clienteData/GetCliente/cpf/${value}`;
        fetch(apiUrl, { cache: "no-cache" })
            .then(response => response.json())
            .then(data => {
                if (data.result === "Error") {
                    if (isPaciente) {
                        setCpfIsValid(false);
                        setIsPaciente((prevState) => !prevState);
                    }
                } else {
                    if(data.clienteData?.dataNascimento)
                        data.clienteData.dataNascimento = moment(data.clienteData.dataNascimento.replace("Z", "").replace("z", "")).format("DD/MM/YYYY")

                    props.setCliente(data.clienteData);
                    handleUpdateCliente(data.clienteData);
                    localStorage.setItem('_pmPacienteAssistido', JSON.stringify(data.clienteData));
                    props.setReviewPage(true);
                }

                setIsLoading(false);
            });
    }

    function handleCancel() {
        setIsEmpty('');
        setIsPaciente((prevState) => !prevState);

        history.push('/hubdesaude/exame');
    }


    return (
        <Container>
            <Content cpfInvalid={cpfIsValid}>
                <p>Para continuar insira o CPF do paciente abaixo</p>
                <div className="cpf">
                    <strong>CPF</strong>
                    <input type="test" value={isEmpty} maxLength={14} placeholder="000.000.000-00" onChange={event => handleMaskCpf(event)} />
                    {cpfIsValid &&
                        <span>CPF inválido</span>
                    }
                </div>
                {isLoading && (
                    <div className="row justifyCenter">
                        <img src={IconLoading} alt="loading" className='spiner' style={
                            {
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '100px',
                            }
                        } />
                    </div>
                )}
                {!isLoading &&
                    <ContentButtons>
                        <ButtonCancel onClick={() => handleCancel()}>
                            Cancelar
                    </ButtonCancel>
                        <ButtonContinue isPermission={isEmpty && isPaciente} disabled={!isEmpty || !isPaciente} onClick={() => handleContinue(isEmpty)}>
                            Continuar
                    </ButtonContinue>
                    </ContentButtons>
                }
                {!isPaciente &&
                    <ContentAlert>
                        <p>Usuário não cadastrado</p>
                        <span>Este cliente não possui cadastro em nosso sistema.<br />Deseja cadastrar este CPF?</span>
                        <ButtonRegister onClick={() => setIsPaciente((prevState) => !prevState)} href={props.linkDadosCliente} target="_blank">Realizar cadastro</ButtonRegister>
                    </ContentAlert>
                }

            </Content>
        </Container>
    )
}

export default Indetification
