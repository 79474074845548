import styled from 'styled-components';
import { media } from "../../../tokens";

export const Container = styled.div`
    //margin: 0 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .select-category {
        label {
            padding: 0;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 26px;
            /* or 217% */

            display: flex;
            align-items: center;

            color: #828282;
        }
    
        select {
            padding: 7px 15px;
            width: 290px; 
            height: 40px; 
            border: 1px solid #E3E4E6;
            background-color: #FFFFFF;
            color: #AAAAAA;
            border-radius: 4px;

            &:focus {
               box-shadow: 0;
               outline: 0;
            }

            option .default-value-option {
                color: black; 
                font-size: 32px;
            }
        }
    }

    .header-sub-category {
        .col-12 {
          display: flex;
          justify-content: center;
        }

        button {
          width: 100%;
        }
        @media (max-width: 1024px) {
            border: none;
        }
    }

    .sub-category {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        width: 100%;
        margin-bottom: 1.5rem;
        background: #FFFFFF;
        /* box-shadow: 1px 1px 20px rgba(0, 24, 48, 0.22); */
        border-radius: 3px;

        @media (max-width: 1024px) {
          padding-bottom: 85px;
        }

        .list-cards-exams {
            box-shadow: 1px 12px 13px rgb(0 24 48 / 22%);
            /* border-top: 1px solid #D3d3d3; */
            /* padding-top: 10px; */
            width: 100%;
            height: 100%;
            margin-top: 0;
            margin-bottom: 20px;

            @media (max-width: 1024px) {
                align-items: center;
            }
        }

        .header-content {
           margin-top: 24px;
           box-shadow: 2px -5px 13px rgb(0 24 48 / 22%);
           width: 100%;
           height: 65px;
           padding: 0 1rem;
           color: #0054A6;

          hr {
            margin-top: 0;
          }
        }

        .border-bottom {
            /* border-bottom: 1px solid #F4F4F4; */
            border-width: 90%;
        }

        button.open-services {
            border: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            color: #0054A6;
            word-break: break-word;
        
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            font-size: 18px;
            font-weight: 500;
            font-family: 'Roboto', sans-serif;
            margin: auto 0;

            &:hover {
                filter: brightness(0.7);
            }
        }

    }
    .contain-category{
        width: 100%;
        margin-bottom: 31px;
        padding: 0 1rem;

        @media (max-width: 1024px) {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .select-category {
          width: 33%;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        #subCategory {
          width: 100%;
        }
    }

    .more-categories {
        border: 0;
        width: 240px;
        height: 43px;
        border-radius: 5px;
        color: white;
        background: #0054A6;

        transition: filter 0.2s;

        &:hover {
                filter: brightness(0.8);
        }

        @media (max-width: 1024px) {
            width: 295px;
        }
    }

    .button-back-sub-category-mobile {
        width: 296px;
        height: 43px; 
        color: #0054A6; 
        background-color: #0054A600; 
        border-radius: 4px; 
        border: solid 1px #0054A6;
    }
`;

export const HeaderSubCategory = styled.div`
  position: relative;
  margin: 0 0 31px;
  width: 100%;
  height: 70px;

  @media (max-width: 1024px) {
    border: none;
    padding: 0;
    height: auto;
    margin: 0;
    position: initial;
  }

  .btn-continuar {
    margin-right: 0;
  }
`;

export const HeaderDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
  padding-left: 30px;
  
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #0054A6;
    margin-bottom: 37px;

    @media(max-width: 1024px) {
      margin-top: 32px;
      margin-bottom: 18px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    padding: 0;
  }
`;

export const TitleSlick = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 500;
    line-height: 20px;
    color: #666;
    margin-bottom: 0;

    ${media.sm} {
      width: 100%;
      text-align: center;
    }
  }
`;

export const ButtonActions = styled.div`
  width: 45%;
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;

  > button {
      width: 100%;
      max-width: 240px;
  }

  @media(max-width: 1024px) {
    position: absolute;
    bottom: -100px;
    background-color: #fff;
    width: 100%;
    padding: 16px 11px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #d3d3d3;

    > button {
      width: 100%;
      margin: 0;
      border-radius: 4px;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 43px;
    }
  }

  @media(max-width: 533px) {
    > button {
        max-width: unset;
    }
  }
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: end;
    margin: 0;
  }

  @media(min-width: 1025px) {
      button, a {
      width: 100%;
    }
  }

  @media(max-width: 1024px) {
    display: flex;
    align-content: center;
    justify-content: center;
    grid-template-columns: unset;

    .lojaVoltarBtn {
      max-width: calc(100% / 4);
    }

    a {
      width: calc(100% / 4);

      button {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  @media(max-width: 510px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .lojaVoltarBtn, a {
      margin: 0;
      max-width: unset;
      width: 100%;
    }
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem 30px;
  padding-left: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  align-items: center;

  button.button-back-sub-category {
    width: 100%;
    max-width: 240px;
    color: #0054A6; 
    background-color: #0054A600; 
    border-radius: 4px; 
    border: solid 1px #0054A6;
  }

  @media(max-width: 1024px) {
    box-shadow: none;
    padding: 0;
    position: initial;
  }
`;