import { ReactNode } from 'react';
import {Container,Imagem,Title, LinkVermais,ContainTitle,ContainImg,SubTitle, Link} from './styles';
interface Props {
    img: string;
    title: string;
    linkRedirect: string;
    subtitle: string;
}

export default function TypeService({img,title,linkRedirect,subtitle} : Props) {
    return (
        <Link href={linkRedirect} target="_blank">
            <Container>
                <ContainImg>
                    <Imagem src={img}/>
                </ContainImg>
                <ContainTitle>
                    <LinkVermais href={linkRedirect} target="_blank"><span>Ver mais</span></LinkVermais>
                    <Title>{title}</Title>
                    <SubTitle>{subtitle}</SubTitle>
                </ContainTitle>
            </Container>
        </Link>
      );
    }