import React from 'react';
import { useAgendamento } from '../../contexts/agendamento';
import handleCompleteStep from '../CompleteStep';
import { Container, Button, Text, ButtonHandle } from './styles';

export type Step = 'exame' | 'loja' | 'agenda' | 'paciente';

interface Props {
  title: string;
  urlBack: string;
  urlContinue: string;
  stepName: Step;
  isDisabled: boolean;
  handleLoginModal?: () => void;
}

const Navigation: React.FC<Props> = ({ title, urlBack, urlContinue, stepName, isDisabled, handleLoginModal = () => {} }) => {
  var agendamento = useAgendamento();

  // function handleCompleteStep() {
  //   if(!isDisabled)
  //     window.CustomScrollToTop();
  //     agendamento.handleCompleteStep(stepName);
  // }

  return (
    <Container>
      <Text>{title}</Text>
      <div className="button-group">
        <Button isBack to={urlBack}>Voltar</Button>
        {urlContinue
          ? (!isDisabled)
            ? <Button onClick={() => handleCompleteStep(isDisabled, stepName, agendamento)} to={urlContinue} isDisabled={isDisabled}>Continuar</Button>
            : <ButtonHandle isDisabled={isDisabled}>Continuar</ButtonHandle>
          : <ButtonHandle onClick={handleLoginModal} isDisabled={isDisabled}>Continuar</ButtonHandle>
        }
      </div>
    </Container>
  );
}

export { Navigation };