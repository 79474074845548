import styled from 'styled-components';
import { media } from "../../tokens";

interface RadioButtonProps {
  isChecked: boolean;
}

export const Container = styled.div`
  @media(max-width: 1024px) {
    margin-top: calc(-15px - 0.5rem);
  }

  .container-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 26px;

    @media (max-width: 1024px) {
      justify-content: center;
      margin-left: 0;
      padding: 0 1rem;
      margin-bottom: 38px;
      
      button {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  .lista-clientes {
    padding: 0 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 22px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 1rem 7rem;
      grid-gap: initial;
      word-break: break-word;

      > div {
        margin-left: 0;
      }
    }
  }
`;

export const HeaderSubCategory = styled.div`
  position: relative;
  margin: 0 0 31px;
  width: 100%;
  height: 70px;

  @media (max-width: 1024px) {
    border: none;
    padding: 0;
    height: auto;
    margin: 0;
    position: initial;

    .button-back-sub-category {
      width: 220px; 
      margin-right: 21px; 
      color: #0054A6; 
      background-color: #0054A600; 
      border-radius: 4px; 
      border: solid 1px #0054A6;
    }
  }
`;

export const HeaderDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #0054A6;
    margin-bottom: 37px;

    @media(max-width: 1024px) {
      margin-top: 32px;
      margin-bottom: 18px;

      &.lista-paciente-hidden {
        display: none;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    padding: 0;
  }
`;

export const CardCliente = styled.div`
  border: 1px solid #0054A6;
  border-radius: 4px;
  width: 100%;
  height: 124px;
  padding: 16px;
  position: relative;

  @media (max-width: 1024px) {
    height: fit-content;

    & + div {
      margin-top: 16px;
    }
  }

  .informacoes-click {
    cursor: pointer;
    display: flex;
    width: calc(100% - 14px);
  }

  svg {
    position: absolute;
    top: 16px;
    right: 14px;

    &:hover {
      cursor: pointer;
      filter: brightness(0.7);
    }
  }

  .menu-acoes {
    background: #fff;
    box-shadow: 1px 1px 10px rgba(0, 24, 48, 0.22);
    padding: 16px;
    width: 164px;
    position: absolute;
    border-radius: 3px;
    top: 36px;
    right: 14px;

    button {
      background: transparent;
      border: 0;

      &:hover {
        filter: brightness(1.2);
      }
    }

    .btn-editar, .btn-remover-paciente {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
    }

    .btn-editar {
      color: #666666;
    }

    .btn-remover-paciente {
      color: #E50004;
    }
  }
  
  input {
    margin-right: 16px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    @media(max-width: 1024px) {
      margin-bottom: 12px;
    }

    span {
      font-weight: 400;
    }
  }
  
  .cliente-nome {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #0054A6;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 12px;

      span {
        display: block;
        line-height: 1;
        margin-bottom: 3px;
      }
    }
  }

  .inline-info {
    display: flex;

    p + p {
      margin-left: 32px;
    }

    @media (max-width: 1024px) {
      flex-direction: column;

      p {
        margin-left: 0 !important;
      }
    }
  }
`;
export const TitleSlick = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;

  span {
    font-weight: 500;
    line-height: 20px;
    color: #666;
    margin-bottom: 0;

    @media(max-width: 1024px) {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }

  .finishBtn{
    width: 100%;
    button{
      margin-bottom: 20px;
      width: 100%;
      height: 43px;
      border: none;
      background-color: #09B2AB;
      border-radius: 6px;
      padding: 8px 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: #FFFFFF;
      -webkit-transition: 0.1s ease;
      transition: 0.1s ease;
      cursor: pointer;
    }

    @media (min-width: 400px) {
      display: none;
    }

  }

  @media(max-width: 1024px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1rem;
  }
`;

export const ButtonActions = styled.div`
  display: flex;

  @media(max-width: 1024px) {
    width: 50%;
    margin: 0 auto;

    > button {
      width: 100%;
      margin: 0;
      border-radius: 4px;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 43px;
    }
  }

  @media(max-width: 767px) {
    width: 100%;
  }
`;

export const ButtonActionsContainer = styled.div`
  @media(max-width: 1024px) {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 16px 12px;
    box-shadow: 0px 0px 10px rgba(0, 24, 48, 0.1);
    background-color: #fff;
    z-index: 99;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem 27px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);

  button.button-back-sub-category {
    width: 220px; 
    margin-right: 21px; 
    color: #0054A6; 
    background-color: #0054A600; 
    border-radius: 4px; 
    border: solid 1px #0054A6;

    @media(max-width: 1024px) {
      width: 100%;
      margin-right: 11px;
    }
  }

  @media(max-width: 1024px) {
    box-shadow: none;
    padding: 0;
    position: initial;
  }
`;

export const RadioButton = styled.div<RadioButtonProps>`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  background-color: ${({ isChecked }) => isChecked ? 'rgba(0, 84, 166, 0.8)' : 'transparent'};
  border: ${({ isChecked }) => isChecked ? '1px solid #072C75' : '1px solid #979899'};

  > img {
    display: ${({ isChecked }) => isChecked ? 'block' : 'none'};
  }

  @media(max-width: 1024px) {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;