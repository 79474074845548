import styled from "styled-components";

interface OpenModalProps {
  openCalendar: boolean;
  styleColor?: string;
}
export const Container = styled.div<OpenModalProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Source Sans Pro", sans-serif;
  width: 195px;

  label {
    font-size: 12px;
    line-height: 26px;
    color: #666666;
    margin-bottom: 2px;
  }

  div.calendar {
    width: 100%;
    margin-bottom: ${(props) => (props.openCalendar ? "100px" : "")};

    span {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #2a333e;
    }

    input {
      margin-top: 4px;
      width: 100%;
      height: 40px;

      border: 2px solid #E3E4E6;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px 16px;

      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: ${(props) => (props.styleColor)};

      ::-webkit-input-placeholder {
        color: #666666;
      }
    }

    img {
      width: 24px;
      height: 24px;

      color: #7b8fa5;

      margin-left: -35px;
    }
  }

  div.calendarComponent {
    position: absolute;
    z-index: 99999999999;
  }

  div.horario {
    margin-top: 28px;

    div {
      left: 0;
    }

    span {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;

      color: #2a333e;
    }

    span.select {
      font-size: 16px;
    }
  }
`;
