import styled from 'styled-components';
import IconArrowBannerPrev from '../../images/icons/arrow-banner-prev.svg';
import IconArrowBannerNext from '../../images/icons/arrow-banner-next.svg';

interface Props {
  width?: string;
}

export const Container = styled.div<Props>`
  background: transparent;
  min-height: 480px;
/*
  .slick-active {
    @media (max-width: 360px) {
      margin-left: 6px !important;

      & + div {
        margin-left: -25px !important;
      }
    }

    @media (min-width: 361px)  and (max-width: 400px) {
      margin-left: -5px !important;
      
      & + div {
        margin-left: -90px;
      }
    }

    @media (min-width: 401px)  and (max-width: 440px) {
      margin-left: 10px !important;
      
      & + div {
        margin-left: -98px;
      }
    }
  }*/
 
  @media (max-width: 1024px) {
    min-height: 500px;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    top: calc(50% - 50px);
    margin: 0;
    width: 30px;
    height: 30px;
    transform: initial;
    background-repeat: no-repeat;
    background-size: 6px;
    background-position: center;
    background-color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 1px 1px 4px rgba(0, 24, 48, 0.25);
    
    @media(max-width: 1024px) {
      margin: 0 !important;
      width: 30px;
      height: 30px;
      top: calc(50% - 5px);
    }

    &::before {
      content: '';
    }
  }

  .slick-prev {
    left: 0;
    background-image: url(${IconArrowBannerPrev});
    margin-left: -25px;
  }

  .slick-next {
    right: 0;
    margin-right: -25px;
    background-image: url(${IconArrowBannerNext});
  } 
/*
  .slick-slide {
    margin-left: 10px;
    margin-right: 0;
    width: 295px;
    padding-top: 0;

    @media (max-width: 360px) {
      width: 338px !important;
      margin-left: 0;
    }

    @media (min-width: 361px)  and (max-width: 440px) {
      width: 418px !important;
      margin-left: 0;
    }
  }
*/
  .slick-track {
    height: 450px;
    overflow: hidden;
    left: 0;

    @media (max-width: 1024px) {
      margin-top: 15px;
      /* margin-left: 30px; */
    }
  }


  .slick-dots {
    
    @media (max-width: 1024px) {
      bottom: -15px;
      /* border: 1px solid red; */
    }
  }

  .slick-dots li {
    margin: 0px 10px 0;
    width: 0;
    height: 0;
  }

  .slick-dots li button {
    width: 0;
    height: 0;
  }

  .slick-dots li button:before {
    opacity: 1;
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #BBBBBB;
  }

  .slick-dots li.slick-active {
    width: 40px;
    margin: 0 0 0 10px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: white;
    content: '';
    position: absolute;
    width: 38px;
    height: 8px;
    border-radius: 120px;
    background-color: #0054A6;
  }
`;