import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

@media (max-width: 1024px) {
    /*width: calc(100% - 12px);*/
}
`;

export const Banner = styled.a`
  width: 100%;
  height: 415px;
  cursor: pointer;

@media (max-width: 1024px) {
    /*width: calc(100% - 12px);*/
}
  > img {
    width: 100%;
    height: 100%; 
  }
`;