const primary = '#00469B';
const secundary = '#E50004';
const blue = '#0054A6';
const green = '#09B2AB';
const greenSuccess = '#208637';
const yellow = '#FFBE02';
const white = '#F4F3F3';
const gray = '#B4B4B4';
const lightGray = '#666666';
const grayBlueLight = '#AEC0D2';
const grayText = '#404F61';
const grayBlue = '#7B8FA5';

const colors = {
  primary,
  secundary,
  blue,
  yellow,
  green,
  white,
  gray,
  lightGray,
  greenSuccess,
  grayBlueLight,
  grayText,
  grayBlue
};

export default colors;

