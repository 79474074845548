import styled, { css } from 'styled-components';

interface Props {
  inverted: boolean;
  context: any;
  isDisabled?: boolean;
}

export const ContinueButton = styled.button<Props>`
  width: 240px;
  height: 43px;
  border: none;
  background-color: ${(props) => (props.inverted ? '#09B2AB' : '#AAAAAA')};
  border-radius: 6px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  transition: 0.1s ease;
  cursor: pointer;

  ${({ isDisabled }) => isDisabled && isDisabledStyle}

  @media (max-width: 1024px) {
    width: 145px;
    margin-right: 0;
  }
`;

export const ButtonFloat = styled.button<{ inverted: boolean }>`
margin-right:68px;
  width: 164px;
  height: 43px;
  border: none;
  background-color: ${(props) => (props.inverted ? '#09B2AB' : '#AAAAAA')};
  border-radius: 6px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  transition: 0.1s ease;
  cursor: pointer;  
`;

const isDisabledStyle = css`
  cursor: not-allowed !important;
`;
