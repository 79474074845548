import { Component } from "react";
import { withRouter } from "react-router-dom";

import Slider from "react-slick";
import IconLoading from '../../../images/icons/Loading.svg';
import { ContainerSlick, TitleSlick, ContainerButton } from "./styles";
import Card from "../Card";
import Button from "../ConfButton/index";
import "../style.css";
import SubCategoriaButton from "../../Category/SubCategory/SubCategoriaButton";

class Slick extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            loadCount: 1,
            errorMessage: "",
            servicoList: [],
            servicoListVisible:[],
            nextButtonActive: false,
            innerWidth: 0,
            totalVisible: 5,
            idSaibaMais: 0
        };

        this.setNextButtonActive = this.setNextButtonActive.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.resizeScreen = this.resizeScreen.bind(this);
        this.carregarMais = this.carregarMais.bind(this);
        this.setIdSaibaMais = this.setIdSaibaMais.bind(this);
    }

    componentDidMount() {

        if (window.innerWidth <= 360)
            this.setState({ innerWidth: 360 });
        else if (window.innerWidth < 420)
            this.setState({ innerWidth: 420 });
        else
            this.setState({ innerWidth: window.innerWidth });

        window.addEventListener('resize', (event) => {
            this.resizeScreen()
        });

        if (window.innerWidth <= 1024)
            this.setState({ totalVisible: 3 });
        else
            this.setState({ totalVisible: 5 });

        this.loadServicos();
    }

    componentDidUpdate(prevProps:any , prevState: any ){
        if(prevState.servicos !== this.state.servicos)
            this.loadServicos();
    }

    loadServicos() {
        this.setState({ isLoading: true });

        const data: any = localStorage.getItem('_subCategorias');
        if(!data) {
            this.setState({ isLoading: false });
            return;
        }
        const dataFormat = JSON.parse(data).filter((item: any) => item.id === this.props.idSubCategorySelected)[0].servicos;

        //SE FOI SELECIONADO SERVICO NA HOME, FILTRA A PRIMEIRA CATEGORIA QUE CONTEM ELE
        const servicoSelecionadoHome = localStorage.getItem('_ApmServicosVitrine');
        if (servicoSelecionadoHome !== null) {
            let servicoSelecionadoHomeFormat = JSON.parse(servicoSelecionadoHome);
            let selecionado = dataFormat.filter((value: any) => { return value.id === servicoSelecionadoHomeFormat[0].id; });

            const firstItem = selecionado[0] as any;
            if (firstItem !== undefined)
                dataFormat.sort((x: any, y: any) => { return x.id === firstItem.id ? -1 : y.id === firstItem.id ? 1 : 0; });

            this.setState({ servicoList: dataFormat });
            this.setState({ isLoading: false });
        } else {
            this.setState({ servicoList: dataFormat });
            this.setState({ isLoading: false });
        }
    }
        
    setNextButtonActive(value: boolean) {
        this.setState({ nextButtonActive: value });
    }

    nextStep() {
        if (this.state.nextButtonActive)
            this.props.history.push("/hubdesaude/loja");
    }

    resizeScreen() {
        if (window.innerWidth <= 360)
            this.setState({ innerWidth: 360 });
        else if (window.innerWidth < 420)
            this.setState({ innerWidth: 420 });
        else
            this.setState({ innerWidth: window.innerWidth });
    }

    carregarMais(){
        this.setState({ totalVisible: this.state.totalVisible + 3 });
    }
    
    setIdSaibaMais(id: number) {
        this.setState({ idSaibaMais: id });
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 320,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "10px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 360,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "30px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 375,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "35px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 380,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "40px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 385,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "45px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 395,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "50px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 411,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "55px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 414,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "57px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 420,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "57px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "90px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 720,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "120px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 750,
                    settings: {
                        dots: true,
                        className: "center",
                        centerMode: true,
                        infinite: false,
                        centerPadding: "125px",
                        slidesToShow: 1,
                        speed: 500,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        dots: true,
                        infinite: false,
                        speed: 500,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (
            <>
                {this.state.isLoading ? 
                    (
                        <div style={{
                            display: "flex",
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <img src={IconLoading} alt="loading" className='spiner' style={
                                {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '100px',
                                }
                            } />
                        </div>
                    ) :
                    <div className="container-fluid">
                        {!this.state.isLoading && this.state.loadCount === 5 && (
                            <div className="row">
                                <div className="w-100">
                                    <p>{this.state.errorMessage}</p>
                                </div>
                            </div>
                        )}
                
                        <div className="row">
                            <div className="w-100">
                                <ContainerSlick>
                                    {!this.state.isLoading && (
                                        <div className="cards-exams">
                                            {this.state.servicoList
                                                    ?.slice(0, this.state.totalVisible)
                                                    ?.filter((item: any) => { if (this.state.idSaibaMais !== 0) return item.id === this.state.idSaibaMais; else return item; })
                                                    ?.map((item: any) => {
                                                if (item) {
                                                    return (
                                                        <Card
                                                            key={item.id + 1}
                                                            servico={item}
                                                            setNextButtonActive={this.setNextButtonActive}
                                                            handleButtonToContinue={this.props.handleButtonToContinue}
                                                            setIdSaibaMais={this.setIdSaibaMais}
                                                        />
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })}
                                            { this.state.totalVisible < this.state.servicoList.length ?
                                            <SubCategoriaButton carregar={this.carregarMais} />
                                            :null }
                                        </div>
                                    )}
                                </ContainerSlick>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}
export default withRouter(Slick);
