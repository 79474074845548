import {
  Container,
  ContainerCard,
  Links,
  ContainerHeader,
  Slider,
  Button,
  ContainerBtn,
} from "./styles";
import { useState, useEffect } from "react";
import CardSempreBem from "./CardSempreBem";
import HeaderTitle from "../HeaderTitle";
//import Slider from "react-slick";

interface SempreData {
  id: number;
  imgUrl: string;
  titulo: string;
  resumo: string;
  linkUrl: string;
}

export default function SempreBem() {
  const [post1, setPost1] = useState<SempreData[]>();
  const [showPost1, setShowPost1] = useState<SempreData[]>();
  const [showPostMobile, setShowPostMobile] = useState<SempreData[]>();
  const [post2, setPost2] = useState<SempreData[]>();
  const [showPost2, setShowPost2] = useState<SempreData[]>();
  const [verMais1, setVerMais1] = useState("");
  const [verMais2, setVerMais2] = useState("");

  const [tag, setTag] = useState("Ver mais");
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    fetch("sempreBemData/GetPosts")
      .then((response) => response.json())
      .then((data) => {
        setPost1(data.categorias.categorias[0].posts);
        setShowPostMobile(data.categorias.categorias[0].posts.slice(0, 5));
        setPost2(data.categorias.categorias[1].posts);
        setVerMais1(data.categorias.categorias[0].linkUrl);
        setVerMais2(data.categorias.categorias[1].linkUrl);
      })
      .catch((error) => console.log(error, "deu ruim categoria"));
  }, []);

  useEffect(() => {
    setShowPost1(post1?.slice(0, 4));
    setShowPost2(post2?.slice(0, 4));
  }, [post1, post2]);

  return (
    <Container>
      <ContainerHeader>
        <HeaderTitle
          title="Sempre bem"
          boxRedTitle="Blog"
          helperText=""
        />
      </ContainerHeader>
      {/* para desktop */}
      {window.innerWidth >= 1024 && (
        <>
          <Links>
            <span>Saúde</span>
            <a href={verMais1} target="_blank">
              <p>Veja mais artigos</p>
            </a>
          </Links>
          <ContainerCard>
            {showPost1?.map((row) => (
              <CardSempreBem
                img={row.imgUrl}
                title={row.titulo}
                description={row.resumo}
                linkRedirect={row.linkUrl}
              />
            ))}
          </ContainerCard>
          <Links>
            <span>Mente e comportamento</span>
            <a href={verMais2} target="_blank">
              <p>Veja mais artigos</p>
            </a>
          </Links>
          <ContainerCard>
            {showPost2?.map((row) => (
              <CardSempreBem
                img={row.imgUrl}
                title={row.titulo}
                description={row.resumo}
                linkRedirect={row.linkUrl}
              />
            ))}
          </ContainerCard>
        </>
      )}

      {/* para mobile (slick) */}
      {window.innerWidth < 1024 && (
        <>
          <Links>
            <span>Categoria</span>
            <a href={verMais2} target="_blank">
              <p>Veja mais artigos</p>
            </a>
          </Links>
          <Slider {...settings}>
            {showPostMobile?.map((row) => (
              <CardSempreBem
                img={row.imgUrl}
                title={row.titulo}
                description={row.resumo}
                linkRedirect={row.linkUrl}
              />
            ))}
          </Slider>
          {/* {flag === true && (
            <>
              <ContainerLinks>
                <Links>
                  <span>Categoria</span>
                  <a href={verMais1} target="_blank"><p>Veja mais artigos</p></a>
                </Links>
              </ContainerLinks>
              <Slider {...settings}>
                {showPost2?.map(row => (
                  <CardSempreBem
                    img={row.imgUrl}
                    title={row.titulo}
                    description={row.resumo}
                    linkRedirect={row.linkUrl}
                  />

                )
                )
                }
              </Slider>
            </>
          )} */}
          <ContainerBtn>
            <a href={verMais1} target="_blank">
              <Button>{tag}</Button>
            </a>
          </ContainerBtn>
        </>
      )}
    </Container>
  );
}
