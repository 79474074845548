import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 16px 24px 24px 24px;

  @media (max-width: 767px) {
    padding: 1.5rem 1.4rem;
  }
  
  h4 { 
    font-weight: 500;
    line-height: 16px;
    color: #0054A6;
    margin-bottom: 2rem;

    @media (max-width: 1024px) {
      margin-bottom: 1rem;
      border-bottom: 1px solid #C4C4C4;
      padding-bottom: 1.1rem;
    }

    @media (max-width: 350px) {
      line-height: 1.8rem;
    }
  }

  .lista-beneficios {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2.2rem;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }

  .grid-sem-beneficios {
    .sem-beneficios {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;

        hr {
          @media (max-width: 1024px) {
           height:2px;
           border-width:0;
           color:gray;
           background-color:gray;
          }
        }

      img {
        width: 100%;
        height: 75.96px;
      }

      .btn{
         width:240px;

        @media (max-width: 590px) {
            width: 100%;
            height: 45px;
        }
      }

      h5 {
        font-weight: bold;
        line-height: 19px;
        text-align: center;
        color: #000000;
        margin-top: 15px;

        @media (max-width: 360px){
          white-space: nowrap;
        }
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #808080;
      }
      
      .botoes-cliente-ouro {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2.2rem;

        @media (max-width: 590px) {
          grid-template-columns: none;
          grid-gap: 1rem;
          margin-top: 1rem;
          width: 100%;
        }
       
        .cliente-ouro {
          background: #FFB100;
          color: #000;
        }
      }
    }
  }
`;

export const CardBeneficio = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 115px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 1rem 24px 0;
  justify-content: center;

  @media (max-width: 1024px) {
    flex: 1;
    border: 0;
    border-top: 1px solid #c4c4c4;
    border-radius: 0;
    padding: 0;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #666666;
  }
  
  .info {
    display: flex;
    justify-content: space-between;

    h6 {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #0054A6;
    }

    p {
      margin: 0;
    }
  }
`;