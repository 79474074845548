import styled from 'styled-components';


export const Container = styled.div`
    width: 100%;
    height: 460px;

    div.title {
        padding-left: 35px;
    
        @media (max-width: 1024px) {
            padding-left: 0;
        }
    }

    @media (min-width: 1025px) {
        margin-top: 86px;
    }
`;

export const PartnersContent = styled.div`
    width: 100%;
`;