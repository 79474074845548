import { Container } from './styles';

interface BoxPartnerProps {
    image: string;
    name: string;
    info: string;
    link: string
}

export default function BoxPartner({image, info, name, link }: BoxPartnerProps) {
    return (
        <Container>
            <a href={link} target="_blank">
                <img src={image} alt={name} />
            </a>
            <div className="line-gray" />
            <strong>{info}</strong>
        </Container>
    );
}