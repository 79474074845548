import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    

    @media (max-width: 1024px) {
        width: 340px;
        max-width: 340px;
    }

    .close-modal {
        border: 0;
        background: transparent;
        margin-left: auto;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.6);
        }
    }

    .icon-attention {
        margin: 15px auto;
    }
    h2{
        width: 274px;
        height: 28px;
        left: 114px;
        top: 104px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #383838;
        padding-bottom:16px;
    }
    p{
        width: 393px;
        height: 36px;
        left: 54.5px;
        top: 148px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */
        text-align: center;
        color: #666666;
    }
    span{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        color:#383838;
        padding-bottom:6px;
    }

    .dropdown-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        
        .dropdown-item {
            height: 48.33px;
            margin-bottom: 24px;
            max-width: 416.01px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #DADEDC; 

            box-shadow: 1px 1px 20px rgba(0, 24, 48, 0.22);
            border-radius: 3px;

            strong {
                width: 150px;
                height: 20px;
                left: 66.68px;
                top: 215.36px;
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: #0054A6;
            }
        }

        .terms-selected {
            //height: 48px;
            margin: 0 auto;
            margin-bottom: 24px;
            width: 380px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #F4F4F4;
            border-radius: 3px;

            strong {
                color: #0054A6;
            }
        }

        .dropdown-terms {
            /* border: 1px solid red; */
            width: 380px;
            //max-height: 200px;
            overflow-y: auto;
            /* margin-bottom: 20px; */
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }

    .reading-terms {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 35px;
        input[type="checkbox"] {
            margin-bottom: 5px;
        }

        p { 
            width: 150px;
            height: 20px;
            left: 66.68px;
            top: 215.36px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #0054A6;
        }
    }


    .buttons {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 15px;
        .button {
            width: 180px;
            height: 43px;
            border-radius: 5px;
            border: none;

            &:hover {
                filter: brightness(0.8);
            }
        }

        .cancel {
            background: transparent;
            border: 1px solid #0054A6;
            color: #0054A6;
        }

        .confirm {
            background: #AAAAAA;
            color: white;
            cursor: not-allowed;
        }

        .confirmed {
            background: #09B2AB;
            color: white;
        }
    }
    @media (max-width: 1024px) {
        padding: 0;
       
        h2{
            width: 274px;
            height: 28px;
            left: 33px;
            top: 92px;

            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            text-align: center;

            color: #383838;
        }
        p{
            width: 295px;
            height: 62px;
            left: 22px;
            top: 131px;

            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            text-align: center;

            color: #666666;
        }
        span{
            left: 45px;
            right: 77px;
            //top: 444.09px;
           // bottom: 92.91px;
            width: 218px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            /* or 117% */


            color: #383838;
        }
        .dropdown-list {
            .dropdown-item {
                width: 295.12px;
                max-width: 295.12px;
                height: 48.33px;
                left: 21.93px;
                top: 218.82px;

                background: #FFFFFF;
                box-shadow: 1px 1px 20px rgba(0, 24, 48, 0.22);
                border-radius: 3px;
            }
            .dropdown-terms{
            width: 247.85;
            max-width: 295.12px;
            }
            .terms-selected {
                width: 247.85;
                max-width: 295.12px;
            }



        }
        .buttons {
            .button 
            {
                width: 144px;
            }
        }
       
    }
`;