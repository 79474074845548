import React, { useRef, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { FiCheckCircle, FiAlertTriangle, FiXCircle } from 'react-icons/fi';

import { CloseModal, Form } from './styles';
import Modal from '../Modal';

interface IModalProps {
    isOpen: boolean;
    setIsOpen: () => void;
    buttonTitle: string;
    typeIcon?: 'success' | 'info';
}

const icons = {
    info: <FiAlertTriangle size={44} />,
    success: <FiCheckCircle size={44} />,
};

const ModalPagamentoConfirmado: React.FC<IModalProps> = ({
    isOpen,
    setIsOpen,
    buttonTitle,
    typeIcon,
}) => {

    const formRef = useRef<FormHandles>(null);
    // const [loadStatus, setLoadStatus] = useState(isLoading);

    const handleSubmit = useCallback(
        async () => {
            setIsOpen();
        }, [setIsOpen],
    );

    const handleClose = useCallback(
        async () => {
            setIsOpen();
        }, [setIsOpen],
    );

    return (
        <Modal isOpen={isOpen} setIsOpen={handleSubmit} Mtop="15%" overflow={false}>
            <CloseModal onClick={() => handleClose()}>
                <FiXCircle size={24} />
            </CloseModal>

            <Form ref={formRef} onSubmit={handleSubmit} type={typeIcon}>
                {icons[typeIcon || 'success']}

                <h1>Pagamento</h1>
                <h1>Efetuado com sucesso</h1>

                <p>Execução de procedimentos em menores de 18 anos só
                será permitida apenas na presença de um responsável legal.</p>

                <button>
                    <div className="text">{buttonTitle}</div>
                </button>
            </Form>
        </Modal>
    );
};

export default ModalPagamentoConfirmado;