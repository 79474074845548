import { useEffect, useState } from 'react';
import * as S from './styles';
import iconIdentity from '../../images/icons/identity.svg';
import Button from '../../components/Button';

interface BeneficioData {
    idServico: number;
    servico: string;
    renovaEm: string;
    quantidadeUtilizada: number;
    quantidadeTotal: number;
}

export function GridBeneficios() {
    const [beneficios, setBeneficios] = useState<BeneficioData[]>([]);
    const [patrocinadores, setPatrocinadores] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);


    const [linkProgramaFidelidade, setLinkProgramaFidelidade] = useState('');
    const [linkSempreBemClub, setLinkSempreBemClub] = useState('');

    useEffect(() => {
        setLoading(true);
        const vtexclientemail = localStorage.getItem('_pmVtexclientemail');
        fetch(`/beneficioData/email/${vtexclientemail}`, { cache: "no-cache" })
            .then(response => response.json())
            .then(data => {

                if (data.result === "Success") {
                    if (data.beneficioCliente.beneficios != null) {
                        setBeneficios(data.beneficioCliente.beneficios);
                        setPatrocinadores(data.beneficioCliente.parcerias.map(function (elem: any) { return elem.nome; }).join("; "));
                    }
                }
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));

        let apiUrl = `beneficioData/LinkProgramaFidelidade`;
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                if (data.result === "Success")
                    setLinkProgramaFidelidade(data.linkProgramaFidelidade);
            });

        apiUrl = `beneficioData/LinkSempreBemClub`;
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                if (data.result === "Success")
                    setLinkSempreBemClub(data.linkSempreBemClub);
            });
    }, []);

    return (
        <>
            <S.Container id="grid-container">
                <h4>Serviços farmacêuticos</h4>
                <div className={"lista-beneficios " + !loading && !error && !beneficios.length ? "grid-sem-beneficios" : ""}>
                    {loading && !error && <p>Carregando...</p>}
                    {!loading && error && <p>Falha ao buscar os dados. Por favor, tente novamente mais tarde.</p>}
                    {!loading && !error && !!beneficios.length && beneficios.map(beneficio => (
                        <S.CardBeneficio key={beneficio.idServico}>
                            <p>{beneficio.servico}</p>
                            <div className="info">
                                <div className="renovacao">
                                    <h6>Renova em:</h6>
                                    <p>{beneficio.renovaEm}</p>
                                </div>

                                <div className="quantidade">
                                    <h6>Quantidade:</h6>
                                    <p>{(beneficio.quantidadeTotal - beneficio.quantidadeUtilizada) + ' de ' + beneficio.quantidadeTotal}</p>
                                </div>
                            </div>
                        </S.CardBeneficio>
                    ))}

                    {!loading && !error && !beneficios.length && (
                        <div className="sem-beneficios">
                            <img src={iconIdentity} alt="Ícone de identidade" />
                            <h5>Você não tem benefícios</h5>
                            {window.innerWidth <= 1024 ?
                                <p>Para obter descontos e gratuidade em <br /> serviços, torne-se um cliente OURO ou adquira <br /> um dos planos Sempre Bem Saúde.</p> :
                                <p>Para obter descontos e gratuidade em serviços, torne-se um cliente <br /> OURO ou adquira um dos planos Sempre Bem Saúde.</p>
                            }
                            <div className="botoes-cliente-ouro">
                                <a href={linkProgramaFidelidade} target="_blank">
                                    <Button className="cliente-ouro btn" >Cliente Ouro</Button>
                                </a>
                                <a href={linkSempreBemClub} target="_blank">
                                    <Button className="btn">Sempre Bem Saúde</Button>
                                </a>

                                {/*<Button className="cliente-ouro" width="240px">Cliente Ouro</Button>
                  <Button width="240px">Sempre Bem Saúde</Button>*/}
                            </div>
                        </div>
                    )}
                </div>
            </S.Container>

            <div className="patrocinadores">
                <h2>Patrocinadores</h2>
                <p>{patrocinadores}</p>
            </div>
        </>
    )
}