import styled from 'styled-components';
import IconArrowBannerPrev from '../../images/icons/arrow-banner-prev.svg';
import IconArrowBannerNext from '../../images/icons/arrow-banner-next.svg';

interface Props {
  width?: string;
}

export const Container = styled.div<Props>`
  background: transparent;
  /* padding: 0; */
  /* margin: 0; */
  /* padding-top: 10px; */
  /* width: 100vw; */

  .slick-active {
    @media (max-width: 360px) {
      margin-left: 6px !important;

      & + div {
        margin-left: -40px;
      }
    }

    @media (min-width: 361px) and (max-width: 1024px) {
      margin-left: -5px !important;
      & + div {
        margin-left: ${() => window.screen.width / -5 + 'px !important'};
      }
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    top: calc(25% - 15px);
    margin: 0;
    transform: initial;
    background-repeat: no-repeat;
    background-size: 6px;
    background-position: center;
    background-color: white;
    margin-left: 50px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 1px 1px 4px rgba(0, 24, 48, 0.25);
   
    @media(max-width: 1024px) {
      margin: 0 !important;
      width: 30px;
      height: 30px;
      top: calc(50% - 25px);
    }

    &::before {
      content: '';
    }
  }

  .slick-prev {
    left: 0;
    background-image: url(${IconArrowBannerPrev});
  }

  .slick-next {
    right: 0;
    margin-right: 50px;
    background-image: url(${IconArrowBannerNext});
  }
    @media(min-width: 1024px) {
  .slick-slide {
    margin-left: 10px;
    /* width: 100%; */
    width: 300px !important;
    padding-top: 10px;
  }

  .slick-track {
    left: 15px;
    height: 415px;
    overflow: hidden;
  }

    @media (max-width: 1024px) {
        .slick-track {
            left: 0;
        }
    }
}

 @media (min-width: 1024px) {
  .slick-dots {
    bottom: 140px;
  }
}


  .slick-dots li {
    margin: 0px 10px 0;
    width: 0;
    height: 0;
  }

  .slick-dots li button {
    width: 0;
    height: 0;
  }

  .slick-dots li button:before {
    opacity: 1;
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #BBBBBB;
  }

  .slick-dots li.slick-active {
    width: 40px;
    margin: 0 0 0 10px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: white;
    content: '';
    position: absolute;
    width: 38px;
    height: 8px;
    border-radius: 120px;
    background-color: #0054A6;
  }
`;