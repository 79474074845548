import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 0 64px;
    margin-top: 10px;
    
    a {
        min-width: 58.02px;
    }

    margin-bottom: 20px;

    @media (max-width: 1024px) {
       padding: 0 11px;
       margin-bottom: 0;
    }
`;

export const HeaderContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    color: #000000E5;

    @media (max-width: 1024px) {
        margin-bottom: -15px;
    }

    div.title {
        font-size: 18px;
        display: flex;

        @media (max-width: 1024px) {
            max-width: 280px;
        }
    }

    div.button {
        height: 25px;
      
        button.link-redirect {
            border: none;
            background: transparent;
            color: #0054A6;
            font-size: 14px;
            font-weight: 700;
            height: 35px;
            text-decoration: underline;
            white-space: nowrap;

            @media (max-width: 1024px) {
                height: 100%;
            }
        }
    }
    
`;

export const Carousel = styled.div`
    display: flex;
    justify-content: space-around;
`;