import styled from 'styled-components';
import media from '../../../tokens/media';


interface DivSliderProps {
  width: number;
}

export const ContainerSlick = styled.div`

  .cards-exams {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    padding: 0 1rem;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  .slick-dots {
    display: flex!important;
    justify-content: center;
    position: relative!important;
    margin: 40px 0 0 !important;
    bottom: unset;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      width: 66px;
      &.slick-active {
        button {
          &::before{
            background: #014CAB;
            opacity: 1;
          }
        }
      }

      button{
        width: 66px;
        &::before {
          content: '';
          opacity: 1;
          width: 66px;
          height: 6px;
          background: #C4C4C4;
          border-radius: 4px;
        }
      }
    }

    ${media.xs} {
     li {
          width: 40px;
          button{
            width: 40px;
            &::before {
              width: 40px;
            }
          }
        }
    }
  }
`;

export const TitleSlick = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  @media (max-width: 1024px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 22px;
    }
  span {
    font-weight: 500;
    line-height: 20px;
    color: #666;
    margin-bottom: 0;

    ${media.sm} {
      width: 100%;
    }

  }
`;

export const ContainerButton = styled.div`
 // padding-left:28px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  position: absolute;
 
  /* border: 1px solid red; */
  

  button.button-back-sub-category {
    width: 220px; 
    margin-right: 21px; 
    color: #0054A6; 
    background-color: #0054A600; 
    border-radius: 4px; 
    border: solid 1px #0054A6;
  }

  button.button-agenda-paciente {
    width: 129px;
  }

  @media (max-width: 1024px) {
    margin-top: 18px;
    max-width: 360px;
    display: flex;
    justify-content: center;
  }
`;
