import { useEffect, useState } from "react";
import CarouselCards from "./CarouselCards";

import ServicoService from "../../services/ServicoService";
import ICategoria from "../../types/Categoria";

import { Container, HeaderContent, Carousel } from "./styles";
import IServico from "../../types/Servico";

export default function ScheduleServices(props: any) {
  const [listCategories, setListCategories] = useState<ICategoria[]>();
  const [categorias, setCategorias] = useState<ICategoria[]>();
  const [pharmaceutical, setPharmaceutical] = useState<IServico[]>();
  const [vaccination, setVacination] = useState<IServico[]>();
  const { toggleIsOpenModal } = props;

  useEffect(() => {
    (async () => {
      await ServicoService.getAllCategoriaServico()
        .then((response) => {
          if (response?.status !== 200 || !response.data) return;

          setCategorias(response.data);
        })
        .catch((e: Error) => {
          console.error(e);
        });
    })();
  }, []);

  useEffect(() => {
    setListCategories(categorias);
    setPharmaceutical(categorias?.[0].servicos);
    setVacination(categorias?.[1].servicos);
  }, [categorias]);

  // useEffect(() => {

  //     loadCategorias();

  // }, [loadCountCategorias])

  // function loadCategorias() {
  //     console.log(`Buscando Categoria ${loadCountCategorias}x`);
  //     fetch('/servicoData/GetAllCategoria')
  //         .then(response => response.json())
  //         .then(data => {
  //             if (data.result === "Error") {
  //                 if (data.code === "001" && loadCountCategorias < 3) {
  //                     let count = loadCountCategorias + 1;
  //                     setLoadCountCategorias(count);
  //                 }
  //             } else {
  //                 setListCategories(data.items);
  //             }
  //         })
  //         .catch(error => console.log(error));
  // }

  // useEffect(() => {
  //     loadServicos();
  // }, [loadCountServicos])

  // function loadServicos() {
  //     console.log(`Buscando Servico ${loadCountServicos}x`);
  //     fetch('/servicoData/GetAllCategoriaServico')
  //         .then(response => response.json())
  //         .then(data => {
  //             if (data.result === "Error") {
  //                 if (data.code === "001" && loadCountServicos < 3) {
  //                     let count = loadCountServicos + 1;
  //                     setLoadCountServicos(count);
  //                 }
  //             } else {
  //                 setPharmaceutical(data.items[0].servicos);
  //                 setVacination(data.items[1].servicos);
  //             }
  //         })
  //         .catch(error => console.log(error));
  // }

  return (
    <Container>
      <HeaderContent>
        <h2>Agendar serviços</h2>
        <p>Conheça TODOS nossos serviços, agende agora</p>
      </HeaderContent>

      <Carousel>
        <CarouselCards
          pharmaceutical={pharmaceutical}
          id={1}
          name={listCategories && listCategories[0]?.nome}
          description={listCategories && listCategories[0]?.descricao}
          toggleIsOpenModal={toggleIsOpenModal}
        />
        <CarouselCards
          vaccination={vaccination}
          id={2}
          name={listCategories && listCategories[1]?.nome}
          description={listCategories && listCategories[1]?.descricao}
          toggleIsOpenModal={toggleIsOpenModal}
        />
      </Carousel>
    </Container>
  );
}
