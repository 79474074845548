import styled from 'styled-components';

export const Container = styled.div`
  width: 307px;
  height: 800px;
  margin-top: -40px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;

  border: 1px solid #F4F4F4;
  box-sizing: border-box;

  .card {
    width: 85%;
    min-height: 57px;
    align-items: baseline;
  }

  .separador {
    margin-left: 47px;
    width: 259px;
    height: 1px;
    background: #F4F4F4;

    @media (max-width: 1024px) {
      width: 100%;
      margin: 0;
    }
  }

  .mobile-menu {
    display: none;
    padding: 0 8px;
  }

  @media (min-width: 1025px) {
    #steps {
      display: block !important;
    }
  }

  @media (max-width: 1024px) {
    width: 100vw;
    
    .mobile-menu {
      display: block;
    }

    #steps {
      display: none;
    }
  }
`;