import React, { useRef, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { FiCheckCircle, FiAlertTriangle, FiXCircle } from 'react-icons/fi';

import { CloseModal, Form } from './styles';
import Modal from '../Modal';

interface IModalProps {
    isOpen: boolean;
    setIsOpen: () => void;
    fecharModal: () => void;
    pagarAgendamento: () => void;
    buttonTitle: string;
    typeIcon?: 'success' | 'info';
    showPaymentButton?: boolean;
    overflow: boolean;
}

const icons = {
    info: <FiAlertTriangle size={44} />,
    success: <FiCheckCircle size={44} />,
};

const ModalSuccess: React.FC<IModalProps> = ({
    isOpen,
    setIsOpen,
    fecharModal,
    pagarAgendamento,
    buttonTitle,
    typeIcon,
    showPaymentButton = false,
    overflow = false
}) => {
    const formRef = useRef<FormHandles>(null);

    const handleFecharModal = useCallback(
        async () => {
            fecharModal();
        }, [fecharModal],
    );

    const handlePagarAgendamento = useCallback(
        async () => {
            pagarAgendamento();
        }, [pagarAgendamento],
    );
    return (
        <>
        {window.innerWidth < 1024 &&(
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} Mtop="15%" overflow={false}>
            <CloseModal onClick={() => fecharModal()}>
                <FiXCircle size={24} />
            </CloseModal>

            <Form ref={formRef} onSubmit={handleFecharModal} type={typeIcon}>
                {icons[typeIcon || 'success']}

                <h1>Agendamento</h1>
                <h1>Concluído com sucesso</h1>

                <p>Execução de procedimentos em menores de 18 anos só
                será permitida apenas na presença de um responsável legal.</p>
                {
                    showPaymentButton &&
                    <button className="success" onClick={() => handlePagarAgendamento()}>
                        <div className="text">Pagar serviço agendado</div>
                    </button>
                }
                <button>
                    <div className="text">{buttonTitle}</div>
                </button>
            </Form>
        </Modal>
        )}
        {window.innerWidth > 1024 &&(
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} Mtop="15%" overflow={overflow}>
            <CloseModal onClick={() => fecharModal()}>
                <FiXCircle size={24} />
            </CloseModal>

            <Form ref={formRef} onSubmit={handleFecharModal} type={typeIcon}>
                {icons[typeIcon || 'success']}

                <h1>Agendamento</h1>
                <h1>Concluído com sucesso</h1>

                <p>Execução de procedimentos em menores de 18 anos só
                será permitida apenas na presença de um responsável legal.</p>
                {
                    showPaymentButton &&
                    <button className="success" onClick={() => handlePagarAgendamento()}>
                        <div className="text">Pagar serviço agendado</div>
                    </button>
                }
                <button>
                    <div className="text">{buttonTitle}</div>
                </button>
            </Form>
        </Modal>
        )}
        </>
    );
};

export default ModalSuccess;
