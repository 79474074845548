import SlickSlider, { Settings } from 'react-slick';

import { Container } from './styles';

interface Props {
    settings: Settings;
}

export type { Settings };

const SliderScheduleServices: React.FC<Props> = ({ children, settings }) => {
    return (
        <Container>
            <SlickSlider {...settings}>
                {children}
            </SlickSlider>
        </Container>
    );
}

export default SliderScheduleServices;