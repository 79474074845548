import styled from 'styled-components';

export const Container = styled.div`
  background-color: #FFF;
  border: 1px solid red;
  width: 295px;
  box-shadow: 1px 1px 10px rgba(0, 24, 48, 0.22);
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  height: 418px;
  border: 0px solid #FFF;
  transition: .35s;
  border-radius: 6px;
  padding-bottom: 12px;

  @media (max-width: 1024px) {
    margin-top: 10px;
    /* width: 301px; */
    margin: auto;
  } 
    &:hover {
      border: 0px solid #0054A6;
      box-sizing: border-box;
      box-shadow: 1px 1px 20px rgba(0, 84, 166, 0.30);
      border-radius: 6px;
    }
    #Subcontainer{
      margin: 0 auto;
      text-align: center;

      @media (max-width: 767px) {
        padding: 0 1rem;
      }
    }
`;

export const Title = styled.p`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #383838;
  margin-bottom: 6px;
`;

export const Type = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  height: 19px;
  margin-bottom: 26px;

  /* @media (max-width: 1024px) {
    margin-bottom: 15px;
  } */
`;

export const Time = styled.div`
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0054A6;

    img {
      display: inline;
      margin-bottom: 4px;
    }

    span{
      font-weight: 400;
      color: #666666;
      font-size: 14px;
    }
  }
`;

export const Value = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 28px;
  color: #0054A6;
`;

export const LinkMore = styled.a`
  margin-top: 11px;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  width: 240px;
  height: 43px;
  background-color: #FFFFFF00;
  border-radius: 6px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  line-height: 16px;
  color: #0054A6;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const Imagem = styled.img`
  background-size: cover;
  object-fit: cover;
  padding: 0;
  margin:0;
  width: 100%;
  min-height: 177px;
  max-height: calc(100% / 1.7);
  margin-bottom: 12px;
  border-radius: 6px 6px 0px 0px;


  @media (max-width: 1024px) {
    margin-bottom: 20px;
    max-height: 250px;
  }
`;

export const AsFrom = styled.span`
  font-size: 14px;
  line-height: 136%;
  color: #666666;
`;

export const Button = styled.button`
font-size: 16px;
  font-weight: 400;
  font-family: Roboto; 
  width: 248px;
  height: 43px;
  border: none;
  background-color: #0054A6;  
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #FFFFFF;
  cursor: pointer;
`;