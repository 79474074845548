import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import { isValid, format } from "date-fns";

import { Calendar } from "../../components/Calendar";

import { Container } from "./styles";
import IconInputCalendar from "../../images/icons/icon-calendar-input.svg";
import { mask } from "../../utils";

interface InputCalendarProps {
  name: string;
  title: string;
  inputStyle?: React.CSSProperties | undefined;
  styleTextColor?: string;
  defaultDateSelected?: Date;
  handleDateChange?: (date: Date[] | Date | undefined) => void;
  isRange?: boolean;
  placeholder?: string;
  titleStyle?: string;
  availableDays?: string[];
  SetDateValidation?: (valid: boolean) => void;
  showAllDates?: boolean;
}

interface ModalDataProps {
  dateInicial: string;
  dateFinal: string;
}

export function InputCalendar({
  name,
  title,
  defaultDateSelected,
  handleDateChange = () => {},
  inputStyle,
  styleTextColor = "#404f61",
  isRange = true,
  placeholder,
  titleStyle,
  availableDays,
  showAllDates = false,
  SetDateValidation = () => {},
}: InputCalendarProps) {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [dateValidation, setDateValidation] = useState(false);

  useEffect(() => {
    SetDateValidation(dateValidation);
  }, [dateValidation]);

  const [dateSelec, setDateSelec] = useState(
    defaultDateSelected
      ? Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
          defaultDateSelected
        )
      : ""
  );
  const [dateInicial, setDateInicial] = useState<string>("");
  const [dateFinal, setDateFinal] = useState<string>("");
  const [valueDefaultDate, setValueDefaultDate] = useState<Date | undefined>(
    defaultDateSelected
  );
  const [modalData, setModalData] = useState<ModalDataProps>(
    {} as ModalDataProps
  );

  const today = new Date();

  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setModalData({
      dateInicial,
      dateFinal,
    });

    window.removeEventListener("click", detectClickOusideModalCalendar);
    if (openCalendar) {
      window.addEventListener("click", detectClickOusideModalCalendar);
    }

    return () => {
      window.removeEventListener("click", detectClickOusideModalCalendar);
    };
  }, [openCalendar, dateInicial, dateFinal]);

  function handleOpenCalendar() {
    setOpenCalendar(!openCalendar);
  }

  function handleDateSelect(date: Date[] | undefined) {
    let initialDate = date;

    if (!initialDate?.length) {
      initialDate = [
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ];
    }
    const formattedInitialDate = new Intl.DateTimeFormat("pt-BR").format(
      initialDate[0]
    );
    const formattedFinalDate = new Intl.DateTimeFormat("pt-BR").format(
      initialDate[1]
    );

    setDateInicial(formattedInitialDate);
    setDateFinal(formattedFinalDate);
    setDateSelec(formattedInitialDate + " até " + formattedFinalDate);
  }

  function handleOneDateSelect(date: Date) {
    let initialDate = new Date(format(date, "yyyy-MM-dd"));
    setValueDefaultDate(initialDate);
    if (!!initialDate) {
      const formattedInitialDate = Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(initialDate);

      handleDateSelect([date] as Date[]);
      setDateSelec(formattedInitialDate);
      setOpenCalendar(false);
      handleDateChange(date);
    }
  }

  function detectClickOusideModalCalendar(e: MouseEvent) {
    const calendarComponent = document.getElementById("calendarComponent");

    // @ts-ignore
    if (!calendarComponent?.contains(e.target)) {
      if (!calendarComponent) {
        return;
      }

      if (openCalendar) {
        setOpenCalendar(false);
      }
    }
  }

  function handleChangeInputText(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value.length) {
      handleDateChange(undefined);
      setValueDefaultDate(undefined);
    }
    
    if (event.target.value.length <= 2) {
      setDateSelec(mask(event.target.value.replaceAll("/", ""), "##"));
    } else if (event.target.value.length <= 5) {
      setDateSelec(mask(event.target.value.replaceAll("/", ""), "##/##"));
    } else {
      setDateSelec(mask(event.target.value.replaceAll("/", ""), "##/##/####"));
    }

    if (event.target.value.length <= 10) setDateValidation(true);

    if (event.target.value.length === 10) {
      const dateSplit = event.target.value.split("/");

      if (
        !isValid(
          new Date(
            new Date(
              Number(dateSplit[2]),
              Number(dateSplit[1]) - 1,
              Number(dateSplit[0])
            )
          )
        ) 
      ) {
        setValueDefaultDate(new Date());
        setDateValidation(true);
        return;
      }

      const newDate = new Date(
        Number(dateSplit[2]),
        Number(dateSplit[1]) - 1,
        Number(dateSplit[0])
      );

      if (!!availableDays && !availableDays.includes(Intl.DateTimeFormat("pt-br", {
        timeZone: "UTC"
      }).format(newDate))) {
        setDateValidation(false);
        return;
      }
      
      handleDateChange(newDate);
      setValueDefaultDate(newDate);
      setDateValidation(false);
    }
  }

  return (
    <Container openCalendar={openCalendar} styleColor={styleTextColor}>
      <div className="calendar">
        <div className="inputCalendar">
          <label
            style={{
              color: titleStyle,
            }}
          >
            {title}
          </label>
          <input
            ref={inputRef}
            type="text"
            name={name}
            placeholder={placeholder ? placeholder : "Selecione uma data"}
            value={dateSelec}            
            style={inputStyle}
          />
          <img
            src={IconInputCalendar}
            alt="calendar"
            onClick={() => handleOpenCalendar()}
          />
        </div>

        {openCalendar && (
          <div className="calendarComponent" id="calendarComponent">
            <Calendar
              isRange={isRange}
              showAllDates={showAllDates}
              availableDays={availableDays}
              valueDefault={
                valueDefaultDate
                  ? new Date(
                      valueDefaultDate?.getUTCFullYear(),
                      valueDefaultDate?.getUTCMonth(),
                      valueDefaultDate?.getUTCDate()
                    )
                  : undefined
              }
              handleSetDate={(date) => {
                handleDateSelect(date);
                if (!!date && date.length === 2) {
                  setDateSelec(
                    new Intl.DateTimeFormat("pt-BR").format(date[0]) +
                      " até " +
                      new Intl.DateTimeFormat("pt-BR").format(date[1])
                  );
                  setOpenCalendar(false);
                }
                if (!!date) {
                  setDateSelec(
                    new Intl.DateTimeFormat("pt-BR").format(date[0])
                  );
                  setOpenCalendar(false);
                }
              }}
              handleClickDay={(date) =>
                isRange
                  ? handleDateChange(date)
                  : handleOneDateSelect(date as Date)
              }
            />
          </div>
        )}
      </div>
    </Container>
  );
}
