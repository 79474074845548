import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useField } from "@unform/core";

import IconSelect from "../../images/icons/icon-select.svg";
import IconSearch from "../../images/icons/icon-search.svg";
import { Container, Label, Required } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  labelStyle?: object;
  containerStyle?: object;
  isDisabled?: boolean;
  showSelectArrow?: boolean;
  isRequired?: boolean;
  isRequiredText?: string;
  isSearchInput?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  labelStyle = {},
  containerStyle = {},
  isDisabled = false,
  showSelectArrow = false,
  isRequired = false,
  isRequiredText = "",
  isSearchInput = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <>
      {label && (
        <Label htmlFor={fieldName}>
          {label}{" "}
          {isRequired && (
            <Required>
              * {isRequiredText && "(" + isRequiredText + ")"}
            </Required>
          )}
        </Label>
      )}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isDisabled={isDisabled}
        data-testid="input-container"
      >
        {showSelectArrow && <img src={IconSelect} alt="Select" />}
        {isSearchInput && <img src={IconSearch} alt="Search" />}

        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          disabled={isDisabled}
          ref={inputRef}
          id={fieldName}
          {...rest}
        />
      </Container>
    </>
  );
};

export default Input;
