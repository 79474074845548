import { Container } from "./styles";
import ReactCalendar from "react-calendar";
import { useState, useEffect } from "react";

interface DateProps {
  handleSetDate?: (date: Date[] | undefined) => void;
  handleClickDay?: (date: Date[] | Date) => void;
  valueDefault?: Date;
  defaultDateSelected?: Date[];
  isRange?: boolean;
  availableDays?: string[];
  showAllDates?: boolean;
}

const today = new Date();

export function Calendar({
  handleSetDate,
  handleClickDay,
  defaultDateSelected = [],
  valueDefault,
  isRange = true,
  availableDays,
  showAllDates = false,
}: DateProps) {
  const [dateSelect, setDateSelect] = useState<Date[]>(
    defaultDateSelected.length
      ? defaultDateSelected
      : [
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
          ),
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            23,
            59,
            59
          ),
        ]
  );
  const [oneDateSelect, setOneDateSelect] = useState<Date>(
    valueDefault as Date
    );
    const [maxDate, setMaxDate] = useState(new Date());

    useEffect(() => {
        console.log(availableDays);
        if (availableDays !== undefined && availableDays.length > 0) {
            const _date = availableDays[availableDays.length];
            setMaxDate(new Date(_date));
        }
    }, [availableDays]);
    
  return (
    <Container>
      <div className="calendar-cointainer">
        {(!showAllDates && availableDays?.length === 0) && (<div className="msgCarregando">carregando...</div>)}
        {(showAllDates || (availableDays !== undefined && availableDays.length) > 0) && (                  
        <ReactCalendar
          locale="pt-br"
          prev2Label={null}
          next2Label={null}
          onClickDay={(event) => {
            if (!handleClickDay) {
              return;
            }

            if (!isRange) {
              setOneDateSelect(event);
              handleClickDay(event);
              return;
            }
            handleClickDay([event]);

            if (dateSelect.length === 2) {
              setDateSelect([event]);
              return;
            }

            setDateSelect(
              [...dateSelect, event]
                .sort((a, b) => a.getDate() - b.getDate())
                .sort((a, b) => a.getMonth() - b.getMonth())
            );
          }}
          onChange={(event: any) => {
            if (!handleSetDate) {
              return;
            }
            if (isRange) {
              setDateSelect(event as unknown as Date[]);
              handleSetDate(event as unknown as Date[]);
            }
          }}
          value={
            isRange
              ? defaultDateSelected.length
                ? defaultDateSelected
                : dateSelect
              : oneDateSelect
          }
          prevLabel={
            <svg
              width="18"
              height="18"
              viewBox="0 0 6 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.08408 0.243701C4.87992 0.0395345 4.55075 0.0395345 4.34658 0.243701L0.884082 3.7062C0.721582 3.8687 0.721582 4.1312 0.884082 4.2937L4.34658 7.7562C4.55075 7.96037 4.87992 7.96037 5.08408 7.7562C5.28825 7.55203 5.28825 7.22287 5.08408 7.0187L2.06742 3.99787L5.08825 0.977035C5.28825 0.777035 5.28825 0.443701 5.08408 0.243701Z"
                fill="#0054A6"
              />
            </svg>
          }
          nextLabel={
            <svg
              width="18"
              height="18"
              viewBox="0 0 6 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.915857 7.7562C1.12002 7.96037 1.44919 7.96037 1.65336 7.7562L5.11586 4.2937C5.27836 4.1312 5.27836 3.8687 5.11586 3.7062L1.65336 0.243701C1.44919 0.0395345 1.12002 0.0395345 0.915857 0.243701C0.71169 0.447868 0.71169 0.777035 0.915857 0.981201L3.93252 4.00203L0.91169 7.02287C0.71169 7.22287 0.71169 7.5562 0.915857 7.7562Z"
                fill="#0054A6"
              />
            </svg>
          }
          tileDisabled={({activeStartDate, date, view }) => {
            const formattedDate = Intl.DateTimeFormat('pt-BR', {
              timeZone: "UTC"
            }).format(date);
            const splittedFormattedDate = formattedDate.split('/');
            return !!availableDays && !availableDays.includes(`${splittedFormattedDate[2]}-${splittedFormattedDate[1]}-${splittedFormattedDate[0]}`);
          }}
            selectRange={isRange}
            minDate={showAllDates ? undefined : new Date()}
            maxDate={showAllDates ? undefined : availableDays ? maxDate : new Date()}
          formatMonthYear={(locale: any, date: any) => {
            const newDate = new Date();

            return [
              `Janeiro ${newDate.getFullYear()}`,
              `Fevereiro ${newDate.getFullYear()}`,
              `Março ${newDate.getFullYear()}`,
              `Abril ${newDate.getFullYear()}`,
              `Maio ${newDate.getFullYear()}`,
              `Junho ${newDate.getFullYear()}`,
              `Julho ${newDate.getFullYear()}`,
              `Agosto ${newDate.getFullYear()}`,
              `Setembro ${newDate.getFullYear()}`,
              `Outubro ${newDate.getFullYear()}`,
              `Novembro ${newDate.getFullYear()}`,
              `Dezembro ${newDate.getFullYear()}`,
            ][date.getMonth()];
          }}
            />
        )}
      </div>
    </Container>
  );
}
