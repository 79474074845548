import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (max-width:1024px){
        margin-top: 340px;
    }
`;
export const ContainerHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 90px;
    @media (max-width: 1024px){
        padding-bottom: 53px;
    }
    h2{
        height: 25px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        color: #0054A6;
        margin-bottom: 23px;
        @media (max-width: 1024px){
            font-size: 30px;
        }
    }
    p{
        height: 17px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: rgba(0, 0, 0, 0.9);
    }
`;