export const removeElementDOM = (elementId: any) => {
  const elements = document.querySelectorAll(`#${elementId}`);

  for (let index = 0; index < elements.length; index++) {
    const element = elements[index];
    if (element) element.parentElement?.removeChild(element);
  }
};

export const LoadGetnetPayment = (
  agendamento: any,
  paymentButtonClass: string,
  redirectToUrl: string,
  onLoaded = () => {},
  onError = () => {}
) => {
  const tagScriptId = "getnetPaymentScript";
  const iframeId = "getnet-checkout";

  removeElementDOM(tagScriptId);
  removeElementDOM(iframeId);
  const existingScript = document.getElementById(tagScriptId);

  /*
    agendamento = {
        identificadorLojaGetnet: "cd8a8d61-1da9-48b1-87b4-6731a4fe5ce4",
        identificadorClienteGetnet: "HubSaude-[CPF]",
        identificadorPedido: "[ANO]-[MES]-[DIA]-[AGENDAMENTO]-[LOJA]",
        quantidadeMaximaParcelas: 3,
        valorTotal: 3221.54,
        primeiroNomeCliente: "",
        sobrenomeCliente: "",
        emailCliente: "",
        cpfCliente: "012345678901",
        getnetToken: "",
        enderecoCliente: {
            cep: "00000-000",
            rua: "Rua Teste",
            numeroRua: "000",
            bairro: "Bairro Teste",
            cidade: "Cidade Teste",
            uf: "TE",
            pais: "País Teste"
        }
    }
    */

  if (existingScript) {
    existingScript.remove();
  }

  const script = document.createElement("script");

  script.id = tagScriptId;
  script.src =
    process.env.REACT_APP_GETNET_CHECKOUT_IFRAME_URL ??
    "https://checkout-homologacao.getnet.com.br/loader.js ";

  const paymentMethodsDisabled = ["debito", "boleto", "qr-code", "pix"];

  const items: any = [
    // {
    //     name: "",
    //     description: "",
    //     value: 0,
    //     quantity: 0,
    //     sku: ""
    // }
  ];

  // new URL(window.location.href).searchParams.get("pagamentoEfetuadoComSucesso") === 'true'
  // new URL("pagamentoConfirmado", window.location.href).toString()
  // `${new URL("agendamento", window.location.href).toString()}?pagamentoEfetuadoComSucesso=true`

  if (redirectToUrl) {
    script.setAttribute("data-getnet-url-callback", redirectToUrl);
  }

  script.setAttribute(
    "data-getnet-sellerid",
    agendamento.getnetToken.identificadorLojaGetnet
  );
  script.setAttribute(
    "data-getnet-token",
    `${agendamento.getnetToken.tipoToken} ${agendamento.getnetToken.tokenAcesso}`
  );
  script.setAttribute(
    "data-getnet-payment-methods-disabled",
    JSON.stringify(paymentMethodsDisabled)
  );
  script.setAttribute("data-getnet-button-class", paymentButtonClass);
  script.setAttribute("data-getnet-disable-shipping-address", "false");
  script.setAttribute(
    "data-getnet-customerid",
    agendamento.identificadorClienteGetnet
  );
  script.setAttribute(
    "data-getnet-orderid",
    agendamento.identificadorPedidoGetnet
  );
  script.setAttribute(
    "data-getnet-installments",
    agendamento.quantidadeMaximaParcelas
  );
  script.setAttribute("data-getnet-amount", agendamento.valorTotal.toFixed(2));
  script.setAttribute(
    "data-getnet-customer-first-name",
    agendamento.primeiroNomeCliente
  );
  script.setAttribute(
    "data-getnet-customer-last-name",
    agendamento.sobrenomeCliente
  );
  script.setAttribute("data-getnet-customer-document-type", "CPF");
  script.setAttribute(
    "data-getnet-customer-document-number",
    agendamento.cpfCliente
  );
  script.setAttribute("data-getnet-customer-email", agendamento.emailCliente);
  script.setAttribute(
    "data-getnet-customer-address-street",
    agendamento.enderecoCliente.rua
  );
  script.setAttribute(
    "data-getnet-customer-address-street-number",
    agendamento.enderecoCliente.numeroRua
  );
  script.setAttribute(
    "data-getnet-customer-address-neighborhood",
    agendamento.enderecoCliente.bairro
  );
  script.setAttribute(
    "data-getnet-customer-address-city",
    agendamento.enderecoCliente.cidade
  );
  script.setAttribute(
    "data-getnet-customer-address-state",
    agendamento.enderecoCliente.uf
  );
  script.setAttribute(
    "data-getnet-customer-address-zipcode",
    agendamento.enderecoCliente.cep
  );
  script.setAttribute(
    "data-getnet-customer-country",
    agendamento.enderecoCliente.pais
  );
  script.setAttribute("data-getnet-items", JSON.stringify(items));

  script.onload = () => {
    onLoaded();
  };

  script.onerror = () => {
    onError();
  };

  document.body.appendChild(script);
};

export default LoadGetnetPayment;
