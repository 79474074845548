import { useState } from 'react';
import { Container, Title, Type, Time, LinkMore, Imagem, Button } from './styles';
import IconValue from '../../../../images/icons/icon-time-exame.svg';
import ModalInfoShowcase from '../../ModalInfoShowcase';

interface CardShowcaseProps {
    name: string;
    description: string;
    timeExecutation: string;
    image: string;
    handleMoveToSubCategory: () => void;
    card: any;
}

export default function CardShowcase({ description, name, timeExecutation, image, handleMoveToSubCategory, card }: CardShowcaseProps) {

    const [showModalKnowMore, setShowModalKnowMore] = useState(false);

    function handleContinue() {
        const cardExam = [
            {
                id: card.id,
                imagem: card.urlImagem,
                nome: card.nomeAbreviado,
                preRequisito: card.preRequisito,
                tempo: card.horaExecucao,
                tempoBase: card.tempoBase,
                preco: card.preco
            }
        ];

        localStorage.setItem('_ApmServicosVitrine', JSON.stringify(cardExam));
        localStorage.setItem('_ApmServicos', JSON.stringify(cardExam));
        localStorage.setItem('_flagReload', JSON.stringify({
            reload: true,
        }))

        handleMoveToSubCategory();
    }

    function handleKnowMore() {
        window.CustomScrollToTop();
        window.removeDivWorkArround();
        setShowModalKnowMore(true);
    }

    function setIsOpen() {
        window.createDivWorkArround();
        setShowModalKnowMore(!showModalKnowMore);
    }

    return (
        <Container>
            <Imagem src={image}>

            </Imagem>
            <div id="Subcontainer">
                <Title>
                    {name}
                </Title>
                <Type>
                    {description}
                    &nbsp;
                </Type>

                <Time>
                    <p>
                        <img src={IconValue} alt="time" /> Tempo de exame: <span>{timeExecutation} min</span>
                    </p>
                </Time>

                <Button onClick={handleContinue}>
                    Agendar serviço
                </Button>

                <ModalInfoShowcase
                    isOpen={showModalKnowMore}
                    setIsOpen={setIsOpen}
                    servico={card}
                    onSelectCard={handleContinue}
                />

                <LinkMore onClick={handleKnowMore}>
                    Saiba mais
                </LinkMore>
            </div>

        </Container>
    );
}