import styled from "styled-components";

interface ContainerProps {
  showCancel: boolean;
  buttonsMarginTop: string;
  isAnimation: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  img {
    width: 44px;
    height: 44px;
  }

  img {
    animation: ${({ isAnimation }) => isAnimation ? 'loading 1.5s linear infinite' : ''};
    width: ${({ isAnimation }) => isAnimation ? '100px' : ''};
    height: ${({ isAnimation }) => isAnimation ? '100px' : ''};
  }

  h2 {
    color: #383838;
    font-size: 24px;
    font-weight: 600;
    margin-top: 24px;
  }

  p {
    /*color: #404f61;*/
    color: #666666;
    margin: 0;
    font-size: 14px;
  }

  div {
    width: 100%;

    display: flex;
    justify-content: center;

    button {
      text-align: center;
    }

    margin-top: ${({ buttonsMarginTop }) => buttonsMarginTop};

    button + button {
      margin-left: 24px;
    }
  }

  .marginButton {
    margin-bottom: 18px;
  }

  @keyframes loading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
`;
export const ButtonNewAppointment = styled.a`
  /* padding-left: 20px; */
  padding-top: 10px;
  width: 180px;
  height: 43px;
  background: #09B2AB;
  border-radius: 6px;
  color: #fff;
  margin-left: 21px;

    &:focus, &:hover, &:visited, &:link, &:active {
        color: #fff;
        text-decoration: none;
    }

  

  @media (max-width: 1024px) {
    width: 160px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;