import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Slick from '../components/Exame/Slick';
import { AgendamentoContext, AgendamentoContextData } from '../contexts/agendamento';

class Exame extends Component<any, any> {
    static contextType = AgendamentoContext; 

    
    constructor(props: any) {
        super(props);

        this.state = {
            agendamentoAssistido: false
        }
    }

    componentDidMount() {
        const agendamentoContext = this.context as AgendamentoContextData;
        agendamentoContext.handleUpdateCurrentStep('exame');
        
        //GUARDAR STEP
        let pathname = this.props.location.pathname;
        let path = pathname.substring(pathname.lastIndexOf('/'));
        localStorage.setItem('_pmLastStep', path);
        //GUARDAR STEP

        //LIMPAR DADOS DAS ETAPAS FUTURAS
        // localStorage.removeItem('_pmLoja');
        // localStorage.removeItem('_pmLojaFiltro');
        // localStorage.removeItem('_pmDataSelecionada');

        //const _agendamentoAssistido = localStorage.getItem('_pmAgendamentoAssistido');
        //if (_agendamentoAssistido !== null) {
        //    this.setState({ agendamentoAssistido: true });
        //    window.CustomScrollToTopAssistido();
        //} else
        //    window.CustomScrollToTop();
    }

    render() {
        return (
            <Slick handleButtonToContinue={this.props.handleButtonToContinue} idSubCategorySelected={this.props.idSubCategorySelected} />
        )
    }
}
export default withRouter(Exame);