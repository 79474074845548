import styled from 'styled-components';

export const Container = styled.div`
    width: calc(100% / 2);
    height: 121.17px;
    border-radius:  8.52px;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
        span{
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.015em;
            color: #808080;
        }

    @media (max-width: 680px) {
        width: calc(100% / 1.5);
        justify-content: center;
    }
    @media (max-width: 400px) {
        width: 100%;
    }
`;
export const Imagem = styled.img`
    width: 84px;
    height: 84px;
    border-radius: 7px;
    object-fit: cover;
    margin-right: 12.16px;

    @media (max-width: 680px) {
        width: 64px;
        height: 64px;
    }

    @media (max-width: 250px) {
        width: 54px;
        height: 54px;
    }
`;
export const Title = styled.h6`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    color: #0054A6;

    @media (max-width: 350px) {
        font-size: 1.4rem;
    }

    @media (max-width: 250px) {
        font-size: 1.2rem;
    }
`;
export const SubTitle = styled.h6`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-align:left;
    @media (max-width: 1024px){
        font-size: 14px;
    }

    @media (max-width: 250px) {
        font-size: 12px;
    }
`;
export const ContainTitle = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 122px;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: 1024px){
        justify-content: flex-start;
    }
`;
export const ContainImg = styled.div`
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content:  flex-start;
    align-items: center;
`;
export const Link = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: end;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
    @media (max-width: 1024px){
        margin-top:20px;
    }
`;
export const LinkVermais = styled.a`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    text-align: end;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
    @media (max-width: 1024px){
        margin-top:20px;
    }
`;