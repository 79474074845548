import context from 'react-bootstrap/esm/AccordionContext';
import styled from 'styled-components';

interface Props {
  inverted: boolean;
  context: any;
}

export const ButtonConf = styled.button<Props>`
 // margin-right:68px;
  width: 240px;
  height: 43px;
  border: none;
  background-color: ${(props) => (props.inverted || props.context?.quantidadeServicos ? '#09B2AB' : '#AAAAAA')};
  border-radius: 6px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  transition: 0.1s ease;
  cursor: pointer;  
`;

export const ButtonFloat = styled.button<{ inverted: boolean }>`
margin-right:68px;
  width: 164px;
  height: 43px;
  border: none;
  background-color: ${(props) => (props.inverted ? '#09B2AB' : '#AAAAAA')};
  border-radius: 6px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  transition: 0.1s ease;
  cursor: pointer;  
`;

