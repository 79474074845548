import { useEffect } from "react";

export function useRemoveSteps() {
  useEffect(() => {
    const stepsSidebar = document.getElementById('stepsSidebar');
    const appTitle = document.getElementById('appTitle');
    stepsSidebar?.classList.add('d-none');
    appTitle?.classList.add('d-none');
    return () => { 
      stepsSidebar?.classList.remove('d-none'); 
      appTitle?.classList.remove('d-none'); 
    }
  }, []);
}