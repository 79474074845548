import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface ButtonProps {
  isBack?: boolean;
  isDisabled?: boolean;
}

export const Container = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  padding: 0 1rem 30px;
  margin-bottom: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 1024px) {
      width: 100%;
      position: fixed;
      bottom: 0;
      /* position: absolute;
      bottom: -100px; */
      padding: 16px 12px;
      box-shadow: 0px 0px 10px rgba(0, 24, 48, 0.1);
      background-color: #fff;
      z-index: 99;
    }
  }

  @media(max-width: 1024px) {
    padding: 0 12px;
    box-shadow: none;
    justify-content: center;
  }

  .button-group {
    column-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);

    a {

      :first-child {
        display: flex;
        justify-self: end;
      }

      margin: 0;
      max-width: 240px;
    }
  }
`;

export const Button = styled(Link)<ButtonProps>`
  width: 220px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  outline: none;
  border: none;
  text-decoration: none !important;
  color: #fff !important;
  background-color: #09B2AB;
  border: 1px solid #09B2AB;
  pointer-events: initial;

  ${({ isBack }) => isBack && isBackStyle}
  
  ${({ isDisabled }) => isDisabled && isDisabledStyle}

  @media(max-width: 1024px) {
    width: 100%;
  }
`;

export const ButtonHandle = styled.a<ButtonProps>`
  width: 220px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  outline: none;
  border: none;
  text-decoration: none !important;
  color: #fff !important;
  background-color: #09B2AB;
  border: 1px solid #09B2AB;
  pointer-events: initial;
  cursor: pointer;

  ${({ isBack }) => isBack && isBackStyle}
  
  ${({ isDisabled }) => isDisabled && isDisabledStyle}

  @media(max-width: 1024px) {
    width: 100%;
  }
`;

const isBackStyle = css`
  color: #0054A6 !important;
  background-color: #fff;
  border: 1px solid #0054A6;
  margin-right: 22px;

  @media(max-width: 1024px) {
    margin-right: 11px;
  }
`;

const isDisabledStyle = css`
  cursor: not-allowed !important;
  background-color: #AAAAAA;
  border: 1px solid #AAAAAA;
`;

export const Text = styled.span`
  color: #666;
  font-weight: 500;
`;