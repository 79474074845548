import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as S from './styles';
import iconCalendar from '../../images/icons/icon-calendar-modal.svg';
import iconCloseModal from '../../images/icons/icon-close.svg';

export interface MotivoData {
  id: number;
  descricao: string;
}

interface ModalFormReagendamentoProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onContinue: (motivoSelecionado: number, descricaoMotivo: string) => void;
}

export function ModalMotivoReagendamento({ isOpen, onRequestClose, onContinue }: ModalFormReagendamentoProps) {
  const [motivoSelecionado, setMotivoSelecionado] = useState(-1);
  const [descricaoMotivo, setDescricaoMotivo] = useState("");
  const [motivos, setMotivos] = useState<MotivoData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
    const [agendamentoAssistido, setAgendamentoAssistido] = useState(false);
    const [loadCount, setLoadCount] = useState(0);

  useEffect(() => {
      loadMotivos();
      
      const _agendamentoAssistido = localStorage.getItem('_pmAgendamentoAssistido');
      if (_agendamentoAssistido !== null)
          setAgendamentoAssistido(true);
  }, [loadCount]);

    function loadMotivos() {
      setLoading(true)
        console.log(`Buscando motivos ${loadCount}x`);
        fetch('AgendamentoData/GetReagendamentoMotivos', { cache: "no-cache" })
            .then(response => response.json())
            .then(data => {
                if (data.result === "Error") {
                    if (data.code === "001" && loadCount < 3) {
                        let count = loadCount + 1;
                        setLoadCount(count);
                    }
                } else {
                    setMotivos(data.items);
                }
            })
            .catch(() => setError(false))
            .finally(() => setLoading(false));
    }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      portalClassName="modal-reagendamento"
      style={{
        content: {
          top: '50%',
          left: '50%',
          marginRight: '-40%',
          transform: 'translate(-50%, -50%)',
          color: '#000000',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '502px',
          height: 'fit-content',
          minHeight: '465px',
          border: 'none',
          padding: '0',
          marginTop: 0,
          overflow: 'visible',
          maxHeight:'449px'
        },
        overlay: {
          backgroundColor: '#231f2066',
        },
      }}
    >
      <S.Container>
        <button type="button" className="close">
          <img src={iconCloseModal} alt="Close" onClick={onRequestClose} />
        </button>

        <S.ModalHeader>
          <img src={iconCalendar} alt="Calendário" />

          <h2>Reagendamento</h2>

                  {window.innerWidth <= 1024 ?
                      <p>Falta pouco para realizar seu agendamento. <br /> Agora nos conte qual é o motivo para sua <br /> solicitação de reagendamento?</p> :
                     <p>Falta pouco para realizar seu agendamento. Agora nos conte <br /> qual é o motivo para sua solicitação de reagendamento?</p>}

        </S.ModalHeader>

        <S.ModalContent>
          <div className="motivosContainer">
            {loading && !error && <p>Carregando...</p>}
            {error && !loading && <p>Falha ao buscar os dados, por favor, tente novamente mais tarde.</p>}
            {!error && !loading && motivos?.map((motivo) => (
              <span key={motivo.id}>
                <input
                  type="radio"
                  name="motivo"
                  id={"motivo-" + motivo.id}
                  className={motivoSelecionado === motivo.id ? "checked" : ""}
                  onClick={() => setMotivoSelecionado(motivo.id)}
                />
                <label htmlFor={"motivo-" + motivo.id}>
                  {motivo.descricao}
                </label>
              </span>
            ))}
            <span>
              <input
                type="radio"
                name="motivo"
                className={motivoSelecionado === 0 ? "checked" : ""}
                id="motivo-outro"
                onClick={() => setMotivoSelecionado(0)}
              />
              <label htmlFor="motivo-outro">Outro motivo.</label>
            </span>
          </div>

          {motivoSelecionado === 0 && (
            <span className="descricaoContainer">
              <label htmlFor="motivo-descricao">Qual o motivo?</label>
              <input
                name="motivo-descricao"
                id="motivo-descricao"
                placeholder="Limite de 40 caracteres"
                maxLength={40}
                value={descricaoMotivo}
                onChange={(event) => setDescricaoMotivo(event.target.value)}
              />
            </span>
          )}
        </S.ModalContent>

        <S.ModalFooter>
          <S.ButtonCancel onClick={onRequestClose}>Cancelar</S.ButtonCancel>
          <S.ButtonGoForward 
            disabled={
                agendamentoAssistido && (
                motivoSelecionado === -1 ||
                (motivoSelecionado === 0 && !descricaoMotivo))
            }
            onClick={() => { 
            onContinue(motivoSelecionado, descricaoMotivo);
            setMotivoSelecionado(-1);
            setDescricaoMotivo('');
          }}>{agendamentoAssistido ||
                         (motivoSelecionado > -1) ? 'Continuar' : 'Pular' }</S.ButtonGoForward>
        </S.ModalFooter>
      </S.Container>
    </Modal>
  );
}