import React, { useRef, useCallback, useState } from "react";
import { schedulingService } from "../../../services/SchedulingService";
import Modal from "../Modal";
import { cepMask } from "../../mask";
import Input from "../../Input";
import {
  Form,
  InputContainer,
  SpanErroCep,
  HeaderModal,
  FooterButtonsModal,
} from "./styles";
import { LoadGetnetPayment } from "../../LoadGetnetPayment";
import Swal from "sweetalert2";

interface IModalEnderecoPagamentoProps {
  paymentTimeout?: boolean;
  isOpen: boolean;
  setIsOpen: () => void;
  idAgendamento: number;
  appointmentToPay?: any;
  onError: () => void;
  onHandler: () => void;
  overflow: boolean;
  redirectToUrl: string;
  setErrorMsg: (e: any) => void;
}

export const validateCep = (cep: string) => {
  // Caso o CEP não esteja nesse formato ele é inválido!
  var validaCep = /^[0-9]{5}-[0-9]{3}$/;

  return validaCep.test(cep);
};

const ModalEnderecoPagamento: React.FC<IModalEnderecoPagamentoProps> = ({
  paymentTimeout,
  isOpen,
  setIsOpen,
  idAgendamento,
  appointmentToPay = null,
  onError,
  onHandler,
  overflow,
  redirectToUrl,
  setErrorMsg = (e: any) => {},
}) => {
  const paymentButtonClass: string = "getnetPaymentButton";
  const botaoGetnetRef = useRef<HTMLButtonElement>(null);

  const [isValidForm, setIsValidForm] = useState(false);
  const [isValidCep, setIsValidCep] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [numeroRua, setNumeroRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [pais, setPais] = useState("");
  const [pagamentoProcessado, setPagamentoProcessado] = useState(false);

  const handleSetIsOpen = useCallback(async () => {
    setIsOpen();
  }, [setIsOpen]);

  const handleClose = useCallback(async () => {
    setIsOpen();
  }, [setIsOpen]);

  const handleFormSubmit = useCallback(async () => {}, []);

  const handlePagarAgendamento = () => {
    salvarEnderecoCliente();
  };

  const salvarEnderecoCliente = () => {
    // if (pagamentoProcessado)
    //     return;

    setIsLoading(true);
    const _data = {
      agendamentoId: idAgendamento,
      cep: cep,
      uf: uf,
      nomeCidade: cidade,
      nomeBairro: bairro,
      logradouro: rua,
      numero: numeroRua,
    };

    fetch("AgendamentoData/SalvarEnderecoCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        agendamentoId: idAgendamento?.toString(),
      },
      body: JSON.stringify(_data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.agendamentoId) {
          console.info(data);
          setPagamentoProcessado(true);
          onHandler();
          loadGetnetIframe();
          return;
        }
        onError();
        setIsLoading(false);
        console.error("Erro no pagamento");
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const onChangeCep = (event: any) => {
    const cepDigitado = cepMask(event.target.value);
    const cepValido = validateCep(cepDigitado);
    setIsValidCep(cepValido);
    setCep(cepDigitado);
    validateForm({ cepValidacao: cepDigitado });
  };

  const onBlurCep = (event: any) => {
    const cepDigitado = cepMask(event.target.value);
    const cepValido = validateCep(cepDigitado);

    if (cepValido) {
      preencheEndereco(cepDigitado);
    }
  };

  const preencheEndereco = (cepDigitado: string) => {
    setIsLoading(true);

    fetch("EnderecoData/BuscarCep?cep=" + cepDigitado.replace("-", ""))
      .then((result) => result.json())
      .then((endereco) => {
        setUf(endereco.uf);
        setCidade(endereco.cidade);
        setBairro(endereco.bairro);
        setRua(endereco.rua);
        setPais("Brasil");
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeRua = (event: any) => {
    const ruaDigitado = event.target.value;
    setRua(event.target.value);
    validateForm({ ruaValidacao: ruaDigitado });
  };

  const onChangeNumeroRua = (event: any) => {
    const numeroRuaDigitado = event.target.value;
    setNumeroRua(event.target.value);
    validateForm({ numeroRuaValidacao: numeroRuaDigitado });
  };

  const onChangeBairro = (event: any) => {
    const bairroDigitado = event.target.value;
    setBairro(event.target.value);
    validateForm({ bairroValidacao: bairroDigitado });
  };

  const onChangeCidade = (event: any) => {
    const cidadeDigitado = event.target.value;
    setCidade(event.target.value);
    validateForm({ cidadeValidacao: cidadeDigitado });
  };

  const onChangeUf = (event: any) => {
    const ufDigitado = event.target.value;
    setUf(event.target.value);
    validateForm({ ufValidacao: ufDigitado });
  };

  const isEmptyString = (text?: string) => {
    let isEmptyString: boolean = true;

    isEmptyString = text === "";

    return isEmptyString;
  };

  const validateForm = ({
    cepValidacao,
    ruaValidacao,
    numeroRuaValidacao,
    bairroValidacao,
    cidadeValidacao,
    ufValidacao,
  }: {
    cepValidacao?: string;
    ruaValidacao?: string;
    numeroRuaValidacao?: string;
    bairroValidacao?: string;
    cidadeValidacao?: string;
    ufValidacao?: string;
  }) => {
    let validForm: boolean = true;

    cepValidacao =
      cepValidacao || isEmptyString(cepValidacao) ? cepValidacao : cep;
    ruaValidacao =
      ruaValidacao || isEmptyString(ruaValidacao) ? ruaValidacao : rua;
    numeroRuaValidacao =
      numeroRuaValidacao || isEmptyString(numeroRuaValidacao)
        ? numeroRuaValidacao
        : numeroRua;
    bairroValidacao =
      bairroValidacao || isEmptyString(bairroValidacao)
        ? bairroValidacao
        : bairro;
    cidadeValidacao =
      cidadeValidacao || isEmptyString(cidadeValidacao)
        ? cidadeValidacao
        : cidade;
    ufValidacao = ufValidacao || isEmptyString(ufValidacao) ? ufValidacao : uf;

    validForm =
      validForm &&
      (cepValidacao || "").length > 0 &&
      validateCep(cepValidacao || "");
    validForm = validForm && (ruaValidacao || "").length > 0;
    validForm = validForm && (numeroRuaValidacao || "").length > 0;
    validForm = validForm && (bairroValidacao || "").length > 0;
    validForm = validForm && (cidadeValidacao || "").length > 0;
    validForm = validForm && (ufValidacao || "").length > 0;

    setIsValidForm(validForm);
  };

  const handleAbandon = () => {
    Swal.fire({
      title: "Abandonar agendamento",
      html: `
                <strong style="color: orange">Ao abandonar o agendamento você perderá a reserva que fizemos de data/horário selecionados</strong>
                <br>
                <br>
                <p>Tem certeza que deseja abandonar o agendamento atual?</p>
            `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#0054A6",
      cancelButtonColor: "#ed1d24",
      confirmButtonText: "Continuar agendamento",
      cancelButtonText: "Abandonar",
      reverseButtons: true,
    }).then(async (result) => {
      if (!result.isConfirmed) {
        setIsLoading(true);

        const req = await schedulingService.abandonScheduling(idAgendamento);

        if (req) {
          Swal.fire({
            title: "Abandonar agendamento",
            html: `
                <p>Agendamento abandonado, data e horário reservados estão liberados, você pode fazer um novo agendamento caso queira.</p>
            `,
            icon: "info",
            confirmButtonColor: "#0054A6",
            confirmButtonText: "Entendi",
          }).then(() => {
            window.location.href = "/hubdesaude";
          });
        } else {
          setIsLoading(false);
        }
      }
    });
  };

  const loadGetnetIframe = () => {
    setIsLoading(true);

    fetch("getnet/gettoken?codigoLoja=" + appointmentToPay.codigoLoja)
      .then((response) => response.json())
      .then((token) => {
        if (token?.message) {
          setIsLoading(false);
          setErrorMsg(token.message);
          return;
        }
        let agendamento = {
          getnetToken: token,

          identificadorClienteGetnet:
            appointmentToPay.identificadorClienteGetnet,
          identificadorPedidoGetnet: appointmentToPay.identificadorPedidoGetnet,
          quantidadeMaximaParcelas: appointmentToPay.quantidadeMaximaParcelas,
          valorTotal: appointmentToPay.valorTotal,
          primeiroNomeCliente: appointmentToPay.primeiroNomeCliente,
          sobrenomeCliente: appointmentToPay.sobrenomeCliente,
          emailCliente: appointmentToPay.emailCliente,
          cpfCliente: appointmentToPay.cpfCliente,

          enderecoCliente: {
            cep: cep,
            rua: rua,
            numeroRua: numeroRua,
            bairro: bairro,
            cidade: cidade,
            uf: uf,
            pais: pais,
          },
        };

        LoadGetnetPayment(
          agendamento,
          paymentButtonClass,
          redirectToUrl,
          () => {
            if (botaoGetnetRef.current) {
              botaoGetnetRef.current.click();
              setTimeout(() => {
                setIsLoading(false);
              }, 3000);
            }
          },
          () => setIsLoading(false)
        );
      })
      .catch((error) => setErrorMsg(error));
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={handleSetIsOpen}
      overflow={overflow}
      Mtop="15%"
      overlay="overlay__modalEnderecoPagamento"
    >
      <HeaderModal>
        {/* <CloseModal onClick={handleClose}>
          <FiXCircle size={24} />
        </CloseModal> */}
        <h1>Informe o endereço de cobrança do cartão</h1>
      </HeaderModal>

      {isLoading && (
        <div className="row justifyCenter" style={{ paddingTop: "50px" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <Form
        onSubmit={handleFormSubmit}
        style={isLoading ? { visibility: "hidden" } : {}}
      >
        <div className="modalForm">
          <div className="InputContent">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <InputContainer className={isValidCep ? "" : "errorCep"}>
                <Input
                  name="cep"
                  label="CEP"
                  placeholder="00000-000"
                  value={cep}
                  onChange={onChangeCep}
                  onBlur={onBlurCep}
                  isRequired={true}
                />
                {!isValidCep && <SpanErroCep>CEP inválido</SpanErroCep>}
              </InputContainer>
              &nbsp;
              <InputContainer>
                <label htmlFor="uf">
                  Estado <span className="sc-cCcYRi heGHnt">*</span>
                </label>
                <select
                  id="uf"
                  value={uf}
                  onChange={onChangeUf}
                  className="form-control DropDown"
                  required={true}
                >
                  <option value="">Selecione seu estado</option>
                  <option key="AC" value="AC">
                    Acre
                  </option>
                  <option key="AL" value="AL">
                    Alagoas
                  </option>
                  <option key="AP" value="AP">
                    Amapá
                  </option>
                  <option key="AM" value="AM">
                    Amazonas
                  </option>
                  <option key="BA" value="BA">
                    Bahia
                  </option>
                  <option key="CE" value="CE">
                    Ceará
                  </option>
                  <option key="DF" value="DF">
                    Distrito Federal
                  </option>
                  <option key="ES" value="ES">
                    Espírito Santo
                  </option>
                  <option key="GO" value="GO">
                    Goiás
                  </option>
                  <option key="MA" value="MA">
                    Maranhão
                  </option>
                  <option key="MT" value="MT">
                    Mato Grosso
                  </option>
                  <option key="MS" value="MS">
                    Mato Grosso do Sul
                  </option>
                  <option key="MG" value="MG">
                    Minas Gerais
                  </option>
                  <option key="PA" value="PA">
                    Pará
                  </option>
                  <option key="PB" value="PB">
                    Paraíba
                  </option>
                  <option key="PR" value="PR">
                    Paraná
                  </option>
                  <option key="PE" value="PE">
                    Pernambuco
                  </option>
                  <option key="PI" value="PI">
                    Piauí
                  </option>
                  <option key="RJ" value="RJ">
                    Rio de Janeiro
                  </option>
                  <option key="RN" value="RN">
                    Rio Grande do Norte
                  </option>
                  <option key="RS" value="RS">
                    Rio Grande do Sul
                  </option>
                  <option key="RO" value="RO">
                    Rondônia
                  </option>
                  <option key="RR" value="RR">
                    Roraima
                  </option>
                  <option key="SC" value="SC">
                    Santa Catarina
                  </option>
                  <option key="SP" value="SP">
                    São Paulo
                  </option>
                  <option key="SE" value="SE">
                    Sergipe
                  </option>
                  <option key="TO" value="TO">
                    Tocantins
                  </option>
                </select>
              </InputContainer>
            </div>

            <InputContainer>
              <Input
                name="cidade"
                label="Cidade"
                value={cidade}
                onChange={onChangeCidade}
                isRequired={true}
                maxLength={50}
              />
            </InputContainer>

            <InputContainer>
              <Input
                name="bairro"
                label="Bairro"
                value={bairro}
                onChange={onChangeBairro}
                isRequired={true}
                maxLength={100}
              />
            </InputContainer>

            <InputContainer>
              <Input
                name="rua"
                label="Endereço"
                value={rua}
                onChange={onChangeRua}
                isRequired={true}
                maxLength={200}
              />
            </InputContainer>

            <InputContainer>
              <Input
                name="numeroRua"
                label="Número"
                value={numeroRua}
                onChange={onChangeNumeroRua}
                isRequired={true}
                maxLength={15}
              />
            </InputContainer>
          </div>
        </div>
      </Form>
      <FooterButtonsModal style={isLoading ? { display: "none" } : {}}>
        <button
          type="button"
          className={"success"}
          onClick={handlePagarAgendamento}
          disabled={!isValidForm || paymentTimeout}
        >
          <div className="text">Pagar serviço agendado</div>
        </button>
        <br />
        <button type="button" onClick={() => handleAbandon()}>
          <div className="text">Abandonar</div>
        </button>
        <button
          type="button"
          ref={botaoGetnetRef}
          className={paymentButtonClass}
          hidden
        ></button>
      </FooterButtonsModal>
    </Modal>
  );
};

export default ModalEnderecoPagamento;
