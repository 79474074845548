import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.12);
  z-index: 2;

  p {
    margin: 0;
    color: #666666;
    font-size: 12px;
  }

  strong {
    color: #0054A6;
    font-size: 14px;
  }

  .icon-select:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }

  .step-name-container {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
`;