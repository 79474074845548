import styled from "styled-components";

export const Container = styled.div`
  padding: 50px 32px;
  position: relative;

  .close {
    border: 0;
    background: transparent;
    position: absolute;
    top: 15px;
    right: 15px; 
  }

  .react-calendar__navigation button:not(:disabled):last-child {
    border: 1px solid #F2F2F2;
  }

  .react-calendar__navigation button:not(:disabled):first-child {
    border: 1px solid #F2F2F2;
  }

  .jtdKmf .react-calendar .react-calendar__navigation {
    border: 0;
  }
  
  .jtdKmf .react-calendar .react-calendar__navigation .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #0054A6;
    font-family: 'Roboto', sans-serif;
  }
  
  .react-calendar__navigation__label {
    margin-top: 20px !important;
  }

  @media (max-width: 1024px) {
    padding: 50px 20px;
    .calendarComponent {
      width: fit-content;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #383838;
    margin-top: 10px;
  }

  p {
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
  }
`;

export const ModalContent = styled.div`
  max-width: 438px;

  .row {
    /* width: 372px; */
    margin: 0 auto;

    label {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #828282;
      margin-bottom: 2px;  
    }
    
    > div {
      flex: 1;
    }

    > span {
      @media (max-width: 1024px) {
        padding: 0px 2px !important;
      }
    }
  }

  @media (max-width: 1024px) { 
    .row {
      width: 100%;

      span {
        padding: 0px 2px;      
      }
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonCancel = styled.button`
  width: 180px;
  height: 43px;
  background: #fff;
  border: 0;
  border-radius: 6px;
  color: #0054A6;
  border: 1px solid #0054A6;
  margin-top: 32px;
`;

export const ButtonGoForward = styled.button`
  width: 179px;
  height: 43px;
  background: #0054A6;
  border: 0;
  border-radius: 6px;
  color: #fff;
  margin-top: 32px;
  margin-left: 24px;

  @media (max-width: 1024px) {
    margin-left: 16px;
  }

  :disabled {
    color: #FFFFFF;
    background: #AAAAAA;
    cursor: not-allowed;
  }
`;