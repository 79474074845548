import styled from 'styled-components';

export const Div = styled.div`
    width: fit-content;
`;

export const Container = styled.div`
    width: 100%;
    height: 80px;
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
        span{
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.015em;
            color: #808080;
        }
`;
export const Imagem = styled.img`
    width: 77px;
    height: 64px;
    border-radius: 7px;
    object-fit: cover;
    margin-right: 12.16px;
    @media (max-width: 1024px){
        width: 69.32px ;
        height: 57.62;
    }
`;
export const Title = styled.h6`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #0054A6;
    margin-bottom: 0px;
`;
export const ContainImgTitle = styled.div`
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content:  flex-start;
    align-items: center;
`;
export const Link = styled.a`
    text-decoration: none;
    cursor: pointer;
    &:hover{
        text-decoration: none;
    }
    @media (max-width: 1024px){
        margin-top:16px;
    }
`;
export const LinkVerMais = styled.span`
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
    @media (max-width: 1024px){
        //margin-top:16px;
    }
`;