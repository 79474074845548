import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { siteKey } from '../../../../config/recaptcha';
import { Container, CloseImg, IconPrimary } from './styles';

interface Props {
  handleClose: () => void;
  Icon: string;
  title: string;
  subtitle?: string;
  description?: string;
  isRecaptcha?: boolean;
  noBenefits?: boolean;
  onChangeRecaptcha?: (token: string | null) => void;
}

const Modal: React.FC<Props> = ({ handleClose, Icon, title, subtitle, description, isRecaptcha, onChangeRecaptcha, noBenefits }) => {
  return (
    <Container subtitle={subtitle}>
      <CloseImg onClick={handleClose} />
      <IconPrimary src={Icon} />
      <h2>{title}</h2>
      {subtitle && <h4>{subtitle}</h4>}
      {description && <span>{description}</span>}
      {!isRecaptcha && <button onClick={handleClose}>{noBenefits ? 'Saiba mais' : 'Fechar'}</button>}
      {isRecaptcha && <ReCAPTCHA sitekey={siteKey} onChange={onChangeRecaptcha} />}
    </Container>
  );
}

export default Modal;