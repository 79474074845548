import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import Modal from 'react-modal';

interface GoldProps {
  gold?: boolean;
}

export const QueryContainer = styled.aside`
  width: calc(100% - 128px);
  height: 100px;
  margin: 25px 64px;
  box-shadow: -2px 7px 20px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  position: absolute;
  top: 375px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 64px;

  > nav {
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 1024px) {
      flex-direction: column;
      width: 100%;
      height: auto;
    }
  }

  @media(max-width: 1024px) {
    width: calc(100% - 32px); 
    height: auto;
    flex-direction: column;
    margin: 0 11px;
    padding: 45px 32px 34px;
  }
`;

export const TextContainer = styled.div<GoldProps>`
  line-height: 1;

  > h2 {
    font-size: 28px;
    color: #0078CB;
    margin: 0 0 6px;   
    @media(max-width: 1024px) {
      font-size: 24px;
      text-align: center;
      margin: 0 0 11px;
    }
    @media (max-width: 360px){
      width: 100%;
    }
  }

  @media (max-width: 360px){
    width: 100%;
  }

  > p {
    font-size: 14px;
    color: #030000;
    margin: 0;

    @media(max-width: 1024px) {
      text-align: center;
    }
  }

  @media(max-width: 1024px) {
    margin-bottom: ${({ gold }) => gold ? '21px' : '31px' };
  }
`;

export const FindContainer = styled.div`
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
 form{
   display: flex;
   flex-direction: row;
   @media (max-width:1024px){
    flex-direction: column;
   }
  > input {
    width: 300px;
    height: 100%;
    margin-right: 17px;
    border: 2px solid #E3E4E6;
    border-radius: 4px;
    padding: 10px 24px;
    color: #666;
    outline: none;

    @media(max-width: 1024px) {
      width: 100%;
      margin: 0 0 22px;
      height: 43px;
      padding: 10px 33px;
    }
  }

  > button {
    
    width: 143px;
    height: 43px;
    border: none;
    outline: none;
    background-color: #09B2AB;
    border-radius: 4px;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 1024px) {
      width: 100%;
      height: 43px;
    }
  }
 }
  @media(max-width: 1024px) {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
`;

export const NoBenefits = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > img {
    width: 55px;
    height: 50px;
    margin-right: 20px;

    @media(max-width: 1024px) {
      width: 56px;
      height: 56px;
      margin: 0 0 34px;
    }
  }

  @media(max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 360px){
    h2{
      white-space: nowrap;
      text-align: center;
    }
  }
`;

export const NoBenefitsButton = styled.a<GoldProps>`
  width: 243px;
  height: 100%;
  border: none;
  outline: none;
  background-color: #0054A6;
  border-radius: 6px;
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer;
  
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ gold }) => gold && goldStyles}

  @media(max-width: 1024px) {
    width: 100%;
    height: 43px;
  }
`;

const goldStyles = css`
  background-color: #FFB100;
  color: #000 !important;
  margin-right: 20px;

  @media(max-width: 1024px) {
    margin: 0 0 12px;
  }
`;

export const ReactModal = styled(Modal)` button{padding: 11PX;}`;
export const overlaySyles: CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden scroll',
  padding: '40px 0 130px',
  outline: 'none',
};


export const overlaySylesMobile: CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '0',
  position: 'static',
  outline: 'none',
};

export const overlaySylesModal: CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  overflow: 'hidden scroll',
  paddingTop: '275px',
  outline: 'none',
};

export const overlaySylesModalMobile: CSSProperties = {
  ...overlaySylesModal,
  width: '100vw',
  padding: '20px 14px 0',
};

export const contentStyles: CSSProperties = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const contentStylesMobile: CSSProperties = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0',
  marginRight: '4px',
  marginTop: '55px'
};
