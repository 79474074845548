import { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { AgendamentoContextData, AgendamentoContext } from '../../../contexts/agendamento';

import IconLoading from "../../../images/icons/Loading.svg";
import HandleCompleteStep from '../../CompleteStep';

class CardLoja extends Component<any, any> {
    static contextType = AgendamentoContext; 
    
    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            loadCount: 1,
            errorMessage: "",
            lojaList: null,
            isPartnerServices: false,

            uf: "",
            cidade: "",
            bairro: "",

            maxElements: 0,
            codigoLoja: 0,
            innerWidth: 0
        }

        this.setBairroList = this.setBairroList.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.resizeScreen = this.resizeScreen.bind(this);
    }

    componentDidMount() {
        this.setState({ innerWidth: window.innerWidth });
        window.addEventListener('resize', (event) => {
            this.resizeScreen()
        });
        let arrayServicos: any[] = [];

        const sServicos = localStorage.getItem('_ApmServicos');
        if (sServicos !== null)
            arrayServicos = JSON.parse(sServicos);

        if (arrayServicos.length === 0) {
            console.log('não selecionou serviços');
            this.setState({ uf: null });
        }

    }

    setBairroList(bairros: any[]) {
        this.props.onLoadBairros(bairros);
    }

    componentDidUpdate() {
        const lojaListStorage = localStorage.getItem('_pmLojaList');

        const numeroDeBairrosSelecionados = new Set(this.state.lojaList?.map((loja: any) => loja.bairro));

        if (lojaListStorage && numeroDeBairrosSelecionados.size <= 1) {
            this.setState({ lojaList: JSON.parse(lojaListStorage) });
            localStorage.removeItem('_pmLojaList');
        }
        if (//this.props.selectedUf !== this.state.uf || 
            this.props.selectedCidade !== this.state.cidade
            //    this.props.selectedBairro !== this.state.bairro
        ) {
            this.setState({ maxElements: (window.innerWidth < 1024) ? 4 : 12 });
            this.setState({ codigoLoja: 0 });

            this.setState({ uf: this.props.selectedUf });
            this.setState({ cidade: this.props.selectedCidade });
            this.setState({ bairro: this.props.selectedBairro });
            this.setState({ isPartnerServices: this.props.isPartnerServices });
            let arrayServicos: any[] = [];

            const sServicos = localStorage.getItem('_ApmServicos');
            if (sServicos !== null)
                arrayServicos = JSON.parse(sServicos);

            if (arrayServicos.length > 0) {
                const servicos = arrayServicos.map(function (elem) { return elem.id; }).join(",");

                const uf = this.props.selectedUf;
                const cidade = this.props.selectedCidade
                const bairro = this.props.selectedBairro;
                const partner = this.props.isPartnerServices;

                if (cidade === "") {
                    this.setState({ lojaList: null });
                    return;
                }

                this.loadLojas(servicos, uf, cidade, bairro, partner);
            }
        }
    }

    loadLojas(servicos: string, uf: string, cidade: string, bairro: string, partner: boolean) {
        console.log(`Buscando Lojas ${this.state.loadCount}x`);
        this.setState({ isLoading: true });
        this.setState({ lojaList: [] });
        localStorage.removeItem('_pmBairroList');

        const apiUrl = `lojaData/GetLojas?servicos=${servicos}&uf=${uf}&cidade=${cidade}&bairro=${bairro}&parceiro=${partner}`;
        fetch(apiUrl, { cache: "no-cache" })
            .then(response => response.json())
            .then(data => {

                if (data.result === "Error") {
                    if (data.code === "001" && this.state.loadCount < 3) {
                        this.setState({ loadCount: this.state.loadCount + 1, errorMessage: data.message });
                        this.loadLojas(servicos, uf, cidade, bairro, partner);
                    }
                } else {
                    this.setState({ lojaList: data.items, codigoLoja: this.props.selectedLoja ? this.props.selectedLoja : 0 });
                    if (!bairro)
                        localStorage.setItem('_pmLojaList', JSON.stringify(data.items));

                    let bairros: any[] = [];

                    data.items.map((item: any) => {
                        if (bairros.indexOf(item.bairro) === -1) bairros.push(item.bairro);
                    });

                    this.setBairroList(bairros);
                }

                this.setState({ isLoading: false });
            });
    }
    resizeScreen() {
        if (window.innerWidth < 1024)
            this.setState({ maxElements: 4 });

        this.setState({ innerWidth: window.innerWidth });
    }

    handleSelect(loja: any) {
        const agendamentoContext = this.context as AgendamentoContextData;

        const dataSelecionada = localStorage.getItem('_pmDataSelecionada');
        if (dataSelecionada) {
            localStorage.removeItem('_pmDataSelecionada');
            agendamentoContext.clearFromStoreForward();
        }
        
        this.setState({ codigoLoja: loja.codigo });

        const _loja = {
            codigo: loja.codigo,
            nome: loja.nome,
            endereco: loja.endereco,
            numero: loja.numero,
            bairro: loja.bairro,
            cep: loja.cep,
            cidade: loja.cidade,
            estado: loja.estado,
            telefone01: loja.telefone01,
            telefone02: loja.telefone02,
            partner: this.props.isPartnerServices
        };

        localStorage.setItem('_pmLoja', JSON.stringify(_loja));
        //gravar dados da loja selecionada

        agendamentoContext.handleUpdateStore(loja);
        HandleCompleteStep(false, "loja", agendamentoContext)
        this.props.history.push(this.props.urlContinue);
    }

    continuarBtn() {
        const agendamentoContext = this.context as AgendamentoContextData;
        agendamentoContext.handleCompleteStep('loja');

        this.props.nextStep();
    }

    verMais() {
        let qty = 12;
        if (this.state.innerWidth < 1024)
            qty = 4;

        this.setState({ maxElements: this.state.maxElements + qty });
    }

    render() {
        return (
            <>
                {this.state.isLoading && (
                    <div className="row justifyCenter">
                        <img src={IconLoading} alt="loading" className='spiner' style={
                            {
                                justifyContent: 'center',
                                alignItems: 'center',
                            }
                        } />
                    </div>
                )}
                {
                    !this.state.isLoading &&
                    this.state.lojaList?.filter((row: any) => { if (this.props.selectedBairro !== "") return row.bairro === this.props.selectedBairro; else return row; })
                        .slice(0, this.state.maxElements).map((item: any) => (
                            !item.temAgendaDisponivel ? (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-unavailable">
                                            Sem agenda disponível no momento para esta loja.
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        key={item.codigo}
                                        className={`col-xs-12 col-sm-12 col-md-3 divCardLoja ${
                                            this.state.codigoLoja === item.codigo ? "cardLojaActived" : ""
                                        } unavailable`}
                                    >
                                    <div className="divCardLojaBody">
                                        <p className="cardLojaTitle">
                                            {item.endereco + ", " + item.numero}
                                        </p>
                                        <p>
                                            {item.bairro + ", " + item.cidade + "/" + item.estado}
                                        </p>
                                        <p>
                                            {"Tel: " +
                                                item.telefone01 +
                                                (item.telefone02 ? "/" + item.telefone02 : "")}
                                        </p>
                                    </div>
                                    </div>
                                </OverlayTrigger>
                                ) : (
                                <div
                                    key={item.codigo}
                                    className={`col-xs-12 col-sm-12 col-md-3 divCardLoja ${
                                        this.state.codigoLoja === item.codigo ? "cardLojaActived" : ""
                                    }`}
                                    onClick={() => this.handleSelect(item)}
                                >
                                    <div className="divCardLojaBody">
                                        <p className="cardLojaTitle">
                                            {item.endereco + ", " + item.numero}
                                        </p>
                                        <p>
                                            {item.bairro + ", " + item.cidade + "/" + item.estado}
                                        </p>
                                        <p>
                                            {"Tel: " +
                                                item.telefone01 +
                                                (item.telefone02 ? "/" + item.telefone02 : "")}
                                        </p>
                                    </div>
                                </div>
                )))}
                {!this.state.isLoading && this.state.lojaList?.filter((row: any) => { if (this.props.selectedBairro !== "") return row.bairro === this.props.selectedBairro; else return row; }).length > this.state.maxElements &&
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <button className="LojaVerMaisBtn" onClick={() => this.verMais()} >Ver mais</button>
                    </div>
                }
                {!this.state.isLoading && this.state.loadCount === 3 && (!this.state.lojaList || this.state.lojaList.length <= 0) && (
                    <div className="row">
                        <div className="w-100">
                            <p>{this.state.errorMessage}</p>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default withRouter(CardLoja);