import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

import media from '../../../tokens/media';
import { colors } from '../../../tokens';

export const Container = styled.div`
  padding: 0 1rem 7rem;
  justify-content: center;
  width: 100%;

  @media(max-width: 1024px) {
    padding: 0 0 7rem;
  }

  @media(max-width: 576px) {
    padding: 0 1rem 7rem;
  }
`;

export const Divider = styled.hr`
  height: 1px;
  border-top: 1px solid #F2F2F2;
  width: 100%;
`;

export const ContainerInfo = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;    
    grid-gap: 1rem;
    align-items: flex-start;

    @media (max-width: 1024px) {
      grid-template-columns: unset;
      grid-gap: .5rem;
    }

  section {

    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 21px 17px;
      margin: -38px 0 0;
      border: 1px solid #F2F2F2;
      border-radius: 8px 8px 0 0;

      &:last-child {
        border-radius: 0 0 8px 8px;
        border-top: none;
        padding: 17px 17px 43px;
      }
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    position: relative;

    .title {
      @media(max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    
    img {
      margin-right: 8px;
    }

    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      color: #0054A6;
      margin: 0;

      @media(max-width: 1024px) {
        font-size: 16px;
      }
    }
  }

  .info-container-mobile {
    display: none;
    .info {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 14px;
      word-break: break-word;

      .item {
        min-width: 50%;

        > h4 {
          margin: 0;
          line-height: 1;
          margin-bottom: 4px;
          font-size: 12px;
        }
      }
    }

    @media(max-width: 1024px) {
      display: initial;
    }
  }  

  .hide-container {
    @media(max-width: 1024px) {
      display: none !important;
    }
  }

  .info-container {
    padding: 20px 22px;
    border: 1px solid #F2F2F2;
    border-radius: 4px;

    @media (max-width: 1024px) {
      display: flex;
      padding: 0;
    }

    .update-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: .5rem;

      a:last-child {
        svg {
          margin-left: 10px;
        }
      }
    }
  }

  .title-container ~ .info-container{
    display: grid;
    grid-gap: 1rem;

    .info-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1rem;
      align-items: start;
    }
    
  }
  
  .info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  .item {
    h4, span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #666666;
      margin-bottom: 8px;
    }

    span {
      font-weight: normal;
    }
  }

  .personal-data {
    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    .info-container {
      border: 1px solid #F2F2F2;
      flex-direction: column;
      border: none;
    }
  }

  .order-summary {
    width: 100%;

    @media (max-width: 1024px) {
      &.order-summary-watched {
        margin: 1rem 0;
      }

      .info-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .info-row {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }
      }
    }

    .title-container {
      border-bottom: 1px solid #C4C4C4;
      justify-content: space-between;
      padding-bottom: 5px;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 136%;
        color: #666666;
        display: flex;
        align-items: center;

        img {
          margin-right: 6px;
        }
      }
    }
    
    .info-row {
      display: flex;
      justify-content: space-between;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 100%;
        color: #383838;
        display: flex;
      }
    }

    .info-row-total {
      margin-top: 18px;

      span {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .services {
    @media (max-width: 1024px) {
      .info-container {
        .info-row {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .title {
      display: flex;
    }

    .title-container {
      justify-content: space-between;

      .time-container {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #014CAB;

        .time {
          z-index: 9;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 136%;
          color: #666666;
        }
      }
    }
    
    .info-container {
      padding: 0;
      border: 0;
    }

    .info-row {
      justify-content: space-between;
      margin-bottom: 25px;

      .address {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #0054A6;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          margin-right: 4px;
        }
      }

      .label {
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        color: #666;
        margin: 16px 0 4px;

        display: none;

        @media(max-width: 1024px) {
          display: block;
        }

        &:first-child {
          margin: 6px 0 4px;
        }
      }

      .complement {
        display: grid;
        grid-template-columns: 0 1fr;
        grid-gap: 1.7rem;
        align-items: baseline;
        color: #0054A6;

        .provider {
          label:first-child {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            font-size: 16px;
            color: #0054A6;
            margin: 0;

            @media(max-width: 1024px) {
              display: none;
            }
          }

          label:last-child {
            color: #666666;
            margin: 0;
            font-size: 15px;
            text-transform: uppercase;
            @media(max-width: 1024px) {
              color: #0054A6;
            }
          }

          :last-child {
            display: flex;
            flex-direction: column;
          }
        }

        @media(max-width: 1024px) {
          grid-gap: 1.5em;
        }
      }

      .date {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #666666;
      }
    }

    .time-container {
      @media(max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #014CAB;
        font-weight: 500;
        font-size: 12px;

        > img {
          margin-right: 6px;
        }

        > span {
          font-size: 14px;
          color: #666;
          font-weight: 400;
          margin-left: 11px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }

    .service {
      display: flex;
      flex-direction: column;
      border: 1px solid #F2F2F2;
      border-radius: 4px;
      padding: 16px;

      @media(max-width: 1024px) {
        margin-bottom: 8px;
      }

      .info-short {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      svg {
        margin-left: 4px;
      }

      & + .service {
        margin-top: 8px;
      }

      h4 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #0054A6;

        @media(max-width: 1024px) {
          font-size: 12px;
        }
      }

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #666666;

        @media(max-width: 1024px) {
          font-size: 12px;
        }
      }

      button {
        border: 0;
        background: transparent;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: #0054A6;

        @media(max-width: 1024px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .details {
        padding: 16px;
        display: grid;
        grid-gap: 1rem;

        @media(max-width: 580px) {
          grid-gap: 0;
        }
        
        .row-services {
          display: flex;
          flex-wrap: wrap;
          word-wrap: break-word;
          display: grid;

          grid-template-columns: 4fr 1fr;
          grid-column-gap: 1.5rem;
          align-items: start;

          :first-child {
            grid-template-columns: 2fr 2fr 1fr;
          }

          :last-child {
            grid-template-columns: 1fr;
          }

          @media(max-width: 1024px) {
            margin-top: 0 !important;
          }

          @media(max-width: 580px) {
            grid-template-columns: unset;
          }

          > div {
            @media(max-width: 1024px) {
              margin-bottom: 16px;
            }
          }

          h4 {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #666666;

            @media(max-width: 1024px) {
              font-size: 12px;
            }
          }

          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #666666;
          }

          & + .row {
            margin-top: 16px;
          }
        }
      }
    }
  }


  .wrapper-ballon {
    position: absolute;
    /*z-index: -1;*/
    right: 0;
    margin-right: -10px;


    .modal-info-ballon{
      z-index: 1;
      position: relative;
      background: rgba(0, 0, 0, 0.8);
      border-radius: .4em;
      width: 150px;
      height: 95px;
      font-size: 12px;
      color: #fff;
      margin-top: 134px;
      box-sizing: border-box;
      padding: 10px;
      padding-top: 5px;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 91%;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.8);
        border-top: 0;
        margin-left: -8px;
        margin-top: -8px;
        }
    }
  }

  
`;

export const Form = styled(Unform)`
  > div {
    .updateData {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-right: 30px;
        margin-top: 14px;

        div {
          margin-top: 22px;
          margin-bottom: 22px;

          svg {
            margin-right: 5px;
          }

          a {
            text-decoration: none;
            color: #7b8fa5;
          }
        }

        ${media.md} {
          margin-bottom: 0;
          align-items: flex-end;
        }
    }
  }

  .InputContent {
    > div {
      width: 100%;
    }
  }

  .atencion {
    text-align: center;
    margin-top: 1rem;
  }

  ${media.md} {
    display: flex;

    .InputContent {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
      margin-right: 30px;
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  min-width: 262px;

  div{
    height: 36px;
  }
  
  &:disabled {
    background: #DDDDDD;
    color: #BDBDBD;
  }

  img {
    position: absolute;
    right: 12px;
    bottom: 15px;
  }
`;

export const Checkbox = styled.div`
  margin: 23px 0 35px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;

  label {
    margin-left: 11px;
  }

  ${media.md} {
    margin: 0;
  }
`;

export const Label = styled.label`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #828282;
`;

export const Appointment = styled.div`
  background: #FFFFFF;
  border: 1px solid #0054A6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 17px;
  display: flex;
  flex-direction: column;
  min-width: 312px;

  ${media.xs} {
    margin-bottom: 80px;
   }

  h2 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #0054A6;
    margin-bottom: 22px;
  }

  h3 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #515151;
    margin: 0;
  }
`;

export const IconText = styled.span`
  display: flex;

  img {
    margin-right: 5px;
    margin-bottom: 12px;
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #515151;
    margin: 0;
  }
`;

export const AddressText = styled.span`
  p {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
`;

export const AppointmentTime = styled.span`
  position: relative;
  color: #014CAB;
  margin: 15px 0;
  display: flex;

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    margin-left: 5px;
    display: flex;

    img {
      cursor: pointer;
    }

    p {
      margin: 0 5px 0 10px;
      color: #666;
    }
  }

  svg {
    color: #014CAB;
  }
`;

export const Line = styled.hr`
  border: 1px solid #C4C4C4;
  width: 100%;
  margin: 5px 0 18px;
`;

export const OrderItems = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: #383838;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

    span {
        font-size: 14px;
        line-height: 136%;
        color: #666666;
        img {
            cursor: pointer;
            margin-top: -4px;
        }
    }
`;

export const Amount = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #515151;
`;

export const Button = styled.button`
  background: #09B2AB;
  border-radius: 6px;
  border: none;
  height: 43px;
  width: 100%;
  margin: 25px 0;

  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  ${media.xs} {
    margin: 0;
  }
`;

export const ButtonEditData = styled.a`
  width: 100%;
  height: 43px;
  cursor: pointer;

  background: #5b72d6;
  border: 1px solid ${colors.blue};
  box-sizing: border-box;
  border-radius: 6px;

  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${colors.white};

  &:hover {
    color: ${colors.white};
    text-decoration: none;
  }
`;

export const SpanErroCPF = styled.span`
    font-size: 11px;
    line-height: 20px;
    color: #CC1117;
    
    position: absolute;
    margin-top: 58px;
    margin-left: 10px;
`;

export const Required = styled.span`
  color: #D14A4A;
  font-weight: bold;
`;
export const DivAlerta = styled.div`
  color: #D14A4A;
  margin-top: 1rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  & > div {
    padding: 10px;
    margin: 0px auto 15px;
    text-align: center;
    max-width: 460px;
    border: 1px solid #D14A4A;
    margin-bottom: 15px;

    & > div {
      & ol {
        text-align: left!important;
        margin-bottom: 0;
      }
    }
  }
`;

export const ButtonVoltar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 43px;
  color: #0054A6;
  border: 1px solid #0054A6;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: -15px;

  ${media.xs} {
    margin-top: .75rem;
  }
`;

export const ButtonFloat = styled.button<{ inverted: boolean }>`
  width: 164px;
  height: 43px;
  border: none;
  background-color: ${(props) => (props.inverted ? '#09B2AB' : '#AAAAAA')};
  border-radius: 6px;
  padding: 8px 0;
  margin-left: 11px;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  transition: 0.1s ease;
  cursor: pointer;  
`;

export const BackButtonFloat = styled.button`
  width: 164px;
  height: 43px;
  background-color: #FFF !important;
  border-radius: 6px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  color: #0054A6;
  transition: 0.1s ease;
  cursor: pointer;
  border: 1px solid #0054A6;
`;