import { Component } from 'react';
import '../style.css'

class Bairro extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            bairroList: [],

            uf: "",
            cidade: "",
            bairro: ""
        }

        this.handleSelect = this.handleSelect.bind(this)
    }

    componentDidMount() {

    }

    componentDidUpdate() {
        if (this.state.bairroList !== this.props.bairroList && this.props.bairroList !== null) {
            var sort = this.props.bairroList.sort(function (a: any, b: any) {
                if (a < b) {
                    return -1;
                }
                if (b < a) {
                    return 1;
                }
                return 0;
            });

            this.setState({ bairroList: sort });
        }
    }

    handleSelect(event: any) {
        this.props.onChange(event);
    }

    render() {
        return (
            <>

                <div className="DropDownBairro">
                    <label className="labelDropDown">Bairro</label>
                    <select onChange={this.handleSelect} className="form-control DropDown" disabled={this.state.bairroList?.length === 0} >
                        <option value="">Bairro</option>
                        {

                            this.state.bairroList && (
                                this.state.bairroList.map((item: any) => (

                                    <option key={item} selected={this.props.selectedBairro?.toLowerCase() === item.toLowerCase()}>{item}</option>
                                )))
                        }
                    </select>
                </div>

            </>
        );
    }
}
export default Bairro;