import styled, { css } from 'styled-components';
import { Form as Unform } from '@unform/web';
import media from '../../../tokens/media';

interface IconProps {
  type?: 'success' | 'info';
}

const iconTypeVariations = {
  info: css`
    color: #FFBE02;
  `,

  success: css`
    color: #09B2AB;
  `,
};

export const CloseModal = styled.button`
  border: none;
  position: absolute;
  background-color: #FFFFFF;
  right: 4px;
  top: 5px;

  svg {
    color: #4D5567;
  }
`;



export const Form = styled(Unform)<IconProps>`
  padding: 27px 20px 14px 20px;
  display: flex;
  flex-direction: column;

  svg {
    width: 44px;
    height: 44px;
    ${props => iconTypeVariations[props.type || 'success']}
    align-self: center;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  ${media.lg} {
    width: 340px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid #C4C4C4;
  padding-bottom: 12px;
  margin-bottom: 12px;

  h1 {
    color: #0054A6;
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    color: #515151;
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-bottom: 16px;
  border-bottom: 1px solid #C4C4C4;
  margin-bottom: 20px;

  h2 {
    color: #515151;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    overflow-wrap: anywhere;
    color: #666666;
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export const Result = styled.div`
  p {
    font-size: 14px;
    color: #014CAB;
    font-weight: 500;
    margin-bottom: 22px;

    span {
      color: #666666;
      font-weight: 400;
    }
  }

  .value {
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 30px;
    }

    strong {
      font-size: 14px;
      color: #014CAB;
    }
  }

  .resultado {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      line-height: 136%;
      color: #666666;
      padding-top: 23px;
    }
  }

  .aPartirDe {

  }

  button {
    width: 100%;
    height: 43px;
    background: #09B2AB;
    border: 1px solid #09B2AB;
    border-radius: 6px;
    color: #FFFFFF;
    padding: 10px 0;
  }
`;

export const ButtonDefault = styled.button`
  width: 100%;
  height: 43px;
  border: none;
  background-color: #0054A6;
  border-radius: 6px;
  padding-top: 9px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;

  &.servico-add {
    background: #208637;
    border-color: #259c40;
  }

  span {
    img {
      display: inline-block;
      margin-right: 10px;
    }
  }
`;
