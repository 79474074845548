export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export function clearDataAfter15min() {
    setTimeout(function () {
        localStorage.removeItem('_subCategorias');
    }, 900000);
}
export const capitalize = (info: string) =>
    info?.charAt(0).toUpperCase() + info?.slice(1);