import http from "../http-common";

// import ISubCategoria from "../types/SubCategoria";
import ICategoria from "../types/Categoria";

const getAllCategoriaServico = () => {
    return http.get<Array<ICategoria>>(`/servicoData/GetAllCategoriaServico`);
};

const ServicoService = {
    getAllCategoriaServico
};

export default ServicoService;