import "./firstLogin.scss";
import { useRef, useState } from "react";
import { useRipple } from "react-use-ripple";
import { toast } from "react-toastify";
import { validateEmail } from "../../utils/utils";
import { loginService } from "../../services/loginService";
import logo from "../../images/logoBrc.png";
import banner from "../../images/banner-login.png";
import Swal from "sweetalert2";
import { FiArrowLeft } from "react-icons/fi";
import BarLoader from "react-bar-loader";
import { useHistory } from "react-router-dom";

function FirstLogin(props: any) {
  let history = useHistory();
  const btnRef: any = useRef();
  useRipple(btnRef);

  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const [loginByCode, setLoginByCode] = useState(false);
  const [loginByCodeEmailSent, setLoginByCodeEmailSent] = useState(false);

  const [fields, setFields] = useState({
    userEmail: "",
    userPassword: "",
    userNewPassword: "",
    userPasswordConfirmation: "",
    loginCode: "",
  });

  const handleFieldValue = (e: any) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleForgotPassword = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (fields.userEmail.trim() === "") {
      toast.error("Verifique o e-mail informado!");
      setLoading(false);
      return false;
    }

    if (!validateEmail(fields.userEmail)) {
      toast.error("Informe um e-mail válido!");
      setLoading(false);
      return false;
    }

    const req: any = await loginService.requestCode(fields);

    if (req.result) {
      switch (req.result) {
        case "Error": {
          toast.error(req.message);
          setLoading(false);
          setEmailSent(false);

          break;
        }

        case "Success": {
          toast.info(req.message);

          setLoading(false);
          setEmailSent(true);
          setForgotPassword(true);

          break;
        }
      }
    } else {
      toast.error(req.message);
      setLoading(false);
      setEmailSent(false);
    }
  };

  const handleSetNewPassword = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (fields.loginCode.trim() === "") {
      toast.error("Informe o código recebido no seu e-mail!");
      setLoading(false);
      return false;
    }

    if (
      fields.userNewPassword.trim() === "" ||
      fields.userPasswordConfirmation.trim() === ""
    ) {
      toast.error("Verifique as senhas informadas!");
      setLoading(false);
      return false;
    }

    if (
      fields.userNewPassword.trim() !== fields.userPasswordConfirmation.trim()
    ) {
      toast.error("As senhas informadas não são iguais!");
      setLoading(false);
      return false;
    }

    if (!validateEmail(fields.userEmail)) {
      toast.error("Informe um e-mail válido!");
      setLoading(false);
      return false;
    }

    const req: any = await loginService.resetPassword(fields);

    if (req.result) {
      switch (req.result) {
        case "Error": {
          toast.error(req.message);
          setLoading(false);

          break;
        }

        case "Success": {
          toast.success(req.message);
          setFields({
            userEmail: "",
            userPassword: "",
            userNewPassword: "",
            userPasswordConfirmation: "",
            loginCode: "",
          });
          setLoading(false);
          setEmailSent(false);
          setForgotPassword(false);

          break;
        }
      }
    } else {
      toast.error(req.message);
      setLoading(false);
    }
  };

  const getLinkRedirect = (): string | null => {
    let urlCallBack = history.location.search;
    if (urlCallBack)
      return urlCallBack.substring(
        urlCallBack.indexOf("=") + 1,
        urlCallBack.length
      );
    return null;
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (fields.userEmail.trim() === "" || fields.userPassword.trim() === "") {
      toast.error("E-mail ou senha não informados!");
      setLoading(false);
      return false;
    }

    if (!validateEmail(fields.userEmail)) {
      toast.error("Informe um e-mail válido!");
      setLoading(false);
      return false;
    }

    const loginData: any = await loginService.performLogin(fields);

    if (loginData.result) {
      switch (loginData.result) {
        case "Error": {
          toast.error(loginData.message);
          setLoading(false);
          setEmailSent(false);
          setForgotPassword(false);

          break;
        }

        case "Success": {
          toast.success(loginData.message);

          localStorage.setItem("_pmVtexclientemail", loginData.userEmail);

          setLoading(false);
          setEmailSent(false);
          setForgotPassword(false);

          Swal.fire({
            title: "Primeiro Login",
            text: "Primeiro login efetuado com sucesso!\nProceda agora para o login definitivo.",
            icon: "success",
            confirmButtonColor: "#0054A6",
            confirmButtonText: "Entendi",
          }).then(() => {
            let linkTemp = getLinkRedirect();
            if (linkTemp) {
              linkTemp = linkTemp
                .replaceAll("%2F", "/")
                .replaceAll("%3A", ":")
                .replaceAll("%23", "#");

              window.location.href = linkTemp;
              return;
            }

            history.push("/hubdesaude/exame");
          });

          break;
        }
      }
    } else {
      toast.error(loginData.message);
      setLoading(false);
      setEmailSent(false);
      setForgotPassword(false);
    }
  };

  const openLink = (url: string) => window.open(url, "_blank")?.focus();

  const handleSubmitButtonText = () => {
    if (!forgotPassword && !emailSent && !loginByCode && !loginByCodeEmailSent)
      return "Continuar";

    if (forgotPassword || emailSent) return "Redefinir";

    if (loginByCode && !loginByCodeEmailSent) return "Enviar código";

    if (loginByCode && loginByCodeEmailSent) return "Continuar com código";
  };

  return (
    <div className="firstLogin">
      <div className="firstLogin__banner">
        <img src={banner} alt="bannerFundo" />
      </div>

      <div className="firstLogin__modal">
        <div className="firstLogin__modal__window">
          <div className="firstLogin__modal__window__header">
            <img style={{ width: "65%", height: "auto" }} src={logo} alt="" />
          </div>

          <div className="firstLogin__modal__window__content">
            <>
              {!forgotPassword &&
                !emailSent &&
                !loginByCode &&
                !loginByCodeEmailSent && <h5>Fazer login</h5>}
              {(forgotPassword || emailSent) && <h5>Redefinir senha</h5>}
              {(loginByCode || loginByCodeEmailSent) && (
                <h5>Receber código de acesso por e-mail</h5>
              )}

              {!emailSent && !loginByCodeEmailSent && (
                <div className="firstLogin__modal__window__content__field">
                  <label htmlFor="email">E-mail</label>
                  <input
                    autoComplete="off"
                    onChange={handleFieldValue}
                    defaultValue={fields.userEmail}
                    name="userEmail"
                    id="email"
                    type="email"
                    placeholder="Ex: exemplo@gmail.com"
                  />
                </div>
              )}

              {((emailSent && forgotPassword) ||
                (loginByCodeEmailSent && loginByCode)) && (
                <>
                  <div className="firstLogin__modal__window__content__field">
                    <label htmlFor="code">Código recebido no e-mail</label>
                    <input
                      autoComplete="off"
                      onChange={handleFieldValue}
                      defaultValue={fields.loginCode}
                      name="loginCode"
                      id="code"
                      type="tel"
                      placeholder="Digite o código que enviamos no seu e-mail"
                      maxLength={6}
                    />
                  </div>
                </>
              )}

              {emailSent && forgotPassword && (
                <>
                  <div className="firstLogin__modal__window__content__field">
                    <label htmlFor="newPass">Nova senha</label>
                    <input
                      autoComplete="off"
                      onChange={handleFieldValue}
                      defaultValue={fields.userNewPassword}
                      name="userNewPassword"
                      id="newPass"
                      type="password"
                      placeholder="Digite a nova senha"
                    />
                  </div>

                  <div className="firstLogin__modal__window__content__field">
                    <label htmlFor="confirmNewPass">Confirmação de senha</label>
                    <input
                      autoComplete="off"
                      onChange={handleFieldValue}
                      defaultValue={fields.userPasswordConfirmation}
                      name="userPasswordConfirmation"
                      id="confirmNewPass"
                      type="password"
                      placeholder="Confirme a nova senha"
                    />
                  </div>
                </>
              )}

              {(forgotPassword ||
                emailSent ||
                loginByCode ||
                loginByCodeEmailSent) && (
                <button
                  onClick={() => {
                    setForgotPassword(false);
                    setEmailSent(false);
                    setLoginByCode(false);
                    setLoginByCodeEmailSent(false);
                    setFields({
                      userEmail: "",
                      userPassword: "",
                      userNewPassword: "",
                      userPasswordConfirmation: "",
                      loginCode: "",
                    });
                  }}
                  ref={btnRef}
                  className="backBtn"
                >
                  <FiArrowLeft />
                  &nbsp;Voltar
                </button>
              )}

              {!forgotPassword &&
                !emailSent &&
                !loginByCode &&
                !loginByCodeEmailSent && (
                  <>
                    <div className="firstLogin__modal__window__content__field">
                      <label htmlFor="password">Senha</label>
                      <input
                        autoComplete="off"
                        onChange={handleFieldValue}
                        defaultValue={fields.userPassword}
                        name="userPassword"
                        id="password"
                        type="password"
                        placeholder="Digite sua senha"
                      />
                    </div>

                    <span
                      onClick={() => {
                        setForgotPassword(true);
                      }}
                      className="forgotPass"
                    >
                      Esqueceu sua senha?
                    </span>
                  </>
                )}
            </>
          </div>

          <div className="firstLogin__modal__window__footer">
            <button
              disabled={loading}
              ref={btnRef}
              className="primaryBtn"
              onClick={(e) => {
                if (
                  !forgotPassword &&
                  !emailSent &&
                  !loginByCode &&
                  !loginByCodeEmailSent
                )
                  handleLogin(e);

                if (forgotPassword && !emailSent) handleForgotPassword(e);

                if (forgotPassword && emailSent) handleSetNewPassword(e);
              }}
            >
              {handleSubmitButtonText()}
            </button>

            <span
              onClick={() => {
                openLink("https://www.paguemenos.com.br/login");
              }}
              style={{ marginTop: "20px" }}
              className="signUp"
            >
              Não tem uma conta? Cadastre-se
            </span>
          </div>

          {loading && (
            <BarLoader
              style={{ width: "100%", backgroundColor: "white" }}
              color="#0054a5"
              height={8}
            />
          )}
          {!loading && (
            <span
              style={{ width: "100%", height: "8px", backgroundColor: "white" }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FirstLogin;
