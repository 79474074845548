import { useAgendamento, Step } from '../../../../contexts/agendamento';

import * as S from './styles';

import iconSelect from '../../../../images/icons/icon-select.svg';

interface MobileMenuProps {
  step: number;
  onClick: () => void;
}

const stepNameMap = {
  1: 'Exame',
  2: 'Loja',
  3: 'Agenda',
  4: 'Paciente',
}

export function MobileMenu({ step, onClick }: MobileMenuProps) {
  const { getStepIcon } = useAgendamento();

  function getStepName(currentStep: number): Step {
    // @ts-ignore
    return stepNameMap[currentStep] as Step
  }

  const stepName = getStepName(step);
  
  return (
    <S.Container>
      <div className="info">
        <div>
          <p>Passo {step} de 4</p>
          <div className="step-name-container">
            {getStepIcon(stepName)} <strong>{stepName}</strong>
          </div>
        </div>
      </div>

      <div className="icon-select" onClick={onClick}>
        <img src={iconSelect} alt="select" />
      </div>
    </S.Container>
  )
}