import styled from 'styled-components';
import { FiXCircle } from 'react-icons/fi';

interface IContainer {
  subtitle?: string;
}

export const Container = styled.div<IContainer>`
  width: 500px;
  height: 299px;
  line-height: 1;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 63px 32px;

  > h2 {
    font-size: 24px;
    color: #383838;
    margin: 10px 0 11px;
    text-align: center;
    white-space: nowrap;
  }

  > h4 {
    margin: 0 0 8px;
    color: #0054A6;
    font-weight: 500;
    font-size: 14px;
  }

  > span {
    text-align: center;
    font-size: 14px;
    color: #666;
    line-height: 20px;
    margin-top: 5px;

    padding: ${({ subtitle }) => subtitle ? '0 60px' : '0'};

    @media(max-width: 1024px) {
      padding: ${({ subtitle }) => subtitle ? '0 10px' : '0'};
    }
  }

  > button {
    width: 180px;
    height: 43px;
    background: #0054A6;
    border-radius: 4px;
    border: none;
    outline: none;
    color: #fff;
    margin-top: 34px;

    @media(max-width: 1024px) {
      margin-top: 32px;
    }
  }

  @media(max-width: 1024px) {
    width: 100%;
    padding: 50px 24px;
  }
`;

export const CloseImg = styled(FiXCircle)`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const IconPrimary = styled.img`
  width: 44px;
  height: 44px;
`;
