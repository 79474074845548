import { Component } from "react";
import { withRouter } from "react-router-dom";
import DropDownUf from "../components/Loja/DropDownUf/";
import DropDownCidade from "../components/Loja/DropDownCidade/";
import DropDownBairro from "../components/Loja/DropDownBairro/";
import Card from "../components/Loja/Card/";
import IconLoading from "../images/icons/Loading.svg";
import {
  AgendamentoContext,
  AgendamentoContextData,
} from "../contexts/agendamento";
import { Navigation } from "../components/Navigation";
import HeaderTitle from "../components/HeaderTitle";

interface StoreData {
  codigo: number;
  endereco: string;
  numero: number;
  bairro: string;
  cidade: string;
  estado: string;
  telefone01: string;
  telefone02: string;
}

interface DateTime {
  data: string;
  horario: string;
  horarioId: number;
}

const urlContinue = "/hubdesaude/agenda";

class Loja extends Component<any, any> {
  static contextType = AgendamentoContext;

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      ufNotFound: false,
      bairroList: null,
      uf: null,
      cidade: "",
      bairro: "",
      codigoLoja: 0,

      agendamentoAssistido: false,
      isPartnerServices: false,
    };

    this.ufNotFound = this.ufNotFound.bind(this);
    this.onLoadBairros = this.onLoadBairros.bind(this);
    this.onSelectUf = this.onSelectUf.bind(this);
    this.onSelectCidade = this.onSelectCidade.bind(this);
    this.onSelectBairro = this.onSelectBairro.bind(this);
    this.returnStep = this.returnStep.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.vefifyButtonContinue = this.vefifyButtonContinue.bind(this);
    this.partnerServices = this.partnerServices.bind(this);
  }

  vefifyButtonContinue(): boolean {
    const agendamentoContext = this.context as AgendamentoContextData;
    var storeCode = agendamentoContext.store.codigo;

    return !!storeCode;
  }

  componentDidMount() {
    const agendamentoContext = this.context as AgendamentoContextData;
    agendamentoContext.handleUpdateCurrentStep("loja");

    this.setState({ isLoading: true });
    //GUARDAR STEP
    let pathname = this.props.location.pathname;
    let path = pathname.substring(pathname.lastIndexOf("/"));
    localStorage.setItem("_pmLastStep", path);
    //GUARDAR STEP

    //LIMPAR DADOS DAS ETAPAS FUTURAS
    // localStorage.removeItem("_pmDataSelecionada");

    const sServicos = localStorage.getItem("_ApmServicos");
    let arrayServicos: any[] = [];
    if (sServicos !== null) arrayServicos = JSON.parse(sServicos);

    const servicos = arrayServicos
      .map(function (elem) {
        return elem.id;
      })
      .join(",");
    if (servicos === "") {
      this.props.history.push("/hubdesaude/subCategoria");
      return;
    }

    const _agendamentoAssistido = localStorage.getItem(
      "_pmAgendamentoAssistido"
    );
    if (_agendamentoAssistido !== null) {
      this.setState({ agendamentoAssistido: true });
      window.CustomScrollToTopAssistido();
    } else window.CustomScrollToTop();

    const lojaFiltroStorage = localStorage.getItem("_pmLojaFiltro");
    if (lojaFiltroStorage) {
      const lojaFiltroStorageJson = JSON.parse(lojaFiltroStorage);
      this.setState({
        uf: lojaFiltroStorageJson.estado || null,
        cidade: lojaFiltroStorageJson.cidade || "",
        bairro: lojaFiltroStorageJson.bairro || "",
      });
    }

    const lojaStorage = localStorage.getItem("_pmLoja");
    if (lojaStorage) {
      const lojaStorageJson = JSON.parse(lojaStorage);
      this.setState({
        codigoLoja: lojaStorageJson.codigo,
      });
    }

    this.setState({ isLoading: false });
  }

  ufNotFound() {
    this.setState({ ufNotFound: true });
  }

  partnerServices(value: boolean) {
    this.setState({ isPartnerServices: value });
  }

  onSelectUf(event: any) {
    const agendamentoContext = this.context as AgendamentoContextData;

    agendamentoContext.handleUpdateDateTime({} as DateTime);
    agendamentoContext.handleUpdateStore({} as StoreData);
    agendamentoContext.clearFromStoreForward();
    this.setState({ codigoLoja: 0 });

    localStorage.removeItem("_pmLoja");
    localStorage.removeItem("_pmDataSelecionada");
    localStorage.removeItem("_pmBairroList");
    localStorage.setItem(
      "_pmLojaFiltro",
      JSON.stringify({ estado: event.target.value })
    );

    this.setState({ uf: event.target.value });
    this.setState({ cidade: "" });
    this.setState({ bairro: "" });

    this.onLoadBairros([]);
  }

  onSelectCidade(event: any) {
    const agendamentoContext = this.context as AgendamentoContextData;

    agendamentoContext.handleUpdateDateTime({} as DateTime);
    agendamentoContext.handleUpdateStore({} as StoreData);
    agendamentoContext.clearFromStoreForward();
    this.setState({ codigoLoja: 0 });

    localStorage.removeItem("_pmLoja");
    localStorage.removeItem("_pmDataSelecionada");
    localStorage.removeItem("_pmBairroList");
    const lojaStorage = JSON.parse(
      localStorage.getItem("_pmLojaFiltro") as string
    );
    localStorage.setItem(
      "_pmLojaFiltro",
      JSON.stringify({ estado: lojaStorage.estado, cidade: event.target.value })
    );

    this.setState({ cidade: event.target.value });
    this.setState({ bairro: "" });

    this.onLoadBairros([]);
  }

  onSelectBairro(event: any) {
    const agendamentoContext = this.context as AgendamentoContextData;

    agendamentoContext.handleUpdateDateTime({} as DateTime);
    agendamentoContext.handleUpdateStore({} as StoreData);
    agendamentoContext.clearFromStoreForward();

    localStorage.removeItem("_pmLoja");
    localStorage.removeItem("_pmDataSelecionada");
    const lojaStorage = JSON.parse(
      localStorage.getItem("_pmLojaFiltro") as string
    );
    localStorage.setItem(
      "_pmLojaFiltro",
      JSON.stringify({
        ...lojaStorage,
        estado: lojaStorage.estado,
        cidade: lojaStorage.cidade,
        bairro: event.target.value,
      })
    );

    this.setState({ codigoLoja: undefined });
    this.setState({ bairro: event.target.value });
  }

  returnStep() {
    this.props.history.push("/hubdesaude/subCategoria");
  }

  nextStep() {
    this.props.history.push(urlContinue);
  }

  onLoadBairros(bairros: any[]) {
    const bairroListStorage = JSON.parse(
      localStorage.getItem("_pmBairroList") as string
    );

    if (bairroListStorage && bairroListStorage.length) {
      this.setState({ bairroList: bairroListStorage });
      return;
    }

    this.setState({ bairroList: bairros });
    localStorage.setItem("_pmBairroList", JSON.stringify(bairros));
  }

  render() {
    return (
      <>
        <HeaderTitle
          title="Compre e agende serviços e vacinas online"
          boxRedTitle="Clinic Farma"
          helperText={`Escolha uma loja para realizar o(s) serviço(s) selecionado(s):`}
        />

        <Navigation
          title=""
          urlBack="/hubdesaude/subCategoria"
          urlContinue={urlContinue}
          stepName="loja"
          isDisabled={!this.vefifyButtonContinue()}
        />
        {this.state.isLoading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={IconLoading}
              alt="loading"
              className="spiner"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            />
          </div>
        ) : (
          <>
            <div className="divCenterLoja row">
              <div className="col-xs-12 col-sm-6 col-md-6 divDropDownUF">
                <DropDownUf
                  onChange={this.onSelectUf}
                  selectedUf={this.state.uf}
                  ufNotFound={this.ufNotFound}
                  returnStep={this.returnStep}
                  partnerServices={this.partnerServices}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 divDropDownCidade">
                <DropDownCidade
                  onChange={this.onSelectCidade}
                  selectedUf={this.state.uf}
                  selectedCidade={this.state.cidade}
                  isPartnerServices={this.state.isPartnerServices}
                />
              </div>
              <div className="col-md-12 divDropDownBairro">
                <DropDownBairro
                  onChange={this.onSelectBairro}
                  bairroList={this.state.bairroList}
                  selectedBairro={this.state.bairro}
                />
              </div>
            </div>

            <div className="divCenterLojas">
              <Card
                nextStep={this.nextStep}
                selectedLoja={this.state.codigoLoja}
                selectedUf={this.state.uf}
                selectedCidade={this.state.cidade}
                selectedBairro={this.state.bairro}
                onLoadBairros={this.onLoadBairros}
                isPartnerServices={this.state.isPartnerServices}
                urlContinue={urlContinue}
              />

              {this.state.ufNotFound === true && (
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <div className="col-lg-5 col-sm-10 lojaNaoEncontrada">
                    <div className="msgLojaNaoEncontrada">
                      <h5>Atenção</h5>
                      <p>
                        Não achamos nenhuma loja que atenda esses serviços
                        simultâneos.
                      </p>
                      <p> Por favor realize os agendamentos separadamente.</p>
                    </div>
                    <div>
                      {/* <button
                        className="voltarBtn"
                        onClick={() => this.returnStep()}
                      >
                        Voltar aos serviços
                      </button> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}
export default withRouter(Loja);
