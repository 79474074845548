import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 16px 16px;
  box-shadow: 0px 0px 4px rgba(71, 71, 71, 0.1);
  color: #666666;

  & + .cardAppointment {
    margin-top: 16px;
  }

  strong {
    color: #0054A6;
    font-size: 18px;
  }

  .inline-info {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    a {
      color: #0054A6;
    }

    div {
      svg {
        margin-left: 4px;
      }
    }

    .address {
      a {
        text-decoration: underline;
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  .details {
    section {
      border-top: 1px solid #F2F2F2;
      margin: 0 -16px;
      padding: 25px 16px;
    }

    p{
      font-size: 14px;
    }
    .details-title {
      font-size: 18px;
      font-weight: 500;
      color: #0054A6;
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }

    .info-row {
      margin-bottom: 10px;

      .complement {
        display: grid;
        grid-template-columns: 0 1fr;
        grid-gap: 1.7rem;
        align-items: baseline;
        color: #0054A6;

        .provider {
          label:first-child {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            font-size: 16px;
            color: #0054A6;
            margin: 0;
          }

          label:last-child {
            color: #666666;
            margin: 0;
            font-size: 15px;
            text-transform: uppercase;
          }

          :last-child {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .row {
      padding: 16px;
      display: flex;
      
      strong {
        color: #666666;
        font-size: 14px;
      }

      .details-email {
        p {
          word-break: break-all;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .inline-info {
      justify-content: initial;
      .status{
        display: flex;
        align-items: center;
        width: 188px;
        img {
          margin-right: .2rem;
        }
      }
      .address{
        width: 365px;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
      .data{
        width: 235px;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
      .servicos{
        width: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
      .see-more {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        a {
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 100% !important;
    padding: 16px;
    box-shadow: 0px 0px 10px rgba(71, 71, 71, 0.25);

    .details {
        .details-title {
                font-size: 16px;
        }
      section {
        .row {
          span {
            width: 50% !important;
          }
        }
      }
        .row {
            strong {        
                font-size: 12px;
            }
        }
    }

    strong {
      color: #0054A6;
      font-size: 14px;
    }

    .status {
      img {
        margin-right: .2rem;
      }
      &::before {
        content: 'Status';
        display: block;
        font-weight: 500;
        color: #666666;
      }
    }

    .address {
      &::before {
        content: 'Local';
        display: block;
        font-weight: 500;
        color: #666666;
      }
    }

    .data {
      margin-top: 16px;

      &::before {
        content: 'Data e horário';
        display: block;
        font-weight: 500;
        color: #666666;
      }
    }

    .servicos {
      &::before {
        content: 'Nº de serviços';
        display: block;
        font-weight: 500;
        color: #666666;
      }
    }

    .seeMore {
      width: 100%;
      border-top: 1px solid #F2F2F2;
      padding-top: 16px;
      text-align: center;
    }

    .inline-info {
      flex-direction: column;

      .row {
        display: flex;
        align-items: center;
        p {
          margin: 0;
          margin-top: 16px;
          padding: 0 16px;
        }
      }
    }

    .details-email {
      order: 1;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    button {
      max-width: 210px;
      width: 100%;
      margin: 0;

      &:first-child {
        margin-top: 7px;
      }
    }
  }
`;

export const ButtonCancelAppointment = styled.button`
  width: 198px;
  height: 43px;
  background: #fff;
  border: 0;
  border-radius: 6px;
  color: #0054A6;
  border: 1px solid #0054A6;
  margin-top: 32px;

  @media (max-width: 1024px) {
    order: 1;
  }
`;

export const ButtonReschedule = styled.button`
  width: 198px;
  height: 43px;
  background: #0054A6;
  border: 0;
  border-radius: 6px;
  color: #fff;
  margin-top: 32px;
  margin-left: 24px;
`;

export const ButtonPay = styled.button`
  width: 198px;
  height: 43px;
  background: #0054A6;
  border: 0;
  border-radius: 6px;
  color: #fff;
  margin-top: 32px;
  margin-left: 24px;
`;