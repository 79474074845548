import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  color: #666666;

  table {
    width: 100%;
    display: block;
  }

  thead {
    display: block;
    font-size: 14px;

    @media (max-width: 1024px) { 
      display: none;
    }
  }

  tbody {
    display: block;
    overflow-y: scroll;
    border-top: 1px solid #E3E4E6;
    overflow-x: hidden;
    font-size: 14px;

    .mobile-title {
      display: none;

      @media (max-width: 1024px) {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #383838;
        margin: 16px 9px;
      }
    }

    .no-content {
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      img {
        width: 86px;
        height: 75px;
        margin-bottom: 15px;
      }

      strong {
        margin-bottom: 12px;
      }

      button {
        max-width: 240px;
        width: 100%;
      }
    }

    tr {
      @media (max-width: 1024px) { 
        display: flex;
        flex-direction: column;
        border-top: 1px solid #c4c4c4;
        margin: 0 8px 24px;

        .loja:before {
          content: 'Loja';
          display: block;
          color: #383838;
          font-weight: 500;
        }

        .dataHorario:before {
          max-width: 70px;
          content: 'Data e horário';
          display: block;
          color: #383838;
          font-weight: 500;
        }
      }
    }
  }

  th {
    padding: 16px 24px;
    width: 365px;
  }

  td {
    padding: 16px 24px 0 24px;
    width: 365px;
  }

  tr:last-child {
    td {
      padding-bottom: 16px;
    }
  }
`;
