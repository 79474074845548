import {Card,Imagem, Title, Description,ContainTitle,Before,ContainLink, ContainWrapped} from './styles';
interface CardSempreBemProps {
    img: string;
    title: string;
    description: string;
    linkRedirect: string;
}

export default function CardSempreBem({img,title,description,linkRedirect} : CardSempreBemProps) {
    return (
        <ContainWrapped className="col-xs-12 col-sm-6 col-md-3 pr-2 pl-2">
            <ContainLink href={linkRedirect} target="_blank">
                <Card>
                    <Imagem src={img}></Imagem>
                    <ContainTitle>
                        <Before/>
                        <Title>{title}</Title>
                    </ContainTitle>
                    <Description>{description.substring(0,70)}{description.length >70 &&("...")}</Description>
                </Card>
            </ContainLink>
        </ContainWrapped>
      );
    }