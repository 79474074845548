import { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { AgendamentoProvider } from './contexts/agendamento';

//import Banner from './components/Banner';
import { DropDownMenu } from './components/DropDownMenu';

import Session from './components/Session';
import UserSession from './components/UserSession';
import Exame from './pages/Exame';
import Home from './pages/Home';
import Loja from './pages/Loja';
import Agenda from './pages/Agenda';
import Paciente from './pages/Paciente';
import Usuario from './pages/Usuario';
import BackEnd from './pages/BackEnd';
import AdicionarPaciente from './pages/AdicionarPaciente';
import PagamentoConfirmado from './pages/PagamentoConfirmado';
import { Agendamentos } from './pages/Agendamentos';
import { Beneficios } from './pages/Beneficios';
import { HistoricoBeneficios } from './pages/HistoricoBeneficios';
import Modal from "react-modal";
//import Category from './components/Category';
import PageCategory from './pages/PageCategory';
import SubCategory from './components/Category/SubCategory';
import HeaderTitle from './components/HeaderTitle';

import ModalPreRequisitos from './components/ModalPreRequisitos';
import FirstLogin from "./pages/FirstLogin/FirstLogin";
declare global {
    interface Window {
        CustomScrollToTop(): void;
        CustomScrollToTopAssistido(): void;

        createDivWorkArround(): void;
        removeDivWorkArround(): void;
    }

}

Modal.setAppElement("#root");

class App extends Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            agendamentoAssistido: false,
            listCategorias: [],
            listImgs: [],
            hideCategory: false,
        }
    }

    render() {
        return (
            <Router>
                <AgendamentoProvider>
                    <Session />
                    <UserSession />
                    <ToastContainer />
                    <div className="grid-container" style={{ paddingTop: window.location.pathname !== '/hubdesaude/home' ? '50px' : '0' }} >
                        {window.location.pathname !== '/hubdesaude/home' &&
                            (<div id="stepsSidebar" className="item1">
                                <DropDownMenu />
                            </div>)}
                        <div className="div100">
                            <Switch>
                                <Route path="/hubdesaude/home" exact>
                                    <Home />
                                </Route>
                                <Route exact path="/hubdesaude/exame">
                                    <PageCategory />
                                </Route>
                                <Route exact path="/hubdesaude/subCategoria">
                                    <SubCategory />
                                </Route>
                                <Route path="/hubdesaude/loja">
                                    <Loja />
                                </Route>
                                <Route path="/hubdesaude/agenda">
                                    <Agenda />
                                </Route>
                                <Route path="/hubdesaude/paciente">
                                    <Paciente />
                                </Route>
                                <Route path="/hubdesaude/usuario">
                                    <Usuario />
                                </Route>
                                <Route path="/hubdesaude/backend">
                                    <BackEnd />
                                </Route>
                                <Route path="/hubdesaude/pagamentoconfirmado">
                                    <PagamentoConfirmado />
                                </Route>
                                <Route path="/hubdesaude/agendamentos" exact>
                                    <Agendamentos />
                                </Route>
                                <Route path="/hubdesaude/beneficios" exact>
                                    <Beneficios />
                                </Route>
                                <Route path="/hubdesaude/beneficios/historico" exact>
                                    <HistoricoBeneficios />
                                </Route>
                                <Route path="/hubdesaude/adicionar-paciente" exact>
                                    <AdicionarPaciente />
                                </Route>
                                <Route path="/hubdesaude/firstLogin" exact>
                                    <FirstLogin />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </AgendamentoProvider>
            </Router>
        );
    }
}
export default App;