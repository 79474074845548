import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

  h2 {
    color: #0054A6;
    font-weight: 500;
    align-self: flex-start;
  }

  strong {
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #515151;
  }

  .patrocinadores {
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      color: #515151;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #666666;
      margin-left: 24px;
    }
  }

    @media (max-width: 1024px) {
      .patrocinadores {
            h2 {
              font-size: 16px;
            }
        }
      }

  .button-bottom {
    display: none;
  }

  @media (max-width: 1024px) {

      h1 {
        padding-bottom: 38px;
        margin-bottom: 0px;
      }
      strong {
        font-size: 16px;
      }

    .button-bottom {
      display: block;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .go-back {
    display: none;
    margin-bottom: 20px;

    img {
      margin-right: 9px;
    }
    
    a {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #0054A6;
    }
  }
  
  button {
    max-width: 332px;
    width: 100%;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: start;
    
    .go-back {
      display: block;
    }
    
    > div {
      flex-direction: column;
      align-items: center;
    }

    button {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 100%;

  #grid-container {
    margin-top: 28px;
  }
`;