import Modal from "react-modal";
import { Container } from "./styles";
import attetionImg from "../../images/icons/attention.svg";
import iconSelectImg from "../../images/icons/icon-select.svg";
import iconCloseimg from "../../images/icons/icon-close.svg";
import iconArrowUp from "../../images/icons/icon-arrow-up.svg";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AgendamentoContext } from "../../contexts/agendamento";

interface ModalPreRequistiosProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

interface Details {
  id: number;
  nome: string;
  preRequisito: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ModalPreRequisitos({
  isOpen,
  onRequestClose,
}: ModalPreRequistiosProps) {
  const [termsConfirmed, setTermsConfirmed] = useState(false);
  const [isOpenDropdownTerms1, setIsOpenDropdownTerms1] = useState(false);
  const [details, setDetails] = useState<Details[]>();
  const [idSubResquestSelected, setIdSubResquestSelected] = useState(0);
  const query = useQuery();
  const servicesSelected = localStorage.getItem("_ApmServicos");

  let history = useHistory();
  const agendamentoContext = useContext(AgendamentoContext);

  const serviceCardShowcase = localStorage.getItem("_ApmServicosVitrine");

  useEffect(() => {
    let dataFormat;
    let cardShowcaseFormat;

    if (serviceCardShowcase) {
      cardShowcaseFormat = JSON.parse(serviceCardShowcase);

      const detailsWithCardShowcase: Details = {
        id: cardShowcaseFormat.id,
        nome: cardShowcaseFormat.nome,
        preRequisito: cardShowcaseFormat.preRequisito,
      };

      setDetails([detailsWithCardShowcase]);
    }

    if (servicesSelected) {
      dataFormat = JSON.parse(servicesSelected);

      const dataFiltered = dataFormat.filter(function (row: any) {
        return row.preRequisito !== "";
      });
      setDetails(dataFiltered);
    }
  }, [servicesSelected, serviceCardShowcase]);

  function openResquest(id: any) {
    if (idSubResquestSelected != id) {
      setIdSubResquestSelected(id);
    } else {
      setIdSubResquestSelected(0);
    }
    //console.log(id)
    //setIsOpenDropdownTerms1(!isOpenDropdownTerms1);
  }

  function handleCancel() {
    onRequestClose();
    const search = query.get("csub");
    let cat = "";

    console.log(search);

    if (!search || search === "") {
      localStorage.removeItem("_ApmServicos");
      agendamentoContext.clearFromExamForward();
      history.push("/hubdesaude/exame");
    } else {
      cat = search.split("|")[0];
    }

    if (cat && parseInt(cat) === 1) {
      console.log("Limpando itens...");
      localStorage.removeItem("_ApmServicos");
      agendamentoContext.clearFromExamForward();
      history.replace(`/hubdesaude/subCategoria?csub=${search}`);
      window.location.reload();
    }

    return;
  }

  function nextStep() {
    if (termsConfirmed) {
      agendamentoContext.handleCompleteStep("exame");
      history.push("/hubdesaude/loja");
    }
  }

  return (
    <>
      {window.innerWidth > 1025 ? (
        <Modal
          shouldCloseOnOverlayClick={false}
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          className="modal-content"
          //overlayClassName
          style={{
            overlay: {
              backgroundColor: "#231f2066",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            },
            content: {
              position: "static",
              width: "502px",
              minWidth: "502px",
              minHeight: "500px",
              maxHeight: "562px",
              border: "1px solid #979899",
              background: "#fff",
              overflow: "auto",
              //WebkitOverflowScrolling: 'touch',
              borderRadius: "4px",
              outline: "none",
              padding: "31px",
              marginTop: "5%",
            },
          }}
        >
          <Container>
            <button
              className="close-modal"
              onClick={() => {
                handleCancel();
              }}
            >
              <img src={iconCloseimg} alt="fechar" />
            </button>
            <img src={attetionImg} className="icon-attention" alt="Atenção" />

            <h2>Serviço com pré requisito</h2>
            <p>
              O(s) serviço(s) a seguir contém pré-requisitos, leia e confirme
              antes de prosseguir.
            </p>

            <div className="dropdown-list">
              {details?.map((item, key) => (
                <>
                  <div
                    key={item.id}
                    className={
                      isOpenDropdownTerms1 ? "terms-selected" : "dropdown-item"
                    }
                    onClick={() => openResquest(item.id)}
                  >
                    <strong>{item?.nome}</strong>
                    <img
                      src={isOpenDropdownTerms1 ? iconArrowUp : iconSelectImg}
                      alt="teste"
                    />
                  </div>
                  {idSubResquestSelected === item.id && (
                    <div className="dropdown-terms">{item?.preRequisito}</div>
                  )}
                </>
              ))}
            </div>

            <div className="reading-terms">
              <input
                type="checkbox"
                onClick={() => setTermsConfirmed(!termsConfirmed)}
              />
              <span>
                Li e estou de acordo com os termos do{" "}
                <a
                  href={process.env.PUBLIC_URL + "/documents/regulamento.pdf"}
                  target="_blank"
                >
                  Serviço do Portal Pague Menos.
                </a>{" "}
              </span>
            </div>

            <div className="buttons">
              <button
                className="cancel button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancelar
              </button>
              <button
                onClick={nextStep}
                className={
                  termsConfirmed ? "button confirmed" : "button confirm"
                }
              >
                Confirmar
              </button>
            </div>
          </Container>
        </Modal>
      ) : (
        <Modal
          shouldCloseOnOverlayClick={false}
          isOpen={isOpen}
          onRequestClose={() => {
            handleCancel();
          }}
          className="modal-content"
          //overlayClassName
          style={{
            overlay: {
              backgroundColor: "#231f2066",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            content: {
              overflowY: "auto",
              overflowX: "hidden",
              position: "absolute",
              //width: '340px',
              maxWidth: "330px",
              minWidth: "340px",
              minHeight: "440px",
              maxHeight: "650px",
              // height: '95%',
              border: "1px solid #979899",
              background: "#fff",
              paddingTop: "5%",
              //overflow: 'auto',
              //WebkitOverflowScrolling: 'touch',
              borderRadius: "4px",
              outline: "none",
            },
          }}
        >
          <Container>
            <button
              className="close-modal"
              onClick={() => {
                handleCancel();
              }}
            >
              <img src={iconCloseimg} alt="fechar" />
            </button>
            <img src={attetionImg} className="icon-attention" alt="Atenção" />

            <h2>Serviço com pré requisito</h2>
            <p>
              O(s) serviço(s) a seguir contém pré-requisitos, leia e confirme
              antes de prosseguir.
            </p>

            <div className="dropdown-list">
              {details?.map((item, key) => (
                <>
                  <div
                    key={item.id}
                    className={
                      isOpenDropdownTerms1 ? "terms-selected" : "dropdown-item"
                    }
                    onClick={() => openResquest(item.id)}
                  >
                    <strong>{item?.nome}</strong>
                    <img
                      src={isOpenDropdownTerms1 ? iconArrowUp : iconSelectImg}
                      alt="teste"
                    />
                  </div>
                  {idSubResquestSelected === item.id && (
                    <div className="dropdown-terms">{item?.preRequisito}</div>
                  )}
                </>
              ))}
            </div>

            <div className="reading-terms">
              <input
                type="checkbox"
                onClick={() => setTermsConfirmed(!termsConfirmed)}
              />
              <span>
                Li e estou de acordo com os termos do{" "}
                <a
                  href={process.env.PUBLIC_URL + "/documents/regulamento.pdf"}
                  target="_blank"
                >
                  Serviço do Portal Pague Menos.
                </a>{" "}
              </span>
            </div>

            <div className="buttons">
              <button
                className="cancel button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancelar
              </button>
              <button
                onClick={nextStep}
                className={
                  termsConfirmed ? "button confirmed" : "button confirm"
                }
              >
                Confirmar
              </button>
            </div>
          </Container>
        </Modal>
      )}
    </>
  );
}
