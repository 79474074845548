export type Step = "exame" | "loja" | "agenda" | "paciente";

const handleCompleteStep = (
  isDisabled: boolean,
  stepName: Step,
  context: any
) => {
  if (!isDisabled) {
    window.CustomScrollToTop();
    context.handleCompleteStep(stepName);
  }
};
export default handleCompleteStep;
