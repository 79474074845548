import styled from 'styled-components';

export const Container = styled.div`
    box-sizing: border-box;
    min-width: 292px;
    min-height: 221px;
    max-width: 292px;
    max-height: 221px;
    background: #FFFFFF;
    box-shadow: 1px 1px 10px rgba(0, 24, 48, 0.22);
    border-radius: 6px;
    padding: 24px;
    margin: auto;
    cursor: pointer;

    transition: all 1s;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        /* width: 130px !important;
        height: 45px !important;
        border: 1px solid red; */
        -moz-background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    div.line-gray {
        display: none;
    }

    strong {
        display: none;
    }

    /* &:hover {
        flex-direction: column;
        justify-content: space-around;
     
        img {
            margin-right: auto;
            margin-top: -15px;
        }
        
        div.line-gray {
            display: block;
            height: 1px;
            width: 172px;
            border: 1px solid #C4C4C4;
            margin-right: auto;
            margin-top: -20px;
        }

        strong {
            display: block;
            font-size: 14px;
            font-weight: 700;
        }
    } */
`;