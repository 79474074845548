import { Component } from "react";
import { withRouter } from "react-router";
import {
  AgendamentoContextData,
  AgendamentoContext,
} from "../../../contexts/agendamento";

import Button from "../Button";
import ModalInfo from "../ModalInfo";

import { Container, Title, Type, Time, LinkMore, Imagem } from "./styles";

import IconValue from "../../../images/icons/icon-time-exame.svg";
import ModalPreRequisitos from "../../ModalPreRequisitos";

class Card extends Component<any, any> {
  static contextType = AgendamentoContext;

  constructor(props: any) {
    super(props);

    this.state = {
      servicoAdicionado: false,
      saibaMaisIsOpen: false,
      preReqOpen: false,
    };

    this.onSelectCard = this.onSelectCard.bind(this);
    this.setIdSaibaMais = this.setIdSaibaMais.bind(this);
  }

  componentDidMount() {
    //verificar se tem serviços
    let arrayServicos: any[] = [];
    let sServicos = localStorage.getItem("_ApmServicos");

    if (sServicos !== null) arrayServicos = JSON.parse(sServicos);
    let exists =
      arrayServicos.filter((row: any) => row.id === this.props.servico.id)
        .length > 0;

    if (exists) {
      this.setState({ servicoAdicionado: true });
      if (arrayServicos.length > 0) this.props.setNextButtonActive(true);
      else this.props.setNextButtonActive(false);
    }

    const agendamentoContext = this.context as AgendamentoContextData;
    const search = this.props.location.search;

    if (!search || search === null || search === undefined || search === "") {
      localStorage.clear();
      agendamentoContext.clearFromExamForward();
      this.props.history.push("/exame");
    }
  }

  onSelectCard() {
    const agendamentoContext = this.context as AgendamentoContextData;
    const search = this.props.location.search;
    let cat = null;

    if (!search || search === null || search === undefined || search === "") {
      localStorage.removeItem("_ApmServicos");
      agendamentoContext.clearFromExamForward();
      this.props.history.push("/exame");
    } else {
      cat = search.split("=")[1].split("|")[0];
    }

    const lojaStorage = localStorage.getItem("_pmLojaFiltro");

    if (lojaStorage) {
      localStorage.removeItem("_pmDataSelecionada");
      localStorage.removeItem("_pmBairroList");
      localStorage.removeItem("_pmLoja");
      localStorage.removeItem("_pmLojaFiltro");
      localStorage.removeItem("_pmLojaList");
      agendamentoContext.clearFromExamForward();
    }

    let arrayServicos: any[] = [];
    let sServicos = localStorage.getItem("_ApmServicos");

    if (sServicos !== null) arrayServicos = JSON.parse(sServicos);

    let exists =
      arrayServicos.filter((row: any) => row.id === this.props.servico.id)
        .length > 0;

    if (this.state.servicoAdicionado) {
      this.setState({ servicoAdicionado: false });

      if (exists)
        arrayServicos = arrayServicos.filter(
          (row: any) => row.id !== this.props.servico.id
        );
    } else {
      this.setState({ servicoAdicionado: true });
      this.props.handleButtonToContinue();
      if (!exists) {
        arrayServicos.push({
          id: this.props.servico.id,
          nome: this.props.servico.nomeAbreviado,
          imagem: this.props.servico.urlImagem,
          preco: this.props.servico.preco,
          tempo: parseInt(this.props.servico.horaServico),
          tempoBase: parseInt(this.props.servico.tempoBase),
          preRequisito: this.props.servico.preRequisito,
        });
      }

      const preReq = this.props.servico.preRequisito.replace(/ /g, "");

      if (cat && parseInt(cat) !== 2) {
        if (preReq.length) {
          this.setState({
            ...this.state,
            preReqOpen: true,
          });
        } else {
          agendamentoContext.handleCompleteStep("exame");
          window.CustomScrollToTop();
          this.props.history.push("/hubdesaude/loja");
        }
      }
    }

    if (arrayServicos.length > 0) {
      this.props.handleButtonToContinue(true);
      this.props.setNextButtonActive(true);
    } else {
      this.props.setNextButtonActive(false);
      this.props.handleButtonToContinue(false);
    }
    localStorage.setItem("_ApmServicos", JSON.stringify(arrayServicos));

    agendamentoContext.handleUpdateQuantidadeServicos(arrayServicos.length);
    agendamentoContext.handleUpdateExames(arrayServicos);
  }

  setIdSaibaMais(id: number) {
    this.setState({ saibaMaisIsOpen: !this.state.saibaMaisIsOpen });

    if (this.state.saibaMaisIsOpen) {
      window.CustomScrollToTop();
      window.removeDivWorkArround();
    } else {
      window.createDivWorkArround();
    }

    this.props.setIdSaibaMais(id);
  }

  handleButton() {
    this.props.handleButtonToContinue();
  }

  render() {
    return (
      <>
        <Container>
          <Imagem src={this.props.servico.urlImagem}></Imagem>
          <div id="Subcontainer">
            <Title>{this.props.servico.nomeAbreviado}</Title>
            <Type>
              {this.props.servico.subtitulo}
              &nbsp;
            </Type>

            <Time>
              <p>
                <img src={IconValue} alt="Clock-Time" /> Tempo de exame:{" "}
                <span>{this.props.servico.horaExecucao} min</span>
              </p>
            </Time>
            {/*
                    <AsFrom>A partir de</AsFrom>
                    <Value>
                        R$  {this.props.servico.preco}
                    </Value>
                    */}
            <ModalInfo
              isOpen={this.state.saibaMaisIsOpen}
              buttonAction={this.onSelectCard}
              setIsOpen={() => {
                this.setState({ saibaMaisIsOpen: false });
                this.setIdSaibaMais(0);
              }}
              servico={this.props.servico}
              onSelectCard={this.onSelectCard}
              servicoAdicionado={this.state.servicoAdicionado}
            />

            <Button
              onSelectCard={this.onSelectCard}
              servicoAdicionado={this.state.servicoAdicionado}
              handleButtonToContinue={this.props.handleButtonToContinue}
            />

            <LinkMore
              onClick={() => {
                this.setIdSaibaMais(this.props.servico.id);
              }}
            >
              Saiba mais
            </LinkMore>
          </div>
        </Container>

        {this.state.preReqOpen && (
          <ModalPreRequisitos
            isOpen={this.state.preReqOpen}
            onRequestClose={() =>
              this.setState({
                ...this.state,
                preReqOpen: !this.state.preReqOpen,
              })
            }
            // toContinue={nextStep}
          />
        )}
      </>
    );
  }
}

export default withRouter(Card);
