import { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { isAfter } from "date-fns";

import { minutes } from "../../config/session";
import { AgendamentoContext, AgendamentoContextData } from "../../contexts/agendamento";

class Session extends Component<any, any> {
  static contextType = AgendamentoContext;
  unlisten: any;

  constructor(props: any) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen(() => {
      this.isSessionValid();
      
      localStorage.setItem(
        "_pmSession",
        JSON.stringify({
          createdAt: this.newUTCDate(),
          minutes,
        })
      );
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  addMinutes(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes * 60000);
  }

  newUTCDate() {
    const date = new Date();
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  isSessionValid() {
    const sessionStorage = JSON.parse(localStorage.getItem("_pmSession") as string);

    if (!sessionStorage || isAfter(this.newUTCDate(), this.addMinutes(new Date(sessionStorage.createdAt), minutes))) {
      const agendamentoContext = this.context as AgendamentoContextData;

      let agendamentoAssistido = localStorage.getItem('_pmAgendamentoAssistido');
      let codigoColaborador = localStorage.getItem('_pmCodigoColaborador');
      let nomeColaborador = localStorage.getItem('_pmNomeColaborador');
      let cargoColaborador = localStorage.getItem('_pmCargoColaborador');

      localStorage.clear();
      agendamentoContext.clearContext();

      if (agendamentoAssistido)
        localStorage.setItem('_pmAgendamentoAssistido', "true");

      if (codigoColaborador)
        localStorage.setItem('_pmCodigoColaborador', codigoColaborador);

      if (nomeColaborador)
        localStorage.setItem('_pmNomeColaborador', nomeColaborador);

      if (cargoColaborador)
        localStorage.setItem('_pmCargoColaborador', cargoColaborador);

      this.setState({ redirect: true }, () => {
        this.props.history.push("/hubdesaude/exame");
      });
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to='/hubdesaude/exame'/>;
    }

    return <></>;
  }
}
export default withRouter(Session);
