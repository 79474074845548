
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 0px 4px rgba(71, 71, 71, 0.1);

  & + .cardService {
    margin-top: 16px;
  }

  strong {
    color: #0054A6;
    font-size: 14px;
  }

  .inline-info-service {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    a {
      cursor: pointer;
      color: #0054A6;
    }

    div {
      svg {
        margin-left: 4px;
      }
    }

    .address {
      text-decoration: underline;

      svg {
        margin-right: 4px;
      }
    }

    @media (max-width: 1024px) {
      flex-direction: row;
        p {
            font-size: 12px;
        }
    }
  }

    @media (max-width: 1024px) {
        strong {
            font-size: 12px !important;
        }
    }

  .details {
    section {
      border-top: 1px solid #F2F2F2;
      margin: 0 -16px;
      padding: 25px 16px;
    }

    .details-title {
      font-size: 18px;
      font-weight: 500;
      color: #0054A6;
    }

    .row {
      padding: 16px;
      display: flex;
      
      strong {
        color: #666666;
        font-size: 14px;
      }

      .times-container {
        display: flex;
      }
      
      @media (max-width: 1024px) {
        flex-direction: column;

        .times-container {
          p {
            margin: 0;
          }
        }
      }
    }
  }
`;