import styled from 'styled-components';
import ReactSlick from "react-slick";
import IconArrowBannerPrev from '../../images/icons/arrow-banner-prev.svg';
import IconArrowBannerNext from '../../images/icons/arrow-banner-next.svg';

export const Slider = styled(ReactSlick)`
  padding-left: 11px;


  .slick-prev,
  .slick-next {
    z-index: 1;
    top: calc(50% - 25px);
    margin: 0;
    width: 30px;
    height: 30px;
    transform: initial;
    background-repeat: no-repeat;
    background-size: 6px;
    background-position: center;
    background-color: white;
    margin-left: 50px;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    @media(max-width: 1024px) {
      margin: 0 !important;
      width: 30px;
      height: 30px;
      top: calc(50% - 15px);
    }

    &::before {
      content: '';
    }
  }

  .slick-prev {
    left: 6px;
    background-image: url(${IconArrowBannerPrev});
  }

  .slick-next {
    right: 0;
    margin-right: 50px;
    background-image: url(${IconArrowBannerNext});
  }

  .slick-track{
    display: flex;
    right: 70px;
    //left: 0px  !important ;
    left: -16px !important;
    right: 50px !important;
  }
  .slick-next{
    z-index: 9999;
  }

  .slick-list{
    /* width: 90%; */
    width: 100%;
    padding-left: 10px;
    margin-left:  0px;
    margin-bottom: 50px !important;
    &:first-child{
      margin-bottom: 50px !important;
    }
 
  }
  .slick-slide .slick-active .slick-current {
 //   margin-left: -30px !important;
    width: 100% !important;
    
    margin-left: 0px;
  }
  .slick-slide{
    width: 80% !important;
    //padding-left: 50px;
    //padding-left: 43px;
   // margin-left: -43px;
   &:last-child {
      margin-left: -55px !important;
      margin-right: 80px !important;
    }
    &:first-child {
      //margin-left: -55px !important;
      margin-right: 20px !important;
      //margin-bottom: 50px;
    }
  }
  .slick-track{
    padding-bottom: 10px;
  }
  .slick-current{
   // width: 80% !important;
    margin-right: -29.13px !important;
    margin-left: 0px !important;
    @media (min-width:400px){
      margin-right: -80px !important;
    }
  }


  .slick-dots li {
    margin: 0px 10px 0;
    width: 0;
    height: 0;
  }

  .slick-dots li button {
    width: 0;
    height: 0;
  }

  .slick-dots li button:before {
    opacity: 1;
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #BBBBBB;
  }

  .slick-dots li.slick-active {
    width: 40px;
    margin: 0 0 0 10px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: white;
    content: '';
    position: absolute;
    width: 38px;
    height: 8px;
    border-radius: 120px;
    background-color: #0054A6;
  }

`;

export const Container = styled.div`
  justify-content: center;
  padding: 0 64px 1rem;
  @media (max-width:1024px){
    margin-bottom: 86px;
    padding: 0 11px;
  }
`;
export const ContainerCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.5rem 38px;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex: 1;
    p{
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: right;
      text-decoration-line: underline;
      color: #0054A6;
    }
    span{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #0054A6;
        text-align: right;
    }
`;
export const ContainerHeader = styled.div`
  div div {
    margin-left: 0;
  }
   @media (max-width:1024px){
    display: flex;
    align-items: flex-start;
    margin-left: 0px;
    *{align-items: flex-start !important}
   }
`;
export const Button = styled.button`
    background: #0054A6;
    border-radius: 4px;
    width: 240px;
    height: 43px;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    border: 0;
`;
export const ContainerBtn = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    border: 0;
    background-color:  #ffffff00;
    a {

      button {
        width: 100%;
      }

      @media (max-width: 767px){
        width: 50%;
      }
    }
`;