import { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Category from "../components/Category";
import IconLoading from "../images/icons/Loading.svg";

import { AgendamentoContext } from "../contexts/agendamento";
import HeaderTitle from "../components/HeaderTitle";
import { servicoDataService } from "../services/servicoDataService";

const PageCategory = () => {
  const agendamentoContext = useContext(AgendamentoContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listCategorias, setListCategorias] = useState<any[]>([]);
  const [loadCount, setLoadCount] = useState<number>(1);
  const [agendamentoAssistido, setAgendamentoAssistido] =
    useState<boolean>(false);
  const [hideCategory, setHideCategory] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    agendamentoContext.handleUpdateCurrentStep("exame");

    let _agendamentoAssistido = localStorage.getItem("_pmAgendamentoAssistido");
    if (_agendamentoAssistido !== null) {
      setAgendamentoAssistido(true);
      window.CustomScrollToTopAssistido();
    } else {
      window.CustomScrollToTop();
    }

    const loadCategories = () => {
      setIsLoading(true);
      servicoDataService
        .getAllCategoria()
        .then((response) => {
          if (response.message) {
            if (response.code === "001" && loadCount < 3) {
              setLoadCount(loadCount + 1);
              setErrorMessage(response.message);
              loadCategories();
            }
            return;
          }

          setListCategorias(response);
          return;
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    };

    loadCategories();
  }, []);

  return (
    <>
      <HeaderTitle
        title="Compre e agende serviços e vacinas online"
        boxRedTitle="Clinic Farma"
        helperText=""
      />
      {isLoading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={IconLoading}
            alt="loading"
            className="spiner"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "100px",
            }}
          />
        </div>
      )}
      <div className="categories">
        {listCategorias?.map((categoria: any) => (
          <Category
            key={categoria.id}
            id={categoria.id}
            image={categoria.icone}
            name={categoria.nome}
            details={categoria.descricao}
            subCategoryApi={`/servicoData/GetAllPorCategoria?idCategoria=${categoria.id}`}
            hideCategoryFunction={() => setHideCategory(!hideCategory)}
            hideCategoryValue={hideCategory}
          />
        ))}
      </div>
    </>
  );
};

export default withRouter(PageCategory);
