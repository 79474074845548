import styled from "styled-components";
import { colors } from '../../tokens';

export const Container = styled.div`
  padding: 50px 32px;
  position: relative;

  .close {
    border: 0;
    background: transparent;
    position: absolute;
    top: 15px;
    right: 15px; 
  }

  .react-calendar__navigation button:not(:disabled):last-child {
    border: 1px solid #F2F2F2;
  }

  .react-calendar__navigation button:not(:disabled):first-child {
    border: 1px solid #F2F2F2;
  }

  .jtdKmf .react-calendar .react-calendar__navigation {
    border: 0;
  }
  
  .jtdKmf .react-calendar .react-calendar__navigation .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #0054A6;
    font-family: 'Roboto', sans-serif;
  }
  
  .react-calendar__navigation__label {
    margin-top: 20px !important;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #383838;
    margin-top: 10px;
  }

  p {
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
  }
`;

export const ModalContent = styled.div`
  font-family: "Source Sans Pro", sans-serif;

  div.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 32px;
  }

  .motivosContainer {
    display: flex;
    flex-direction: column;
    color: #2A333E;

    padding-left: 16px;

    span {
      display: flex;
      align-items: center;

      & + span {
        margin-top: 12px;
      }

      label {
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        margin: 0;
        margin-left: 7px;
        color: #2A333E;
      }

      input[type="radio"] {
        position: relative;
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #7B8FA5;
        border-radius: 50%;
        outline: none;
        color: #2A333E;
      }

      input[type="radio"][class="checked"] {
        border-color: #0054A6;
        color: #2A333E;
      }

      input[type="radio"]:before {
        content: "";
        display: block;
        border-radius: 50%;
        color: #2A333E;
      }
      
      input[type="radio"]:checked:before {
        position: absolute;
        width: 8px;
        height: 8px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #0054A6;
        color: #2A333E;
      }
    }
  }

  .descricaoContainer {
    display: block;
    margin-top: 8px;
    padding-left: 16px;

    label {
      font-family: 'Source Sans Pro', sans-serif;
      color: #2A333E;
    }

    label::after {
      content: " (obrigatório)";
      color: ${colors.grayBlue};
    }

    input {
      width: 304px;
      height: 40px;

      display: block;
      border: 1px solid #aec0d2;
      border-radius: 4px;

      padding: 0 8.69px;
      color: #2A333E;

      &::placeholder {
        color: ${colors.grayBlue};
      }
    }

    @media (max-width: 1024px) {
      #motivo-descricao {
        width: 100%;
      }
    }
  }

  .row {
    width: 372px;
    margin: 0 auto;

    label {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #828282;
      margin-bottom: 2px;  
    }
    
    > div {
      flex: 1;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonCancel = styled.button`
  width: 180px;
  height: 43px;
  background: #fff;
  border: 0;
  border-radius: 6px;
  color: #0054A6;
  border: 1px solid #0054A6;
  margin-top: 32px;
`;

export const ButtonGoForward = styled.button`
  width: 179px;
  height: 43px;
  background: #0054A6;
  border: 0;
  border-radius: 6px;
  color: #fff;
  margin-top: 32px;
  margin-left: 24px;

  :disabled {
    color: #404f61;
    background: #f2f2f2;
    cursor: not-allowed;
  }
`;