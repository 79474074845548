import { useState } from 'react';
import * as S from './styles';
import { Service } from './types';

interface CardServiceProps {
  service: Service;
}

export function CardService({ service }: CardServiceProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const tempoFormatado = (tempo: string) => {
    const splitTempo = tempo.split(":");
    const newDate = new Date();

    const horas = splitTempo[0];
    const minutos = splitTempo[1];

    newDate.setHours(Number(horas));
    newDate.setMinutes(Number(minutos));

    if (horas !== "00") {
      return `${horas} hora(s)`;
    }

    return `${minutos} minutos`;
  };

  return (
    <S.Container className="cardService" style={{ height: isExpanded ? '100%' : '71px' }}>
      <strong>{service.nomeAbreviado}</strong>

      <div className="inline-info-service">
        <p>{service.subtitulo}</p>
        <div>
          <a onClick={() => setIsExpanded(prevState => !prevState)}>
            {(!isExpanded) ? 'Mostrar' : 'Ocultar'}
            {(!isExpanded) ?
                <svg width="10" height="10" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.39502 6.00025C5.11881 6.00025 4.8426 5.90225 4.6322 5.70725L0.316402 1.70725C-0.105467 1.31625 -0.105467 0.68425 0.316402 0.29325C0.738272 -0.09775 1.42017 -0.09775 1.84204 0.29325L5.40797 3.59825L8.96095 0.41825C9.39145 0.03525 10.0723 0.04625 10.4866 0.44325C10.9009 0.84025 10.889 1.47425 10.4607 1.85725L6.14489 5.71925C5.9345 5.90725 5.66476 6.00025 5.39502 6.00025Z" fill="#0054A6" />
                </svg> :
                <svg width="10" height="20" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: 10 }}>
                  <path transform="scale(1, -1)" fillRule="evenodd" clipRule="evenodd" d="M5.39502 6.00025C5.11881 6.00025 4.8426 5.90225 4.6322 5.70725L0.316402 1.70725C-0.105467 1.31625 -0.105467 0.68425 0.316402 0.29325C0.738272 -0.09775 1.42017 -0.09775 1.84204 0.29325L5.40797 3.59825L8.96095 0.41825C9.39145 0.03525 10.0723 0.04625 10.4866 0.44325C10.9009 0.84025 10.889 1.47425 10.4607 1.85725L6.14489 5.71925C5.9345 5.90725 5.66476 6.00025 5.39502 6.00025Z" fill="#0054A6" />
                </svg>
            }
          </a>
        </div>

      </div>

      <div style={{ display: isExpanded ? '' : 'none' }} className="details">
        <div className="row">
          <span style={{ width: 505 }}>
            <strong>Coleta de material</strong>
            <p>{service.coletaMaterial}</p>
          </span>
          <span style={{ width: 248 }}>
            <strong>Preparo para o exame</strong>
            <p>{service.descricaoPreparo}</p>
          </span>

          <br />

          <span style={{ width: 505 }}>
            <strong>Recomendações</strong>
            <p>{service.descricaoRestricao}</p>
          </span>
          <div className="times-container">
            <span style={{ width: 248 }}>
              <strong>Tempo de exame</strong>
              <p>{tempoFormatado(service.horaExecucao)}</p>
            </span>
            <span>
              <strong>Resultado em</strong>
              <p>{service.tempoResultado}</p>
            </span>
          </div>

          <br />

          <span>
            <strong>Descrição</strong>
            <p>{service.descricao}</p>
          </span>
        </div>
      </div>
    </S.Container>
  )
}
