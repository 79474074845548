import styled from 'styled-components';
import IconArrowBannerPrev from '../../images/icons/arrow-banner-prev.svg';
import IconArrowBannerNext from '../../images/icons/arrow-banner-next.svg';

export const Container = styled.div`
  background: transparent;
  padding: 0;
  margin: 0;

  .slick-prev,
  .slick-next {
    z-index: 1;
    top: 49%;
    margin: 0;
    width: 30px;
    height: 30px;
    transform: initial;
    background-repeat: no-repeat;
    background-size: 6px;
    background-position: center;
    background-color: white;
    margin-left: 50px;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    @media(max-width: 1024px) {
      margin: 0 !important;
      width: 30px;
      height: 30px;
      top: calc(50% - 15px);
    }

    &::before {
      content: '';
    }
  }

  .slick-prev {
    left: 0;
    background-image: url(${IconArrowBannerPrev});
  }

  .slick-next {
    right: 0;
    margin-right: 50px;
    background-image: url(${IconArrowBannerNext});
  } 
 /*
  .slick-slide {
    margin-left: -15px;
    margin-right: 15px;
    width: ${() => ((window.innerWidth < 1024 && !(navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/))) ? window.innerWidth - 10 : window.innerWidth) + 'px !important'};
  }*/

  .slick-track {
    height: 415px;
    overflow: hidden;
  }

  .slick-dots {
    bottom: 20px;
  }

  .slick-dots li {
    margin: 0px 10px 0;
    width: 0;
    height: 0;
  }

  .slick-dots li button {
    width: 0;
    height: 0;
  }

  .slick-dots li button:before {
    opacity: 1;
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #BBBBBB;
  }

  .slick-dots li.slick-active {
    width: 40px;
    margin: 0 0 0 10px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: white;
    content: '';
    position: absolute;
    width: 38px;
    height: 8px;
    border-radius: 120px;
    background-color: #fff;
  }
`;