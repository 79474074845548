import styled from "styled-components";
import "react-calendar/dist/Calendar.css";
import { colors } from "../../tokens";

export const Container = styled.div`
  .react-calendar {
    width: 325px;
    border: 1px solid ${colors.grayBlueLight};
    border-radius: 4px;

    .react-calendar__navigation {
      height: 60px;
      border-bottom: 1px solid ${colors.grayBlueLight};
      margin-bottom: 30.8px;

      .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        color: ${colors.grayText};
      }
    }

    .react-calendar__month-view {
      padding: 0 16px 16px;
    }

    .react-calendar__month-view__weekdays__weekday {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 10px;
      color: ${colors.grayBlueLight};

      abbr {
        text-decoration: none;
        cursor: auto;
      }
    }

    .react-calendar__tile.react-calendar__month-view__days__day {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
      color: #002a53;

      &:disabled {
        background: transparent;
        color: ${colors.grayBlue};
      }
    }

    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
      color: ${colors.grayBlueLight};
    }

    .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
      background: ${colors.white};
      color: #002a53;
    }

    .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day:enabled:hover {
      background: #E6E6E6;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__month-view__days__day {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
      color: ${colors.white};
      background: ${colors.blue};
      border-radius: 4px;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
      border-radius: 4px 0 0 4px;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
      border-radius: 0 4px 4px 0;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day {
      background: #D7E2ED;
      border-radius: 0;
      color: #002a53;
    }

    .react-calendar__tile:enabled abbr:hover {
      border-radius: 4px;
    }

    .react-calendar__tile--active abbr {
      border-radius: 4px;
    }

    abbr {
      font-size: 12px;
      font-weight: bold;
    }

    .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
      width: 49px;
      height: 49px;
      margin-left: 17px;
      margin-top: 13px;
    }
    
    .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
      width: 49px;
      height: 49px;
      margin-right: 17px;
      margin-top: 13px;
    }
  }

  .msgCarregando {
    margin-left: -170px;
  }

@media screen and (max-width: 1200px) {
  .msgCarregando {
    margin-top: 165px;
  }
}
`;
