import http from "../http-common";

import IBanner from "../types/Banner";

const getAllHomeBannerConfig = () => {
    return http.get<Array<IBanner>>(`/servicoData/GetAllHomeBannerConfig`);
};

const HomeBannerService = {
    getAllHomeBannerConfig
};

export default HomeBannerService;