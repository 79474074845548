import { Container, Apptitle, ClinicFarm } from "./styles";

interface HeaderTitleProps {
  title: string;
  boxRedTitle: string;
  helperText: string;
}

export default function HeaderTitle({
  title,
  boxRedTitle,
  helperText,
}: HeaderTitleProps) {
  return (
    <Container>
      <Apptitle>
        <ClinicFarm>
          <button>{boxRedTitle}</button>
          <h2 className="titleText">{title}</h2>

          {helperText != "" && <span>{helperText}</span>}
        </ClinicFarm>
      </Apptitle>
    </Container>
  );
}
