import Button from '../../components/Button';
import { GridHistoricoBeneficios } from '../../components/GridHistoricoBeneficios';
import { useRemoveSteps } from '../../hooks/useRemoveSteps';
import * as S from './styles';
import iconSettings from '../../images/icons/icon-settings.svg';
import arrowLeft from '../../images/icons/arrow-left.svg';
import { useHistory } from 'react-router-dom';
import { ModalFiltroHistoricoBeneficios } from '../../components/ModalFiltroHistoricoBeneficios';
import { useEffect, useState } from 'react';
import { convert } from '../../utils';
import { ModalSimple } from '../../components/ModalSimple';
import iconError from '../../images/icons/icon-error.svg';

interface HistoricoBeneficioData {
    id: number;
    servico: HistoricoBeneficioServicoData;
    loja: LojaData;
    dataConsumo: string;
}

interface LojaData {
    codigo: number;
    nome: string;
    endereco: string;
    numero: string;
    bairro: string;
    cep: string;
    cidade: string;
    estado: string;
    telefone01: string;
    telefone02: string;
}

interface HistoricoBeneficioServicoData {
    id: number;
    nome: string;
    codProduto: string;
}


export function HistoricoBeneficios() {
    useRemoveSteps();

    const [servico, setServico] = useState('');
    const [periodoDe, setPeriodoDe] = useState('');
    const [periodoAte, setPeriodoAte] = useState('');

    const [beneficios, setBeneficios] = useState<HistoricoBeneficioData[]>([]);
    const [servicos, setServicos] = useState<HistoricoBeneficioServicoData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);

    const { push, goBack } = useHistory();

    const [isModalFiltroHistoricoBeneficiosOpen, setIsModalFiltroHistoricoBeneficiosOpen] = useState(false);

    function handleGoBack() {
        const email = localStorage.getItem('_pmVtexclientemail');
        push(`/hubdesaude/beneficios?vtexclientemail=${email}`);
        window.CustomScrollToTop();
    }

    function handleClearFilter() {
        setServico('');
        setPeriodoDe('');
        setPeriodoAte('');
    }

    useEffect(() => {

        setLoading(true);
        const vtexclientemail = localStorage.getItem('_pmVtexclientemail');

        fetch(`/beneficioData/GetHistoricoConsumo?email=${vtexclientemail}`, { cache: "no-cache" })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.result === "Success") {
                    if (data.items != null) {
                        console.log(data.items);
                        setBeneficios(data.items);

                        let servicos = data.items.map((beneficio: any) => beneficio.servico).map((servico: HistoricoBeneficioServicoData) => ({
                            id: servico.id,
                            nome: servico.nome,
                            codProduto: servico.codProduto
                        }));
                        let servicosDistinct = servicos.reduce((unique: HistoricoBeneficioServicoData[], item: HistoricoBeneficioServicoData) => unique.filter((x: any) => x.id === item.id).length > 0 ? unique : [...unique, item], []);
                        setServicos(servicosDistinct);

                    }
                }
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <S.Container>
                <S.Header>
                    <span className="go-back">
                        <img src={arrowLeft} alt="Go back" />
                        <a href="#" onClick={goBack}>VOLTAR</a>
                    </span>

                    <div className="title-content">
                        <h1>Histórico de consumo</h1>
                        <strong>Seus benefícios</strong>
                    </div>

                    <div className="container-filtro">
                        <Button style={{ borderRadius: '6px' }} icon={iconSettings} width={"319px"} height={"43px"} inverted onClick={() => setIsModalFiltroHistoricoBeneficiosOpen(true)}>Filtro</Button>
                        {(servico || periodoDe || periodoAte) && <button className="button-link" onClick={handleClearFilter}>Limpar filtro</button>}
                    </div>
                </S.Header>
                <S.Content>
                    {(window.innerWidth > 1024 || (!isModalErrorOpen && !isModalFiltroHistoricoBeneficiosOpen)) &&
                        <GridHistoricoBeneficios beneficios={beneficios
                            .filter((row: any) => { if (servico !== "") return row.servico.id === servico; else return row; })
                            .filter((row: any) => { if (periodoDe !== "") return new Date(convert({ dateString: row.dataConsumo.split(" ")[0], pattern: "yyyy-mm-dd" })) >= new Date(convert({ dateString: periodoDe, pattern: "yyyy-mm-dd" })); else return row; })
                            .filter((row: any) => { if (periodoAte !== "") return new Date(convert({ dateString: row.dataConsumo.split(" ")[0], pattern: "yyyy-mm-dd" })) <= new Date(convert({ dateString: periodoAte, pattern: "yyyy-mm-dd" })); else return row; })} loading={loading} error={error} />
                    }
                    <div className="button-container">
                        <Button width="322px" onClick={handleGoBack}>
                            Voltar para benefícios
                        </Button>
                    </div>
                </S.Content>

                {(isModalFiltroHistoricoBeneficiosOpen || (servico || periodoDe || periodoAte)) && <ModalFiltroHistoricoBeneficios
                    isOpen={isModalFiltroHistoricoBeneficiosOpen}
                    onRequestClose={() => setIsModalFiltroHistoricoBeneficiosOpen(false)}
                    onContinue={(servico, periodoDe, periodoAte) => {
                        console.log(periodoDe, periodoAte);

                        let dateInicial: Date = new Date(convert({ dateString: periodoDe, pattern: "yyyy-mm-dd" }));
                        let dateFinal: Date = new Date(convert({ dateString: periodoAte, pattern: "yyyy-mm-dd" }));

                        if (dateInicial > dateFinal) {
                            setIsModalErrorOpen(true);
                        } else {

                            setServico(servico);
                            setPeriodoDe(periodoDe);
                            setPeriodoAte(periodoAte);
                        }
                        setIsModalFiltroHistoricoBeneficiosOpen(false);
                    }}
                    servicos={servicos}
                />}


                <ModalSimple Height={"305px"}
                    isOpen={isModalErrorOpen}
                    title={"Data inválida"}
                    message={"Data Inicial não pode ser maior que data final"}
                    icon={iconError}
                    continueButtonText="Fechar"
                    widthButtonContinue="180px"
                    onRequestClose={() => setIsModalErrorOpen(false)}
                    confirm={() => {
                        setIsModalErrorOpen(false);
                    }}
                />
            </S.Container>
        </>
    )
}