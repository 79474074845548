import { Component } from 'react';
import '../style.css'

class UF extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            loadCount: 1,
            errorMessage: "",
            estadoList: [],

            uf: "",
            innerWidth: 0
        }

        this.handleSelect = this.handleSelect.bind(this)
        this.resizeScreen = this.resizeScreen.bind(this);
        this.partnerServices = this.partnerServices.bind(this);
    }

    componentDidMount() {

        this.setState({ innerWidth: window.innerWidth });
        window.addEventListener('resize', (event) => {
            this.resizeScreen()
        });

        const sServicos = localStorage.getItem('_ApmServicos');
        let arrayServicos: any[] = [];
        if (sServicos !== null)
            arrayServicos = JSON.parse(sServicos);

        const servicos = arrayServicos.map(function(elem) { return elem.id; }).join(",");
        if (servicos === "") {
            this.props.returnStep();
            return;
        }

        this.loadUfs(servicos);
    }

    loadUfs(servicos: string) {
        console.log(`Buscando Estados ${this.state.loadCount}x`);
        this.setState({ isLoading: true });
        const apiUrl = `lojaData/GetEstados?servicos=${servicos}`;

        fetch(apiUrl, { cache: "no-cache" })
            .then(response => response.json())
            .then(data => {
                if (data.result === "Error") {
                    if (data.code === "001" && this.state.loadCount < 3) {
                        this.setState({ loadCount: this.state.loadCount + 1, errorMessage: data.message });
                        this.loadUfs(servicos);
                    }
                } else {
                    this.setState({ estadoList: data.items });

                    if (data.items.length === 0)
                        this.props.ufNotFound();

                    this.partnerServices(data.items);
                }

                this.setState({ isLoading: false });
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({ isLoading: false });
            });
            this.setState({ isLoading: false });
    }

    resizeScreen() {
        this.setState({ innerWidth: window.innerWidth });
    }

    handleSelect(event: any) {
        this.props.onChange(event);
    }

    partnerServices(items: any[]){
        var isPartner = items?.some((item: any) => item.parceiro);
        return this.props.partnerServices(isPartner);
    }

    render() {
        return (
            <>
                <div className="DropDownUf">
                    <label className="labelDropDown">Estado</label>
                    <select onChange={this.handleSelect} className="form-control DropDown" disabled={this.state.estadoList?.length === 0} >
                        {this.state.innerWidth >= 1024 &&
                            <>
                                <option value="">Selecione seu estado</option>
                                {
                                    this.state.estadoList && (
                                        this.state.estadoList.map((item: any) => (
                                            <option key={item.sigla} value={item.sigla} selected={this.props.selectedUf?.toLowerCase() === item.sigla.toLowerCase()}>{item.descricao}</option>
                                        )))
                                }
                            </>
                        }
                        {this.state.innerWidth < 1024 &&
                            <>
                                <option value="">UF</option>
                                {
                                    this.state.estadoList && (
                                        this.state.estadoList.map((item: any) => (
                                            <option key={item.sigla} value={item.sigla} selected={this.props.selectedUf?.toLowerCase() === item.sigla.toLowerCase()}>{item.sigla}</option>
                                        )))
                                }
                            </>
                        }

                    </select>
                </div>
                {!this.state.isLoading && this.state.loadCount === 3 && (
                    <div className="row">
                        <div className="w-100">
                            <p>{this.state.errorMessage}</p>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default UF;