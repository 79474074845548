import styled, { css } from "styled-components";

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border: 2px solid #e3e4e6;
  border-radius: 4px;
  padding: 8px 0 8px 17.65px;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isDisabled &&
    css`
      background-color: #ddd;
    `}

  input {
    flex: 1;
    width: 100%;
    height: 100%;
    margin-right: 8px;
    color: #666360;
    background: transparent;
    border: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    &::placeholder {
      color: #a9a9a9;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background: #dddddd;
      color: #bdbdbd;
    }
  }

  img {
    margin-right: 14px;
  }
`;

export const Label = styled.label`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #828282;
  margin-bottom: 2px;
`;

export const Required = styled.span`
  color: #d14a4a;
  font-weight: bold;
`;
