import React from 'react';

import { IBenefit } from '../..';
import { 
  Container, 
  CloseImg, 
  Header, 
  Beneficiary, 
  BeneficiaryCard, 
  BeneficiaryInfo, 
  Main,
  ServiceTitle,
  Divider,
  Footer, 
  Sponsors, 
  CloseButton 
} from './styles';

interface Props {
  handleToggleModal: () => void;
  data: {
    name: string;
    loading: boolean;
    benefits: IBenefit[];
    sponsors: string[];
  }
}

const Benefits: React.FC<Props> = ({ handleToggleModal, data }) => {
  function privateName(fullName: string) {
    const singleNames = fullName.split(' ');
    let newSingleNames: string[] = [];

    singleNames.forEach((name, index) => {
      if (index === (singleNames.length - 1)) {
        let asterisks = '';
        for(let i = 0; i < name.length - 3; i++) asterisks += '*';

        const lastName = name[name.length - 3] + name[name.length - 2] + name[name.length - 1];
        
        newSingleNames.push(asterisks + lastName);
      } else if (index) {
        let asterisks = '';
        for (let _ of name) asterisks += '*';

        newSingleNames.push(asterisks);
      } else
        newSingleNames.push(name);
    });

    return newSingleNames.join(' ');
  }

  return (
    <>
      {!data.loading &&
      (<Container>
        <CloseImg onClick={handleToggleModal} />
        <Header>
          <h2>Consulta de benefícios</h2>
          <aside>
            <p>Informações disponíveis por um tempo limitado de 5 minutos, após isso o modal será fechado automaticamente.</p>
            <Beneficiary>
              <label>Nome do beneficiario</label>
              <span>{privateName(data.name)}</span>
            </Beneficiary>
          </aside>
        </Header>

        <Main>
          <h3>Benefícios</h3>

          <div>
            <ServiceTitle>
              <h4>Serviços farmacêuticos</h4>
              <Divider />
            </ServiceTitle>
            {data.benefits.map((benefit) => (
              <BeneficiaryCard key={benefit.id}>
                <span>{benefit.name}</span>
                <BeneficiaryInfo>
                  <div>
                    <label>Renova em</label>
                    <span>{benefit.renews}</span>
                  </div>
                  <div>
                  <label>Quantidade</label>
                  <span>{benefit.quantity}</span>
                  </div>
                </BeneficiaryInfo>
              </BeneficiaryCard>
            ))}
          </div>
        </Main>

        <Footer>
          <Sponsors>
            <h3>Patrocinadores</h3>
            <span>{data.sponsors.join('; ') + ';'}</span>
          </Sponsors>
          <CloseButton onClick={handleToggleModal}>Fechar</CloseButton>
        </Footer>
      </Container>)}
    </>
  );
}

export default Benefits;