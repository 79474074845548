import axiosConfig from '../config/axiosConfig'
import axios from 'axios'

export const loginService = {

    performLogin: async userInfo => {
        const { userEmail, userPassword } = userInfo

        const req = await axios.post(
            "login/PerformLogin",
            {
                email: userEmail,
                password: userPassword
            },
            axiosConfig
        )

        if (req.status === 200) {
            if (req.data.result === "Success") {
                return {
                    result: "Success",
                    message: "Login realizado!",
                    userEmail: req.data.userEmail
                }
            } else {
                return {
                    result: "Error",
                    message: req.data.message
                }
            }
        } else {
            const { message } = req.data

            return {
                message
            }
        }
    },

    requestCode: async userInfo => {
        const { userEmail } = userInfo

        const req = await axios.post(
            "login/RequestCode",
            {
                email: userEmail
            },
            axiosConfig
        )

        return req.data
    },

    resetPassword: async userInfo => {
        const { userEmail, loginCode, userNewPassword } = userInfo

        const req = await axios.post(
            "login/ChangePassword",
            {
                userEmail,
                loginCode,
                userNewPassword
            },
            axiosConfig
        )

        return req.data
    }
}
