import styled from 'styled-components';
import colors from '../../../tokens/colors';

export const ButtonDefault = styled.button`
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto; 
  width: 240px;
  height: 43px;
  border: none;
  background-color: ${colors.blue};
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  color: ${colors.white};;
  cursor: pointer;
  
  &.servico-add {
    background: ${colors.greenSuccess};
    border-color: #259c40;
  }
  
  span {
    img {
      display: inline-block;
      margin-right: 10px;
    }
  }
`;
