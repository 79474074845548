import styled from "styled-components";

export const Container = styled.div`
  max-width: 1039px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 11px;

  h1 {
    color: #0054A6;
    font-size: 32px;
    font-weight: 500;
    align-self: flex-start;
  }

  strong {
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #515151;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .title-content {

    @media (max-width: 1024px) {
      h1 {
        font-size: 22px;
      }

      strong {
        font-size: 16px;
      }
    }
  }
  
  .go-back {
    display: none;
    margin-bottom: 20px;

    img {
      margin-right: 9px;
    }
    
    a {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #0054A6;
    }
  }
  
  .container-filtro {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    
    button {
      width: 100%;

      @media(max-width: 1024px) {
        max-width: initial;
        min-width: initial;
      }
    }

    .button-link {
      margin-right: 32px;

      @media(max-width: 1024px) {
        margin: 16px 0 0;
      }
    }

    @media(max-width: 1024px) {
      justify-content: flex-start;
      width: 100%;
      flex-direction: column;
    }
  }
  
  @media (max-width: 1024px) {
    flex-direction: column;

      h1 {
        padding-bottom: 38px;
        margin-bottom: 0px;
      }

    .go-back {
      display: block;
    }
    
    > div {
      flex-direction: column;
      align-items: center;
    }

    button {
      margin-top: 16px;
      text-align: left !important;
    }
    .button-link {
      margin-right: 9px !important;
    }
  }
`;

export const Content = styled.div`
  #grid-container {
    margin-top: 28px;
  }

  .button-container {
    width: 100%;
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }
`;