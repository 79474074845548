import React, { useState, useEffect } from 'react';

import ReactModal from 'react-modal';

interface IModalProps {
    children: any;
    isOpen: boolean;
    setIsOpen: () => void;
}

const ModalShowCase: React.FC<IModalProps> = ({ children, isOpen, setIsOpen }) => {
    const [modalStatus, setModalStatus] = useState(isOpen);

    useEffect(() => {
        setModalStatus(isOpen);
    }, [isOpen]);

    return (
        <ReactModal
            shouldCloseOnOverlayClick={!false}
            onRequestClose={setIsOpen}
            isOpen={modalStatus}
            ariaHideApp={false}
            style={window.innerWidth > 1025 ?
                {
                    content: {
                        marginTop: window.innerWidth > 1024 ? '10%' : '40%',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-40%',
                        transform: 'translate(-50%, -50%)',
                        background: '#FFFFFF',
                        color: '#000000',
                        borderRadius: '8px',
                        width: 'auto',
                        maxWidth: '542px',
                        border: 'none',
                        padding: '0',
                    },
                    overlay: {
                        position: 'absolute',
                        backgroundColor: '#231f2066',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: 'auto'
                    },
                }
                :
                {
                    overlay: {
                        backgroundColor: '#231f2066',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        marginTop: window.innerWidth > 1024 ? '10%' : '-40%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        position: 'absolute',

                        // height: '95%',
                        border: '1px solid #979899',
                        background: '#fff',
                        padding: '0',
                        //overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '8px',
                        outline: 'none',
                        height: 'fit-content'
                    }
                }}
        >
            {children}
        </ReactModal>
    );
};

export default ModalShowCase;