import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-top: 80px;

    @media (max-width: 1024px) {
        margin-top: 50px;
    }
`;

export const HeaderContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 36px;
        font-weight: 700;
        color: #0054A6;

        @media (max-width: 1024px) {
            font-size: 30px;
        }
    }

    p {
        font-size: 14px;
        font-weight: 400;
        color: #000;
    }
`;

export const Carousel = styled.div`
    display: flex;
    flex-direction: column;
    /* height: 100%; */
`;