import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import { AgendamentoContext, AgendamentoContextData } from "../../contexts/agendamento";
import { clearDataAfter15min } from "../../utils/utils";
import ModalLoading from "../ModalLoading";

type Service = {
    id: number;
    nome: string;
    nomeAbreviado: string;
    horaExecucao: string;
    tempoResultado: string;
    preRequisito?: string;
    descricao?: string;
    preparo?: string;
    restricao?: string;
    ativo?: boolean;
    codProduto?: string;
    horaServico?: string;
    tempoBase?: string | null;
    subTitulo?: string;
    preco?: string | null;
    coletaMaterial?: string;
}
interface SubCategory {
    id: number;
    nome: string;
    servicos: Service[];
}

interface CategoryProps {
    id: number;
    image: string;
    name: string;
    details: string;
    subCategoryApi: string;
    hideCategoryFunction: () => void;
    hideCategoryValue: boolean;
}

export default function Category({ image, name, details, subCategoryApi, hideCategoryFunction, hideCategoryValue, id }: CategoryProps) {

    const agendamentoContext = useContext(AgendamentoContext);

    const history = useHistory();
    const handleClick = (categoryId: number) => history.push(`/hubdesaude/subCategoria?csub=${categoryId}`);
    const [loadCount, setLoadCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    function goToSubCategory() {
        setIsLoading(true);
        const sCategory = localStorage.getItem('_pmCategory');
        if (id.toString() !== sCategory) {
            localStorage.removeItem('_ApmServicos');
            agendamentoContext.handleUpdateQuantidadeServicos(0);
            agendamentoContext.handleUpdateExames([]);

            //LIMPAR PRÓXIMAS ETAPAS TBM
            localStorage.removeItem('_pmDataSelecionada');
            localStorage.removeItem('_pmBairroList');
            localStorage.removeItem('_pmLoja');
            localStorage.removeItem('_pmLojaFiltro');
            localStorage.removeItem('_pmLojaList');
            localStorage.removeItem('_pmCompletedSteps');
            localStorage.removeItem('_pmTitular');

            agendamentoContext.clearContext();
        }

        localStorage.setItem('_pmCategory', id.toString());

        console.log(`Buscando subCategoryApi ${loadCount}x`);
        fetch(subCategoryApi, { cache: "no-cache" })
            .then(response => response.json())
            .then(data => {
                if (data.result === "Success") {
                    localStorage.setItem('_subCategorias', JSON.stringify(data.items))
                    hideCategoryFunction();
                    handleClick(id);
                    clearDataAfter15min();

                    const id1 = 'farmacêuticos';
                    const id2 = 'de vacinação';

                    localStorage.setItem('_tituloSubCategorias', JSON.stringify(
                        id === 1 ? id1 : id2
                    ))
                }
            })
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <Container>
                <div className={hideCategoryValue ? 'hide-category' : 'box-category'}>
                    <img src={image} alt="image category" />
                    <strong>{name}</strong>

                    <p>{details}</p>

                    <button onClick={goToSubCategory}>Ver mais</button>
                </div>
            </Container>
            <ModalLoading isOpen={isLoading} />
        </>
    );
}