import styled from "styled-components";
import { media } from "../../tokens";

export const Container = styled.div`
  padding-left: 36px;
  font-family: 'Roboto', sans-serif;
 
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #666666;
    margin-bottom: 30px;
  }

  @media (max-width: 1024px) {

    padding: 0 11px !important;
    overflow-x: hidden;

  }
`;

export const FormRow = styled.div`
  display: flex;

  .input-wrapper {
    width: 100%;
    max-width: 266px;
    margin-bottom: 16px;

    & + .input-wrapper {
      margin-left: 16px;

      @media(max-width: 1024px) {
        width: calc(52% - 8px);
      }
    }

    label {
      font-weight: normal;
      font-size: 12px;
      line-height: 26px;
      display: flex;
      color: #666666;
      margin-bottom: 2px;
    }

    @media(max-width: 1024px) {
      width: calc(48% - 8px);
    }
  }

  .input-wrapper-half {
    label {
      &::after {
        content: '* (obrigatório)';
        color: #D14A4A;
        font-weight: bold;
        font-family: 'Roboto', sans-serif;
      }
    }
  }

  .name {
    width: 100% !important;
    max-width: 548px !important;
  }

  .email {
    width: 100% !important;
    max-width: 548px !important;
  }

  @media(max-width: 1024px) {
    /* position: fixed;
    bottom: 0;
    width: 100%; */
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  
  @media(max-width: 1024px) {
    bottom: 0;
    width: 100%;
    flex-direction: column;
    
    > button {
      max-width: 100%;
      min-width: 100%;
      margin-left: 0 !important;
      height: 43px;
    }

    > button:first-child {
      margin-bottom: 16px;
    }
  }
`;

export const ModalRemoverPaciente = styled.div`
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #383838;
  }
`;

export const Checkbox = styled.div`
  margin: 23px 0 35px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;

  label {
    margin-left: 11px;
  }

  ${media.md} {
    margin: 0;
  }
`;

export const Required = styled.span`
  color: #D14A4A;
  font-weight: bold;
`;

export const TitleSlick = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
 // width: 55%;

  span {
    padding-left: 30px;
    font-weight: 500;
    line-height: 20px;
    color: #666;
    margin-bottom: 0;

    @media (max-width: 1024px){
    width: 286px;
  }
  }
  @media (max-width: 1024px){
    width: 286px;
    justify-content: flex-start;
    padding-left: 17px;
  }

  @media(max-width: 1024px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 7px;
  }
`;


export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  position: absolute;

  @media (max-width: 1024px){
    width: 286px;
    justify-content: flex-start;
  }

  padding-bottom: 27px;
 // padding-left: 30px;
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);  

  button.button-back-sub-category {
    width: 220px; 
    margin-right: 21px; 
    color: #0054A6; 
    background-color: #0054A600; 
    border-radius: 4px; 
    border: solid 1px #0054A6;

    @media(max-width: 1024px) {
      width: 100%;
      margin-right: 11px;
    }
  }

  @media(max-width: 1024px) {
    box-shadow: none;
    padding: 0;
  }
`;

export const HeaderSubCategory = styled.div`
  position: relative;
  margin: 0 0 31px;
  width: 100%;
  padding: 10px;
  height: 70px;

  @media (max-width: 1024px) {
    border: none;
    padding: 0;
    height: auto;
    margin: 0;

    .button-back-sub-category {
      width: 220px; 
      margin-right: 21px; 
      color: #0054A6; 
      background-color: #0054A600; 
      border-radius: 4px; 
      border: solid 1px #0054A6;
    }
  }
`;

export const ButtonActions = styled.div`
  display: flex;

  @media(max-width: 1024px) {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    padding: 16px 11px;
    z-index: 9999;

    > button {
      width: 100%;
      margin: 0;
      border-radius: 4px;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 43px;
    }
  }
`;

export const ButtonContinue = styled.button`
  height: 43px;
  width: 240px;

    @media (max-width: 1024px) {
        width: 164px;
    }

  border: none;
  background: #09B2AB;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 16px;

  :disabled {
    background: #AAAAAA;
    cursor: not-allowed;

    a {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  a {
    color: #FFFFFF;
  }

  a:hover {
    text-decoration: none;
    color: #FFFFFF;
  }
`;
