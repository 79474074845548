import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { minutes } from "../../config/session";

class UserSession extends Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    newUTCDate() {
        const date = new Date();
        return new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
            )
        );
    }

    componentDidMount() {
        // //PEGAR VERSÃO LAMBDA
        // fetch("servicoData/version").then(response => response.text()).then(data => { console.log('lambda servico: ' + data); });
        // fetch("lojaData/version").then(response => response.text()).then(data => { console.log('lambda lojaServico: ' + data); });
        // fetch("agendaData/version").then(response => response.text()).then(data => { console.log('lambda agenda: ' + data); });
        // fetch("agendamentoData/version").then(response => response.text()).then(data => { console.log('lambda agendamento: ' + data); });
        // fetch("clienteData/version").then(response => response.text()).then(data => { console.log('lambda vtex: ' + data); });
        // fetch("comunicacaoData/version").then(response => response.text()).then(data => { console.log('lambda comunicacao: ' + data); });

        localStorage.setItem(
            "_pmSession",
            JSON.stringify({
                createdAt: this.newUTCDate(),
                minutes,
            })
        );

        let storageDataSelecionada = localStorage.getItem('_pmDataSelecionada');

        let pathname = this.props.location.pathname;
        let path = pathname.substring(pathname.lastIndexOf('/'));
        if (path === "/" || path === "/hubdesaude")
            this.props.history.push('/hubdesaude/exame');
        
        let searchParams = new URLSearchParams(this.props.location.search);
        let vtexclientemail = searchParams.get('vtexclientemail');
        let tipo = searchParams.get('tipo');


        localStorage.removeItem('_pmTitular');
        if (vtexclientemail !== null) {
            console.log('Cliente Logado: ' + vtexclientemail);
            localStorage.setItem('_pmVtexclientemail', vtexclientemail);
        } else {
            localStorage.removeItem('_pmVtexclientemail');
        }

        if (path !== '/agendamentos' && path !== '/beneficios' && path !== '/home' && path !== '/firstLogin') {
            let lastStep = localStorage.getItem('_pmLastStep');
            if ((tipo !== null && tipo === "assistido") || storageDataSelecionada === null || (lastStep !== "/agenda" && lastStep !== "/paciente")) {
                localStorage.removeItem('_pmDataSelecionada');
                localStorage.removeItem('_pmLastStep');
                localStorage.removeItem('_pmBairroList');
                localStorage.removeItem('_pmLoja');
                localStorage.removeItem('_pmLojaFiltro');
                localStorage.removeItem('_pmLojaList');
                localStorage.removeItem('_pmAgendamentoAssistido');
                localStorage.removeItem('_pmPacienteAssistido');
                localStorage.removeItem('_pmCompletedSteps');
                localStorage.removeItem('_pmTitular');

                const servicoSelecionadoHome = localStorage.getItem('_ApmServicosVitrine');
                if (servicoSelecionadoHome === null) 
                    localStorage.removeItem('_ApmServicos');
            }

            if (tipo !== null && tipo === "assistido")
                localStorage.setItem('_pmAgendamentoAssistido', "true");

            let codigoColaborador = searchParams.get('colaborador');
            if (codigoColaborador !== null)
                localStorage.setItem('_pmCodigoColaborador', codigoColaborador);
            else
                localStorage.removeItem('_pmCodigoColaborador');

            let nomeColaborador = searchParams.get('nome_colaborador');
            if (nomeColaborador !== null)
                localStorage.setItem('_pmNomeColaborador', nomeColaborador);
            else
                localStorage.removeItem('_pmNomeColaborador');

            let cargoColaborador = searchParams.get('cargo_colaborador');
            if (cargoColaborador !== null)
                localStorage.setItem('_pmCargoColaborador', cargoColaborador);
            else
                localStorage.removeItem('_pmCargoColaborador');

            if (path !== '/home' && path !== '/hubdesaude/home') {
                //SE CLIENTE TEM HORÁRIO AGENDADO... ENVIA PARA CLIENTE
                let dataSelecionada = localStorage.getItem('_pmDataSelecionada');
                if (dataSelecionada != null)
                    this.props.history.push('/hubdesaude/paciente');
            }
        }

        //SE CLIENTE TEM HORÁRIO AGENDADO... ENVIA PARA CLIENTE
        let dataSelecionada = localStorage.getItem('_pmDataSelecionada');
        if (dataSelecionada != null && path !== '/agendamentos' && path !== '/beneficios' && path !== '/home' && path !== '/firstLogin')
            this.props.history.push('/hubdesaude/paciente');
    }

    render() {
        return (
            <>
            </>
        );
    }
}
export default withRouter(UserSession);