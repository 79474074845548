import React, { useState, useEffect } from "react";

import ReactModal from "react-modal";

interface IModalProps {
  children: any;
  isOpen: boolean;
  setIsOpen: () => void;
  Mtop?: string;
  styles?: object;
  overflow?: boolean;
  overlay?: string;
}

const Modal: React.FC<IModalProps> = ({
  children,
  isOpen,
  setIsOpen,
  Mtop,
  styles,
  overflow = true,
  overlay = undefined
}) => {
  const [modalStatus, setModalStatus] = useState(isOpen);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={setIsOpen}
      isOpen={modalStatus}
      ariaHideApp={false}
      overlayClassName={overlay}
      style={Object.assign(
        {
          content: {
            marginTop: Mtop ? Mtop : 0,
            top: "20%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            background: "#F0F0F5",
            color: "#000000",
            borderRadius: "8px",
            width: "auto",
            maxWidth: "542px",
            border: "none",
            maxHeight: "85%",
            "overflow-y": overflow ? "scroll" : "unset",
            padding: "0",
          },
          overlay: {
            backgroundColor: "#121214e6",
            zIndex: 10,
          },
        },
        styles ? { ...styles } : {}
      )}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
