import HeaderTitle from "../components/HeaderTitle";

const Usuario: React.FC = () => {
  return (
    <>
      <HeaderTitle
        title="Agendamento online"
        boxRedTitle="Clinic Farma"
        helperText="Usuario"
      />
    </>
  );
};
export default Usuario;
