import { useState, useRef } from 'react';
import { CSSProperties } from 'styled-components';
import useClickAway from 'react-use/lib/useClickAway';

import { Step, useAgendamento } from '../../contexts/agendamento';

import { Exame, Loja, Agenda, Paciente, MobileMenu } from './DropDownMenuItems';

import * as S from './styles';
import { useCallback } from 'react';
import { useEffect } from 'react';

const stepNameMap = {
  'exame': 1,
  'loja': 2,
  'agenda': 3,
  'paciente': 4,
}

export function DropDownMenu() {
  const { currentStep, handleCompleteStep } = useAgendamento();

  const [stepsStyle, setStepsStyle] = useState({} as CSSProperties);

  useEffect(() => {
    const stepsCompletedStorage = localStorage.getItem('_pmCompletedSteps');

    if (stepsCompletedStorage) {
      const parsedSteps = JSON.parse(stepsCompletedStorage);
      parsedSteps.forEach((step: Step) => handleCompleteStep(step));
    }
  }, [handleCompleteStep]);
  
  const ref = useRef(null);
  useClickAway(ref, () => {
    if (stepsStyle.display === 'block') {
      handleToggleMenu();
    }
  });
  
  const handleToggleMenu = useCallback(() => {
    setStepsStyle(prevState => ({
      display: prevState.display === 'block' ? 'none' : 'block',
    }));
  }, []);

  return (
    <S.Container ref={ref}>
      <div className="mobile-menu">
        <MobileMenu step={stepNameMap[currentStep]} onClick={handleToggleMenu} />
      </div>
      
      <div id="steps" style={stepsStyle}>
        <Exame onClick={handleToggleMenu} />
        <Loja onClick={handleToggleMenu} />
        <Agenda onClick={handleToggleMenu} />
        <Paciente onClick={handleToggleMenu} />
      </div>
      
      <div className="separador"></div>
    </S.Container>
  );
}