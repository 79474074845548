import React, { useState, useEffect } from 'react';

import ReactModal from 'react-modal';

interface IModalProps {
    children: any;
    isOpen: boolean;
    setIsOpen: () => void;
    position?: string;
}

const Modal: React.FC<IModalProps> = ({ children, isOpen, setIsOpen, position }) => {
    const [modalStatus, setModalStatus] = useState(isOpen);
    const [documentInnerHeight, setDocumentInnerHeight] = useState(0);


    useEffect(() => {
        setDocumentInnerHeight(window.document.documentElement.scrollHeight);
        window.addEventListener('resize', (event) => {
            resizeScreen();
        });
    }, []);

    useEffect(() => {
        setModalStatus(isOpen);
    }, [isOpen]);

    const isPosition: string = position ? position : 'fixed'

    function resizeScreen() {
        setDocumentInnerHeight(window.document.documentElement.scrollHeight);
    }

    return (
        <ReactModal
            shouldCloseOnOverlayClick={!false}
            onRequestClose={setIsOpen}
            isOpen={modalStatus}
            ariaHideApp={false}

            style={
                position ?
                    //SAIBA MAIS
                    window.innerWidth > 1025 ?
                        {
                            content: {
                                marginTop: window.innerWidth > 1024 ? '10%' : '40%',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-40%',
                                transform: 'translate(-50%, -50%)',
                                background: '#FFFFFF',
                                color: '#000000',
                                borderRadius: '8px',
                                width: 'auto',
                                maxWidth: '542px',
                                border: 'none',
                                padding: '0',
                            },
                            overlay: {
                                position: 'absolute',
                                backgroundColor: '#231f2066',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                height: 'auto'
                            },
                        }
                        :
                        {
                            overlay: {
                                backgroundColor: '#231f2066',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                            content: {
                                marginTop: window.innerWidth > 1024 ? '10%' : '-40%',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                position: 'absolute',

                                // height: '95%',
                                border: '1px solid #979899',
                                background: '#fff',
                                padding: '0',
                                //overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '8px',
                                outline: 'none',
                                height: 'fit-content'
                            }
                        }
                    //SAIBA MAIS
                    :
                    {
                        content: {
                            marginTop: '10%',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-40%',
                            transform: 'translate(-50%, -50%)',
                            background: '#FFFFFF',
                            color: '#000000',
                            borderRadius: '8px',
                            width: 'auto',
                            maxWidth: '542px',
                            border: 'none',
                            padding: '0',
                        },
                        overlay: {
                            // position: position ? position : 'fixed' ,
                            position: 'fixed',
                            backgroundColor: '#231f2066',
                            // backgroundSize: 'cover',
                            // top: '50%',
                            // left: '50%',
                            // // marginLeft: '-150px',
                            // // marginTop: '-150px',
                            // right: '50%',
                            // bottom: '50%',
                        },
                    }
            }

        /*{position ? {
            content: {
                marginTop: window.innerWidth > 1024 ? '10%' : '40%',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-40%',
                transform: 'translate(-50%, -50%)',
                background: '#FFFFFF',
                color: '#000000',
                borderRadius: '8px',
                width: 'auto',
                maxWidth: '542px',
                border: 'none',
                padding: '0',
            },
            overlay: {
                position: 'absolute',
                backgroundColor: '#231f2066',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                height: documentInnerWidth
            },
        }: {
            content: {
                marginTop:'10%',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-40%',
                transform: 'translate(-50%, -50%)',
                background: '#FFFFFF',
                color: '#000000',
                borderRadius: '8px',
                width: 'auto',
                maxWidth: '542px',
                border: 'none',
                padding: '0',
            },
            overlay: {
                // position: position ? position : 'fixed' ,
                position:'fixed',
                backgroundColor: '#231f2066',
                // backgroundSize: 'cover',
                // top: '50%',
                // left: '50%',
                // // marginLeft: '-150px',
                // // marginTop: '-150px',
                // right: '50%',
                // bottom: '50%',
            },
        }}*/
        >
            {children}
        </ReactModal>
    );
};

export default Modal;