import React, { useEffect, useState } from "react";
import HeaderTitle from "../components/HeaderTitle";

function App() {
  const [servicoData, setServicoData] = useState<ServicoData | null>({
    isLoading: false,
    items: [],
  });
  interface ServicoData {
    isLoading: boolean;
    items: [];
  }

  const [ufData, setUfData] = useState<UFData | null>({
    isLoading: false,
    items: [],
  });
  interface UFData {
    isLoading: boolean;
    items: [];
    selectedItem?: string;
  }

  const [cidadeData, setCidadeData] = useState<CidadeData | null>({
    isLoading: false,
    items: [],
  });
  interface CidadeData {
    isLoading: boolean;
    items: [];
    selectedItem?: string;
  }

  const [bairroData, setBairroData] = useState<BairroData | null>({
    isLoading: false,
    items: [],
  });
  interface BairroData {
    isLoading: boolean;
    items: [];
    selectedItem?: string;
  }

  const [lojaData, setLojaData] = useState<LojaData | null>({
    isLoading: false,
    items: [],
  });
  interface LojaData {
    isLoading: boolean;
    items: [];
    selectedItem?: string;
  }

  useEffect(() => {
    setServicoData({ isLoading: false, items: [] });
    setUfData({ isLoading: false, items: [] });
    setBairroData({ isLoading: false, items: [] });
    setCidadeData({ isLoading: false, items: [] });
    setLojaData({ isLoading: false, items: [] });
  }, [setServicoData, setUfData, setBairroData, setCidadeData, setLojaData]);

  function btnRedirectLogin() {
    let element: HTMLElement = window.parent.document.getElementsByClassName(
      "vtex-login-2-x-label"
    )[0] as HTMLElement;
    element.click();
  }

  //SERVICO
  async function loadServicoData() {
    setServicoData({ isLoading: true, items: [] });
    const apiUrl = `servicoData`;
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //setDados(data.items)
        setServicoData({ isLoading: false, items: data.items });
        console.log(data);
      });
  }
  function btnLoadServicoData() {
    console.log("btnRefresh");
    loadServicoData();
  }
  //SERVICO

  //UF
  async function loadUfData() {
    setUfData({ isLoading: true, items: [] });
    const idServico = 7; //appState.servicos.selectedValue;
    const apiUrl = `lojaData/GetUFs?servicos=${idServico}`;
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //setDados(data.items)
        setUfData({ isLoading: false, items: data.items });
        console.log(data);
      });
  }

  function btnLoadUfData() {
    console.log("btnLoadUfData");
    loadUfData();
  }

  function setSelectedUf(event: any) {
    // this.setState({ value: event.target.value });
    console.log("selecioneiUf");
    loadCidadeData(event.target.value);

    //setUfData({ isLoading: ufData?.isLoading, items: ufData?.items, selectedItem: event.target.value });
  }
  //UF

  //CIDADE
  async function loadCidadeData(uf: string) {
    setCidadeData({ isLoading: true, items: [] });
    const idServico = 7; //appState.servicos.selectedValue;

    const apiUrl = `lojaData/GetCidades?servicos=${idServico}&uf=${uf}`;
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //setDados(data.items)
        setCidadeData({ isLoading: false, items: data.items });
        console.log(data);
      });
  }

  function setSelectedCidade(event: any) {
    // this.setState({ value: event.target.value });
    console.log("selecioneiCidade");
    loadBairroData(event.target.value);

    //        setCidadeData({ isLoading: cidadeData?.isLoading, items: cidadeData?.items, selectedItem: event.target.value });
  }
  //CIDADE

  //BAIRRO
  async function loadBairroData(cidade: string) {
    setBairroData({ isLoading: true, items: [] });
    const idServico = 7; //appState.servicos.selectedValue;
    const uf = ufData?.selectedItem;
    //const cidade = "FORTALEZA";//appState.cidades.selectedValue;
    const apiUrl = `lojaData/GetBairros?servicos=${idServico}&uf=${uf}&cidade=${cidade}`;

    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //setDados(data.items)
        setBairroData({ isLoading: false, items: data.items });
        console.log(data);
      });
  }
  //Bairro

  //LOJA
  async function loadLojaData() {
    setLojaData({ isLoading: true, items: [] });

    const uf = ufData?.selectedItem;
    const cidade = cidadeData?.selectedItem;
    const bairro = bairroData?.selectedItem;

    //variáveis
    const apiUrl = `lojaData/GetLojas?servicos=7&uf=${uf}&cidade=${cidade}&bairro=${bairro}`;
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //setDados(data.items)
        setLojaData({ isLoading: false, items: data.items });
        console.log(data);
      });
  }

  function btnLoadLojaData() {
    console.log("btnLoadLojaData");
    loadLojaData();
  }
  //LOJA

  return (
    <>
      <HeaderTitle
        title="Agendamento online"
        boxRedTitle="Clinic Farma"
        helperText=""
      />

      <button onClick={btnRedirectLogin}>Redirect to Login</button>
      <br />
      <br />
      <br />
      <button onClick={btnLoadServicoData}>Carregar Serviços</button>
      {servicoData?.isLoading && <h5>Carregando ...</h5>}
      {servicoData?.items.map((item: any) => (
        <p key={item.id}>{item.nome}</p>
      ))}

      <button onClick={btnLoadUfData}>Carregar UF</button>
      {ufData?.isLoading && <h5>Carregando ...</h5>}
      {
        <select onChange={setSelectedUf}>
          <option></option>
          {ufData?.items.map((item: any) => (
            <option key={item}>{item}</option>
          ))}
        </select>
      }

      {cidadeData?.isLoading && <h5>Carregando ...</h5>}
      {
        <select onChange={setSelectedCidade}>
          <option></option>
          {cidadeData?.items.map((item: any) => (
            <option key={item}>{item}</option>
          ))}
        </select>
      }

      {bairroData?.isLoading && <h5>Carregando ...</h5>}
      {bairroData?.items.map((item: any) => (
        <p key={item}>{item}</p>
      ))}

      <button onClick={btnLoadLojaData}>Carregar Loja</button>
      {lojaData?.isLoading && <h5>Carregando ...</h5>}
      {lojaData?.items.map((item: any) => (
        <p key={item.codigo}>{item.nome}</p>
      ))}
    </>
  );
}

export default App;
