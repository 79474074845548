import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAgendamento } from '../../../../contexts/agendamento';

import * as S from './styles';

interface PacienteProps {
  onClick: () => void;
}

export function Paciente({ onClick }: PacienteProps) {
  const { completedSteps, cliente, getStepIconMobile } = useAgendamento();

  const [disabled, setDisabled] = useState('');

  useEffect(() => {
    if (!completedSteps.includes('agenda')) {
      setDisabled('disabled');
    } else {
      setDisabled('');
    }
  }, [completedSteps]);
  
  return (
    <S.Container>
      <div className='card'>
        <div className={`info ${disabled}`}>
          {getStepIconMobile('paciente')}
          <div className="detail">
            <Link to={!disabled ? '/hubdesaude/paciente' : '#'} onClick={onClick}>Paciente</Link>

            {disabled && <span className="text-disabled">-</span>}
            
            {!disabled && <span>{cliente?.nome?.trim().length ? cliente.nome : cliente.email}</span>}
          </div>
        </div>

      </div>

      <div className="separador"></div>
  </S.Container>
  )
}