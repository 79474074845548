import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1039px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #0054A6;
    font-size: 32px;
    font-weight: 500;
    align-self: flex-start;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px;
  position: relative;

  input {
    width: 307px;
  }

  > form {
    position: absolute;
    right: 186px;
  }

  @media (max-width: 1024px) {
    padding: 0 11px;

    h1 {
      order: 1;
      font-size: 22px;
      align-self: center;
      margin-bottom: 0;
      position: absolute;
      margin-top: -55px;
    }

    button {
      width: fit-content;
      order: 2;
    }

    form {
      order: 3;
      width: 100%;
      margin-top: 16px;
      position: initial;
      right: 0;
    }
  }

  @media (max-width: 350px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const AppointmentsList = styled.div`
  width: 100%;
  border: 1px solid #E3E4E6;
  border-radius: 4px;
  padding: 24px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .no-results {
    font-size: 18px;
    color: #0054A6;
  }

  @media (max-width: 1024px) {
    border: none;
    padding: 24px 11px 0;
    width: 100%;
    margin: 0;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  width: 100%;

  p {
    font-weight: 500;
    font-size: 14px;
    color: #666666;
  }

  @media (max-width: 1024px) {
    p {
      display: none;
    }
  }
`;

export const NewAppointment = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  
  > span {
    font-size: 22px;
    color: #0054A6;
    font-weight: 500;
  }
`;

export const ButtonNewAppointment = styled.a`
  color: #fff;
  border-radius: 6px;
  background: #09B2AB;
  height: 43px;
  width: 165px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover, &:visited, &:link, &:active {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    width: auto;
  }

  @media (max-width: 350px) {
    width: 100%;
  }
`;

export const ButtonSeeMore = styled.button`
  width: 198px;
  height: 43px;
  background: #0054A6;
  border: 0;
  border-radius: 6px;
  color: #fff;
  margin-top: 32px;
`;