import { Form } from "@unform/web";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { setEmitFlags } from "typescript";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { InputSelect } from "../../components/InputSelect";
import { cpfMask, phoneMask, dateMask } from "../../components/mask";
import * as S from "./styles";
import ContinueButton from "../../components/Paciente/ContinueButton";

import ConfButton from "../../components/Exame/ConfButton";

import IconLoading from "../../images/icons/Loading.svg";
import HeaderTitle from "../../components/HeaderTitle";
import moment from "moment";

export default function AdicionarPaciente() {
  const { goBack } = useHistory();
  const history = useHistory();
  const [genero, setGenero] = useState("");

  const [id, setId] = useState(0);
  const [idTitular, setIdTitular] = useState(0);
  const [cpfTitular, setCpfTitular] = useState("");
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [emailTitular, setEmailTitular] = useState("");
  const [codigoClienteVinculado, setCodigoClienteVinculado] = useState("");
  const [primeiroAcesso, setPrimeiroAcesso] = useState(false);
  const [sexo, setSexo] = useState("");
  const [termChecked, setTermChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //CARREGAR RESUMO AGENDAMENTO
    const _titular = localStorage.getItem("_pmTitular");
    if (_titular !== null) {
      setIdTitular(JSON.parse(_titular)?.id);
      setCpfTitular(JSON.parse(_titular)?.cpf);
    }

    let searchParams = new URLSearchParams(history.location.search);
    let _id = searchParams.get("id");

    if (_id !== null) {
      setLoading(true);
      const apiUrl = `pacienteData/${_id}`;
      fetch(apiUrl, { cache: "no-cache" })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.result === "Success") {
            setId(data.paciente.id);
            setNome(data.paciente.nome.toUpperCase());
            setCpf(data.paciente.cpf);
            setTelefone(data.paciente.telefone);
            setEmail(data.paciente.email);
            setDataNascimento(data.paciente.dataNascimento);
            setSexo(data.paciente.sexo);
          }

          setLoading(false);
        });
    }
    let _email = searchParams.get("vtexclientemail");
    if (_email !== null) {
      setEmailTitular(_email);
      setEmail(_email);
    }
    let _codigoClienteVinculado = searchParams.get("codigoClienteVinculado");
    if (_codigoClienteVinculado !== null)
      setCodigoClienteVinculado(_codigoClienteVinculado);
    let _primeiroAcesso = searchParams.get("primeiroAcesso");
    if (_primeiroAcesso) setPrimeiroAcesso(Boolean(_primeiroAcesso));
  }, []);

  function onChangeNome(event: any) {
    setNome(event.target.value.toUpperCase());
    //validateForm();
  }
  function onChangeCpf(event: any) {
    setCpf(cpfMask(event.target.value));
    //validateForm();
  }
  function onChangeDataNascimento(event: any) {
    setDataNascimento(dateMask(event.target.value));
    //validateForm();
  }
  function onChangeTelefone(event: any) {
    setTelefone(phoneMask(event.target.value));
    //validateForm();
  }
  function onChangeEmail(event: any) {
    setEmail(event.target.value);
    //validateForm();
  }

  function validateForm() {
    const validNome = nome?.length > 2;
    const validTelefone = telefone?.length > 10;
    const validCpf = cpf?.length > 13;
    const validEmail = email?.length > 0;
    const validGenero = sexo?.length > 0;
    const validDataNascimento = dataNascimento?.length > 9;

    const cpfIgualTitular = cpfTitular === cpf;

    if (
      termChecked &&
      validNome &&
      validCpf &&
      validGenero &&
      validDataNascimento &&
      validEmail &&
      validTelefone &&
      (!cpfIgualTitular || idTitular)
    )
      return true;
    else return false;
  }

  function toggleTerms(): void {
    setTermChecked(!termChecked);
  }

  function validateFormNovoTitular() {
    const validNome = nome?.length > 2;
    const validTelefone = telefone?.length > 10;
    const validCpf = cpf?.length > 13;
    const validEmail = email?.length > 0;
    const validGenero = sexo?.length > 0;
    const validDataNascimento = dataNascimento?.length > 9;

    if (
      termChecked &&
      validNome &&
      validCpf &&
      validGenero &&
      validDataNascimento &&
      validEmail &&
      validTelefone
    )
      return true;
    else return false;
  }

  function saveForm() {
    if (!validateForm()) return;

    setLoading(true);
    const _data = {
      id: id,
      codigoClienteVinculado: codigoClienteVinculado,
      nome: nome,
      cpf: cpf,
      email: email,
      telefone: telefone,
      dataNascimento: dataNascimento,
      sexo: sexo,
    };
    let url = "";
    if (id !== 0) {
      if (idTitular !== 0 && idTitular === id)
        url = "/PacienteData/Update/" + id;
      else url = "/PacienteData/UpdateDependente/" + id;
    } else {
      url = "/PacienteData/AddDependente/" + idTitular;
    }

    fetch(url, {
      method: id === 0 ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(_data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result === "Success") {
          goBack();
        }

        setLoading(false);
      })
      .catch((error) => {
        alert("Erro chamada API");
        console.error("Error:", error);
      });
  }

  function saveFormNovoTitular() {
    const _data = {
      codigoClienteVinculado: codigoClienteVinculado,
      nome: nome,
      cpf: cpf,
      email: email,
      telefone: telefone,
      dataNascimento: dataNascimento,
      sexo: sexo,
    };

    setLoading(true);
    let url = "/PacienteData/AddTitular";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(_data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "saveFormNovoTitular");
        if (data.result === "Success") {
          goBack();
        }
        setLoading(false);
      })
      .catch((error) => {
        alert("Erro chamada API");
        console.error("Error:", error);
      });
  }

  function loadClienteCPF() {
    const apiUrl = `clienteData/GetCliente/cpf/${cpf}`;

    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        if (data.result !== "Error") {
          setNome(data.clienteData.nome.toUpperCase());
          setCpf(data.clienteData.cpf);
          setTelefone(data.clienteData.telefone);
          if (email === "") setEmail(data.clienteData.email);
          if (data.clienteData?.dataNascimento) {
            data.clienteData.dataNascimento = moment(
              data.clienteData.dataNascimento.replace("Z", "").replace("z", "")
            ).format("DD/MM/YYYY");
            setDataNascimento(data.clienteData.dataNascimento);
          }
          setSexo(data.clienteData.sexo);
        }
      });
  }

  return (
    <>
      <HeaderTitle
        title="Adicionar paciente"
        boxRedTitle="Clinic Farma"
        helperText=""
      />

      <S.Container>
        {id === 0 && idTitular === 0 && (
          <>
            {window.innerWidth > 1023 ? (
              <div
                style={{ boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.04)" }}
                className="containBtn"
              >
                <div>
                  <p
                    style={{
                      fontWeight: 500,
                      color: "#666",
                      textAlign: "left",
                      width: "500px",
                      paddingLeft: "10px",
                      margin: "0",
                    }}
                  >
                    {id === 0 && idTitular === 0 ? (
                      <>Preencha seus dados pessoais.</>
                    ) : (
                      <>
                        Preencha os campos abaixo, para adicionar um paciente.
                      </>
                    )}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="lojaVoltarBtn"
                    onClick={() => history.push("/hubdesaude/agenda")}
                  >
                    Voltar
                  </button>
                  <S.ButtonContinue
                    onClick={() => saveFormNovoTitular()}
                    disabled={!validateFormNovoTitular()}
                  >
                    Continuar
                  </S.ButtonContinue>
                </div>
              </div>
            ) : (
              <>
                <div>
                  <p
                    style={{
                      fontWeight: 500,
                      color: "#666",
                      textAlign: "left",
                      paddingLeft: "12px",
                      margin: "0",
                    }}
                  >
                    {id === 0 && idTitular === 0 ? (
                      <>Preencha seus dados pessoais.</>
                    ) : (
                      <>
                        Preencha os campos abaixo, para adicionar um paciente.
                      </>
                    )}
                  </p>
                </div>
                <div
                  style={{ boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.04)" }}
                  className="containBtn"
                >
                  {/* <div>
                                        <p style={{
                                        fontWeight: 500,
                                        color: '#666',
                                        textAlign:"center",
                                        width: '500px',
                                        paddingLeft:'10px',
                                        margin: '0',
                                    }}>Informe a data e o horário que deseja realizar o procedimento.</p>
                                </div> */}
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="lojaVoltarBtn"
                      onClick={() => history.push("/hubdesaude/agenda")}
                    >
                      Voltar
                    </button>
                    <S.ButtonContinue
                      onClick={() => saveFormNovoTitular()}
                      disabled={!validateFormNovoTitular()}
                    >
                      Continuar
                    </S.ButtonContinue>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {(id !== 0 || idTitular !== 0) && (
          <h2 style={{ fontFamily: '"Roboto", sans-serif', marginBottom: 0 }}>
            {`Preencha os campos abaixo para ${
              id === 0 ? "adicionar um" : "atualizar o"
            } paciente.`}
          </h2>
        )}
        {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={IconLoading}
              alt="loading"
              className="spiner"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            />
          </div>
        )}
        {!loading && (
          <Form onSubmit={(data) => {}} style={{ paddingTop: "16px" }}>
            <S.FormRow>
              <div className="input-wrapper name">
                <Input
                  name="nomeCompleto"
                  isRequired
                  isRequiredText="obrigatório"
                  label="Nome completo"
                  placeholder="Digite aqui"
                  labelStyle={{ fontFamily: '"Source Sans Pro", sans-serif' }}
                  containerStyle={{ height: "40px" }}
                  onChange={onChangeNome}
                  value={nome}
                />
              </div>
            </S.FormRow>

            <S.FormRow>
              <div className="input-wrapper">
                <Input
                  name="cpf"
                  isRequired
                  isRequiredText="obrigatório"
                  label="CPF"
                  placeholder="000.000.000-00"
                  onBlur={loadClienteCPF}
                  labelStyle={{ fontFamily: '"Source Sans Pro", sans-serif' }}
                  containerStyle={{ height: "40px" }}
                  onChange={onChangeCpf}
                  value={cpf}
                  disabled={
                    id === idTitular &&
                    id > 0 &&
                    idTitular > 0 &&
                    !primeiroAcesso
                  }
                />
              </div>

              <div className="input-wrapper">
                <Input
                  name="dataNascimento"
                  isRequired
                  isRequiredText="obrigatório"
                  label="Data de nascimento"
                  placeholder="00/00/0000"
                  labelStyle={{
                    fontFamily: '"Source Sans Pro", sans-serif',
                    minWidth: "190px",
                    marginLeft: window.innerWidth < 360 ? "-12px" : {},
                  }}
                  containerStyle={{ height: "40px" }}
                  onChange={onChangeDataNascimento}
                  value={dataNascimento}
                />
              </div>
            </S.FormRow>

            <S.FormRow>
              <div className="input-wrapper">
                <Input
                  name="telefone"
                  isRequired
                  isRequiredText="obrigatório"
                  label="Telefone"
                  placeholder="(00) 00000-0000"
                  labelStyle={{ fontFamily: '"Source Sans Pro", sans-serif' }}
                  containerStyle={{ height: "40px" }}
                  onChange={onChangeTelefone}
                  value={telefone}
                />
              </div>

              <div className="input-wrapper input-wrapper-half">
                <label
                  htmlFor="genero"
                  style={{ fontFamily: '"Source Sans Pro", sans-serif' }}
                >
                  Gênero
                </label>
                <InputSelect
                  name="genero"
                  placeholder="Selecione"
                  id="genero"
                  newValue={{
                    label:
                      [
                        {
                          value: "M",
                          label: "MASCULINO",
                        },
                        {
                          value: "F",
                          label: "FEMININO",
                        },
                      ].find((item) => item.value === sexo)?.label ||
                      "Selecione",
                    value: sexo ? sexo : "",
                  }}
                  options={[
                    {
                      value: "M",
                      label: "MASCULINO",
                    },
                    {
                      value: "F",
                      label: "FEMININO",
                    },
                  ]}
                  onChange={(event: any) => setSexo(event.value)}
                />
              </div>
            </S.FormRow>

            <S.FormRow>
              <div className="input-wrapper email" style={{ width: "548px" }}>
                <Input
                  name="email"
                  isRequired
                  isRequiredText="obrigatório"
                  label="Email"
                  placeholder="Digite aqui"
                  containerStyle={{ height: "40px" }}
                  labelStyle={{ fontFamily: '"Source Sans Pro", sans-serif' }}
                  onChange={onChangeEmail}
                  value={email}
                  disabled={
                    (id === idTitular && id > 0 && idTitular > 0) ||
                    !!emailTitular
                  }
                />
              </div>
            </S.FormRow>

            <S.Checkbox>
              <input
                type="checkbox"
                id="termService"
                name="termService"
                onChange={toggleTerms}
              />
              <label htmlFor="termService">
                Termos do{" "}
                <a
                  href={
                    process.env.PUBLIC_URL +
                    "/documents/regulamento_terceiro.pdf"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="linkTermos"
                >
                  Serviço do Portal Pague Menos.
                </a>
              </label>
            </S.Checkbox>

            {(id !== 0 || idTitular !== 0) && (
              <S.ButtonContainer>
                <Button inverted width="266px" height="43px" onClick={goBack}>
                  Voltar pacientes
                </Button>
                <Button
                  width="266px"
                  height="43px"
                  style={{ marginLeft: 16 }}
                  onClick={saveForm}
                  disabled={!validateForm()}
                >
                  {id === 0 ? "Adicionar" : "Atualizar"}
                </Button>
              </S.ButtonContainer>
            )}
          </Form>
        )}
      </S.Container>
    </>
  );
}
