import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { debounce } from '../utils';

export type Step = 'exame' | 'loja' | 'agenda' | 'paciente';

interface ExameData {
  id: number;
  nome: string;
  valor: string;
}

interface DateTime {
  data: string;
  horario: string;
  horarioId: number;
}

interface StoreData {
  codigo: number;
  endereco: string;
  numero: number;
  bairro: string;
  cidade: string;
  estado: string;
  telefone01: string;
  telefone02: string;
}

export interface AgendamentoContextData {
  completedSteps: Step[];
  handleCompleteStep: (step: Step) => void;
  quantidadeServicos: number;
  handleUpdateQuantidadeServicos: (novaQuantidade: number) => void;
  store: StoreData;
  handleUpdateStore: (newStore: StoreData) => void;
  dateTime: DateTime;
  handleUpdateDateTime: (newDateTime: DateTime) => void;
  exames: ExameData[],
  handleUpdateExames: (novosExames: ExameData[]) => void;
  aceitouTermos: boolean;
  handleUpdateTermos: (termos: boolean) => void;
  telefone: string;
  handleUpdateTelefone: (telefone: string) => void;
  cliente: any;
  handleUpdateCliente: (cliente: any) => void;
  getStepIcon: (currentStep: Step) => ReactNode;
  getStepIconMobile: (currentStep: Step) => ReactNode;
  clearContext: () => void;
  clearFromExamForward: () => void;
  clearFromStoreForward: () => void;
  clearFromScheduleForward: () => void;
  currentStep: Step;
  handleUpdateCurrentStep: (currentStep: Step) => void;
}

export const AgendamentoContext = createContext<AgendamentoContextData>({} as AgendamentoContextData);

interface AgendamentoProviderProps {
  children: ReactNode;
}

function AgendamentoProvider({ children }: AgendamentoProviderProps) {
  const [completedSteps, setCompletedSteps] = useState<Step[]>([]);
  const [quantidadeServicos, setQuantidadeServicos] = useState(0);
  const [store, setStore] = useState({} as StoreData);
  const [dateTime, setDateTime] = useState({} as DateTime);
  const [nomePaciente, setNomePaciente] = useState('');
  const [exames, setExames] = useState<ExameData[]>([]);
  const [aceitouTermos, setAceitouTermos] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [cliente, setCliente] = useState({});
  const [currentStep, setCurrentStep] = useState<Step>('exame');

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () =>
      debounce(() => {
        setInnerWidth(window.innerWidth);
      }, 500)
    );
  }, []);

  const handleCompleteStep = useCallback((step: Step) => {
    const storagedCompletedSteps = localStorage.getItem('_pmCompletedSteps');
    
    if (storagedCompletedSteps) {
      const parsedSteps = JSON.parse(storagedCompletedSteps);

      if (!parsedSteps.includes(step)) {
        localStorage.setItem('_pmCompletedSteps', JSON.stringify([...parsedSteps, step]));
      }
    } else {
      localStorage.setItem('_pmCompletedSteps', JSON.stringify([step]));
    }
    
    if (!completedSteps.includes(step)) {
      setCompletedSteps(prevState => [...prevState, step]);
    }
  }, [completedSteps]);

  const handleUpdateQuantidadeServicos = useCallback((novaQuantidade: number) => {
    setQuantidadeServicos(novaQuantidade);
  }, []);

  const handleUpdateStore = useCallback((newStore: StoreData) => {
    setStore(newStore);
  }, []);

  const handleUpdateDateTime = useCallback((newDateTime: DateTime) => {
    setDateTime(newDateTime);
  }, []);
  
  const handleUpdateExames = useCallback((novosExames: ExameData[]) => {
    setExames(novosExames);
  }, []);

  const handleUpdateTermos = useCallback((termos: boolean) => {
    setAceitouTermos(termos);
  }, []);

  const handleUpdateTelefone = useCallback((telefone: string) => {
    setTelefone(telefone);
  }, []);

  const handleUpdateCliente = useCallback((cliente: any) => {
    setCliente(cliente);
  }, []);

  const handleUpdateCurrentStep = useCallback((currentStep: Step) => {
    setCurrentStep(currentStep);
  }, []);

  const clearContext = useCallback(() => {
    setCompletedSteps([]);
    setQuantidadeServicos(0);
    setStore({} as StoreData);
    setDateTime({} as DateTime);
    setNomePaciente('');
    setExames([]);
    setAceitouTermos(false);
    setTelefone('');
    setCliente({});
    localStorage.removeItem('_pmCompletedSteps');
    localStorage.removeItem('_subCategorias');
  }, []);


  const clearFromExamForward = useCallback(() => {
    setCompletedSteps([]);
    setStore({} as StoreData);
    setDateTime({} as DateTime);
    setNomePaciente('');
    setAceitouTermos(false);
    setTelefone('');
    setCliente({});
    localStorage.removeItem('_pmCompletedSteps');
  }, []);

  const clearFromStoreForward = useCallback(() => {
    setCompletedSteps(['exame']);
    setDateTime({} as DateTime);
    setNomePaciente('');
    setAceitouTermos(false);
    setTelefone('');
    setCliente({});
    localStorage.setItem('_pmCompletedSteps', JSON.stringify(['exame']));
  }, []);

  const clearFromScheduleForward = useCallback(() => {
    setCompletedSteps(['exame', 'loja']);
    setNomePaciente('');
    setAceitouTermos(false);
    setTelefone('');
    setCliente({});
    localStorage.setItem('_pmCompletedSteps', JSON.stringify(['exame', 'loja']));
  }, []);

  
  function getStepIconMobile(currentStep: Step) {
    if (innerWidth < 1024) {
      return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.00004 1.66667C4.05937 1.66667 1.6667 4.05934 1.6667 7.00001C1.6667 9.94067 4.05937 12.3333 7.00004 12.3333C9.9407 12.3333 12.3334 9.94067 12.3334 7.00001C12.3334 4.05934 9.9407 1.66667 7.00004 1.66667ZM7.00004 13.6667C3.32404 13.6667 0.333374 10.676 0.333374 7.00001C0.333374 3.32401 3.32404 0.333344 7.00004 0.333344C10.676 0.333344 13.6667 3.32401 13.6667 7.00001C13.6667 10.676 10.676 13.6667 7.00004 13.6667Z" fill="#A9A9A9" />
        </svg>
      )
    }

    return getStepIcon(currentStep);
  }

  function getStepIcon(currentStep: Step) {
    switch (currentStep?.toLocaleLowerCase()) {
      case 'exame': {
        return (
          <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.8333 14.8333C13.8333 15.2933 13.4592 15.6667 13 15.6667H3C2.54083 15.6667 2.16667 15.2933 2.16667 14.8333V5.66666C2.16667 5.20666 2.54083 4.83332 3 4.83332V5.66666C3 6.58582 3.7475 7.33332 4.66667 7.33332H11.3333C12.2525 7.33332 13 6.58582 13 5.66666V4.83332C13.4592 4.83332 13.8333 5.20666 13.8333 5.66666V14.8333ZM4.66667 2.33332L11.3333 2.33582V3.16666V5.66666H4.66667V3.16666V2.33332ZM13 3.16666V2.33332C13 1.41416 12.2525 0.666656 11.3333 0.666656H4.66667C3.7475 0.666656 3 1.41416 3 2.33332V3.16666C1.62167 3.16666 0.5 4.28832 0.5 5.66666V14.8333C0.5 16.2117 1.62167 17.3333 3 17.3333H13C14.3783 17.3333 15.5 16.2117 15.5 14.8333V5.66666C15.5 4.28832 14.3783 3.16666 13 3.16666Z" fill="#0054A6" />
          </svg>
        );
      }

      case 'loja': {
        return (
          <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 8.43704C6.72469 8.43704 6.09375 7.8061 6.09375 7.03079C6.09375 6.25548 6.72469 5.62454 7.5 5.62454C8.27531 5.62454 8.90625 6.25548 8.90625 7.03079C8.90625 7.8061 8.27531 8.43704 7.5 8.43704ZM7.5 3.74954C5.69063 3.74954 4.21875 5.22142 4.21875 7.03079C4.21875 8.84017 5.69063 10.312 7.5 10.312C9.30937 10.312 10.7812 8.84017 10.7812 7.03079C10.7812 5.22142 9.30937 3.74954 7.5 3.74954ZM7.5 16.5431C5.92969 15.0581 1.875 10.8891 1.875 7.42688C1.875 4.36594 4.39781 1.875 7.5 1.875C10.6022 1.875 13.125 4.36594 13.125 7.42688C13.125 10.8891 9.07031 15.0581 7.5 16.5431ZM7.5 0C3.36469 0 0 3.33094 0 7.42688C0 12.5597 6.60844 18.2822 6.88969 18.5231C7.06594 18.6741 7.2825 18.75 7.5 18.75C7.7175 18.75 7.93406 18.6741 8.11031 18.5231C8.39156 18.2822 15 12.5597 15 7.42688C15 3.33094 11.6353 0 7.5 0Z" fill="#0054A6" />
          </svg>
        );
      }

      case 'agenda': {
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.8333 13.0288C13.8333 13.4888 13.4592 13.8621 13 13.8621H3C2.54083 13.8621 2.16667 13.4888 2.16667 13.0288V7.19548H13.8333V13.0288ZM4.66667 3.02881C5.12667 3.02881 5.5 3.40131 5.5 3.86214C5.5 4.32298 5.12667 4.69548 4.66667 4.69548C4.20667 4.69548 3.83333 4.32298 3.83333 3.86214C3.83333 3.40131 4.20667 3.02881 4.66667 3.02881ZM8 3.02881C8.46 3.02881 8.83333 3.40131 8.83333 3.86214C8.83333 4.32298 8.46 4.69548 8 4.69548C7.54 4.69548 7.16667 4.32298 7.16667 3.86214C7.16667 3.40131 7.54 3.02881 8 3.02881ZM13 0.528809H3C1.62167 0.528809 0.5 1.65048 0.5 3.02881V5.52881V7.19548V13.0288C0.5 14.4071 1.62167 15.5288 3 15.5288H13C14.3783 15.5288 15.5 14.4071 15.5 13.0288V7.19548V5.52881V3.02881C15.5 1.65048 14.3783 0.528809 13 0.528809Z" fill="#0054A6" />
          </svg>
        );
      }

      case 'paciente': {
        return (
          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.66675 3.83333C7.66675 2.91417 6.91925 2.16667 6.00008 2.16667C5.08091 2.16667 4.33341 2.91417 4.33341 3.83333C4.33341 4.7525 5.08091 5.5 6.00008 5.5C6.91925 5.5 7.66675 4.7525 7.66675 3.83333ZM9.33342 3.83333C9.33342 5.67167 7.83842 7.16667 6.00008 7.16667C4.16175 7.16667 2.66675 5.67167 2.66675 3.83333C2.66675 1.995 4.16175 0.5 6.00008 0.5C7.83842 0.5 9.33342 1.995 9.33342 3.83333ZM0.166748 14.6667C0.166748 11.45 2.78425 8.83333 6.00008 8.83333C9.21592 8.83333 11.8334 11.45 11.8334 14.6667C11.8334 15.1267 11.4609 15.5 11.0001 15.5C10.5392 15.5 10.1667 15.1267 10.1667 14.6667C10.1667 12.3692 8.29758 10.5 6.00008 10.5C3.70258 10.5 1.83341 12.3692 1.83341 14.6667C1.83341 15.1267 1.46091 15.5 1.00008 15.5C0.539248 15.5 0.166748 15.1267 0.166748 14.6667Z" fill="#0054A6" />
          </svg>
        );
      }

      default: {
        return <></>;
      }
    }
  }

  return (
    <AgendamentoContext.Provider value={{
      completedSteps,
      handleCompleteStep,
      quantidadeServicos,
      handleUpdateQuantidadeServicos,
      store,
      handleUpdateStore,
      dateTime,
      handleUpdateDateTime,
      exames,
      handleUpdateExames,
      aceitouTermos,
      handleUpdateTermos,
      telefone,
      handleUpdateTelefone,
      cliente,
      handleUpdateCliente,
      getStepIcon,
      getStepIconMobile,
      clearContext,
      clearFromExamForward,
      clearFromStoreForward,
      clearFromScheduleForward,
      currentStep,
      handleUpdateCurrentStep,

    }}>
      {children}
    </AgendamentoContext.Provider>
  )
}

function useAgendamento() {
  const context = useContext(AgendamentoContext);

  return context;
}

export { AgendamentoProvider, useAgendamento };