import { Component } from "react";
import { ButtonDefault } from "./styles";
import { withRouter } from "react-router";
import IconCheck from "../../../images/icons/icon-check-exame.svg";

class Button extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      disabled: false,
    };
  }

  componentDidMount() {
    const search = this.props.location.search;
    let cat = null;

    if (!search || search === "") {
      localStorage.removeItem("_ApmServicos");
      this.props.history.push("/exame");
    } else {
      cat = search.split("=")[1].split("|")[0];
    }

    let arrayServicos: any[] = [];
    let sServicos = localStorage.getItem("_ApmServicos");

    if (sServicos !== null) arrayServicos = JSON.parse(sServicos);

    if (cat && parseInt(cat) === 1 && arrayServicos.length >= 1) {
      this.setState({
        disabled: !this.state.disabled,
      });
    }
  }

  render() {
    return (
      <>
        {!this.props.servicoAdicionado ? (
          <ButtonDefault
            onClick={this.props.onSelectCard}
            disabled={this.state.disabled}
          >
            <>Agendar serviço</>
          </ButtonDefault>
        ) : (
          <ButtonDefault
            onClick={this.props.onSelectCard}
            style={{ backgroundColor: "#09B2AB" }}
          >
            <>
              <span>
                <img src={IconCheck} alt="Check" />
                Serviço adicionado
              </span>
            </>
          </ButtonDefault>
        )}
      </>
    );
  }
}

export default withRouter(Button);
