import styled from 'styled-components';
import colors from '../../tokens/colors';


export const Title = styled.div`
  display: flex;
  justify-content: center;

  h2 {
    justify-content: center;
    color: ${colors.blue};
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  p {
    color: ${colors.lightGray};
    font-size: 14px;
    width: 98%;
    margin-left: 2%;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;

  p {
    margin: 0 40px;
    font-weight: 500;
    line-height: 20px;
    color: #666;
    margin-bottom: 0;
    width: 417px;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;