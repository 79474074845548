import React, { useRef, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { FiCheckCircle, FiAlertTriangle, FiXCircle } from 'react-icons/fi';

import { CloseModal, Container, Form } from './styles';
import Modal from '../Modal';
import icon from '../../../images/icons/icon-access-denied.svg';

interface IModalProps {
    isOpen: boolean;
    setIsOpen: () => void;
    styles?: object
}

const icons = {
    info: <FiAlertTriangle size={44} />,
    success: <FiCheckCircle size={44} />,
};

const ModalErrorEndereco: React.FC<IModalProps> = ({
    isOpen,
    setIsOpen,
    styles
}) => {
    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(
        async () => {
            setIsOpen();
        },
        [setIsOpen],
    );

    const handleClose = useCallback(
        async () => {
            setIsOpen();
        },
        [setIsOpen],
    );

    return (
        <Modal isOpen={isOpen} setIsOpen={handleSubmit} styles={styles}>
            <Container style={{}} showCancel={false} buttonsMarginTop={'32px'}>
                <CloseModal onClick={() => handleClose()}>
                    <FiXCircle size={24} />
                </CloseModal>

                <img src={icon} alt={"Imagem Error"} />
                <h2>Erro!</h2>
                <p>Não é possível cadastrar o endereço.</p>
                <p>Efetue o pagamento em loja.</p>
                <div>
                    <button onClick={handleClose}>Voltar</button>
                </div>
            </Container>
        </Modal>
    );
};

export default ModalErrorEndereco;