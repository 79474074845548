import styled from 'styled-components';

export const Container = styled.div`
    font-family: 'Roboto', sans-serif;

    .hide-category {
        display: none;
    }

    .box-category {
        width: 100%;
        height: 293px;
        border: 1px solid #DADEDC;
        border-radius: 6px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;

        @media (max-width: 1024px) {
            height: 256px;
        }

        strong {

            @media (max-width: 1024px) {
                font-size: 16px;
            }
        }

        p {
            width: 80%;
            text-align: center;
        
            @media (max-width: 1024px) {
                font-size: 14px;
            }
        }


        img{
            width:79px;
            height:81px;

            @media (max-width: 1024px) {
                width: 75px;
                height: 75px;
            } 
        }

        button { 
            width: 40%;
            height: 43px;
            border: 0;
            border-radius: 4px;
            color: #fff;
            background-color: #0054A6;

            &:hover {
                filter: brightness(0.8);
            }

            @media (max-width: 1024px) {
                width: 231;
            }
        }
    }
`;