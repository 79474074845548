import { Component } from "react";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, Container, Row, Col } from "react-bootstrap";
import Calendar from "react-calendar";
import LoginModal from "../components/LoginModal/loginModal";
import { Navigation } from "../components/Navigation";
import {
  AgendamentoContextData,
  AgendamentoContext,
} from "../contexts/agendamento";

import "react-calendar/dist/Calendar.css";
import "../styles/agenda/base_style.css";

import IconLoading from "../images/icons/Loading.svg";
import HeaderTitle from "../components/HeaderTitle";
import { capitalize } from "../utils/utils";
import handleCompleteStep from "../components/CompleteStep";

class Agenda extends Component<any, any> {
  static contextType = AgendamentoContext;

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      loadCount: 1,
      errorMessage: "",
      isHorarioLoading: false,
      clienteLogado: false,
      date: new Date(),

      horarioSelected: false,
      horarioValor: "",

      dateSelected: false,
      dataValor: "",

      datasDisponiveis: null,
      dataHorariosManha: [],
      dataHorariosTarde: [],
      active: false,
      activeId: null,
      key: 1,

      MaxDate: null,
      disabledDates: [new Date(), new Date()],
      agendamentoAssistido: false,
      innerWidth: 0,

      headerFromMobile: false,

      loginModalOpen: false,
    };

    this.openManha = this.openManha.bind(this);
    this.openTarde = this.openTarde.bind(this);
    this.setActiveElement = this.setActiveElement.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.getDisables = this.getDisables.bind(this);
    this.voltarBtn = this.voltarBtn.bind(this);
    this.continuarBtn = this.continuarBtn.bind(this);
    this.handleLoginModal = this.handleLoginModal.bind(this);
  }

  getDias(endDate: Date) {
    var date = new Date();
    endDate.setDate(endDate.getDate() + 1);

    var days: string[] = [];
    while (date <= endDate) {
      let dia = date.getDate().toString();
      dia = dia.length === 1 ? "0" + dia : dia;

      const mes = date.getMonth() + 1;
      let mesStr = mes < 10 ? "0" + mes : mes;
      dia = date.getFullYear() + "-" + mesStr + "-" + dia;

      days.push(dia);
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  //REQUEST DATAS DISPONIVEIS
  componentDidMount() {
    const agendamentoContext = this.context as AgendamentoContextData;
    agendamentoContext.handleUpdateCurrentStep("agenda");

    //GUARDAR STEP
    let pathname = this.props.location.pathname;
    let path = pathname.substring(pathname.lastIndexOf("/"));
    localStorage.setItem("_pmLastStep", path);
    //GUARDAR STEP

    this.setState({ innerWidth: window.innerWidth });
    window.addEventListener("resize", (event) => {
      this.resizeScreen();
    });

    const sServicos = localStorage.getItem("_ApmServicos");
    let arrayServicos: any[] = [];
    if (sServicos !== null) arrayServicos = JSON.parse(sServicos);

    const servicos = arrayServicos
      .map(function (elem) {
        return elem.id;
      })
      .join(",");
    if (servicos === "") {
      this.props.history.push("/hubdesaude/exame");
      return;
    }

    let codigoLoja = localStorage.getItem("_pmLoja");
    if (codigoLoja === null) {
      this.props.history.push("/hubdesaude/loja");
      return;
    }

    let loja = JSON.parse(codigoLoja);
    if (loja.codigo === undefined) {
      this.props.history.push("/hubdesaude/loja");
      return;
    }

    let vtexclientemail = localStorage.getItem("_pmVtexclientemail");
    if (vtexclientemail !== null) this.setState({ clienteLogado: true });

    this.loadDias(servicos, loja.codigo, loja.partner);

    const _agendamentoAssistido = localStorage.getItem(
      "_pmAgendamentoAssistido"
    );
    if (_agendamentoAssistido !== null) {
      this.setState({ agendamentoAssistido: true });
      window.CustomScrollToTopAssistido();
    } else window.CustomScrollToTop();

    const dataStorage = JSON.parse(
      localStorage.getItem("_pmDataSelecionada") as string
    );
    if (dataStorage) {
      const splitDataStorage = dataStorage.data.split("/");
      this.onChange(
        new Date(
          Number(splitDataStorage[2]),
          Number(splitDataStorage[1]) - 1,
          Number(splitDataStorage[0])
        )
      );
      if (dataStorage.horario) {
        this.setState({ activeId: dataStorage.horarioId });
        this.setState({ horarioSelected: true });
        this.setState({ horarioValor: dataStorage.horario });
      }
    }

    this.verifyScreenAndUser();
  }

  resizeScreen() {
    if (window.innerWidth <= 360) this.setState({ innerWidth: 360 });
    else if (window.innerWidth < 420) this.setState({ innerWidth: 420 });
    else this.setState({ innerWidth: window.innerWidth });
  }

  loadDias(servicos: string, codigoLoja: string, partner: boolean) {
    console.log(`Buscando Dias ${this.state.loadCount}x`);
    this.setState({ isLoading: true });
    const apiUrl = `agendaData/DisponiveisMes?servicos=${servicos}&codigoLoja=${codigoLoja}&parceiro=${partner}`;
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          if (data.code === "001" && this.state.loadCount < 3) {
            this.setState({
              loadCount: this.state.loadCount + 1,
              errorMessage: data.message,
            });
            this.loadDias(servicos, codigoLoja, partner);
          }
        } else {
          let maxDate = data.items[data.items.length - 1];
          let dias = this.getDias(new Date(maxDate + "T10:00"));

          let indisponiveis: Date[] = [];
          dias.forEach((d) => {
            const disponivel =
              data.items.filter((row: any) => row === d).length > 0;

            if (!disponivel) {
              indisponiveis.push(new Date(d + "T10:00")); //workarround UTC
            }
          });

          this.setState({ datasDisponiveis: data.items });
          this.setState({ MaxDate: new Date(maxDate + "T10:00") });
          this.setState({ disabledDates: indisponiveis });
        }

        this.setState({ isLoading: false });
      });
  }

  onChange = (date: any) => {
    this.setState({ activeId: null });
    this.setState({ horarioSelected: false });
    this.setState({ horarioValor: "" });
    this.setState({ selectedDate: date });

    let dia = date.getDate();
    let diaStr = dia < 10 ? "0" + dia : dia;
    let mes = date.getMonth() + 1;
    let mesStr = mes < 10 ? "0" + mes : mes;
    let dataFormatada = diaStr + "/" + mesStr + "/" + date.getFullYear();
    this.setState({ dateSelected: true });
    this.setState({ dataValor: dataFormatada });

    const dataStorage = JSON.parse(
      localStorage.getItem("_pmDataSelecionada") as string
    );
    localStorage.setItem(
      "_pmDataSelecionada",
      JSON.stringify({ ...dataStorage, data: dataFormatada })
    );

    const sServicos = localStorage.getItem("_ApmServicos");
    let arrayServicos: any[] = [];
    if (sServicos !== null) arrayServicos = JSON.parse(sServicos);

    const servicos = arrayServicos
      .map(function (elem) {
        return elem.id;
      })
      .join(",");
    if (servicos === "") return;

    let codigoLoja = localStorage.getItem("_pmLoja");
    if (codigoLoja === null) return;

    let loja = JSON.parse(codigoLoja);
    if (loja.codigo === undefined) return;

    this.setState({ loadCount: 1, errorMessage: "" });
    this.loadHorarios(servicos, loja.codigo, dataFormatada, loja.partner);
  };

  loadHorarios(
    servicos: string,
    codigoLoja: string,
    data: string,
    partner: boolean
  ) {
    console.log(`Buscando Horarios ${this.state.loadCount}x`);
    this.setState({
      isHorarioLoading: true,
      dataHorariosManha: [],
      dataHorariosTarde: [],
    });
    const apiUrl = `agendaData/DisponiveisDia?servicos=${servicos}&codigoLoja=${codigoLoja}&data=${data}&parceiro=${partner}`;
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          if (data.code === "001" && this.state.loadCount < 3) {
            this.setState({
              loadCount: this.state.loadCount + 1,
              errorMessage: data.message,
            });
            this.loadHorarios(servicos, codigoLoja, data, partner);
          }
        } else {
          this.setState({ dataHorariosManha: data.manha });
          this.setState({ dataHorariosTarde: data.tarde });
        }

        this.setState({ isHorarioLoading: false });
      });
  }

  setActiveElement(id: number, horarioParceiro: any) {
    const agendamentoContext = this.context as AgendamentoContextData;

    const emailStorage = localStorage.getItem("_pmVtexclientemail");
    if (emailStorage) {
      agendamentoContext.clearFromScheduleForward();
      //localStorage.removeItem('_pmVtexclientemail');
    }

    this.setState({ activeId: id });
    this.setState({ horarioValor: horarioParceiro.item1 });

    //GUARDAR DATA SELECIONADA
    const dataSelecionada = {
      data: this.state.dataValor,
      horario: horarioParceiro.item1,
      horarioId: id,
      parceiroLojaSalaId: horarioParceiro.item3,
    };

    localStorage.setItem("_pmDataSelecionada", JSON.stringify(dataSelecionada));

    agendamentoContext.handleUpdateDateTime(dataSelecionada);
    handleCompleteStep(false, "agenda", agendamentoContext);

    if (this.state.agendamentoAssistido || this.state.clienteLogado)
      this.props.history.push("/hubdesaude/paciente");
    else
      this.setState({ horarioSelected: true }, () => this.handleLoginModal());
  }

  handleSelect(key: any) {
    this.setState({ key });
  }

  verifyScreenAndUser() {
    if (window.innerWidth > 1024) {
      if (this.state.agendamentoAssistido || this.state.clienteLogado) {
        this.setState({
          headerFromMobile: true,
        });
      }
    }
  }

  handleSend() {
    alert("Agendado para - " + this.state.horarioValor);
  }

  getDisables({ date, view }: { date: any; view: string }) {
    return (
      view === "month" && // Block day tiles only
      this.state.disabledDates.some(
        (disabledDate: Date) =>
          date.getFullYear() === disabledDate.getFullYear() &&
          date.getMonth() === disabledDate.getMonth() &&
          date.getDate() === disabledDate.getDate()
      )
    );
  }

  voltarBtn() {
    return "/hubdesaude/loja";
  }

  continuarBtn() {
    const agendamentoContext = this.context as AgendamentoContextData;
    agendamentoContext.handleCompleteStep("agenda");

    return "/hubdesaude/paciente";
  }

  openManha() {
    this.setState({ key: 1 });
  }

  openTarde() {
    this.setState({ key: 2 });
  }

  handleLoginModal() {
    if (this.state.horarioSelected)
      this.setState({ loginModalOpen: !this.state.loginModalOpen });
  }

  render() {
    return (
      <>
        <HeaderTitle
          title="Compre e agende serviços e vacinas online"
          boxRedTitle="Clinic Farma"
          helperText="Escolha a data e hora para a realização do(s) serviço(s) selecionado(s)"
        />

        <Navigation
          title=""
          urlBack={this.voltarBtn()}
          urlContinue={
            this.state.agendamentoAssistido || this.state.clienteLogado
              ? this.continuarBtn()
              : ""
          }
          stepName="agenda"
          isDisabled={!this.state.horarioSelected}
          handleLoginModal={() => this.handleLoginModal()}
        />

        {this.state.isLoading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={IconLoading}
              alt="loading"
              className="spiner"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            />
          </div>
        ) : (
          <Container>
            {!this.state.isLoading && this.state.loadCount === 3 && (
              <div className="row">
                <div className="w-100">
                  <p>{this.state.errorMessage}</p>
                </div>
              </div>
            )}

            <Row>
              <Col lg={12} xs={12} md={12} sm={12}>
                <div className="Agenda">
                  {this.state.isLoading && (
                    <div className="row justifyCenter">
                      <img
                        src={IconLoading}
                        alt="loading"
                        className="spiner"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "100px",
                        }}
                      />
                    </div>
                  )}

                  {!this.state.isLoading &&
                    this.state.datasDisponiveis?.length > 0 && (
                      <>
                        <div className="calendar-cointainer">
                          <Calendar
                            locale="pt-br"
                            onClickDay={this.onChange}
                            prev2Label={null}
                            next2Label={null}
                            prevLabel={
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.2031 4.8251L2.23133 4.8251L4.67225 1.8821C4.91009 1.5959 4.87113 1.16997 4.58625 0.931699C4.3007 0.692749 3.87742 0.731898 3.63958 1.0181L0.280203 5.0681C0.254 5.09982 0.241235 5.13695 0.22175 5.17205C0.205625 5.2004 0.186141 5.2247 0.174047 5.25575C0.143812 5.33337 0.125672 5.41505 0.125672 5.4974C0.125672 5.49807 0.125 5.49942 0.125 5.5001C0.125 5.50077 0.125672 5.50212 0.125672 5.5028C0.125672 5.58515 0.143812 5.66682 0.174047 5.74445C0.186141 5.7755 0.205625 5.7998 0.22175 5.82815C0.241235 5.86325 0.254 5.90037 0.280203 5.9321L3.63958 9.9821C3.77328 10.1421 3.96409 10.2251 4.15625 10.2251C4.30809 10.2251 4.46061 10.1738 4.58625 10.0685C4.87113 9.83022 4.91009 9.4043 4.67225 9.1181L2.23133 6.1751L10.2031 6.1751C10.574 6.1751 10.875 5.8727 10.875 5.5001C10.875 5.1275 10.574 4.8251 10.2031 4.8251Z"
                                  fill="#E3E4E6"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.796875 6.1749H8.76867L6.32775 9.1179C6.08991 9.4041 6.12887 9.83003 6.41375 10.0683C6.6993 10.3073 7.12258 10.2681 7.36042 9.9819L10.7198 5.9319C10.746 5.90018 10.7588 5.86305 10.7782 5.82795C10.7944 5.7996 10.8139 5.7753 10.826 5.74425C10.8562 5.66663 10.8743 5.58495 10.8743 5.5026C10.8743 5.50193 10.875 5.50058 10.875 5.4999C10.875 5.49923 10.8743 5.49788 10.8743 5.4972C10.8743 5.41485 10.8562 5.33318 10.826 5.25555C10.8139 5.2245 10.7944 5.2002 10.7782 5.17185C10.7588 5.13675 10.746 5.09963 10.7198 5.0679L7.36042 1.0179C7.22672 0.857927 7.03591 0.774902 6.84375 0.774902C6.69191 0.774902 6.53939 0.826202 6.41375 0.931502C6.12887 1.16978 6.08991 1.5957 6.32775 1.8819L8.76867 4.8249H0.796875C0.426 4.8249 0.125 5.1273 0.125 5.4999C0.125 5.8725 0.426 6.1749 0.796875 6.1749Z"
                                  fill="#0054A6"
                                />
                              </svg>
                            }
                            value={this.state.selectedDate}
                            minDate={new Date()}
                            maxDate={this.state.MaxDate}
                            tileDisabled={this.getDisables}
                            showNeighboringMonth={false}
                            formatMonthYear={(locale: any, date: any) => {
                              return capitalize(
                                new Intl.DateTimeFormat(locale, {
                                  year: "numeric",
                                  month: "long",
                                }).format(date)
                              );
                            }}
                          />
                        </div>

                        {!this.state.dateSelected && (
                          <div className="divAgendaContinuar">
                            Selecione o dia para Continuar
                          </div>
                        )}
                      </>
                    )}

                  {!this.state.isLoading &&
                    this.state.datasDisponiveis?.length === 0 && (
                      <div className="agendaNaoEncontrada">
                        <h5>Atenção</h5>
                        <p>Atenção: nenhuma data disponível.</p>
                        <p> Por favor selecione outra loja.</p>
                        {/* <div className="col-12 d-flex align-items-center justify-content-center">
                                            <button className="voltarBtn" onClick={() => this.voltarBtn()} >Voltar as lojas</button>
                                        </div> */}
                      </div>
                    )}

                  {this.state.dateSelected && (
                    <div className="horarios">
                      <span className="principalTXT">Horários disponíveis</span>
                      <div className="tab-wrapper">
                        <Tabs
                          activeKey={this.state.key}
                          onSelect={this.handleSelect}
                        >
                          <Tab eventKey="1" title="Manhã">
                            <div className="tab-item-wrapper">
                              <div className="gridBtns">
                                {this.state.isHorarioLoading && (
                                  <div className="row justifyCenter">
                                    <img
                                      src={IconLoading}
                                      alt="loading"
                                      className="spiner"
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    />
                                  </div>
                                )}

                                {!this.state.isHorarioLoading &&
                                  this.state.dataHorariosManha?.map(
                                    (item: any, index: number) => (
                                      <button
                                        key={index}
                                        type="button"
                                        className={
                                          index === this.state.activeId
                                            ? "activeButton"
                                            : ""
                                        }
                                        onClick={() =>
                                          this.setActiveElement(index, item)
                                        }
                                      >
                                        {item.item1}
                                      </button>
                                    )
                                  )}
                              </div>

                              {!this.state.isHorarioLoading &&
                                this.state.dataHorariosManha?.length === 0 && (
                                  <div className="empty-data">
                                    <span>
                                      No momento não temos horários disponíveis
                                      no período da manhã
                                    </span>
                                    <button
                                      type="button"
                                      className="verHorariosBtn"
                                      onClick={this.openTarde}
                                    >
                                      Ver horários da tarde
                                    </button>
                                  </div>
                                )}
                            </div>
                          </Tab>

                          <Tab eventKey="2" title="Tarde">
                            <div className="tab-item-wrapper">
                              <div className="gridBtns">
                                {this.state.isHorarioLoading && (
                                  <div className="row justifyCenter">
                                    <img
                                      src={IconLoading}
                                      alt="loading"
                                      className="spiner"
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    />
                                  </div>
                                )}

                                {!this.state.isHorarioLoading &&
                                  this.state.dataHorariosTarde?.map(
                                    (item: any, index: number) => (
                                      <button
                                        key={index}
                                        type="button"
                                        className={
                                          index === this.state.activeId
                                            ? "activeButton"
                                            : ""
                                        }
                                        onClick={() =>
                                          this.setActiveElement(index, item)
                                        }
                                      >
                                        {item.item1}
                                      </button>
                                    )
                                  )}
                              </div>

                              {!this.state.isHorarioLoading &&
                                this.state.dataHorariosTarde?.length === 0 && (
                                  <div>
                                    <span>
                                      No momento não temos horários disponíveis
                                      no período da tarde
                                    </span>
                                    <button
                                      type="button"
                                      className="verHorariosBtn"
                                      onClick={this.openManha}
                                    >
                                      Ver horários da manhã
                                    </button>
                                  </div>
                                )}
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        )}

        <LoginModal
          handleOpen={this.handleLoginModal}
          open={this.state.loginModalOpen}
          history={this.props.history}
        />
      </>
    );
  }
}
export default withRouter(Agenda);
