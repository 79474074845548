import styled from 'styled-components';
import { FiXCircle } from 'react-icons/fi';

export const Container = styled.div`
  width: 734px;
  height: 700px;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border: 1px solid #979899;
  border-radius: 4px;
  padding: 30px 40px;
  line-height: 1;
  position: relative;
  

  h3 {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    color: #515151;
    margin: 0 0 20px;

    @media(max-width: 1024px) {
      font-size: 16px;
      margin: 0 0 15px;
    }
  }

  @media(max-width: 1024px) {
    width: 100%;
    height: auto;
    position: absolute;
    top: 10px;
    padding: 37px 19px 16px;
    border: none;
    z-index: 2;
  }
`;

export const CloseImg = styled(FiXCircle)`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  @media(max-width: 1024px) {
    top: 12px;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  > h2 {
    font-size: 24px;
    color: #383838;
    font-weight: 500;
    margin: 0 0 5px;
  }

  > aside {
    > p {
      text-align: center;
      max-width: 408px;
      font-size: 14px;
      color: #666;
      margin-bottom: 24px;

      @media(max-width: 1024px) {
        margin-bottom: 8px;
        padding: 0 11px;
      }
    }

    @media(max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;

export const Beneficiary = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

  > label {
    color: #0054A6;
    font-weight: 500;
    font-size: 14px;
    margin: 0 10px 0 0;
  }

  > span {
    color: #666;
    font-size: 14px;
  }

  @media(max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 11px 0 16px;

    > label {
      margin: 0 0 8px;
    }
  }
`;

export const Main = styled.main`
  margin-bottom: 30px;

  > div {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 17px 0;
    padding-left: 14px;
    max-height: 250px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #666;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
      background-color: #fff;
    }

    @media(max-width: 1024px) {
      grid-template-columns: 100%;
      grid-gap: 9px 0;
      padding: 0;
      margin: 0 -9px 70px;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      padding: 16px 3px 0 8px;
      /*max-height: initial;*/
      overflow: scroll;
    }
  }
`;

export const ServiceTitle = styled.nav`
  display: none;

  > h4 {
    font-size: 14px;
    color: #0054A6;
    font-weight: 500;
    margin: 0 0 0 8px;
  }

  @media(max-width: 1024px) {
    display: block;
  }
`;

export const Divider = styled.hr`
  margin: 16px 0 -9px;
  border-top: 1px solid #C4C4C4;
`;

export const BeneficiaryCard = styled.article`
  width: calc(100% - 17px);
  height: 100px;
  background-color: #fff;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  > span {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
  }

  @media(max-width: 1024px) {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    padding: 16px 7px 23px;
    width: 100%;
  }
`;

export const BeneficiaryInfo = styled.aside`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > label {
      color: #0054A6;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
    }

    > span {
      font-size: 14px;
      color: #666;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(max-width: 1024px) {
    /*position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px 0;
    margin-left: -19px;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);*/
  }
`;

export const Sponsors = styled.div`
  margin-bottom: 30px;
  width: 100%;

  > h3 {
    margin-bottom: 15px;
  }

  > span {
    font-size: 14px;
    color: #666666;
    margin: -5px 0 14px;
  }

  @media(max-width: 1024px) {
    /*display: none;*/
  }
`;

export const CloseButton = styled.button`
  width: 180px;
  height: 43px;
  background: #0054A6;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #fff;
`;
