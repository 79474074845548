import Button from '../../components/Button';
import { GridBeneficios } from '../../components/GridBeneficios';
import { useRemoveSteps } from '../../hooks/useRemoveSteps';
import * as S from './styles';
import iconHistoric from '../../images/icons/icon-historic.svg';
import arrowLeft from '../../images/icons/arrow-left.svg';
import { useHistory } from 'react-router-dom';

export function Beneficios() {
  useRemoveSteps();

  const { push, goBack } = useHistory();

  function handleGoToHistoric() {
    const email = localStorage.getItem('_pmVtexclientemail');
    push(`/hubdesaude/beneficios/historico?vtexclientemail=${email}`);

    if (window.innerWidth < 1024) {
      window.CustomScrollToTop();
    }
  }

  return (
    <>
      <S.Container>
        <S.Header>
          <span className="go-back">
            <img src={arrowLeft} alt="Go back" />
            <a href="#" onClick={goBack}>VOLTAR</a>
          </span>

          <div>
            <h2>Benefícios</h2>
            <strong>Seus benefícios</strong>
          </div>

          <Button icon={iconHistoric} onClick={handleGoToHistoric}>Histórico de consumo</Button>
        </S.Header>
        <S.Content>
          <GridBeneficios />
          <Button width="100%" icon={iconHistoric} className="button-bottom" onClick={handleGoToHistoric}>Histórico de consumo</Button>
        </S.Content>
      </S.Container>

    </>
  )
}